import { styled } from "styled-components";
import { Flex } from '@aws-amplify/ui-react';
import {
  MainTitle,
  SubTitle,
  FullLineSubTitle,
  CommonLink,
  Ul,
  Li,
  LeftText,
  LeftChat,
  RightChat,
  BoldText,
  Kugiri10000,
  Table,
  Th,
  Td
} from "../../../../common-my-ui-components";

import LearnGameFlow1 from '../../../../common-my-ui-components/img/introduction/LearnGameFlow1.png';
import LearnGameFlow2 from '../../../../common-my-ui-components/img/introduction/LearnGameFlow2.png';
import LearnGameFlow3 from '../../../../common-my-ui-components/img/introduction/LearnGameFlow3.png';
import LearnGameFlow4 from '../../../../common-my-ui-components/img/introduction/LearnGameFlow4.png';
import LearnGameFlow5 from '../../../../common-my-ui-components/img/introduction/LearnGameFlow5.png';
import LearnGameFlow6 from '../../../../common-my-ui-components/img/introduction/LearnGameFlow6.png';
import LearnGameFlow7 from '../../../../common-my-ui-components/img/introduction/LearnGameFlow7.png';
import teacherSmile from '../../../../common-my-ui-components/img/miniChar/teacherSmile.png';
import teacherPoint from '../../../../common-my-ui-components/img/miniChar/teacherPoint.png';
import teacherHappy from '../../../../common-my-ui-components/img/miniChar/teacherHappy.png';
import girlQuestion from '../../../../common-my-ui-components/img/miniChar/girlQuestion.png';
import girlSmile from '../../../../common-my-ui-components/img/miniChar/girlSmile.png';
import girlHappy from '../../../../common-my-ui-components/img/miniChar/girlHappy.png';

// ページ全体の設定
const Page = styled.div`
width: 100%; 
`;

const Contents = styled.div`
width: 100%;
padding: 1rem;
margin: 0 auto;
text-align: center;
`;

const LearnGameFlow = () => {
  return (
    <Page>
      <Contents>
        <MainTitle text={"ゲームの流れを知ろう！"} tabText={"麻雀の基本"} textAlign={"left"} />
        <LeftChat src={teacherPoint} alt="teacherPoint" text={(<>
          麻雀がどんなゲームかわかったと思いますが、それだけではゲームを始められません。<br />
          ゲームの開始から終了まで順を追って説明しますね！
        </>)} />

        <SubTitle text={"ゲーム準備"} textAlign={"left"} />
        <FullLineSubTitle text={"席決め"} />
        <LeftText text={<>
          まずは4人の座る席を決めます。<br />
          東南西北の牌を1枚ずつ裏にして混ぜます。<br />
          各プレイヤーが1枚ずつ牌を取り、東の牌を引いたプレイヤーが好きな場所(仮東)に座ります。<br />
          そのプレイヤーから反時計回りに南→西→北の順に座ります。
        </>} />
        <img src={LearnGameFlow1} style={{ width: '100%', maxWidth: '500px' }} alt="麻雀卓上つかみ取り説明図" />
        <RightChat src={girlHappy} alt="girlHappy" text={(<>くじ引きで席を決めるんだね！</>)} />
        <LeftChat src={teacherSmile} alt="teacherSmile" text={(<>
          ここでは簡単なつかみ取りという方法を解説しました。<br />
          他にも決め方はありますので気になる人は調べてみてください。
        </>)} />

        <FullLineSubTitle text={"親決め"} />
        <LeftText text={<>
          席決めで仮東になったプレイヤーがサイコロを2つ振ります。<br />
          その合計値を図のように反時計回りに数え、親が決まります。<br />
          親の席から反時計回りにそれぞれ<BoldText text={"東家(トンチャ)"} />・<BoldText text={"南家(ナンチャ)"} />・
          <BoldText text={"西家(シャーチャ)"} />・<BoldText text={"北家(ペーチャ)"} />といいます。<br />
          親以外のプレイヤーは子とよびます。
        </>} />
        <img src={LearnGameFlow2} style={{ width: '100%', maxWidth: '500px' }} alt="麻雀卓上サイコロ説明図" />

        <RightChat src={girlSmile} alt="girlSmile" text={(<>親はどんな役割なの？</>)} />
        <LeftChat src={teacherHappy} alt="teacherHappy" text={(<>
          麻雀は親からゲームがスタートします。<br />
          他にも和了の点数が約1.5倍になるなどメリットがありますよ。
        </>)} />

        <SubTitle text={"ゲーム開始"} textAlign={"left"} />
        <FullLineSubTitle text={"牌山・配牌"} />
        <LeftText text={<>
          手積みで麻雀をする場合、牌を<BoldText text={"17列2段"} />に積んで牌山を作ります。<br />
          親決めで行ったように、サイコロを振って配牌を始める位置を決めます。<br />
          サイコロの合計値に従って、指定された席のプレイヤーの前の牌山を右から数え区切ります。<br />
          配牌はその区切り位置から反時計回りに、東家の親から順に2列2段の合計4枚ずつ取っていきます。<br />
          これを全員が3回ずつ繰り返し、合計12枚にします。<br />
          最後に1枚ずつ取って、親がさらに第一ツモの牌を取ったところでゲームが開始となります。<br />
          <br />
          例えば、図のようにサイコロ合計値が7の場合は、西家の前にある牌山を7個で区切ります。<br />
          東家の親から順に赤の部分の2列2段の合計4枚ずつ牌を取っていきます。<br />
        </>} />
        <img src={LearnGameFlow3} style={{ width: '100%', maxWidth: '500px' }} alt="麻雀卓上配牌説明図" />
        <LeftText text={<>
          3回ずつ牌を取り手牌が12枚になったら、最後は図のように1枚ずつ取ります。<br />
        </>} />
        <img src={LearnGameFlow4} style={{ width: '100%', maxWidth: '500px' }} alt="最終ツモ説明図" />

        <LeftChat src={teacherPoint} alt="teacherPoint" text={(<>
          最後の1枚を取るときに親は第一ツモを一緒に「チョンチョン」と取ることがあります。
        </>)} />
        <RightChat src={girlSmile} alt="girlSmile" text={(<>どうせみんな1枚ずつ取ったあとにすぐツモるなら先に取っちゃったほうが楽なんだね！</>)} />


        <FullLineSubTitle text={"王牌・ドラ表示牌"} />
        <LeftText text={<>
          配牌を取るときに区切った場所から逆方向の7列14枚を<BoldText text={"王牌(ワンパイ)"} />といいます。<br />
          王牌はゲーム終了まで必ず14枚残しておき、ツモ牌として使用しません。<br />
          最後のツモ牌は王牌の横にある牌で、その牌を<BoldText text={"海底牌(ハイテイパイ)"} />といいます。<br />
          また、区切った場所から3列目の上段を<BoldText text={"ドラ表示牌"} />といい、その次の牌を<BoldText text={"ドラ"} />といいます。<br />
        </>} />
        <img src={LearnGameFlow5} style={{ width: '100%', maxWidth: '500px' }} alt="王牌説明図" />
        <LeftText text={<>
          ドラ表示牌とドラの関係は下図のようになっています。<br />
          数牌の場合、ドラ表示牌の次の数字がドラになり、1と9はつながっています。<br />
          字牌の場合、「東南西北」と「白発中」でそれぞれ一周します。
        </>} />
        <img src={LearnGameFlow6} style={{ width: '100%', maxWidth: '500px' }} alt="ドラ説明図" />

        <RightChat src={girlQuestion} alt="girlQuestion" text={(<>ドラってなに？</>)} />
        <LeftChat src={teacherPoint} alt="teacherPoint" text={(<>
          アガったときに持っているだけで点数が上がる特殊な牌のことです。<br />
          1ゲームごとにランダムに設定されますよ。<br />
          例えば1枚ドラを持っていると点数が2倍になることもあります。
        </>)} />

        <FullLineSubTitle text={"ツモ順"} />
        <LeftText text={<>
          配牌が完了したら親の東家から順に南家、西家、北家と反時計回りにツモって捨てるのを繰り返します。
        </>} />

        <RightChat src={girlQuestion} alt="girlQuestion" text={(<>
          1局始めるのに牌を混ぜて並べて積んで……<br />
          大変すぎるよー！
        </>)} />
        <LeftChat src={teacherSmile} alt="teacherSmile" text={(<>
          実は雀荘(麻雀ができるお店)には、全自動麻雀卓という自動で山を積んでくれる機械があります。<br />
          機種によっては配牌やドラ表示牌までめくってくれますよ。<br />
        </>)} />

        <SubTitle text={"ゲーム終了"} textAlign={"left"} />
        <FullLineSubTitle text={"ツモ・ロン・流局"} />
        <LeftText text={<>
          局は誰かがアガるか、誰もアガらず王牌以外のすべての牌をツモることで終了します。<br />
          アガり方には自分のツモ順でアガる<BoldText text={"ツモ"} />と、相手の捨てた牌でアガる<BoldText text={"ロン"} />の2種類あります。<br />
          ツモは他の3人で得点を分割して支払い、ロンは和了牌を捨てた人が全て支払うことになります。<br />
          誰もアガらず終了することを<BoldText text={"流局(リュウキョク)"} />といいます。<br />
        </>} />

        <FullLineSubTitle text={"ノーテン罰符"} />
        <LeftText text={<>
          流局時に聴牌していない人は、聴牌している人に点数を支払います。<br />
        </>} />
        <Table text={(<>
          <tr><Th text={"状態"} /><Th text={"点数"} /></tr>
          <tr><Td text={"1人聴牌"} /><Td text={"ノーテン者3人が聴牌者に1,000点ずつ支払う"} /></tr>
          <tr><Td text={"2人聴牌"} /><Td text={"ノーテン者は1,500点ずつ支払い、聴牌者は1,500点ずつ受け取る"} /></tr>
          <tr><Td text={"3人聴牌"} /><Td text={"ノーテン者1人が聴牌者に1,000点ずつ支払う"} /></tr>
          <tr><Td text={"全員聴牌・全員ノーテン"} /><Td text={"なにもしない"} /></tr>
        </>)} />
        <RightChat src={girlQuestion} alt="girlQuestion" text={(<>
          えー！私何もしてないのに点数ひかれちゃうの！？
        </>)} />
        <LeftChat src={teacherSmile} alt="teacherSmile" text={(<>
          そうですね、流局時にテンパイしていないと、点数を支払わなければならないんです。<br />
          ただ、聴牌していれば他のプレイヤーから点数をもらえるので、ゲーム終盤では聴牌を目指すことが大切ですよ！
        </>)} />

        <FullLineSubTitle text={"親の移動・連荘"} />
        <LeftText text={<>
          局が終わると、親が移動します。<br />
          南家だった人は親になり、親だった人は北家になります。<br />
          ただし、以下の条件で<BoldText text={"連荘(レンチャン)"} />となり、親は移動せずもう一度親になります。
          <Ul text={(
            <>
              <Li text={"親がアガったとき"} />
              <Li text={"流局時に親が聴牌していたとき"} />
            </>)} />
        </>} />
        <img src={LearnGameFlow7} style={{ width: '100%', maxWidth: '500px' }} alt="親の移動説明図" />

        <LeftChat src={teacherPoint} alt="teacherPoint" text={(<>
          親の回数に制限はありません。<br />
          大きく負けていても親が続く限り逆転のチャンスはありますよ。
        </>)} />
        <RightChat src={girlHappy} alt="girlHappy" text={(<>
          点数もアップするし、親のときは頑張っちゃうよー！
        </>)} />

        <FullLineSubTitle text={"積み場"} />
        <LeftText text={<>
          <BoldText text={"親の連荘"} />や<BoldText text={"流局"} />をした場合、<BoldText text={"積み場(ツミバ)"} />が増えていきます。<br />
          積み場が1つ増えると1本場、2つ増えると2本場とよびます。<br />
          積み場が1つにつき、和了点数が300点ずつ加算されます。
        </>} />
        <LeftChat src={teacherPoint} alt="teacherPoint" text={(<>
          親が連荘するたびボーナスが加算されるイメージです。<br />
          子がアガったらリセットされます。
        </>)} />
        <RightChat src={girlSmile} alt="girlSmile" text={(<>
          連荘が続くと緊張しちゃうね！<br />
          子のときは親の連荘を止めるのも大事なんだ。
        </>)} />

        <FullLineSubTitle text={"ゲームの回数"} />
        <LeftText text={<>
          全員が親を1回または2回やるとゲームが終了します。<br />
          1週目を<BoldText text={"東場(トンバ)"} />、2週目を<BoldText text={"南場(ナンバ)"} />といいます。<br />
          東場で終わるゲームを<BoldText text={"東風戦(トンプウセン)"} />、
          東場と南場で終わるゲームを<BoldText text={"半荘戦(ハンチャンセン)"} />や<BoldText text={"東南戦(トンナンセン)"} />といいます。
        </>} />

        <br />
        <Kugiri10000 />
        <br />

        <RightChat src={girlQuestion} alt="girlQuestion" text={(<>
          むむむ……<br />
          やることが多いけど、なんとなく全体の流れがわかったような気がする！
        </>)} />
        <LeftChat src={teacherSmile} alt="teacherSmile" text={(<>
          良かったです。<br />
          ゲームの流れを理解することは、麻雀を楽しむための第一歩です。<br />
          次は和了についてもう少し詳しく説明しますね。
        </>)} />

        <Flex direction={"column"} alignItems={'center'}>
          <Ul text={(
            <>
              <Li text={<CommonLink to="/RuleIntroduction/MahjongIntroduction#start" text={"麻雀ルールTOP"} />} />
              <Li text={<CommonLink to="/RuleIntroduction/LetsWin#start" text={"次ページ 和了を目指そう！"} />} />
              <Li text={<CommonLink to="/RuleIntroduction/RememberBasic#start" text={"前ページ まずはこれを覚えよう！"} />} />
            </>)} />
        </Flex>
      </Contents>
    </Page>
  );
};

export default LearnGameFlow;
