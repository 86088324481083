// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { RecruitmentHistoryM, UserTalkRoomM, MessageM, NotificationTokenM, RecruitmentListM, CategoryM, UserM, TalkRoomM, ContactM, FeedbackM, MatchingReserveM, MatchingM, BlockedUsersM } = initSchema(schema);

export {
  RecruitmentHistoryM,
  UserTalkRoomM,
  MessageM,
  NotificationTokenM,
  RecruitmentListM,
  CategoryM,
  UserM,
  TalkRoomM,
  ContactM,
  FeedbackM,
  MatchingReserveM,
  MatchingM,
  BlockedUsersM
};