import React, { useState } from "react"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Rating from "@mui/material/Rating"

function FeedbackModal({ open, handleClose, handleSubmit }) {
  const [rating, setRating] = useState(0)
  const [content, setContent] = useState("")

  const handleRatingChange = (event, newValue) => {
    setRating(newValue)
  }

  const handleFeedbackChange = (event) => {
    setContent(event.target.value)
  }

  const handleFormSubmit = () => {
    handleSubmit({ rating, content })
    handleClose() // モーダルを閉じる
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>雀の巣の満足度を教えて下さい！</DialogTitle>
      <DialogContent>
        <Rating name="simple-controlled" value={rating} onChange={handleRatingChange} />
        <TextField
          autoFocus
          margin="dense"
          id="content"
          label="要望・感想など"
          type="text"
          fullWidth
          variant="outlined"
          multiline
          rows={4}
          value={content}
          onChange={handleFeedbackChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          キャンセル
        </Button>
        <Button onClick={handleFormSubmit} color="primary">
          送信
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FeedbackModal
