import { styled } from "styled-components";
import {
  MainGradationTitle,
  MainTitle,
  SubTitle,
  LineSubTitle,
  CommonLink,
  Ul,
  Li,
  LeftText,
  LeftChat,
  RightChat
} from "../../../../common-my-ui-components"
import { Grid, Flex, View, useBreakpointValue } from '@aws-amplify/ui-react';
import PcTopImg from '../../../../common-my-ui-components/img/Top/PC_TopImg.png';
import SpTopImg from '../../../../common-my-ui-components/img/Top/SP_TopImg.png';
import teacherSmile from '../../../../common-my-ui-components/img/miniChar/teacherSmile.png';
import girlSmile from '../../../../common-my-ui-components/img/miniChar/girlSmile.png';

// ページ全体の設定
const Page = styled.div`
width: 100%; 
`;

const Contents = styled.div`
width: 100%;
padding: 1rem;
`

const TopImage = styled.div`
text-align: center;
position: relative;
${(props) => (props.variation === 'base' ? '' : 'max-width: 1920px;')}
margin: auto;
& topText{
  text-align: center;
  background-color: ${(props) => (props.theme.palette.text.white)};
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  margin: auto;
  padding:1rem 0.5rem;
  width: ${(props) => (props.variation === 'base' ? '20rem' : '30rem')};
  border-radius: 25px;
  box-shadow: 0px 0px 0px 5px ${(props) => (props.theme.palette.text.white)};
  border: dashed 1px ${(props) => (props.theme.palette.primary.main)};
  }
`;

const MahjongIntroduction = () => {
  // 画面サイズを取得
  const variation = useBreakpointValue({
    base: 'base',
    large: 'large',
  });
  return (
    <Page>
      <TopImage variation={variation}>
        <img src={variation === 'base' ? SpTopImg : PcTopImg} style={{ width: '100%' }} alt="トップページ" />
        <topText variation={variation}>
          <MainGradationTitle text={"麻雀ってどんなゲーム？"} />
        </topText>
      </TopImage>
      <Contents>
        <MainTitle text={"麻雀ってどんなゲーム？"} tabText={"はじめに"} textAlign={"left"} />
        <LeftText text={(<>
          麻雀は、特定の絵柄の組み合わせをいち早く完成させることを目指すシンプルな絵合わせゲームです。<br />
          このページでは、初心者の方にもわかりやすく麻雀の基本ルールを詳しく解説しています。<br />
          <br />

          麻雀はシンプルながらも、戦略性と運が絡み合う魅力的なゲームです。<br />
          初心者から上級者まで、麻雀のルールやコツを学んで楽しみながら、その奥深さをぜひ味わってください！<br />
        </>)} />
        <LeftChat src={teacherSmile} alt="teacherSmile" text={(<>皆さんの学習をサポートします</>)} />
        <RightChat src={girlSmile} alt="girlSmile" text={(<>よろしくお願いします！</>)} />
        <SubTitle text={"麻雀ルール解説 目次"} textAlign={"left"} />
        {/** <Grid templateColumns={{ base: '1fr', large: '1fr 1fr' }}>*/}
        <Grid templateColumns={{ base: '1fr' }}>
          <View rowSpan={1}>
            <LineSubTitle text={"はじめての麻雀"} width={"20rem"} />
            <Flex direction={"column"} alignItems={'center'}>
              <Ul text={(
                <>
                  <Li text={<CommonLink to="/RuleIntroduction/WhatIsMahjong#start" text={"麻雀ってなに？"} />} />
                  <Li text={<CommonLink to="/RuleIntroduction/RememberBasic#start" text={"まずはこれを覚えよう！"} />} />
                </>)} />
            </Flex>

            <LineSubTitle text={"麻雀の基本"} width={"20rem"} />
            <Flex direction={"column"} alignItems={'center'}>
              <Ul text={(
                <>
                  <Li text={<CommonLink to="/RuleIntroduction/LearnGameFlow#start" text={"ゲームの流れを知ろう！"} />} />
                  <Li text={<CommonLink to="/RuleIntroduction/LetsWin#start" text={"和了を目指そう！"} />} />
                  <Li text={<CommonLink to="/RuleIntroduction/LetsReach#start" text={"リーチをしよう！"} />} />
                  <Li text={<CommonLink to="/RuleIntroduction/LetsCall#start" text={"鳴いてみよう！"} />} />
                  <Li text={<CommonLink to="/RuleIntroduction/LearnScore#start" text={"点数を知ろう！"} />} />
                </>)} />
            </Flex>

            <LineSubTitle text={"麻雀の役"} width={"20rem"} />
            <Flex direction={"column"} alignItems={'center'}>
              <Ul text={(
                <>
                  <Li text={<CommonLink to="/YakuList#start" text={"麻雀の役一覧"} />} />
                </>)} />
            </Flex>
          </View>
          {/**
          <View rowSpan={1}>
            <LineSubTitle text={"点数計算のやり方"} width={"20rem"} />
            <Flex direction={"column"} alignItems={'center'}>
              <Ul text={(
                <>
                  <Li text={"点数計算のやり方を書く"} />
                  <Li text={"例題とか？点数計算機とか？"} />
                </>)} />
            </Flex>

            <LineSubTitle text={"やっていはいけないこと"} width={"20rem"} />
            <Flex direction={"column"} alignItems={'center'}>
              <Ul text={(
                <>
                  <Li text={"チョンボってなに？"} />
                  <Li text={"パオってなに？"} />
                  <Li text={"麻雀のマナーを知ろう！"} />
                </>)} />
            </Flex>

            <LineSubTitle text={"麻雀用語集"} width={"20rem"} />
            <Flex direction={"column"} alignItems={'center'}>
              <Ul text={(
                <>
                  <Li text={"びっしりと解説するページを書く"} />
                </>)} />
            </Flex>
          </View>
           */}
        </Grid>
      </Contents>
    </Page>
  );
};

export default MahjongIntroduction;
