import '@aws-amplify/ui-react/styles.css';
import '../../App.css';
import { useState, useEffect } from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';
import { TermsContent, Over18 } from '../../common-my-ui-components';
import { ProfileForm } from '../ProfileEdit/my-ui-components';
import { Flex } from '@aws-amplify/ui-react';
import { Button } from '@mui/material';
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogContent, DialogTitle, CircularProgress } from '@mui/material';

const InitialSettingPage = styled.div`
padding: 2rem 0;
text-align: center;
margin: 0 auto;
`;

const Content = styled.div`
padding: 2rem;
text-align: center;
margin: 0 auto;
display: inline-block;
`;

const Center = styled.table`
margin: auto; /* サークルを中央に配置 */
`;

const InitialSetting = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);

  // 戻るボタンと進むボタンを管理
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  /* ------------------------------------------ */

  // 利用規約についてコンポーネント作成
  const [isAgreeTerms, setIsAgreeTerms] = useState(false);
  // 同意ボタン押下の操作
  const agreeTerms = () => {
    setIsAgreeTerms(true);
    handleNext();
  };

  const Step1 = () => (
    <Content>
      <TermsContent />
      <Flex direction={"row"} alignItems={'center'} justifyContent="center">
        <Button variant="contained" color="primary" onClick={agreeTerms} >同意する</Button>
      </Flex>
    </Content>
  );
  /* ------------------------------------------ */

  // 年齢確認についてコンポーネント作成
  const [isOver18, setIsOver18] = useState(false);

  // 年齢確認押下の操作
  const handleOver18 = (e) => {
    setIsOver18(e.target.name === "isOver18");
    handleNext();
  };

  const Step2 = () => (
    <Content>
      <Over18 handleOver18={handleOver18} />
      <br />
      <Button onClick={handleBack}>
        1つ前に戻る
      </Button>
    </Content>
  );
  /* ------------------------------------------ */

  // 完了コンポーネント作成
  const StepFinish = () => {
    const reload = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000))
      navigate("/");
      window.location.reload();
    }
    useEffect(() => {
      reload();
    }, []);

    return (
      <div>
        <Dialog open={true}>
          <DialogTitle>処理中</DialogTitle>
          <DialogContent>
            <Center>
              <CircularProgress />
            </Center>
          </DialogContent>
        </Dialog>
      </div>
    );
  };
  /* ------------------------------------------ */

  return (
    <InitialSettingPage>
      <Stepper alternativeLabel activeStep={activeStep}>
        <Step>
          <StepLabel>利用規約</StepLabel>
        </Step>
        <Step>
          <StepLabel>年齢確認</StepLabel>
        </Step>
        <Step>
          <StepLabel>プロフィール設定</StepLabel>
        </Step>
      </Stepper>
      <div>
        {/* プロフィールフォームはstepコンポーネントで定義すると動作が不安定なため以下で定義している */}
        {activeStep === 0 && <Step1 />}
        {activeStep === 1 && <Step2 />}
        {activeStep === 2 && <>
          <ProfileForm
            onFormSubmit={handleNext}
            isAgreeTerms={isAgreeTerms}
            isOver18={isOver18} />
          <br />
          <Button onClick={handleBack}>
            1つ前に戻る
          </Button></>}
        {activeStep === 3 && <StepFinish />}
      </div>
    </InitialSettingPage>
  );
};

export default InitialSetting;