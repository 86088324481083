import { Link } from "react-router-dom";
import { styled } from "styled-components";
import kugiri from '../common-my-ui-components/img/bones/10000.png';

const MainGradationTitleStyled = styled.h1`
  font-family: 'BIZ UDPGothic', sans-serif;
  margin: 1rem 0 0.5rem;
  display: inline-block;
  background: linear-gradient( 120deg, ${(props) => (props.theme.palette.secondary.main)},
  ${(props) => (props.theme.palette.secondaryred.main)});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const MainGradationTitle = (props) => {
  return (
    <MainGradationTitleStyled>
      {props.text}
    </MainGradationTitleStyled>
  );
};

const MainTitleStyled = styled.h1`
font-family: 'BIZ UDPGothic', sans-serif;
text-align: ${props => props.textAlign || 'left'};
position: relative;
border-top: solid 3px ${(props) => (props.theme.palette.primary.main)};
border-bottom: solid 3px ${(props) => (props.theme.palette.primary.main)};
background: ${(props) => (props.theme.palette.text.white)};
line-height: 1.4;
padding: 0.4em 0.5em;
margin: 1rem 0 0.5rem;
  &::after {
    /*タブ*/
    position: absolute;
    font-family: "Font Awesome 5 Free",'Quicksand','Avenir','Arial',sans-serif;
    font-weight: 900;
    content: ${props => "'" + props.tabText + "'"};
    background: ${(props) => (props.theme.palette.primary.main)};
    color: ${(props) => (props.theme.palette.text.white)};
    left: 0px;
    bottom: 100%;
    border-radius: 5px 5px 0 0;
    padding: 5px 7px 3px;
    font-size: 0.5em;
    line-height: 1;
    letter-spacing: 0.05em;
  }
`;
const MainTitle = (props) => {
  return (
    /* 
      テキストの位置
      タブテキスト
    */
    <MainTitleStyled textAlign={props.textAlign} tabText={props.tabText}>
      {props.text}
    </MainTitleStyled>
  );
};

const SubTitleStyled = styled.h2`
  font-family: 'BIZ UDPGothic', sans-serif;
  margin: 1rem 0 0.5rem;
  text-align: ${props => props.textAlign || 'left'};
  font-weight: bold;
  padding: 0.4rem 0.5rem;
  background: ${(props) => (props.theme.palette.text.white)};
  border-left: solid 5px ${(props) => (props.theme.palette.primary.main)};
  border-bottom: solid 3px ${(props) => (props.theme.palette.quaternary.main)};
`;
const SubTitle = (props) => {
  return (
    /* 
      テキストの位置
    */
    <SubTitleStyled textAlign={props.textAlign}>
      {props.text}
    </SubTitleStyled>
  );
};

const FullLineSubTitleStyled = styled.h3`
width:100%;
line-height: 160%;
text-align: ${props => props.textAlign || 'left'};
margin: 1rem 0 0.5rem;
position: relative;
padding: 0.25em 0;
font-family: 'BIZ UDPGothic', sans-serif;
&::after {
  content: "";
  display: block;
  height: 4px;
  background: linear-gradient(90deg, ${(props) => (props.theme.palette.text.green)},
  ${(props) => (props.theme.palette.quinary.main)});
}
`;
const FullLineSubTitle = (props) => {
  return (
    /* 
      テキストの位置
    */
    <FullLineSubTitleStyled textAlign={props.textAlign}>
      {props.text}
    </FullLineSubTitleStyled>
  );
};

const LineSubTitleStyled = styled.h4`
  font-family: 'BIZ UDPGothic', sans-serif;
  border-bottom: 3px solid ${(props) => (props.theme.palette.primary.main)};
  display: inline-block;
  line-height: 2;
  width: ${props => props.width || '15rem'};
  margin: 1rem 0 0.5rem;
`;
const LineSubTitle = (props) => {
  return (
    /* 
      線の幅
    */
    <LineSubTitleStyled width={props.width}>
      {props.text}
    </LineSubTitleStyled>
  );
};

const LinkStyled = styled(Link)`
textDecoration: 'underline';
color: ${(props) => (props.theme.palette.text.link)};
`;
const CommonLink = (props) => {
  return (
    /* 
      to 遷移先URL
    */
    <LinkStyled to={props.to}>
      {props.text}
    </LinkStyled>
  );
};

const UlStyled = styled.ul`
width: 20rem;
line-height: 1.5;
padding-left:0;
`;
const Ul = (props) => {
  return (
    <UlStyled>
      {props.text}
    </UlStyled>
  );
};

const LiStyled = styled.li`
text-align: left;
list-style:none;
position: relative;
padding-left: 15px;
&::before {
  content: "";
  position: absolute;
  top: .45em;
  left: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 5px;
  height: 5px;
  border-top: 2px solid ${(props) => (props.theme.palette.primary.main)};
  border-right: 2px solid ${(props) => (props.theme.palette.primary.main)};
}
`;
const Li = (props) => {
  return (
    <LiStyled>
      {props.text}
    </LiStyled>
  );
};

const LeftTextStyled = styled.p`
text-align: left;
line-height:1.8rem;
letter-spacing:0.05rem;
`;
const LeftText = (props) => {
  return (
    <LeftTextStyled>
      {props.text}
    </LeftTextStyled>
  );
};

const ChatBalloonContainer = styled.div`
  width: 100%;
  margin: 1.5em 0;
  overflow: hidden;
`;

const LeftFaceIcon = styled.div`
  float: left;
  margin-right: -200px;
  width: 80px;

  img {
    width: 100%;
    height: auto;
    border: solid 3px ${(props) => (props.theme.palette.quinaryblue.main)};
    border-radius: 50%;
  }
`;

const RightFaceIcon = styled.div`
  float: right;
  margin-left: -200px;
  width: 80px;

  img {
    width: 100%;
    height: auto;
    border: solid 3px ${(props) => (props.theme.palette.quinaryred.main)};
    border-radius: 50%;
  }
`;

const ChatChattingContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const LeftChatSays = styled.div`
  display: inline-block;
  position: relative;
  margin: 15px 0 0 100px;
  padding: 17px 13px;
  border-radius: 12px;
  background: ${(props) => (props.theme.palette.quinaryblue.main)};

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 15px;
    left: -24px;
    border: 12px solid transparent;
    border-right: 12px solid ${(props) => (props.theme.palette.quinaryblue.main)};
  }

  p {
    margin: 0;
    padding: 0;
  }
`;

const RightSays = styled.div`
  float: right;
  display: inline-block;
  position: relative;
  margin: 15px 100px 0 0;
  padding: 17px 13px;
  border-radius: 12px;
  background: ${(props) => (props.theme.palette.quinaryred.main)};

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 15px;
    right: -24px;
    border: 12px solid transparent;
    border-left: 12px solid ${(props) => (props.theme.palette.quinaryred.main)};
  }

  p {
    margin: 0;
    padding: 0;
  }
`;
const LeftChat = (prop) => {
  return (
    <ChatBalloonContainer>
      <LeftFaceIcon>
        <img src={prop.src} alt={prop.alt} />
      </LeftFaceIcon>
      <ChatChattingContainer>
        <LeftChatSays>
          <p>{prop.text}</p>
        </LeftChatSays>
      </ChatChattingContainer>
    </ChatBalloonContainer>
  );
};

const RightChat = (prop) => {
  return (
    <ChatBalloonContainer>
      <RightFaceIcon>
        <img src={prop.src} alt={prop.alt} />
      </RightFaceIcon>
      <ChatChattingContainer>
        <RightSays>
          <p>{prop.text}</p>
        </RightSays>
      </ChatChattingContainer>
    </ChatBalloonContainer>
  );
};

const BoldTextStyled = styled.span`
font-weight:bold;
line-height:1.8rem;
letter-spacing:0.05rem;
`;
const BoldText = (props) => {
  return (
    <BoldTextStyled>
      {props.text}
    </BoldTextStyled>
  );
};

const Kugiri10000Styled = styled.img`
max-height:30px;
max-width:50%;
margin: 2rem auto;
display: block;
`;
const Kugiri10000 = () => {
  return (
    <Kugiri10000Styled src={kugiri} alt="区切り10000点棒" />
  );
};

const TableStyled = styled.table`
width: 100%;
text-align: center;
border-collapse: collapse;
border-spacing: 0;
table-layout: fixed;
`;
const Table = (props) => {
  return (
    <TableStyled >{props.text}</TableStyled>
  );
};

const ThStyled = styled.th`
font-family: 'BIZ UDPGothic', sans-serif;
padding: 10px;
border-bottom: solid 4px ${(props) => (props.theme.palette.primary.main)};
`;
const Th = (props) => {
  return (
    <ThStyled >{props.text}</ThStyled>
  );
};

const TdStyled = styled.td`
padding: 10px;
border-bottom: solid 1px ${(props) => (props.theme.palette.primary.main)};
`;
const Td = (props) => {
  return (
    <TdStyled >{props.text}</TdStyled>
  );
};

export {
  MainGradationTitle,
  MainTitle,
  SubTitle,
  FullLineSubTitle,
  LineSubTitle,
  CommonLink,
  Ul,
  Li,
  LeftText,
  LeftChat,
  RightChat,
  BoldText,
  Kugiri10000,
  Table,
  Th,
  Td
};
