import { Link } from "react-router-dom";
import { Flex } from "@aws-amplify/ui-react";
import { styled } from "styled-components";
import { FOOTER_MENU_HEIGHT } from "../constants.js";
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useRef, useEffect, useState } from "react";
import { deleteDataFromIndexedDB, openIndexedDB, saveToIndexedDB, fetchFromIndexedDB, } from "../my-func/indexedDBUtils";
import UserContext from "../UserContext";
import { useBreakpointValue } from '@aws-amplify/ui-react';

// 左側広告用スタイル
const LeftAdContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 2rem;
  transform: translateY(-50%);
`;

// 右側広告用スタイル
const RightAdContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
`;

// スマホ向け広告用スタイル
const SpAdContainer = styled.div`
margin: 0.5rem;
`;

/**
 * まだ未発展のコンポーネントのため注意が必要です
 * PC用広告は幅120pxまでを使用してください
 * 現状の66%の横幅設定だと正常に表示されないことがあるためです
 * 
 * 携帯用の広告は
 */
const CommonAdSense = ({ position }) => {

  // 画面サイズを取得
  const variation = useBreakpointValue({
    base: 'base',
    large: 'large',
  });

  const pcAdList = [
    <>
      <a href="https://px.a8.net/svt/ejp?a8mat=3Z79B1+CDCA1M+2PEO+1I7QCH" rel="nofollow">
        <img border="0" width="120" height="600" alt="" src="https://www25.a8.net/svt/bgt?aid=240523741748&wid=002&eno=01&mid=s00000012624009106000&mc=1" /></a>
      <img border="0" width="1" height="1" src="https://www16.a8.net/0.gif?a8mat=3Z79B1+CDCA1M+2PEO+1I7QCH" alt="" />
    </>
    ,
    <>
      <a href="https://px.a8.net/svt/ejp?a8mat=3YYH7F+F0LMU2+1WP2+5YRHD" rel="nofollow">
        <img border="0" width="120" height="600" alt="" src="https://www28.a8.net/svt/bgt?aid=240114075908&wid=002&eno=01&mid=s00000008903001002000&mc=1" /></a>
      <img border="0" width="1" height="1" src="https://www17.a8.net/0.gif?a8mat=3YYH7F+F0LMU2+1WP2+5YRHD" alt=""></img>
    </>
    ,
    <>
      <a href="https://px.a8.net/svt/ejp?a8mat=3YYH7F+F1SI1M+50+2HGLCX" rel="nofollow">
        <img border="0" width="120" height="600" alt="" src="https://www24.a8.net/svt/bgt?aid=240114075910&wid=002&eno=01&mid=s00000000018015026000&mc=1" /></a>
      <img border="0" width="1" height="1" src="https://www18.a8.net/0.gif?a8mat=3YYH7F+F1SI1M+50+2HGLCX" alt=""></img>
    </>
    ,
    <>
      <a href="https://rpx.a8.net/svt/ejp?a8mat=3YYH7F+F172FU+2HOM+61JSH&rakuten=y&a8ejpredirect=http%3A%2F%2Fhb.afl.rakuten.co.jp%2Fhgc%2F0ea62065.34400275.0ea62066.204f04c0%2Fa24011486666_3YYH7F_F172FU_2HOM_61JSH%3Fpc%3Dhttp%253A%252F%252Fwww.rakuten.co.jp%252F%26m%3Dhttp%253A%252F%252Fm.rakuten.co.jp%252F" rel="nofollow" >
        <img src="http://hbb.afl.rakuten.co.jp/hsb/0eb4bbd6.e4f5b3a4.0eb4bbaa.95151395/" border="0" /></a >
      <img border="0" width="1" height="1" src="https://www11.a8.net/0.gif?a8mat=3YYH7F+F172FU+2HOM+61JSH" alt=""></img>
    </>
    ,
    <>
      <a href="https://rpx.a8.net/svt/ejp?a8mat=3YYH7F+F172FU+2HOM+65ME9&rakuten=y&a8ejpredirect=http%3A%2F%2Fhb.afl.rakuten.co.jp%2Fhgc%2F0ea62065.34400275.0ea62066.204f04c0%2Fa24011486666_3YYH7F_F172FU_2HOM_65ME9%3Fpc%3Dhttp%253A%252F%252Fwww.rakuten.co.jp%252F%26m%3Dhttp%253A%252F%252Fm.rakuten.co.jp%252F" rel="nofollow">
        <img src="http://hbb.afl.rakuten.co.jp/hsb/0ec09ba5.4d3660f9.0eb4bbaa.95151395/" border="0" /></a>
      <img border="0" width="1" height="1" src="https://www10.a8.net/0.gif?a8mat=3YYH7F+F172FU+2HOM+65ME9" alt=""></img>
    </>
    ,
    <>
      <a href="https://px.a8.net/svt/ejp?a8mat=3Z7A3C+36B9P6+1MWA+2ZFGE9" rel="nofollow">
        <img border="0" width="120" height="600" alt="" src="https://www28.a8.net/svt/bgt?aid=240524760192&wid=002&eno=01&mid=s00000007633018044000&mc=1" /></a>
      <img border="0" width="1" height="1" src="https://www16.a8.net/0.gif?a8mat=3Z7A3C+36B9P6+1MWA+2ZFGE9" alt=""></img>
    </>
  ]

  const spAdList = [
    <>
      <a href="https://px.a8.net/svt/ejp?a8mat=3Z79B1+CDCA1M+2PEO+1HVQ8X" rel="nofollow">
        <img border="0" width="234" height="60" alt="" src="https://www28.a8.net/svt/bgt?aid=240523741748&wid=002&eno=01&mid=s00000012624009050000&mc=1" /></a>
      <img border="0" width="1" height="1" src="https://www10.a8.net/0.gif?a8mat=3Z79B1+CDCA1M+2PEO+1HVQ8X" alt=""></img>
    </>
    ,
    <>
      <a href="https://px.a8.net/svt/ejp?a8mat=3Z79B1+CDCA1M+2PEO+1I5SWH" rel="nofollow">
        <img border="0" width="234" height="60" alt="" src="https://www25.a8.net/svt/bgt?aid=240523741748&wid=002&eno=01&mid=s00000012624009097000&mc=1" /></a>
      <img border="0" width="1" height="1" src="https://www18.a8.net/0.gif?a8mat=3Z79B1+CDCA1M+2PEO+1I5SWH" alt=""></img>
    </>
    ,
    <>
      <a href="https://px.a8.net/svt/ejp?a8mat=3Z79B1+A5K6ZE+5CCQ+60WN5" rel="nofollow">
        <img border="0" width="100" height="60" alt="" src="https://www25.a8.net/svt/bgt?aid=240523741614&wid=002&eno=01&mid=s00000024929001012000&mc=1" /></a>
      <img border="0" width="1" height="1" src="https://www17.a8.net/0.gif?a8mat=3Z79B1+A5K6ZE+5CCQ+60WN5" alt=""></img>
    </>
    ,
    <>
      <a href="https://px.a8.net/svt/ejp?a8mat=3YYH7F+F2DXNE+5244+614CX" rel="nofollow">
        <img border="0" width="320" height="50" alt="" src="https://www27.a8.net/svt/bgt?aid=240114075911&wid=002&eno=01&mid=s00000023602001013000&mc=1" /></a>
      <img border="0" width="1" height="1" src="https://www15.a8.net/0.gif?a8mat=3YYH7F+F2DXNE+5244+614CX" alt=""></img>
    </>
    ,
    <>
      <a href="https://rpx.a8.net/svt/ejp?a8mat=3YYH7F+F172FU+2HOM+6O1Z5&rakuten=y&a8ejpredirect=http%3A%2F%2Fhb.afl.rakuten.co.jp%2Fhsc%2F0ea81083.052227c6.0ea81082.5814e1a7%2Fa24011486666_3YYH7F_F172FU_2HOM_6O1Z5" rel="nofollow">
        <img src="http://hbb.afl.rakuten.co.jp/hsb/0ea8108d.e9282def.0ea81082.5814e1a7/" border="0" /></a>
      <img border="0" width="1" height="1" src="https://www16.a8.net/0.gif?a8mat=3YYH7F+F172FU+2HOM+6O1Z5" alt=""></img>
    </>
    ,
    <>
      <a href="https://rpx.a8.net/svt/ejp?a8mat=3YYH7F+F172FU+2HOM+64Z8X&rakuten=y&a8ejpredirect=http%3A%2F%2Fhb.afl.rakuten.co.jp%2Fhgc%2F0ea62065.34400275.0ea62066.204f04c0%2Fa24011486666_3YYH7F_F172FU_2HOM_64Z8X%3Fpc%3Dhttp%253A%252F%252Fwww.rakuten.co.jp%252F%26m%3Dhttp%253A%252F%252Fm.rakuten.co.jp%252F" rel="nofollow">
        <img src="http://hbb.afl.rakuten.co.jp/hsb/0ec09ba2.ae233841.0eb4bbaa.95151395/" border="0" /></a>
      <img border="0" width="1" height="1" src="https://www12.a8.net/0.gif?a8mat=3YYH7F+F172FU+2HOM+64Z8X" alt=""></img>
    </>
    ,
    <>
      <a href="https://rpx.a8.net/svt/ejp?a8mat=3YYH7F+F172FU+2HOM+68EPD&rakuten=y&a8ejpredirect=http%3A%2F%2Fhb.afl.rakuten.co.jp%2Fhgc%2F0ea62065.34400275.0ea62066.204f04c0%2Fa24011486666_3YYH7F_F172FU_2HOM_68EPD%3Fpc%3Dhttp%253A%252F%252Fwww.rakuten.co.jp%252F%26m%3Dhttp%253A%252F%252Fm.rakuten.co.jp%252F" rel="nofollow">
        <img src="http://hbb.afl.rakuten.co.jp/hsb/0eb4bbc1.9717e3a2.0eb4bbaa.95151395/" border="0" /></a>
      <img border="0" width="1" height="1" src="https://www10.a8.net/0.gif?a8mat=3YYH7F+F172FU+2HOM+68EPD" alt=""></img>
    </>
    ,
    <>
      <a href="https://px.a8.net/svt/ejp?a8mat=3YYH7F+F0LMU2+1WP2+661TT" rel="nofollow">
        <img border="0" width="234" height="60" alt="" src="https://www25.a8.net/svt/bgt?aid=240114075908&wid=002&eno=01&mid=s00000008903001036000&mc=1" /></a>
      <img border="0" width="1" height="1" src="https://www16.a8.net/0.gif?a8mat=3YYH7F+F0LMU2+1WP2+661TT" alt=""></img>
    </>
    ,
    <>
      <a href="https://px.a8.net/svt/ejp?a8mat=3YYH7F+F1SI1M+50+2HU3GX" rel="nofollow">
        <img border="0" width="234" height="60" alt="" src="https://www22.a8.net/svt/bgt?aid=240114075910&wid=002&eno=01&mid=s00000000018015089000&mc=1" /></a>
      <img border="0" width="1" height="1" src="https://www15.a8.net/0.gif?a8mat=3YYH7F+F1SI1M+50+2HU3GX" alt=""></img>
    </>
    ,
    <>
      <a href="https://px.a8.net/svt/ejp?a8mat=3Z7AV6+47857E+4EKC+5ZEMP" rel="nofollow">
        <img border="0" width="320" height="50" alt="" src="https://www23.a8.net/svt/bgt?aid=240525762254&wid=002&eno=01&mid=s00000020550001005000&mc=1" /></a>
      <img border="0" width="1" height="1" src="https://www18.a8.net/0.gif?a8mat=3Z7AV6+47857E+4EKC+5ZEMP" alt=""></img>
    </>
    ,
    <>
      <a href="https://px.a8.net/svt/ejp?a8mat=3Z7AV6+4619ZU+6HW+3T0HG1" rel="nofollow">
        <img border="0" width="234" height="60" alt="" src="https://www20.a8.net/svt/bgt?aid=240525762252&wid=002&eno=01&mid=s00000000842023013000&mc=1" /></a>
      <img border="0" width="1" height="1" src="https://www13.a8.net/0.gif?a8mat=3Z7AV6+4619ZU+6HW+3T0HG1" alt=""></img>
    </>
    ,
    <>
      <a href="https://px.a8.net/svt/ejp?a8mat=3Z7AV6+448Z6I+42Y0+62MDD" rel="nofollow">
        <img border="0" width="320" height="50" alt="" src="https://www29.a8.net/svt/bgt?aid=240525762249&wid=002&eno=01&mid=s00000019044001020000&mc=1" /></a>
      <img border="0" width="1" height="1" src="https://www11.a8.net/0.gif?a8mat=3Z7AV6+448Z6I+42Y0+62MDD" alt=""></img>
    </>
    ,
    <>
      <a href="https://px.a8.net/svt/ejp?a8mat=3Z7A3C+36B9P6+1MWA+2ZC0XT" rel="nofollow">
        <img border="0" width="320" height="50" alt="" src="https://www23.a8.net/svt/bgt?aid=240524760192&wid=002&eno=01&mid=s00000007633018028000&mc=1" /></a>
      <img border="0" width="1" height="1" src="https://www11.a8.net/0.gif?a8mat=3Z7A3C+36B9P6+1MWA+2ZC0XT" alt=""></img>
    </>

  ]

  // ランダムなインデックスを生成
  const randomLeftIndex = Math.floor(Math.random() * pcAdList.length);
  const randomRightIndex = Math.floor(Math.random() * pcAdList.length);
  const randomSpHeadIndex = Math.floor(Math.random() * spAdList.length);
  const randomSpFootIndex = Math.floor(Math.random() * spAdList.length);

  if (variation == 'base' && position === 'left') {
    return (
      <SpAdContainer>
        {spAdList[randomSpHeadIndex]}
      </SpAdContainer>
    )
  } else if (variation == 'base' && position === 'right') {
    return (
      <SpAdContainer>
        {spAdList[randomSpFootIndex]}
      </SpAdContainer>
    )
  } else if (position === 'left') {
    return (
      <LeftAdContainer>
        {pcAdList[randomLeftIndex]}
      </LeftAdContainer>
    );
  } else if (position === 'right') {
    return (
      <RightAdContainer>
        {pcAdList[randomRightIndex]}
      </RightAdContainer>
    );
  }


};

export default CommonAdSense;
