import { API, graphqlOperation } from "aws-amplify";
import { listUserTalkRoomMS ,listUserTalkRoomMByTalkroommID, getUserTalkRoomM} from "../graphql/queries";
import { updateUserTalkRoomM } from "../graphql/mutations";

const getUserTalkRoomMS = async (userId, talkRoomID) => {
    let nextToken = null;
    let unDeletedTalkRoom = null;

    while (unDeletedTalkRoom === null) {
        const opt1 = {
            talkroommID:talkRoomID,
            filter: { usermID: { eq: userId }, },
            limit: 100,
            nextToken,
        };
      
        const result = await API.graphql({
            query: listUserTalkRoomMByTalkroommID,
            variables: opt1,
        });

        // Get items from the result
        const items = result.data.listUserTalkRoomMByTalkroommID.items;

        // Filter out items where _deleted is true
        const unDeletedTalkRooms = items.filter((item) => item._deleted !== true);

        // If we find an undeleted talk room, we break out of the loop
        if (unDeletedTalkRooms.length > 0) {
            unDeletedTalkRoom = unDeletedTalkRooms[0];
            break;
        }

        // If there is no next token, we break out of the loop as well
        if (!result.data.listUserTalkRoomMByTalkroommID.nextToken) {
            break;
        }

        // Otherwise, update the nextToken and continue to the next iteration
        nextToken = result.data.listUserTalkRoomMByTalkroommID.nextToken;
    }

    return unDeletedTalkRoom || [];
};

const setHiddenUserTalkRoom = async (usertalkRoomMID,hidden) => {
    

    const result=await API.graphql({
            query:getUserTalkRoomM,
            variables:{ id: usertalkRoomMID },
        })
    const userTalkRoomM=result.data.getUserTalkRoomM; 

    const data={
        input:{
            id:userTalkRoomM.id,
            hidden:hidden,
            _version:userTalkRoomM._version
        }
    }
    await API.graphql({
        query: updateUserTalkRoomM,
        variables: data,
    });

};

export {getUserTalkRoomMS,setHiddenUserTalkRoom};