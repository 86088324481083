import '@aws-amplify/ui-react/styles.css';
import '../../App.css';
import { RecruitmentForm } from '../../common-my-ui-components'
import { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import UserContext from "../../UserContext";
import { getRecruitmentListM } from '../../graphql/queries'
import { API, graphqlOperation } from "aws-amplify";
import useSaveLastViewRecruitmentPage from '../../my-func/useSaveLastViewRecruitmentPage';
import { Link } from "react-router-dom";

const RecruitmentEdit = () => {
  useSaveLastViewRecruitmentPage();

  // urlから募集IDを取得
  const { recruitmentId } = useParams();

  // ログインユーザーを取得
  const { user } = useContext(UserContext);

  // 募集画面を描画
  const [disp, setDisp] = useState();

  const checkEditRecruitment = async () => {
    const opt = {
      id: recruitmentId
    };
    const recruitment = (await API.graphql(graphqlOperation(
      getRecruitmentListM, opt))).data.getRecruitmentListM;

    if (!recruitment) {
      // 募集が見つからない
      setDisp(<div>
        この募集は存在しません
        <Link to="/RecruitmentList">
          <p>募集一覧に戻る</p>
        </Link>
      </div>)
      return;
    } else if (recruitment._deleted) {
      // 募集が削除済みであれば表示しない
      setDisp(<div>
        この募集は削除済みです
        <Link to="/RecruitmentList">
          <p>募集一覧に戻る</p>
        </Link>
      </div>)
      return;
    } else if (recruitment.userID !== user.id) {
      // 編集者とページアクセス者が同一ではない場合は募集編集画面を表示しない
      setDisp(<div>募集作成者以外が募集の編集をすることはできません</div>)
    } else {
      // 編集者とページアクセス者が同一だった場合、編集用のページを表示する
      setDisp(<RecruitmentForm recruitmentData={recruitment} />)
    }
  };

  // ページアクセス時に作成か編集か判定する
  useEffect(() => {
    if (!recruitmentId || !user) return;
    checkEditRecruitment();
  }, [recruitmentId, user]);

  return (
    <div>
      {disp}
    </div>
  );
};

export default RecruitmentEdit;