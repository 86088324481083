import React from 'react';
import styled from 'styled-components';
import { yyyyMMddHHmmFormatter } from "../../../my-func/dateTimeFormatter";

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0rem 0.5rem 0.5rem 0.5rem;
  max-width: 75%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const NameTimeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  padding-bottom: 0.5rem;
  user-select: none;
  -webkit-user-select: none; /* iOS Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer 10+ */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-tap-highlight-color: transparent; /* Android Chrome */
`;

const NameText = styled.div`
  font-size: 0.5rem;
  color: rgba(52,64,84,1);
`;

const TimeText = styled.div`
  font-size: 0.5rem;
  color: rgba(102,112,133,1);
`;

const MessageContainer = styled.div`
  padding: 0.6rem 0.8rem;
  border-radius: 0rem 0.5rem 0.5rem 0.5rem;
  background-color: rgba(242,244,247,1);
  box-shadow: 0px 1px 3px rgba(0.062745101749897, 0.0941176488995552, 0.1568627506494522, 0.10000000149011612);
  max-width: fit-content;
`;

const MessageText = styled.div`
  font-size: 0.8rem;
  color: rgba(16,24,40,1);
  text-align: start;
  word-break: break-all;
  white-space: pre-wrap;
`;

export default function OtherMessage({ userM, messageM }) {
const sentAt = yyyyMMddHHmmFormatter(new Date(messageM?.createdAt));
  return (
    <Container>
      <ContentContainer>
        <NameTimeContainer>
          <NameText>{userM?.name}</NameText>
          <TimeText>{sentAt}</TimeText>
        </NameTimeContainer>
        <MessageContainer>
          <MessageText>{messageM?.content}</MessageText>
        </MessageContainer>
      </ContentContainer>
    </Container>
  );
}
