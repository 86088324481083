/**
 * ユーザ間でのトークルームを作成します。
 * 
 * @param {Array<string>} userIDs - トークルームに参加するユーザのIDの配列
 * @returns {Promise<Object>} 作成されたトークルームの情報を含むPromiseオブジェクト
 * 
 * @async
 * @function createTalkRoom
 */
import { API } from "aws-amplify";
import { deleteTalkRoomM } from '../graphql/mutations';
import { getTalkRoomM } from "../graphql/queries";

const createTalkRoom = async (userIDs, isDirect, setErrorMessage) => {
  // ヘッダーの作成
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  // リクエスト情報の作成
  var raw = JSON.stringify({
    "env": process.env.REACT_APP_NOW_ENV,
    "isDirect": isDirect,
    "userIDs": userIDs
  });
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  // APIを実行
  try {
    const response = await fetch(process.env.REACT_APP_CREATE_TALKROOM_API, requestOptions);
    const result = await response.text();
    if ((JSON.parse(result).statusCode) === 500) throw new Error();
    return JSON.parse((JSON.parse(result).body)).talkRoomId;
  } catch (error) {
    setErrorMessage(<div>
      トークルーム作成時にエラーが発生しました。<br />
      しばらくしてからもう一度お試しください。
    </div>);
  }
};


const deleteTalkRoom = async (talkRoomID, setErrorMessage) => {
  const talkRoomM = await API.graphql({
    query: getTalkRoomM,
    variables: { id: talkRoomID },
  })
  try {
    await API.graphql({
      query: deleteTalkRoomM,
      variables: { input: { id: talkRoomID, _version: talkRoomM.data.getTalkRoomM._version } },
    });
  } catch (error) {
    setErrorMessage(<div>
      トークルーム削除時にエラーが発生しました。<br />
      しばらくしてからもう一度お試しください。
    </div>);
  }
};

export { createTalkRoom, deleteTalkRoom };
