import { styled } from "styled-components";
import { Flex } from '@aws-amplify/ui-react';
import {
  MainTitle,
  SubTitle,
  FullLineSubTitle,
  CommonLink,
  Ul,
  Li,
  LeftText,
  LeftChat,
  RightChat,
  BoldText,
  Kugiri10000
} from "../../../../common-my-ui-components";
import LetsWin1 from '../../../../common-my-ui-components/img/introduction/LetsWin1.png';
import LetsWin2 from '../../../../common-my-ui-components/img/introduction/LetsWin2.png';
import LetsWin3 from '../../../../common-my-ui-components/img/introduction/LetsWin3.png';
import LetsWin4 from '../../../../common-my-ui-components/img/introduction/LetsWin4.png';
import LetsWin5 from '../../../../common-my-ui-components/img/introduction/LetsWin5.png';
import LetsWin6 from '../../../../common-my-ui-components/img/introduction/LetsWin6.png';
import teacherSmile from '../../../../common-my-ui-components/img/miniChar/teacherSmile.png';
import teacherPoint from '../../../../common-my-ui-components/img/miniChar/teacherPoint.png';
import girlSmile from '../../../../common-my-ui-components/img/miniChar/girlSmile.png';
import girlHappy from '../../../../common-my-ui-components/img/miniChar/girlHappy.png';

// ページ全体の設定
const Page = styled.div`
width: 100%; 
`;

const Contents = styled.div`
width: 100%;
padding: 1rem;
margin: 0 auto;
text-align: center;
`;

const LetsWin = () => {
  return (
    <Page>
      <Contents>
        <MainTitle text={"和了を目指そう！"} tabText={"麻雀の基本"} textAlign={"left"} />
        <LeftChat src={teacherPoint} alt="teacherPoint" text={(<>
          前章までに和了には役が必要になること、ロンとツモの2種類あることを説明しました。<br />
          ここではもう少し詳しく解説しますね。
        </>)} />

        <SubTitle text={"和了"} textAlign={"left"} />
        <FullLineSubTitle text={"ロン"} />
        <LeftText text={<>
          <BoldText text={"ロン"} />とは相手の捨てた牌でアガることです。<br />
          自分が聴牌しているときに和了牌がでた場合、「ロン」と発声し手牌を見せる必要があります。<br />
          点数は和了牌を捨てた人からもらいます。
        </>} />

        <LeftChat src={teacherPoint} alt="teacherPoint" text={(<>
          何度か出ているこちらの手牌で点数を見ていきましょう。<br />
        </>)} />
        <img src={LetsWin1} style={{ width: '100%' }} alt="断么九" />
        <LeftText text={<>
          この手牌は<BoldText text={"断么九(タンヤオ)"} />という役がついており、相手が五索を捨てた場合ロンができます。<br />
          自分が親のときは2,000点を五索を捨てた人からもらいます。<br />
          自分が子のときは1,300点を五索を捨てた人からもらいます。
        </>} />

        <FullLineSubTitle text={"ツモ"} />
        <LeftText text={<>
          <BoldText text={"ツモ"} />とは自分で和了牌をツモってアガることです<br />
          自分が聴牌しているときに和了牌をツモった場合、「ツモ」と発声し手牌を見せる必要があります。<br />
          点数は他の3人からそれぞれもらいます。
        </>} />

        <img src={LetsWin1} style={{ width: '100%' }} alt="断么九" />
        <LeftText text={<>
          この手牌は<BoldText text={"断么九(タンヤオ)"} />と<BoldText text={"門前清自摸(メンゼンツモ)"} />という2つの役がついており、自分が五索をツモった場合ツモができます。<br />
          自分が親のときは全員から1,000点ずつもらいます。(合計3,000点)<br />
          自分が子のときは親から1,000点を、子から500点をそれぞれもらいます。(合計2,000点)
        </>} />

        <RightChat src={girlSmile} alt="girlSmile" text={(<>
          親はたくさん点数をもらえるんだね！
        </>)} />
        <LeftChat src={teacherSmile} alt="teacherSmile" text={(<>
          その代わり親は子にツモられたとき、たくさん支払う必要があります。<br />
          点数計算の詳細については別章で解説します。
        </>)} />

        <SubTitle text={"待ちの形"} textAlign={"left"} />
        <LeftText text={<>
          あと1枚で和了になる状態を<BoldText text={"聴牌(テンパイ)"} />といいます。<br />
          また、1枚で完成する部分のことを<BoldText text={"待ち"} />とよび、基本的に5種類に分けることができます。
        </>} />

        <FullLineSubTitle text={"リャンメン待ち/両面待ち"} />
        <LeftText text={<>
          <BoldText text={"リャンメン待ち"} />は連続した2枚の数牌の両端の牌を待つ形です。<br />
          最大<BoldText text={"2種類8枚"} />の待ち枚数になります。
        </>} />
        <img src={LetsWin2} style={{ width: '100%' }} alt="リャンメン待ち" />

        <FullLineSubTitle text={"カンチャン待ち/嵌張待ち"} />
        <LeftText text={<>
          <BoldText text={"カンチャン待ち"} />は1つとびの2枚の数牌の真ん中の牌を待つ形です。<br />
          最大<BoldText text={"1種類4枚"} />の待ち枚数になります。
        </>} />
        <img src={LetsWin3} style={{ width: '100%' }} alt="カンチャン待ち" />

        <FullLineSubTitle text={"ペンチャン待ち/辺張待ち"} />
        <LeftText text={<>
          <BoldText text={"ペンチャン待ち"} />は数牌の1・2牌か8・9牌を持っているときの3・7牌を待つ形です。<br />
          最大<BoldText text={"1種類4枚"} />の待ち枚数になります。
        </>} />
        <img src={LetsWin4} style={{ width: '100%' }} alt="ペンチャン待ち" />

        <FullLineSubTitle text={"シャンポン待ち/双碰待ち"} />
        <LeftText text={<>
          <BoldText text={"シャンポン待ち"} />は2組の対子の2種類の牌を待つ形です。<br />
          最大<BoldText text={"2種類4枚"} />の待ち枚数になります。
        </>} />
        <img src={LetsWin5} style={{ width: '100%' }} alt="シャンポン待ち" />

        <FullLineSubTitle text={"タンキ待ち/単騎待ち"} />
        <LeftText text={<>
          <BoldText text={"タンキ待ち"} />は未完成の雀頭の牌を待つ形です。<br />
          最大<BoldText text={"1種類3枚"} />の待ち枚数になります。
        </>} />
        <img src={LetsWin6} style={{ width: '100%' }} alt="タンキ待ち" />
        
        <br />
        <Kugiri10000 />
        <br />

        <RightChat src={girlHappy} alt="girlHappy" text={(<>
          リャンメン待ちが一番待ち枚数が多いんだ！
        </>)} />
        <LeftChat src={teacherPoint} alt="teacherPoint" text={(<>
          はい、リャンメン待ちのように待ち枚数が多い形を<BoldText text={"良形(リョウケイ)"} />といいます。<br />
          それに対して待ち枚数が少ない形を<BoldText text={"愚形(グケイ)"} />とよびます。
        </>)} />
        <RightChat src={girlSmile} alt="girlSmile" text={(<>
          全部リャンメン待ちになるように捨てる牌を選べばいいんだね！<br />
          簡単じゃん！
        </>)} />
        <LeftChat src={teacherSmile} alt="teacherSmile" text={(<>
          そんな簡単ではないですがいい視点ですね！<br />
          麻雀はリャンメン待ちが強いので、この形を意識して麻雀を打ってみてください。<br/>
        </>)} />

        <Flex direction={"column"} alignItems={'center'}>
          <Ul text={(
            <>
              <Li text={<CommonLink to="/RuleIntroduction/MahjongIntroduction#start" text={"麻雀ルールTOP"} />} />
              <Li text={<CommonLink to="/RuleIntroduction/LetsReach#start" text={"次ページ リーチをしよう！"} />} />
              <Li text={<CommonLink to="/RuleIntroduction/LearnGameFlow#start" text={"前ページ ゲームの流れを知ろう！"} />} />
            </>
          )} />
        </Flex>
      </Contents>
    </Page>
  );
};

export default LetsWin;
