
import '@aws-amplify/ui-react/styles.css';
import '../App.css';
import { useState, useEffect, useContext } from 'react';
import React from 'react';
import { Button} from '@mui/material';
import { Dialog, DialogContent, DialogActions, DialogTitle, CircularProgress } from '@mui/material';
import { Authenticator, ThemeProvider} from "@aws-amplify/ui-react";
import { getCognitoUser } from "../my-func/userManager";
import MessageContext from "../MessageContext";
import logo from './img/logo/logo.png'
import styled from 'styled-components';
import theme from "../theme"

const Center = styled.table`
margin: auto; /* サークルを中央に配置 */
`;

const HeaderContainer = styled.div`
  background-color: ${(props) => (props.theme.palette.background.default)};
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  padding: 0.2rem;
`

const Title = styled.h5`
  text-align: center;
  margin: auto;
  font-weight:600;
  border-bottom: 3px solid ${(props) => (props.theme.palette.primary.main)};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 2;
  width:15rem;
`

const LoginDialog = (props) => {
  const { setErrorMessage } = useContext(MessageContext);
  // ログインダイアログ表示フラグ
  const { isOpenLoginDialog, setIsOpenLoginDialog } = props;

  // ログインダイアログ表示
  const [dispData, setDispData] = useState(null);

  // ログインダイアログの開閉
  const dialogHandler = () => {
    setIsOpenLoginDialog(!isOpenLoginDialog);
  }

  // ログインチェック
  const checkLogin = async () => {
    const currentUser = await getCognitoUser(setErrorMessage);
    if (currentUser) {
      window.location.reload();
    }
  }

  const components = {
    Header() {
      return (
        <HeaderContainer >
          <img
            src={logo}
            alt="ロゴ"
            style={{ width: '40%' }}
          />
        </HeaderContainer>
      );
    },
    Footer() {
      return (
        <HeaderContainer>
        </HeaderContainer>
      );
    },
    SignIn: {
      Header() {
        return (
          <Title>
            アカウントにログイン
          </Title>
        );
      },
    },
    SignUp: {
      Header() {
        return (
          <Title>
            新規会員登録
          </Title>
        );
      },
    }
  }

  const loginTheme = {
    tokens: {
      colors: {
        background: {
          primary: {
            value: theme.palette.background.default,// 主背景
          },
          secondary: {
            value: theme.palette.quinary.main,// 未選択の背景
          },
        },
        brand: {
          primary: {
            '10': theme.palette.quinary.main,// パスワードを忘れた場合のホバー背景色
            '80': theme.palette.secondary.main, // ボタンの背景色
            '90': theme.palette.primary.main,// ボタンにカーソルを当てた時背景色
          },
        },
      },
      components: {
        buttons: {},
        tabs: {
          item: {
            color: {
              value: theme.palette.text.black,// 未選択のタブの文字の色
            },
            _focus: {
              color: {
                value: theme.palette.primary.main,// 選択済みのタブの文字の色
              },
            },
            _hover: {
              color: {
                value: theme.palette.primary.main,// カーソル不当てているときののタブの文字の色
              },
            },
            _active: {
              color: {
                value: theme.palette.primary.main,// 選択済みのタブの文字の色
              },
            },
          },
        },
      },
    },
  };

  // ダイアログが開かれたらログインフォームを表示
  useEffect(() => {
    if (isOpenLoginDialog) {
      setDispData(
        <ThemeProvider theme={loginTheme}>
          <Authenticator components={components}>
            <Dialog open={true}>
              <DialogTitle>処理中</DialogTitle>
              <DialogContent>
                <Center>
                  <CircularProgress />
                </Center>
              </DialogContent>
            </Dialog>
          </Authenticator>
        </ThemeProvider>
      );

      // ダイアログ表示中にログインが完了するかチェックし続ける
      const intervalId = setInterval(() => {
        checkLogin();
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isOpenLoginDialog]);

  return (
    <>
      {/* ログイン/会員登録ダイアログ */}
      <Dialog open={isOpenLoginDialog} >
        {dispData}
        <DialogActions>
          <Button onClick={dialogHandler}>キャンセル</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LoginDialog;
