import { Link, useNavigate } from "react-router-dom";
import { Button, Dialog, DialogContent, DialogTitle,  CircularProgress } from "@mui/material";
import { API } from "aws-amplify";
import { getTalkRoomM, getUserM } from "../../../graphql/queries";
import { getJoinedTalkRooms } from "../../../my-func/getJoinedTalkRooms";
import { createTalkRoom } from "../../../my-func/talkRoomManager";
import { useContext, useState } from 'react';
import MessageContext from "../../../MessageContext";
import styled from 'styled-components';

const Center = styled.table`
margin: auto; /* サークルを中央に配置 */
`;

// 作成済みDMルームを取得
const searchDMRoom = async (myUserID, toUserID) => {
  const [joinedTalkRooms, targetJoinedTalkRooms] = await Promise.all([
    getJoinedTalkRooms(myUserID),
    getJoinedTalkRooms(toUserID),
  ]);

  const commonTalkRooms = joinedTalkRooms.filter((room1) =>
    targetJoinedTalkRooms.some(
      (room2) => room1.talkroommID === room2.talkroommID
    )
  );

  for (const commonTalkRoom of commonTalkRooms) {
    const room = await API.graphql({
      query: getTalkRoomM,
      variables: { id: commonTalkRoom.talkroommID },
    }).then((result) => {
      return result.data.getTalkRoomM;
    });

    if (room.isDirect) {
      return room;
    }
  }

  return null;
};

const ProfileActionButton = ({ profileUserId, currentUserId }) => {
  const { setErrorMessage } = useContext(MessageContext);
  const navigate = useNavigate();

  // ロード中
  const [isLoading, setIsLoading] = useState(false);

  const handleDirectMessage = async () => {
    const existingDMRoom = await searchDMRoom(currentUserId, profileUserId);
    var roomID = null;
    if (existingDMRoom) {
      roomID = existingDMRoom?.id
    } else {
      setIsLoading(true)
      roomID = (await createTalkRoom([currentUserId, profileUserId], true, setErrorMessage));
    }
    if (roomID) navigate(`/TalkRoom/${roomID}`);
  };

  if (profileUserId === currentUserId) {
    return (
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/ProfileEdit"
      >
        プロフィール編集
      </Button>
    );
  } else {
    return (
      <>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDirectMessage}
        >
          ダイレクトメッセージ
        </Button>
        {/* ルーム作成中ダイアログ */}
        <Dialog open={isLoading}>
          <DialogTitle>ルーム作成中</DialogTitle>
          <DialogContent>
            <Center>
              <CircularProgress />
            </Center>
          </DialogContent>
        </Dialog>
      </>

    );
  }
};

export default ProfileActionButton;
