import React from "react";
import styled from "styled-components";
import { UserIconDefault, UserIconImage } from "../common-my-ui-components";
import { useNavigate } from "react-router-dom";
import { Storage } from "aws-amplify";
import { useState, useEffect } from "react";
import {
  openIndexedDB,
  saveToIndexedDB,
  deleteOldDataFromIndexedDB,
  fetchFromIndexedDB,
} from "../my-func/indexedDBUtils";

// アイコンのスタイルを定義
const IconWrapper = styled.div`
  padding: 0%;
  user-select: none;
  -webkit-user-select: none; /* iOS Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer 10+ */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-tap-highlight-color: transparent; /* Android Chrome */
`;


// ユーザーアイコンコンポーネント
export default function UserIcon({ userM, size, onClick }) {
  const navigate = useNavigate();
  const [copyUserM, setCopyUserM] = useState(null);
  useEffect(() => {
    // アイコンのソースを取得する関数
    // アイコンをindexedDBに保存
    // 一定期間まではそのアイコン情報を取得する
    // safariのシークレットモードなどではURLをindexedDBに保存できないためエラーになる
    // エラーの際は毎回アイコン情報をS3から取得する
    const getIconSrc = async () => {
      try {
        const db = await openIndexedDB("userIconsDB", "icons", "identityId");
        const storedIconData = await fetchFromIndexedDB(db, "icons");

      const storedIcon = storedIconData.find(
        (data) => data.identityId === userM.identityId
      )?.iconBlob;
      const storedIconTimeStamp = storedIconData.find(
        (data) => data.identityId === userM.identityId
      )?.timestamp;

        if (
          storedIcon &&
          (Date.now() - storedIconTimeStamp) / 24 / 60 / 60 / 1000 <= 1
        ) {
          const src = URL.createObjectURL(storedIcon);
          setTimeout(() => { // ブラウザに情報が蓄積されるので５秒間待って表示後に削除
            URL.revokeObjectURL(src);
          }, 5000);
          return src;
        } else {
          const imageUrl = await Storage.get(userM.iconSrc, {
            level: "protected",
            identityId: userM.identityId,
          });
          const response = await fetch(imageUrl);
          const blob = await response.blob();
          await saveToIndexedDB(db, "icons", {
            identityId: userM.identityId,
            iconBlob: blob,
            timestamp: Date.now(),
          });
          const src = URL.createObjectURL(blob);
          setTimeout(() => { // ブラウザに情報が蓄積されるので５秒間待って表示後に削除
            URL.revokeObjectURL(src);
          }, 5000);
          return src;
        }
      } catch (error) {
        // indexedDBが使用できないときは毎回S3からアイコンを取得
        try {
          const imageUrl = await Storage.get(userM.iconSrc, {
            level: "protected",
            identityId: userM.identityId,
          });
          const response = await fetch(imageUrl);
          const blob = await response.blob();
          const src = URL.createObjectURL(blob);
          setTimeout(() => {
            URL.revokeObjectURL(src);
          }, 5000);
          return src;
        } catch (error) {
          // エラー時は初期アイコンを表示させるためにnullを返す
          return null;
        }
      }
    };

    if (userM != null) {
      if (userM.iconSrc != null) {
        getIconSrc().then((src) => {
          let tempUserM = Object.assign({}, userM);
          tempUserM.iconSrc = src;
          setCopyUserM(tempUserM);
        });
      }
    }
    setCopyUserM(userM);
  }, [userM]);

  // プロフィールページへの遷移関数
  const goProfile = (userID) => {
    const profileUrl = `/Profile/${userID}`;
    navigate(profileUrl);
  };
  const handleClick = onClick || ((userID) => goProfile(userID));

  // アイコンを取得するまでは表示しない
  if (!copyUserM) return;

  return (
    <IconWrapper className="m-1">
      {
        // アイコンソースがnullの場合、デフォルトアイコンを表示
        // そうでない場合、画像アイコンを表示
        userM == null || userM._deleted ? (
          <UserIconDefault
            size={size}
          />
        ) : copyUserM.iconSrc == null ? (
          <UserIconDefault
            iconText={userM.name[0]}
            size={size}
            onClick={() => handleClick(userM.id)}
          />
        ) : (
          <UserIconImage
            userM={copyUserM}
            size={size}
            onClick={() => handleClick(userM.id)}
          />
        )
      }
    </IconWrapper>
  );
}
