import '@aws-amplify/ui-react/styles.css';
import '../../App.css';
import { useState, useEffect, useRef, useContext } from 'react';
import { Link } from "react-router-dom";
import { Grid, Flex, View } from '@aws-amplify/ui-react';
import UserContext from "../../UserContext";
import { FilteringRecruitmentList } from './my-ui-components'
import { RecruitmentDetailSetting, CommonAdSense } from '../../common-my-ui-components'
import { useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tabs from '@mui/material/Tabs';
import { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styled from 'styled-components';
import useSaveLastViewRecruitmentPage from '../../my-func/useSaveLastViewRecruitmentPage';
import { useLocation } from 'react-router-dom';
import { Dialog, DialogActions, AppBar, Toolbar, IconButton, Typography, DialogTitle } from '@mui/material';


const CustomFlex = styled(Flex)`
margin:0.5rem;
`

const CustomLink = styled(Link)`
text-decoration: none;
color: inherit;
margin:0 1rem;
`;

const TabContent = styled.div`
width:100%;
`

const Page = styled.div`
padding: 1rem;
`
const DialogContents = styled.div`
margin:5rem 0 2rem 0;
width:100%;
padding:0 2rem 0 2rem;
`;
const RecruitmentListDisp = () => {
  useSaveLastViewRecruitmentPage();

  const location = useLocation();

  // コンテキストに保存したユーザー情報を読み込み
  const { user } = useContext(UserContext);

  // ルール詳細のフォーム取得用
  const childRef = useRef();

  // 表示したいタブとリソースを取得
  var { requestNowTab, resourceName } = useParams();

  useEffect(() => {
    setIsRequestNowTab(requestNowTab)
  }, [location]);

  //タブ関連
  // 現在表示のタブ
  const [nowTab, setNowTab] = useState("isOpenRecruitmentTab");
  // 現在表示のタブの順番
  const [nowTabNum, setNowTabNum] = useState(0);
  // 募集中タブ
  const [isOpenTab, setIsOpenTab] = useState();
  // 自分の募集タブ
  const [isMyRecTab, setIsMyRecTab] = useState();
  // 自分の参加中の募集タブ
  const [isMyEntryTab, setIsMyEntryTab] = useState();
  // カテゴリタブ
  const [isCategoryTab, setIsCategoryTab] = useState();
  // 募集終了タブ
  const [isCloseTab, setIsCloseTab] = useState();
  // 表示リクエストのあったタブ
  const [isRequestNowTab, setIsRequestNowTab] = useState(requestNowTab);

  //検索関連
  // 検索バー入力値
  const [inputSerchBar, setInputSerchBar] = useState("");
  // 検索条件
  const [filtering, setFiltering] = useState(inputSerchBar);
  // 並び順チェックボックス
  const [isDescCheckBox, setIsDescCheckBox] = useState(false);
  // 詳細検索のオプション
  const [detailFilterOption, setDetailFilterOption] = useState({});
  // 詳細検索ダイアログ表示
  const [isOpenDetailDialog, setIsOpenDetailDialog] = useState(false);
  // 詳細検索ダイアログの開閉
  const dialogHandler = () => {
    setIsOpenDetailDialog(!isOpenDetailDialog)
  }
  // 検索バー入力値を操作
  const inputSerchBarRef = useRef(null);
  // 並び順チェックボックスを操作
  const isDescCheckBoxRef = useRef(null);
  // 検索欄に文字が入力されたときにinputを更新する
  const doChange = (event) => {
    setInputSerchBar(event.target.value);
  }
  // 検索ボタンが押されたときに検索条件を更新する
  const doFilter = (event) => {
    if (childRef.current) setDetailFilterOption(childRef.current.getFilterOption());
    setFiltering(inputSerchBar);
    setIsOpenDetailDialog(false);
  }
  // 並び順チェックボックスの切り替え
  const doIsDescCheckBoxChange = (event) => {
    setIsDescCheckBox(prevState => !prevState);
  }
  // 検索リセットボタンが押されたときに検索条件をリセットする
  const doFilterReset = (event) => {
    // 内部情報のリセット
    setInputSerchBar("");
    setFiltering("");
    setIsDescCheckBox(false);
    setDetailFilterOption({});
    setDetailFilterOption({});
    // 表示のリセット
    inputSerchBarRef.current.value = "";
    isDescCheckBoxRef.current.checked = false;
  }

  useEffect(() => {
    const sortDirection = isDescCheckBox ? "ASC" : "DESC";
    // リクエストで指定された初期表示したいタブを強制的に表示する
    if (isRequestNowTab) {
      if ("isCategoryTab" === requestNowTab) {
        setIsCategoryTab(<FilteringRecruitmentList nowTab={requestNowTab}
          sortDirection={sortDirection} filtering={filtering} resourceName={resourceName} keyTime={Date.now()} />)
        setNowTab(requestNowTab);
        if (user) {
          setNowTabNum(3);
        } else {
          setNowTabNum(1);
        }
      }
      // リクエストは一回だけなので使用後はnullを設定
      setIsRequestNowTab(null);
    }

    if ("isOpenRecruitmentTab" === nowTab) {
      setIsOpenTab(<FilteringRecruitmentList nowTab={nowTab}
        sortDirection={sortDirection} filtering={filtering} detailFilterOption={detailFilterOption} keyTime={Date.now()} />)
    }
    if (user != null && "isMyRecruitmentTab" === nowTab) {
      setIsMyRecTab(<FilteringRecruitmentList userId={user.id} nowTab={nowTab}
        sortDirection={sortDirection} filtering={filtering} keyTime={Date.now()} />)
    }
    if (user != null && "isMyEntryRecruitmentTab" === nowTab) {
      setIsMyEntryTab(<FilteringRecruitmentList userId={user.id} nowTab={nowTab}
        sortDirection={sortDirection} filtering={filtering} keyTime={Date.now()} />)
    }
    if ("isCategoryTab" === nowTab) {
      setIsCategoryTab(<FilteringRecruitmentList nowTab={nowTab}
        sortDirection={sortDirection} filtering={filtering} resourceName={null} keyTime={Date.now()} />)
    }
    if ("isCloseRecruitmentTab" === nowTab) {
      setIsCloseTab(<FilteringRecruitmentList nowTab={nowTab}
        sortDirection={sortDirection} filtering={filtering} detailFilterOption={detailFilterOption} keyTime={Date.now()} />)
    }
  }, [filtering, detailFilterOption, isDescCheckBox, user, nowTab, isRequestNowTab, resourceName]);

  return (
    <Page>
      {/* PC時に中央の66%にコンテンツを配置する */}
      <Grid
        templateColumns={{ base: '1fr', large: "2fr 8fr 2fr" }}
      >
        <View rowSpan={1}>{/* PC時の両脇 空白スペース */}<CommonAdSense position="left" /></View>
        <View rowSpan={1}>
          <Flex direction={"column"} alignItems={'center'} >
            <Flex>
              <Grid
                templateColumns={{ base: '1fr 1fr' }}
              >
                <CustomLink to="/RegisterRecruitment">
                  <span className="material-symbols-rounded">
                    event_note
                  </span><p>募集作成</p>
                </CustomLink>
                <CustomLink to="/RuleDetail" >
                  <span className="material-symbols-rounded">
                    import_contacts
                  </span>
                  <p>ルール確認</p>
                </CustomLink>
              </Grid>
            </Flex>

            {/* タブバーの切り替えを設定 */}
            <Box sx={{ maxWidth: '80vw', borderBottom: 1, borderColor: 'divider' }}>
              {user == null ? (
                <Tabs value={nowTabNum}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                      '&.Mui-disabled': { opacity: 0.3 },
                    }
                  }}>
                  <Tab label="募集中" onClick={() => { setNowTab("isOpenRecruitmentTab"); setNowTabNum(0); doFilterReset(); }} />
                  <Tab label="カテゴリ一覧" onClick={() => { setNowTab("isCategoryTab"); setNowTabNum(1); doFilterReset(); }} />
                  <Tab label="終了した募集" onClick={() => { setNowTab("isCloseRecruitmentTab"); setNowTabNum(2); doFilterReset(); }} />
                </Tabs>
              ) : (
                <Tabs value={nowTabNum}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                      '&.Mui-disabled': { opacity: 0.3 },
                    }
                  }}>
                  <Tab label="募集中" onClick={() => { setNowTab("isOpenRecruitmentTab"); setNowTabNum(0); doFilterReset(); }} />
                  <Tab label="自分の募集" onClick={() => { setNowTab("isMyRecruitmentTab"); setNowTabNum(1); doFilterReset(); }} />
                  <Tab label="参加中の募集" onClick={() => { setNowTab("isMyEntryRecruitmentTab"); setNowTabNum(2); doFilterReset(); }} />
                  <Tab label="カテゴリ一覧" onClick={() => { setNowTab("isCategoryTab"); setNowTabNum(3); doFilterReset(); }} />
                  <Tab label="終了した募集" onClick={() => { setNowTab("isCloseRecruitmentTab"); setNowTabNum(4); doFilterReset(); }} />
                </Tabs>
              )}
            </Box>

            {/* 募集中のコンテンツを表示 */}
            <TabContent hidden={"isOpenRecruitmentTab" !== nowTab} >
              {/* 検索バーを表示 */}
              <CustomFlex direction={"row"} alignItems={'center'} justifyContent="center" style={{ padding: "0.5em" }}>
                <TextField id="outlined-basic" label="検索" variant="outlined" onChange={doChange} ref={inputSerchBarRef}
                  sx={{ width: '100%' }} />
              </CustomFlex>
              <CustomFlex direction={"row"} alignItems={'center'} justifyContent="center">
                <Button variant="contained" onClick={doFilter}>検索</Button>
                <Button variant="contained" color="quaternary" onClick={doFilterReset} >リセット</Button>

              </CustomFlex>
              <CustomFlex direction={"row"} justifyContent="center">
                <Button onClick={dialogHandler}>詳細条件を指定</Button>
                <FormControlLabel
                  control={<Checkbox checked={isDescCheckBox} onChange={doIsDescCheckBoxChange} id="isDescCheckBox"
                    ref={isDescCheckBoxRef} />}
                  label="古い順に表示"
                />
              </CustomFlex>
              {isOpenTab}
            </TabContent>

            {/* 自分の募集のコンテンツを表示 */}
            <TabContent hidden={"isMyRecruitmentTab" !== nowTab} >
              {/* 検索バーを表示 */}
              <CustomFlex direction={"row"} alignItems={'center'} justifyContent="center" style={{ padding: "0.5em" }}>
                <TextField id="outlined-basic" label="検索" variant="outlined" onChange={doChange} ref={inputSerchBarRef}
                  sx={{ width: '100%' }} />
              </CustomFlex>
              <CustomFlex direction={"row"} alignItems={'center'} justifyContent="center">
                <Button variant="contained" color="primary" onClick={doFilter}>検索</Button>
                <Button variant="contained" color="quaternary" onClick={doFilterReset} >リセット</Button>
              </CustomFlex>
              <CustomFlex direction={"row"} justifyContent="center">
                <FormControlLabel
                  control={<Checkbox checked={isDescCheckBox} onChange={doIsDescCheckBoxChange} id="isDescCheckBox"
                    ref={isDescCheckBoxRef} />}
                  label="古い順に表示"
                />
              </CustomFlex>
              {isMyRecTab}
            </TabContent>

            {/* 参加中の募集中のコンテンツを表示 */}
            <TabContent hidden={"isMyEntryRecruitmentTab" !== nowTab}>
              {/* 検索バーを表示 */}
              <CustomFlex direction={"row"} alignItems={'center'} justifyContent="center" style={{ padding: "0.5em" }}>
                <TextField id="outlined-basic" label="検索" variant="outlined" onChange={doChange} ref={inputSerchBarRef}
                  sx={{ width: '100%' }} />
              </CustomFlex>
              <CustomFlex direction={"row"} alignItems={'center'} justifyContent="center">
                <Button variant="contained" color="primary" onClick={doFilter}>検索</Button>
                <Button variant="contained" color="quaternary" onClick={doFilterReset} >リセット</Button>
              </CustomFlex>
              <CustomFlex direction={"row"} justifyContent="center">
                <FormControlLabel
                  control={<Checkbox checked={isDescCheckBox} onChange={doIsDescCheckBoxChange} id="isDescCheckBox"
                    ref={isDescCheckBoxRef} />}
                  label="古い順に表示"
                />
              </CustomFlex>
              {isMyEntryTab}
            </TabContent>

            {/* カテゴリ一覧のコンテンツを表示 */}
            <TabContent hidden={"isCategoryTab" !== nowTab}>
              {isCategoryTab}
            </TabContent>

            {/* 参加中の募集中のコンテンツを表示 */}
            <TabContent hidden={"isCloseRecruitmentTab" !== nowTab}>
              {/* 検索バーを表示 */}
              <CustomFlex direction={"row"} alignItems={'center'} justifyContent="center" style={{ padding: "0.5em" }}>
                <TextField id="outlined-basic" label="検索" variant="outlined" onChange={doChange} ref={inputSerchBarRef}
                  sx={{ width: '100%' }} />
              </CustomFlex>
              <CustomFlex direction={"row"} alignItems={'center'} justifyContent="center">
                <Button variant="contained" color="primary" onClick={doFilter}>検索</Button>
                <Button variant="contained" color="quaternary" onClick={doFilterReset} >リセット</Button>
              </CustomFlex>
              <CustomFlex direction={"row"} justifyContent="center">
                <FormControlLabel
                  control={<Checkbox checked={isDescCheckBox} onChange={doIsDescCheckBoxChange} id="isDescCheckBox"
                    ref={isDescCheckBoxRef} />}
                  label="古い順に表示"
                />
              </CustomFlex>
              {isCloseTab}
            </TabContent>
          </Flex>
        </View >
        <View rowSpan={1}>{/* PC時の両脇 空白スペース */}<CommonAdSense position="right" /></View>
      </Grid >

      <Dialog fullScreen open={isOpenDetailDialog} >
        <AppBar color="tertiary">
          <Toolbar >
            <IconButton color="inherit" onClick={dialogHandler}>
              <span className="material-symbols-rounded">
                close
              </span>
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              ルール詳細を指定
            </Typography>
            <Button variant="contained" color="primary" onClick={doFilter}>検索</Button>
          </Toolbar>
        </AppBar>
        <DialogContents>
          <RecruitmentDetailSetting isSearch={true} ref={childRef} />
          <DialogActions>
            <Button onClick={dialogHandler}>キャンセル</Button>
            <Button variant="contained" color="primary" onClick={doFilter}>検索</Button>
          </DialogActions>
        </DialogContents>
      </Dialog>
    </Page>
  );
};

export default RecruitmentListDisp;