
export const limitString=(str)=> {
    let limitedStr = str || "";
    if (limitedStr.length > 20) {
      limitedStr = limitedStr.substring(0, 20) + " …";
    }
    if (limitedStr.split("\n").length > 1) {
      limitedStr = limitedStr.split("\n")[0] + " …";
    }
    return limitedStr;
  }