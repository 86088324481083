import '@aws-amplify/ui-react/styles.css';
import '../../../App.css';
import { styled } from 'styled-components';
import { useState, useContext } from 'react';
import UserContext from "../../../UserContext";
import TopLogo from '../../../common-my-ui-components/img/Top/TopLogo.png'
import { Button } from '@mui/material';
import { LoginDialog } from '../../../common-my-ui-components';
import { useNavigate } from "react-router-dom";

const TopLogoFormStyle = styled.div`
& Button{
  position: absolute;
  top: 85%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}
`;

const TopLogoForm = () => {
  // コンテキストに保存したユーザー情報を読み込み
  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  // ログインダイアログ表示フラグ
  const [isOpenLoginDialog, setIsOpenLoginDialog] = useState(false);

  // ログインダイアログの開閉
  const dialogHandler = () => {
    setIsOpenLoginDialog(!isOpenLoginDialog);
  }

  //募集画面に遷移
  const navigateHandler = () => {
    navigate(`/Matching`);
  }

  return (
    <>
      <TopLogoFormStyle>
        <img
          src={TopLogo}
          alt="ログインフォーム"
          style={{ width: '100%' }}
        />
        {user ? (
          <Button variant="contained" style={{ width: '80%' }} color="secondary" size="large" onClick={navigateHandler} >
            マッチング
          </Button>
        )
          : (
            <Button variant="contained" style={{ width: '80%' }} color="secondary" size="large" onClick={dialogHandler} >
              ログイン/会員登録
            </Button>
          )
        }

      </TopLogoFormStyle>

      {/* ログイン/会員登録ダイアログ */}
      <LoginDialog isOpenLoginDialog={isOpenLoginDialog}
        setIsOpenLoginDialog={setIsOpenLoginDialog} />
    </>
  );
};

export default TopLogoForm;