import '@aws-amplify/ui-react/styles.css';
import '../App.css';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const paths = {
  "default": {
    title: "雀の巣",
    description: "マッチングサービス「雀の巣」の公式ページです。麻雀初心者でも気軽に友だちを見つけられる基本料無料のサービスです。" +
      "近年Mリーグの登場により人気が高まった麻雀というゲームを、より身近に楽しめるように一緒にプレイできる友達を探すことができます。"
  },
  "RecruitmentList": {
    title: "雀の巣 募集",
    description: "麻雀募集への参加やマッチング登録で麻雀友達を見つけることができます。" +
      "なかなか麻雀友達が見つからないというあなた、ぜひ登録して素敵な麻雀ライフをお過ごしください。"
  },
  "Matching": {
    title: "雀の巣 マッチング",
    description: "希望日や場所、腕前、年齢などを登録することで条件満たすユーザーとマッチングします。マッチング前にはプロフィールの確認ができるので、事前にどんな人かわかるので安心です！"
  },
  "YakuList": {
    title: "雀の巣 役一覧",
    description: "麻雀の役を紹介しています。麻雀初心者の方はぜひこのページを見て勉強してみてください。役は翻数ごとに分けて掲載しています。"
  },
  "Contact": {
    title: "雀の巣 お問い合わせ",
    description: "雀の巣に関するご質問やご相談を幅広くお受けしています。お気軽にお問い合わせください。"
  },
  "PrivacyPolicy": {
    title: "雀の巣 プライバシーポリシー",
    description: "雀の巣のプライバシーポリシーについて、個人情報保護に関する基本方針などをご案内しています。"
  },
  "Terms": {
    title: "雀の巣 利用規約",
    description: "雀の巣の利用規約について、本サービスの規約などをご案内しています。"
  },
  "RuleDetail": {
    title: "雀の巣 ルール",
    description: "雀の巣の麻雀ルールについて記載しています。マナーや点数についても記載しているのでご確認ください。"
  },
  "Profile": {
    title: "雀の巣 プロフィール",
    description: "雀の巣登録ユーザーのプロフィールです。"
  },
  "ProfileEdit": {
    title: "雀の巣 プロフィール編集",
    description: "雀の巣登録ユーザーのプロフィールを編集できます。"
  },
  "Recruitment": {
    title: "雀の巣 募集詳細",
    description: "麻雀の募集詳細を確認できます。"
  },
  "PrivacySetting": {
    title: "雀の巣 プライバシー設定",
    description: "個人情報の変更ができます。"
  },
  "TalkRoomList": {
    title: "雀の巣 トークリスト",
    description: "ほかユーザーとメッセージ交換することができます。"
  },
  "RegisterRecruitment": {
    title: "雀の巣 募集登録",
    description: "麻雀の募集を登録することができます。"
  },
  "RecruitmentEdit": {
    title: "雀の巣 募集編集",
    description: "麻雀の募集を編集することができます。"
  },
  "MatchingRegister": {
    title: "雀の巣 マッチング登録",
    description: "マッチングを登録することができます。"
  },
  "MatchingEdit": {
    title: "雀の巣 マッチング編集",
    description: "マッチングを編集することができます。"
  },
  "MatchingHistory": {
    title: "雀の巣 マッチング履歴",
    description: "過去のマッチングを閲覧することができます。"
  },
  "RuleIntroduction": {
    title: "雀の巣 ルール入門",
    description: "麻雀は、特定の絵柄の組み合わせ完成させることを目指す絵合わせゲームです。" +
      "初心者の方にもわかりやすく基本ルールを詳しく解説しています。"
  },
  "RuleIntroduction/WhatIsMahjong": {
    title: "雀の巣 麻雀ってなに？",
    description: "麻雀は、手牌を組み合わせて完成形を目指すゲームです。麻雀の概要を専門用語を使わずに解説しています。"
  },
  "RuleIntroduction/RememberBasic": {
    title: "雀の巣 まずはこれを覚えよう！",
    description: "麻雀でまずはじめに覚えておきたいことを解説しています。基本用語や牌の読み方を解説しています。"
  },
  "RuleIntroduction/LearnGameFlow": {
    title: "雀の巣 ゲームの流れを知ろう！",
    description: "麻雀のゲームの流れを開始から終了まで解説しています。初めて麻雀をする人でも麻雀をプレイできるようになります。"
  },
  "RuleIntroduction/LetsWin": {
    title: "雀の巣 和了を目指そう！",
    description: "麻雀の和了について解説しています。ロンとツモの違いや基本的な待ちの形を初心者にもわかるように解説しています。"
  },
  "RuleIntroduction/LetsReach": {
    title: "雀の巣 リーチをしよう！",
    description: "麻雀の役で最も重要なリーチについて解説しています。リーチの手順やメリット、デメリットを解説しています。"
  },
  "RuleIntroduction/LetsCall": {
    title: "雀の巣 鳴いてみよう！",
    description: "麻雀の副露/鳴きについて解説しています。副露の手順やメリット、デメリットを解説しています。"
  },
  "RuleIntroduction/LearnScore": {
    title: "雀の巣 点数を知ろう！",
    description: "麻雀の点数や順位点について解説しています。簡単な点数を計算できるようになります。"
  },
}

/**
 * /pagename/queryのようなpathのpagenameを取得する
 * @param {*} pathname 
 * @returns 
 */
function getPageName(pathname) {
  var pageName = "default"
  // パスをスラッシュで分割して配列に変換
  const pathSegments = pathname.split('/').filter(segment => segment !== "");

  if (pathSegments.length > 0) {
    // スラッシュで区切られた最初の二つを結合してページ名を取得
    const joinedSegments = pathSegments.slice(0, 2).join('/');

    // 取得したページ名が辞書に存在するか確認
    if (paths[joinedSegments]) {
      pageName = joinedSegments;
    } else {
      // 存在しない場合、最初のセグメントだけを使用
      pageName = pathSegments[0];
    }
  }
  return pageName;
};

/**
 * pageごとにdescriptionとtitleを変更する
 * pageにtitleとdescriptionが設定されていない場合は初期値の設定に戻る
 * @param {*} pageName 
 */
function setTag(pageName) {
  const metaDescription = document.querySelector('meta[name="description"]');
  if (paths[pageName]) {
    document.title = paths[pageName].title ? paths[pageName].title : paths["default"].title;
    metaDescription.setAttribute('content', paths[pageName].description
      ? paths[pageName].description : paths["default"].description);
  } else {
    document.title = paths["default"].title;
    metaDescription.setAttribute('content', paths["default"].description);
  }
};

const CommonTag = () => {
  const location = useLocation();

  useEffect(() => {
    const pageName = getPageName(location.pathname);
    setTag(pageName);
  }, [location]);
};
;

export default CommonTag;