import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import UserContext from "../UserContext";
import { openIndexedDB, saveToIndexedDB } from "./indexedDBUtils";

// 募集関連のページに遷移（マウント）した時にindexedDBにURLを保存する。
const useSaveLastViewTalkRoomPage = () => {
  const { user } = useContext(UserContext);
  const location = useLocation();

  useEffect(() => {
    const saveLastViewTalkRoomPage = async () => {
      try {
        const data = { "keyPath":"keyPath","LastViewTalkRoomPage": location.pathname };
        const db = await openIndexedDB("LastViewTalkRoomPage", user ? user.id : "UnregisteredUser", "keyPath");
        await saveToIndexedDB(db, user ? user.id : "UnregisteredUser", data);
      } catch (error) {
        console.error("Error saving last view talkroom page", error);
      }
    };

    saveLastViewTalkRoomPage();
  }, [user, location.pathname]);
};

export default useSaveLastViewTalkRoomPage;
