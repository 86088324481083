import '@aws-amplify/ui-react/styles.css';
import '../../App.css';
import { Grid, Flex, View } from '@aws-amplify/ui-react';
import { styled } from 'styled-components';
import { YakuHeader } from './my-ui-components';
import Daisangen from '../../common-my-ui-components/img/Yaku/Daisangen.png'
import Daisushi from '../../common-my-ui-components/img/Yaku/Daisushi.png'
import Honitsu from '../../common-my-ui-components/img/Yaku/Honitsu.png'
import Honroto from '../../common-my-ui-components/img/Yaku/Honroto.png'
import Ipeko from '../../common-my-ui-components/img/Yaku/Ipeko.png'
import Itsu from '../../common-my-ui-components/img/Yaku/Itsu.png'
import Juntyan from '../../common-my-ui-components/img/Yaku/Juntyan.png'
import Kokushimuso from '../../common-my-ui-components/img/Yaku/Kokushimuso.png'
import Pinhu from '../../common-my-ui-components/img/Yaku/Pinhu.png'
import Ryanpeko from '../../common-my-ui-components/img/Yaku/Ryanpeko.png'
import Ryuiso from '../../common-my-ui-components/img/Yaku/Ryuiso.png'
import Sananko from '../../common-my-ui-components/img/Yaku/Sananko.png'
import Sankantsu from '../../common-my-ui-components/img/Yaku/Sankantsu.png'
import Sansyokudojun from '../../common-my-ui-components/img/Yaku/Sansyokudojun.png'
import Sansyokudoko from '../../common-my-ui-components/img/Yaku/Sansyokudoko.png'
import Suanko from '../../common-my-ui-components/img/Yaku/Suanko.png'
import Sukantsu from '../../common-my-ui-components/img/Yaku/Sukantsu.png'
import Syosangen from '../../common-my-ui-components/img/Yaku/Syosangen.png'
import Syosushi from '../../common-my-ui-components/img/Yaku/Syosushi.png'
import Tanyao from '../../common-my-ui-components/img/Yaku/Tanyao.png'
import Tinitsu from '../../common-my-ui-components/img/Yaku/Tinitsu.png'
import Tinroto from '../../common-my-ui-components/img/Yaku/Tinroto.png'
import Titoisu from '../../common-my-ui-components/img/Yaku/Titoitsu.png'
import Toitoi from '../../common-my-ui-components/img/Yaku/Toitoi.png'
import Tsuiso from '../../common-my-ui-components/img/Yaku/Tsuiso.png'
import Tyanta from '../../common-my-ui-components/img/Yaku/Tyanta.png'
import Tyuren from '../../common-my-ui-components/img/Yaku/Tyuren.png'
import Yakuhai from '../../common-my-ui-components/img/Yaku/Yakuhai.png'
import { useEffect } from 'react';
import { CommonAdSense } from '../../common-my-ui-components'

const YakuDetailPage = styled.div`
padding: 1rem;
text-align: left;
`;

const Title = styled.h3`
margin: 0.5em 0;
text-align: left;
font-weight: bold;
/*文字の上下 左右の余白*/
padding: 0.4rem 0.5rem;
/*文字色*/
color: ${(props) => (props.theme.palette.text.black)};
/*背景色*/
background: ${(props) => (props.theme.palette.text.white)};
/*左線*/
border-left: solid 5px ${(props) => (props.theme.palette.primary.main)};
/*下線*/
border-bottom: solid 3px ${(props) => (props.theme.palette.quaternary.main)};
`;

const Yaku = styled.div`
padding: 0.5em 0 1.5em 0;
`;

const YakuList = () => {
  useEffect(() => {
    window.location.href = "#start";
  }, []);

  return (
    <Grid id="start"
      templateColumns={{ base: '1fr', large: "2fr 8fr 2fr" }}
    >
      <View rowSpan={1}>{/* PC時の両脇 空白スペース */}<CommonAdSense position="left" /></View>
      <View rowSpan={1}>
        <Flex direction={"column"} alignItems={'center'}>
          <YakuDetailPage>
            <Title>麻雀の役 一覧</Title>
            <a href="#section1">1翻役を見る</a><br />
            <a href="#section2">2翻役を見る</a><br />
            <a href="#section3">3翻役を見る</a><br />
            <a href="#section6">6翻役を見る</a><br />
            <a href="#sectionMax">役満を見る</a><br />

            <Title id="section1">1翻</Title>
            <Yaku>
              <YakuHeader name="立直 / リーチ" isMenzen={true} />
              鳴かずに聴牌している時、「リーチ」と宣言し場に1000点棒を出すことで成立します。
            </Yaku>

            <Yaku>
              <YakuHeader name="一発 / イッパツ" isMenzen={true} />
              立直の宣言後、1巡以内にアガることで成立します。<br />
              ただし、1巡以内に副露が入ると成立しません。<br />
              加槓された牌が和了牌なのであれば、槍槓としてあがることができ一発も成立します。<br />
              ただし、加槓は成立しないので、新ドラは開けません。
            </Yaku>

            <Yaku>
              <YakuHeader name="門前清自摸和 / メンゼンツモ" isMenzen={true} />
              門前の状態で、ツモによってアガることで成立します。
            </Yaku>

            <Yaku>
              <YakuHeader name="役牌 / ヤクハイ" isCall={true} />
              <img src={Yakuhai} style={{ width: '100%' }} alt="役牌" />
              三元牌の白・發・中と自風牌や場風牌の東・南・西・北で刻子や槓子を作ることで成立します。<br />
              自風牌や場風牌の役は重複します。<br />
              例えば東場で東家のプレイヤーが東を3枚揃えた場合は、自風牌と場風牌で２翻となります。
            </Yaku>

            <Yaku>
              <YakuHeader name="断么九 / タンヤオチュー" isCall={true} />
              <img src={Tanyao} style={{ width: '100%' }} alt="断么九" />
              么九牌である1・9・字牌を一つも使わず、中張牌である2から8の数牌のみを使い手牌を完成させることで成立します。<br />
              面子は刻子・順子・槓子のいずれでも成立します。<br />
              ルールによっては鳴きで無効になる場合があります。
            </Yaku>

            <Yaku>
              <YakuHeader name="平和 / ピンフ" isMenzen={true} />
              <img src={Pinhu} style={{ width: '100%' }} alt="平和" />
              4つの面子がすべて順子であり、雀頭が役牌ではなく、待ちが両面待ちの状態でアガることで成立します。<br />
              ※東場東家とした例。
            </Yaku>

            <Yaku>
              <YakuHeader name="一盃口 / イーペーコー" isMenzen={true} />
              <img src={Ipeko} style={{ width: '100%' }} alt="一盃口" />
              同種の牌で同じ並びの順子を2面子作ることで成立します。
            </Yaku>

            <Yaku>
              <YakuHeader name="海底撈月 / ハイテイモーユエ" isCall={true} />
              牌山の最後の牌を海底といい、その牌をツモによってアガることで成立します。<br />
              門前の状態では門前清自摸和と複合して２翻となります。<br />
              嶺上開花とは複合しません。
            </Yaku>

            <Yaku>
              <YakuHeader name="河底撈魚 / ホウテイラオユイ" isCall={true} />
              海底牌をツモした人が最後に打牌した牌を河底牌といい、その牌でロンによってアガることで成立します。
            </Yaku>

            <Yaku>
              <YakuHeader name="嶺上開花 / リンシャンカイホウ" isCall={true} />
              槓をした際にツモる嶺上牌でアガることで成立します。<br />
              門前の状態では門前清自摸和と複合して２翻となります。<br />
              海底撈月とは複合しません。
            </Yaku>

            <Yaku>
              <YakuHeader name="槍槓 / チャンカン" isCall={true} />
              他家が加槓を宣言した牌が和了牌であった場合に成立します。<br />
              槍槓が成立した場合、他家の加槓は成立しなかったとみなし槓ドラはめくりません。<br />
              ルールによっては暗槓でも国士無双に限り槍槓が成立する場合があります。
            </Yaku>

            <Title id="section2">2翻役</Title>
            <Yaku>
              <YakuHeader name="ダブル立直 / ダブルリーチ" isMenzen={true} />
              1巡目の捨牌で立直をかけることで成立します。<br />
              それまでに副露があった場合は成立しません。
            </Yaku>

            <Yaku>
              <YakuHeader name="七対子 / チートイツ" isMenzen={true} />
              <img src={Titoisu} style={{ width: '100%' }} alt="七対子" />
              対子を7組揃えることで成立します。<br />
              一般的に同牌4枚では成立しません。<br />
              二盃口とは複合しません。
            </Yaku>

            <Yaku>
              <YakuHeader name="一気通貫 / イッキツウカン" kuisagari={1} />
              <img src={Itsu} style={{ width: '100%' }} alt="一気通貫" />
              同種の牌で123-456-789と順子で揃えることで成立します。
            </Yaku>

            <Yaku>
              <YakuHeader name="三色同順 / サンショクドウジュン" kuisagari={1} />
              <img src={Sansyokudojun} style={{ width: '100%' }} alt="三色同順" />
              萬子、筒子、索子の3種類の全てで同じ数字の順子を揃えることで成立します。
            </Yaku>

            <Yaku>
              <YakuHeader name="三色同刻 / サンショクドウコウ" isCall={true} />
              <img src={Sansyokudoko} style={{ width: '100%' }} alt="三色同刻" />
              萬子、筒子、索子の3種類の全てで同じ数字の刻子を揃えることで成立します。
            </Yaku>

            <Yaku>
              <YakuHeader name="三暗刻 / サンアンコウ" isCall={true} />
              <img src={Sananko} style={{ width: '100%' }} alt="三暗刻" />
              3つの面子を暗刻で揃えることで成立します。
            </Yaku>

            <Yaku>
              <YakuHeader name="対々和 / トイトイホー" isCall={true} />
              <img src={Toitoi} style={{ width: '100%' }} alt="対々和" />
              4つの面子を刻子で揃えることで成立します。<br />
              それぞれの刻子は副露で揃えることが可能です。<br />
              三暗刻と複合します。
            </Yaku>

            <Yaku>
              <YakuHeader name="三槓子 / サンカンツ" isCall={true} />
              <img src={Sankantsu} style={{ width: '100%' }} alt="三槓子" />
              暗槓・明槓に関わらず、3つの槓子を揃えることで成立します。
            </Yaku>

            <Yaku>
              <YakuHeader name="混全帯么九 / ホンチャンタイヤオチュウ" kuisagari={1} />
              <img src={Tyanta} style={{ width: '100%' }} alt="混全帯么九" />
              面子や雀頭の全て1・9・字牌を含めて揃えることで成立します。
            </Yaku>

            <Yaku>
              <YakuHeader name="混老頭 / ホンロウトウ" isCall={true} />
              <img src={Honroto} style={{ width: '100%' }} alt="混老頭" />
              面子や雀頭を1・9・字牌のみで揃えることで成立します。<br />
              対々和や七対子と必ず複合するため実質的に4翻以上となります。
            </Yaku>

            <Yaku>
              <YakuHeader name="小三元 / ショウサンゲン" isCall={true} />
              <img src={Syosangen} style={{ width: '100%' }} alt="小三元" />
              三元牌のいずれか1つを雀頭に、残り2つを刻子で揃えることで成立します。<br />
              役牌2つと必ず複合するため実質的に4翻以上となります。
            </Yaku>

            <Title id="section3">3翻役</Title>

            <Yaku>
              <YakuHeader name="二盃口 / リャンペーコー" isMenzen={true} />
              <img src={Ryanpeko} style={{ width: '100%' }} alt="二盃口" />
              一盃口を2つ作ることで成立します。<br />
              七対子とは複合しません。
            </Yaku>

            <Yaku>
              <YakuHeader name="純全帯么九 / ジュンチャンタイヤオチュウ" kuisagari={2} />
              <img src={Juntyan} style={{ width: '100%' }} alt="純全帯么九" />
              面子や雀頭の全て1・9を含めて揃えることで成立します。
            </Yaku>

            <Yaku>
              <YakuHeader name="混一色 / ホンイーソー" kuisagari={2} />
              <img src={Honitsu} style={{ width: '100%' }} alt="混一色" />
              萬子、筒子、索子のいずれか1種類と、字牌のみで揃えることで成立します。
            </Yaku>

            <Title id="section6">6翻役</Title>

            <Yaku>
              <YakuHeader name="清一色 / チンイーソー" kuisagari={5} />
              <img src={Tinitsu} style={{ width: '100%' }} alt="清一色" />
              萬子、筒子、索子のいずれか1種類のみで揃えることで成立します。
            </Yaku>

            <Title id="sectionMax">役満</Title>

            <Yaku>
              <YakuHeader name="天和 / テンホウ" isMenzen={true} />
              親が配牌の時点で聴牌し第一ツモでアガると成立します。
            </Yaku>

            <Yaku>
              <YakuHeader name="地和 / チーホウ" isMenzen={true} />
              子が副露のない1巡目にツモでアガると成立します。
            </Yaku>

            <Yaku>
              <YakuHeader name="大三元 / ダイサンゲン" isCall={true} />
              <img src={Daisangen} style={{ width: '100%' }} alt="大三元" />
              三元牌の全てを刻子もしくは槓子で揃えることで成立します。
            </Yaku>

            <Yaku>
              <YakuHeader name="四暗刻 / スーアンコウ" isMenzen={true} />
              <img src={Suanko} style={{ width: '100%' }} alt="四暗刻" />
              4つの面子を全て暗刻で揃えることで成立します。<br />
              ルールによっては単騎待ちでアガるとダブル役満とする場合があります。
            </Yaku>

            <Yaku>
              <YakuHeader name="国士無双 / コクシムソウ" isMenzen={true} />
              <img src={Kokushimuso} style={{ width: '100%' }} alt="国士無双" />
              1・9・字牌をそれぞれ1枚ずつ、計13枚集めて、その内のどれかを雀頭にすることで成立します。<br />
              ルールによっては雀頭のない13面待ちでアガるとダブル役満とする場合があります。
            </Yaku>

            <Yaku>
              <YakuHeader name="小四喜 / ショウスーシー" isCall={true} />
              <img src={Syosushi} style={{ width: '100%' }} alt="小四喜" />
              東・南・西・北のいずれか1つを頭、残り3つを刻子で揃えるとことで成立します。
            </Yaku>

            <Yaku>
              <YakuHeader name="大四喜 / ダイスーシー" isCall={true} />
              <img src={Daisushi} style={{ width: '100%' }} alt="大四喜" />
              東・南・西・北の全てを刻子で揃えるとことで成立します。<br />
              ルールによってはダブル役満とする場合があります。
            </Yaku>

            <Yaku>
              <YakuHeader name="緑一色 / リューイーソー" isCall={true} />
              <img src={Ryuiso} style={{ width: '100%' }} alt="緑一色" />
              發と、索子の2・3・4・6・8のみで揃えるとことで成立します。
            </Yaku>

            <Yaku>
              <YakuHeader name="字一色 / ツウイーソー" isCall={true} />
              <img src={Tsuiso} style={{ width: '100%' }} alt="字一色" />
              字牌のみで揃えるとことで成立します。
            </Yaku>

            <Yaku>
              <YakuHeader name="清老頭 / チンロウトウ" isCall={true} />
              <img src={Tinroto} style={{ width: '100%' }} alt="清老頭" />
              1・9のみで揃えるとことで成立します。
            </Yaku>

            <Yaku>
              <YakuHeader name="四槓子 / スーカンツ" isCall={true} />
              <img src={Sukantsu} style={{ width: '100%' }} alt="四槓子" />
              槓子を4つ揃えるとことで成立します。
            </Yaku>

            <Yaku>
              <YakuHeader name="九蓮宝燈 / チューレンポウトウ" isMenzen={true} />
              <img src={Tyuren} style={{ width: '100%' }} alt="九蓮宝燈" />
              萬子、筒子、索子のいずれか1種類のみで、1112345678999+同種の数牌1枚を揃えるとことで成立します。<br />
              ルールによっては1112345678999でテンパイした際に、9面待ちの純正九蓮宝燈としてダブル役満とする場合があります。
            </Yaku>

          </YakuDetailPage>
        </Flex>
      </View >
      <View rowSpan={1}>{/* PC時の両脇 空白スペース */}<CommonAdSense position="right" /></View>
    </Grid >

  );
};

export default YakuList;