import '@aws-amplify/ui-react/styles.css';
import '../../../App.css';
import { styled } from 'styled-components';
import { useBreakpointValue } from '@aws-amplify/ui-react';
import { Grid, View } from '@aws-amplify/ui-react';
import HowToUse1 from '../../../common-my-ui-components/img/Top/HowToUse1.png';
import HowToUse2 from '../../../common-my-ui-components/img/Top/HowToUse2.png';
import HowToUse3 from '../../../common-my-ui-components/img/Top/HowToUse3.png';

const HowToUseStyle = styled.div`
width: 100%;
overflow: hidden;
`;

const Title = styled.h1`
font-family: 'BIZ UDPGothic', sans-serif;
display: inline-block;
margin-bottom: 1rem;
background: linear-gradient( 120deg, ${(props) => (props.theme.palette.secondary.main)},
${(props) => (props.theme.palette.secondaryred.main)});
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
`;

const HowToUseImgStyle = styled.img`
border: 3px solid ${(props) => (props.theme.palette.primary.main)};
border-radius: 20px;
width: ${(props) => (props.variation === 'base' ? '250px' : '300px')};
margin: 0.5rem;
`

const LineSubTitle = styled.h4`
font-family: 'BIZ UDPGothic', sans-serif;
border-bottom: 3px solid ${(props) => (props.theme.palette.primary.main)};
display: inline-block;
line-height: 2;
width:15rem;
`;

// ステップの表示
const STEP = styled.div`
width:5em;
background: ${(props) => (props.theme.palette.primary.main)};
border-radius: 10px;
color:${(props) => (props.theme.palette.text.white)};
font-size: clamp(16px, 3vw, 20px);
font-weight: 600;
text-align: center;
margin:auto;
padding: 0.2em 0.2em;
`;

const Text = styled.div`
display: flex;
line-height: 1.5;
justify-content: center;
text-align: ${(props) => (props.variation === 'base' ? 'left' : 'center')};
margin-bottom: 1.5rem;
`;

const StepText = styled.div`
display: flex;
line-height: 1.5;
justify-content: center;
text-align: left;
margin: 0 1rem  1.5rem 1rem;
`;

const Scroll = styled.div`
width: ${(props) => (props.variation === 'base' ? '90vw' : '66vw')};
overflow: auto;
`;

const ImgComponent = ({ src, alt, order, text, variation }) => {
  return (
    <div>
      <STEP >STEP {order}</STEP>
      <HowToUseImgStyle src={src} alt={alt} variation={variation} />
      <StepText variation={variation} dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

const HowToUse = () => {
  // 画面サイズを取得
  const variation = useBreakpointValue({
    base: 'base',
    large: 'large',
  });
  return (
    <HowToUseStyle>
      <Title>
        麻雀募集
      </Title>
      <Text variation={variation}>
        ルールや人数の詳細な設定、麻雀イベントや友達集め<br/>
        使い方は無限大！幅広い募集に対応しています！<br />
      </Text>
      <LineSubTitle>
        募集の参加方法
      </LineSubTitle>
      <Scroll variation={variation}>
        <Grid templateColumns={{ base: '1fr 1fr 1fr', large: '1fr 1fr 1fr' }}>
          <View rowSpan={1}>
            <ImgComponent src={HowToUse1} alt="使い方1" order="1" text={"条件を指定して募集を検索します"} variation={variation} />
          </View>

          <View rowSpan={1}>
            <ImgComponent src={HowToUse2} alt="使い方2" order="2" text={"詳細を確認して参加してみましょう"} variation={variation} />
          </View >

          <View rowSpan={1}>
            <ImgComponent src={HowToUse3} alt="使い方3" order="3" text={"参加後は自由にメッセージ交換することができます"} variation={variation} />
          </View>
        </Grid >
      </Scroll>
    </HowToUseStyle>
  );
};

export default HowToUse;