import { Link } from "react-router-dom";
import { Flex } from "@aws-amplify/ui-react";
import { styled } from "styled-components";
import { FOOTER_MENU_HEIGHT } from "../constants.js";
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext } from "react";
import { deleteDataFromIndexedDB,openIndexedDB,saveToIndexedDB ,fetchFromIndexedDB,} from "../my-func/indexedDBUtils";
import UserContext from "../UserContext";

const FooterContainer = styled.div`
  width: 100%;
  background-color:  ${(props) => (props.theme.palette.tertiary.main)};
  height: ${FOOTER_MENU_HEIGHT};
  @media ((min-device-width : 768px) and (max-device-width : 1024px)) or (min-device-height:668px) {
    height: 3.5rem;
  }
  z-index: 5000;
  padding-top: 0.4rem;
`;

const LinkIcon=styled(Link)`
  color: ${(props) => (props.theme.palette.text.white)};
  margin: 0;
  padding: 0;
  text-decoration: none;
  &:hover {
    color: ${(props) => (props.theme.palette.text.link)};
  }
`

const FooterText=styled.p`
  font-size: 0.5rem;
  margin: 0;
  padding: 0;
  line-height: 0;
  &:hover {
    color: ${(props) => (props.theme.palette.text.link)};
  }
`;

const IconSpan = styled.span`
  margin: 0;
`;

const IconLink = styled.div`
  color: ${(props) => (props.theme.palette.text.white)};
  margin: 0;
  padding: 0;
  cursor: pointer;
  &:hover {
    color: ${(props) => (props.theme.palette.text.link)};
  }
`;

const RecruitmentPages=["RecruitmentList","RegisterRecruitment","RecruitmentEdit","Recruitment"]

const Footer=()=> {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const location = useLocation();

  // フッターの「募集」をクリックした際に前回表示した募集関連のページに遷移する。
  const openLastViewRecruitmentPage=()=>{
    const pathParts = location.pathname.split('/').filter(part => part !== "");
  
    if(!RecruitmentPages.includes(pathParts[0])){
      openIndexedDB("LastViewRecruitmentPage", user?user.id:"UnregisteredUser", "keyPath").then(db => {
        return fetchFromIndexedDB(db, user?user.id:"UnregisteredUser");
      }).then(dataFromDB => {
        if(dataFromDB.length>0){
          navigate(dataFromDB[0].LastViewRecruitmentPage);
        }else{
          navigate("/RecruitmentList");
        }
      } )
    }else{
      navigate("/RecruitmentList");
    }
    };

    const openLastViewTalkRoomPage=()=>{
      const pathParts = location.pathname.split('/').filter(part => part !== "");
        openIndexedDB("LastViewTalkRoomPage", user?user.id:"UnregisteredUser", "keyPath").then(db => {
          return fetchFromIndexedDB(db, user?user.id:"UnregisteredUser");
        }).then(dataFromDB => {
          if(dataFromDB.length>0){
            navigate(dataFromDB[0].LastViewTalkRoomPage);
          }else{
            navigate("/TalkRoomList");
          }
        } )
      };

  return (
    <FooterContainer id="App-footer">
      <Flex
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <LinkIcon to="/" >
          <IconSpan className="material-symbols-rounded" >home</IconSpan>
          <FooterText >ホーム</FooterText>
        </LinkIcon>
        <IconLink onClick={()=>openLastViewTalkRoomPage()} >
          <IconSpan className="material-symbols-rounded">sms</IconSpan>
          <FooterText >トーク</FooterText>
        </IconLink>
        <IconLink onClick={()=>openLastViewRecruitmentPage()} >
          <IconSpan className="material-symbols-rounded">
            quick_reference_all
          </IconSpan>
          <FooterText >募集</FooterText>
        </IconLink>
        <LinkIcon to="/Matching" >
          <IconSpan className="material-symbols-rounded" >globe_asia</IconSpan>
          <FooterText >マッチング</FooterText>
        </LinkIcon>
      </Flex>
    </FooterContainer>
  );
}

export default Footer;
