import '@aws-amplify/ui-react/styles.css';
import '../App.css';
import UserIcon from "./UserIcon";
import { styled } from 'styled-components';
import { useState, useEffect, useContext } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { getUserM } from "../graphql/queries";
import { getBlockedUserByUserID, registerBlockedUserM, deleteBlockedUserM } from '../my-func/BlockedUserManager'
import {
  Button, Card, Dialog, DialogContent, DialogTitle,
  ListItem, ListItemText, ListItemAvatar, Box, CircularProgress,
} from '@mui/material';
import MessageContext from "../MessageContext";
import UserContext from "../UserContext";
import { Link } from "react-router-dom";

// ページ全体の設定
const Page = styled.div`
width: 100%;  
padding: 0 0.5rem;
`;

// ボタンの間隔設定
const TextStyle = styled.div`
margin: 1rem;
`;

const Right = styled.div`
margin: 0 0 0 auto; 
`;

const Center = styled.table`
margin: auto; /* サークルを中央に配置 */
`;

const MinTitle = styled.div`
width:100%;
line-height: 120%;
font-size: 1.2rem;
font-weight: 450;
text-align: left;
margin:1rem 0 0 0;
position: relative;
padding: 0.25em 0;
&::after {
  content: "";
  display: block;
  height: 4px;
  background: linear-gradient(90deg, ${(props) => (props.theme.palette.text.green)},
  ${(props) => (props.theme.palette.quinary.main)});
}
`;

const IconContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const CustomLink = styled(Link)`
text-decoration: none;
color: inherit;
&:hover {
    color: ${(props) => (props.theme.palette.text.link)};
  }
`;


const BlockUserForm = (props) => {
  // 引数で募集情報を受け取る
  const { targetUserList, targetTitle } = props;
  const { setErrorMessage } = useContext(MessageContext);
  const { user } = useContext(UserContext);
  // 更新処理用ステート
  const [keyTime, setKeyTime] = useState(Date.now());

  // ブロックしているユーザー
  // ブロックしているユーザーのUserM
  const [blockUserList, setBlockUserList] = useState(null);
  // ブロックしているユーザーのuserID
  const [blockUserIDList, setBlockUserIDList] = useState(null);
  // ブロックしているユーザーのBlockedUsersM
  const [blockedUsersMList, setBlockedUsersMList] = useState(null);

  // ブロックユーザーを表示するコンポーネント
  const BlockUserList = (props) => {
    const { userList, setKeyTime } = props;
    const [dispUserList, setDispUserList] = useState(null);
    // 処理中の状態を保存する
    const [isLoading, setIsLoading] = useState(false);

    const handleUnBlock = async (id) => {
      setIsLoading(true);
      blockedUsersMList.map(async blockedUsersM => {
        if (blockedUsersM.blockedUsermID === id) {
          deleteBlockedUserM(blockedUsersM, setErrorMessage);
          await new Promise((resolve) => setTimeout(resolve, 1000));
          setKeyTime(Date.now());
        };
      })
    }

    const handleBlock = async (id) => {
      setIsLoading(true);
      registerBlockedUserM(user.id, id, setErrorMessage);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setKeyTime(Date.now());
    }

    const drawBlockUserList = async () => {
      if (!userList.length) {
        setDispUserList(<TextStyle>ブロックしているユーザーはいません</TextStyle>);
        return;
      }
      const list = userList.map(userM => (
        <ListItem>
          <ListItemAvatar>
            <IconContainer>
              <UserIcon userM={userM} />
            </IconContainer>
          </ListItemAvatar>
          <CustomLink to={`/Profile/${userM.id}`} >
            <ListItemText primary={userM.name} />
          </CustomLink>
          <Right >
            {blockUserIDList.includes(userM.id) ? (
              <Button variant="contained" onClick={() => handleUnBlock(userM.id)} >
                解除
              </Button>
            ) : (
              <Button onClick={() => handleBlock(userM.id)}  >
                ブロック
              </Button>
            )}
          </Right>
        </ListItem>
      ));
      setDispUserList(<>{list}</>);
    };

    useEffect(() => {
      drawBlockUserList();
    }, []);

    return (
      <>
        <Card variant="outlined" sx={{ minWidth: 300 }} >
          {dispUserList}
        </Card>

        {/* 更新処理ダイアログ */}
        <Dialog open={isLoading}>
          <DialogTitle>処理中</DialogTitle>
          <DialogContent>
            <Center>
              <CircularProgress />
            </Center>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  // ブロックしているユーザーリストを取得
  const getBlockedUserList = async () => {
    try {
      const blockedUsersMList = await getBlockedUserByUserID(user, setErrorMessage);
      const blockUserMList = Array(blockedUsersMList.length);
      const blockUserIDList = Array(blockedUsersMList.length);
      await Promise.all(blockedUsersMList.map(async (blockedUsers, i) => {
        if (!blockedUsers) return;
        const opt = {
          id: blockedUsers.blockedUsermID
        };
        const user = (await API.graphql(graphqlOperation(getUserM, opt))).data.getUserM;
        blockUserMList[i] = user;
        blockUserIDList[i] = user.id;
      }));
      setBlockedUsersMList(blockedUsersMList);
      setBlockUserList(blockUserMList);
      setBlockUserIDList(blockUserIDList);
    } catch (e) {
      setErrorMessage(<div>
        ユーザー情報取得時にエラーが発生しました。<br />
        しばらくしてからもう一度お試しください。
      </div>);
    }
  }

  useEffect(() => {
    getBlockedUserList();
  }, [keyTime]);

  if (!blockUserList) return;

  return (
    <Page>
      {blockUserList ? (
        <>
          <MinTitle>ブロック済みユーザー</MinTitle>
          <BlockUserList userList={blockUserList} keyTime={keyTime} setKeyTime={setKeyTime} />
        </>
      ) : (
        <></>
      )}

      {targetUserList && targetTitle ? (
        <>
          <MinTitle>{targetTitle}</MinTitle>
          <BlockUserList userList={targetUserList} keyTime={keyTime} setKeyTime={setKeyTime} />
        </>
      ) : (
        <></>
      )}

    </Page>
  );
};

export default BlockUserForm;
