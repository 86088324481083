import '@aws-amplify/ui-react/styles.css';
import '../../../App.css';
import { Grid } from '@aws-amplify/ui-react';
import { yyyyMMddHHmmFormatter } from '../../../my-func/dateTimeFormatter'
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Chip, Divider } from '@mui/material';
import styled from 'styled-components';

// ページ全体の設定
const Page = styled.div`
width: 100%;  
padding: 0 0.5rem;
`;

// Gridの設定
const GridStyle = styled(Grid)`
text-align: left;
margin: 0.5rem 0;
`;

// 募集カード
const Recruitment = styled.div`
width: 100%; 
padding: 0.5rem 1rem;
margin: 0.5rem 0;
background: ${(props) => (props.theme.palette.quinary.main)};
border: solid 1px ${(props) => (props.theme.palette.quaternary.main)};
border-radius: 10px;
`;

// 募集ステータスの表示
const IsEnd = styled.div`
width:5em;
padding: 0.5em 0.5em;
background: ${(props) => (props.isEnd === '募集中' ?
    props.theme.palette.primaryred.main : props.theme.palette.disabled.main)};
border-radius: 10px;
color:${(props) => (props.theme.palette.text.white)};
font-size: clamp(16px, 3vw, 20px);
font-weight: 600;
text-align: center;
margin-right: auto;
`;

// 募集名
const Title = styled.div`
padding: 0em 0.5em;
width: 100%;
line-height: 160%;
font-size: clamp(16px, 3vw, 20px);
font-weight: 700;
text-align: left;
&::after {
  content: "";
  display: block;
  height: 4px;
  background: linear-gradient(90deg, ${(props) => (props.theme.palette.text.green)},
  ${(props) => (props.theme.palette.quinary.main)});
}
`;

const MinTitle = styled.div`
width:100%;
line-height: 120%;
font-size: clamp(14px, 1.1vw, 16px);
font-weight: 600;
text-align: left;
margin:auto;
position: relative;
padding: 0.25em 0;
`;

//項目タイトルの設定
const ItemTitleStyle = styled.div`
text-align: left;
white-space: break-spaces;
font-size: clamp(12px, 1.1vw, 16px);
overflow: hidden;
display: -webkit-box;
-webkit-box-orient: vertical;
`;

//項目内容の設定
const ItemContentStyle = styled.div`
text-align: left;
white-space: break-spaces;
font-size: clamp(12px, 1.1vw, 16px);
overflow: hidden;
display: -webkit-box;
-webkit-box-orient: vertical;
`;

const CustomLink = styled(Link)`
text-decoration: none;
color: inherit;
`;

const StartDateTime = styled.div`
margin: 0.25rem 0;
text-align: right;
white-space: break-spaces;
font-size: clamp(10px, 1.1vw, 12px);
overflow: hidden;
display: -webkit-box;
-webkit-box-orient: vertical;
`;

const RecruitmentDetail = (props) => {
  // 引数で募集情報と参加ユーザーリストを受け取る
  const { recruitment, entryUserList, categoryList } = props;
  const [formattedRecruitment, setFormattedRecruitment] = useState(null);
  const [formattedCategory, setFormattedCategory] = useState(null);

  const hasValue = (value) => {
    return value === "1" ? "あり" : "なし";
  }

  useEffect(() => {
    const formattedRecruitment = {
      ...recruitment,
      startDateTime: yyyyMMddHHmmFormatter(new Date(recruitment.startDateTime)),
      endDateTime: yyyyMMddHHmmFormatter(new Date(recruitment.endDateTime))
    };

    // 募集の状態
    if (formattedRecruitment.isEnd === "1") {
      formattedRecruitment.isEnd = "募集終了"
    } else {
      formattedRecruitment.isEnd = "募集中"
    }

    // 参加条件がない時
    if (!formattedRecruitment.participationConditions) {
      formattedRecruitment.participationConditions = "特になし";
    }

    // 局数
    if (formattedRecruitment.gameRoundNum === "1") formattedRecruitment.gameRoundNum = "東風戦"
    if (formattedRecruitment.gameRoundNum === "2") formattedRecruitment.gameRoundNum = "半荘戦"

    // 持ち点
    if (formattedRecruitment.additionalRound === "0") {
      formattedRecruitment.additionalRound = "なし";
    } else { formattedRecruitment.additionalRound = formattedRecruitment.additionalRound + "点"; }

    // 喰いタン
    formattedRecruitment.allSimples = hasValue(formattedRecruitment.allSimples);

    // 一発・裏ドラ・槓ドラ・槓裏ドラ
    formattedRecruitment.additionalYaku = hasValue(formattedRecruitment.additionalYaku);

    // 後付け
    formattedRecruitment.yakuBack = hasValue(formattedRecruitment.yakuBack);

    // 喰い替え
    if (formattedRecruitment.swapCalling === "2") formattedRecruitment.swapCalling = "あり(現物以外)"
    if (formattedRecruitment.swapCalling === "1") formattedRecruitment.swapCalling = "あり"
    if (formattedRecruitment.swapCalling === "0") formattedRecruitment.swapCalling = "なし"

    // ツモ番なしリーチ
    formattedRecruitment.noDrawReach = hasValue(formattedRecruitment.noDrawReach);

    // フリテンツモ
    formattedRecruitment.furitenDraw = hasValue(formattedRecruitment.furitenDraw);

    // 30符4翻の切り上げ
    formattedRecruitment.points30Doubles4 = hasValue(formattedRecruitment.points30Doubles4);

    // 頭ハネ
    formattedRecruitment.nearestPlayerWin = hasValue(formattedRecruitment.nearestPlayerWin);

    // 連荘
    if (formattedRecruitment.moreGameRound === "2") formattedRecruitment.moreGameRound = "聴牌連荘"
    if (formattedRecruitment.moreGameRound === "1") formattedRecruitment.moreGameRound = "和了連荘"
    if (formattedRecruitment.moreGameRound === "0") formattedRecruitment.moreGameRound = "なし"

    // 和了止め
    formattedRecruitment.lastWinStop = hasValue(formattedRecruitment.lastWinStop);

    // 飛び
    formattedRecruitment.minusScore = hasValue(formattedRecruitment.minusScore);

    // ツモ損
    formattedRecruitment.drawLoss_3 = hasValue(formattedRecruitment.drawLoss_3);

    // 花牌
    formattedRecruitment.flower_3 = hasValue(formattedRecruitment.flower_3);

    // 途中流局
    if (formattedRecruitment.drawnGame !== "0" && formattedRecruitment.drawnGame !== null) {
      var drawnGames = formattedRecruitment.drawnGame.split(',');
      formattedRecruitment.drawnGame = "";
      for (var i = 0; i < drawnGames.length; i++) {
        if (drawnGames[i] === "1") formattedRecruitment.drawnGame = "九種九牌 ";
        if (drawnGames[i] === "2") formattedRecruitment.drawnGame = formattedRecruitment.drawnGame + "四風子連打 ";
        if (drawnGames[i] === "3") formattedRecruitment.drawnGame = formattedRecruitment.drawnGame + "四槓流れ ";
        if (drawnGames[i] === "4") formattedRecruitment.drawnGame = formattedRecruitment.drawnGame + "三家和 ";
        if (drawnGames[i] === "5") formattedRecruitment.drawnGame = formattedRecruitment.drawnGame + "四家立直 ";
      }
    } else {
      formattedRecruitment.drawnGame = "なし";
    }

    // 北の扱い
    if (formattedRecruitment.north_3 === "0" || !formattedRecruitment.north_3) formattedRecruitment.north_3 = "なし"
    if (formattedRecruitment.north_3 === "1") formattedRecruitment.north_3 = "抜きドラ"
    if (formattedRecruitment.north_3 === "2") formattedRecruitment.north_3 = "共通役牌"

    const formattedCategory = []
    if (categoryList) {
      for (let item of categoryList) {
        formattedCategory.push(
          <CustomLink to={`/RecruitmentList/isCategoryTab/` + item.categoryName}>
            <Chip sx={{ fontSize: 'clamp(12px, 1.1vw, 16px)', margin: '0.3rem;' }} label={item.categoryName} color="primary" /> </CustomLink>
        )
      }
    }
    // 上書きした値をコンポーネントのローカルステートにセット
    setFormattedRecruitment(formattedRecruitment);
    setFormattedCategory(formattedCategory)
  }, [recruitment]);

  // formattedRecruitmentがnullの場合はロード中の表示
  if (!formattedRecruitment) {
    return <Page>Loading...</Page>;
  }


  return (
    <Page>
      <Recruitment>
        <GridStyle alignItems="center" templateColumns={{ base: '2fr 8fr', large: "3fr 17fr" }}>
          <IsEnd isEnd={formattedRecruitment.isEnd}>{formattedRecruitment.isEnd}</IsEnd>
          <Title>{formattedRecruitment.title}</Title>
        </GridStyle>

        <GridStyle alignItems="center" templateColumns={{ base: '3fr 7fr', large: "3fr 7fr 3fr 7fr" }}>
          <ItemTitleStyle>募集終了日</ItemTitleStyle>
          <ItemContentStyle>{formattedRecruitment.endDateTime}</ItemContentStyle>
          <ItemTitleStyle>場所</ItemTitleStyle>
          <ItemContentStyle>{formattedRecruitment.place}</ItemContentStyle>
          <ItemTitleStyle>募集人数</ItemTitleStyle>
          {/* 参加ユーザーには募集作成者を含まないので+1して募集作成者を含める */}
          <ItemContentStyle>{entryUserList.length + 1}/{formattedRecruitment.recruitmentNumbers + "人"}</ItemContentStyle>
          <ItemTitleStyle>参加条件</ItemTitleStyle>
          <ItemContentStyle>{formattedRecruitment.participationConditions}</ItemContentStyle>
        </GridStyle>

          <MinTitle>詳細説明</MinTitle>
          <Divider />
          <ItemContentStyle>
            {formattedRecruitment.detail}
          </ItemContentStyle>

        {formattedCategory}
        <StartDateTime>募集作成日：{formattedRecruitment.startDateTime}</StartDateTime>

        <MinTitle>ルール詳細</MinTitle>
        <Divider />

        <GridStyle alignItems="center" templateColumns={{ base: '1fr 1fr', large: "2fr 1fr 2fr 1fr" }}>

          <ItemTitleStyle >プレイ人数</ItemTitleStyle>
          <ItemContentStyle>
            {formattedRecruitment.playNum + "人麻雀"}
          </ItemContentStyle>


          <ItemTitleStyle >局数</ItemTitleStyle>
          <ItemContentStyle>
            {formattedRecruitment.gameRoundNum}
          </ItemContentStyle>


          <ItemTitleStyle >持ち点</ItemTitleStyle>
          <ItemContentStyle>
            {formattedRecruitment.gameScore + "点"}
          </ItemContentStyle>


          <ItemTitleStyle >赤牌</ItemTitleStyle>
          <ItemContentStyle>
            {formattedRecruitment.redFive + "枚"}
          </ItemContentStyle>


          <ItemTitleStyle >喰いタン</ItemTitleStyle>
          <ItemContentStyle>
            {formattedRecruitment.allSimples}
          </ItemContentStyle>


          <ItemTitleStyle >一発・裏ドラ・槓ドラ・槓裏ドラ</ItemTitleStyle>
          <ItemContentStyle>
            {formattedRecruitment.additionalYaku}
          </ItemContentStyle>


          <ItemTitleStyle >喰い替え</ItemTitleStyle>
          <ItemContentStyle>
            {formattedRecruitment.swapCalling}
          </ItemContentStyle>


          <ItemTitleStyle >後付け</ItemTitleStyle>
          <ItemContentStyle>
            {formattedRecruitment.yakuBack}
          </ItemContentStyle>


          <ItemTitleStyle >フリテンツモ</ItemTitleStyle>
          <ItemContentStyle>
            {formattedRecruitment.furitenDraw}
          </ItemContentStyle>


          <ItemTitleStyle >ツモ番なしリーチ</ItemTitleStyle>
          <ItemContentStyle>
            {formattedRecruitment.noDrawReach}
          </ItemContentStyle>


          <ItemTitleStyle >頭ハネ</ItemTitleStyle>
          <ItemContentStyle>
            {formattedRecruitment.nearestPlayerWin}
          </ItemContentStyle>


          <ItemTitleStyle >30符4翻の切り上げ</ItemTitleStyle>
          <ItemContentStyle>
            {formattedRecruitment.points30Doubles4}
          </ItemContentStyle>


          <ItemTitleStyle >途中流局</ItemTitleStyle>
          <ItemContentStyle>
            {formattedRecruitment.drawnGame}
          </ItemContentStyle>


          <ItemTitleStyle >連荘</ItemTitleStyle>
          <ItemContentStyle>
            {formattedRecruitment.moreGameRound}
          </ItemContentStyle>


          <ItemTitleStyle >和了止め</ItemTitleStyle>
          <ItemContentStyle>
            {formattedRecruitment.lastWinStop}
          </ItemContentStyle>


          <ItemTitleStyle >南入・西入</ItemTitleStyle>
          <ItemContentStyle>
            {formattedRecruitment.additionalRound}
          </ItemContentStyle>


          <ItemTitleStyle >飛び</ItemTitleStyle>
          <ItemContentStyle>
            {formattedRecruitment.minusScore}
          </ItemContentStyle>
        </GridStyle>

        <MinTitle>3人麻雀のみ</MinTitle>
        <Divider />
        <GridStyle alignItems="center" templateColumns={{ base: '1fr 1fr', large: "2fr 1fr 2fr 1fr" }}>

          <ItemTitleStyle >北の扱い</ItemTitleStyle>
          <ItemContentStyle> {formattedRecruitment.north_3}
          </ItemContentStyle>


          <ItemTitleStyle >ツモ損</ItemTitleStyle>
          <ItemContentStyle> {formattedRecruitment.drawLoss_3}
          </ItemContentStyle>


          <ItemTitleStyle >花牌</ItemTitleStyle>
          <ItemContentStyle> {formattedRecruitment.flower_3}
          </ItemContentStyle>
        </GridStyle>
      </Recruitment>
    </Page >
  );
};

export default RecruitmentDetail;
