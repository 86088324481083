import React from 'react';
import { styled } from 'styled-components';
import { useState, useEffect, useContext } from 'react';
import UserContext from "../../UserContext";
import { Link } from "react-router-dom";
import { Button } from '@mui/material';
import { LoginDialog } from "../../common-my-ui-components"

const Page = styled.div`
padding: 1rem;
margin: 0 auto;
`;

const CustomIcon = styled.span`
  && {
    font-size: 8rem; 
    color: ${(props) => (props.theme.palette.primary.main)}; 
  }
`;

const CustomStepLabel = styled.p`
  && {
    color: ${(props) => (props.theme.palette.primary.main)}; 
    font-size: 2rem;
  }
`;

const Contents = styled.div`
margin: 2rem;
`;

const MyLink = styled(Link)`
textDecoration: 'underline';
color: ${(props) => (props.theme.palette.text.link)};
`;

const Restriction = () => {
  // ログインユーザーを取得
  const { user } = useContext(UserContext);
  const [userExists, setUserExists] = useState(null);

  // ログインダイアログ表示フラグ
  const [isOpenLoginDialog, setIsOpenLoginDialog] = useState(false)

  // ログインダイアログの開閉
  const dialogHandler = () => {
    setIsOpenLoginDialog(!isOpenLoginDialog)
  }

  useEffect(() => {
    if (user) {
      setUserExists(true);
    } else {
      setUserExists(false);
    }
  }, [user]);

  return (
    <Page>
      <CustomIcon className="material-symbols-rounded" >
        <span class="material-symbols-outlined">
          Lock
        </span>
      </CustomIcon>
      <CustomStepLabel>この機能は利用できません</CustomStepLabel>
      {
        userExists == null ? (
          // ユーザー情報を取り終えるまでは何も表示しない
          <>
          </>
        ) : userExists ? (
          <Contents>
            18歳未満は利用できません。<br />
            {user.name}さんが18歳以上であれば、<br />
            <MyLink to="/PrivacySetting" >
              年齢確認を完了
            </MyLink>することで利用できるようになります。
          </Contents>
        ) : (
          <Contents>
            未ログインユーザーは利用できません。<br />
            利用するためには<MyLink onClick={dialogHandler}>ログイン</MyLink>をしてください。<br />
          </Contents>
        )}

      <Link to="/" >
        <Button variant="contained" color="primary" size="large">ホームに戻る</Button>
      </Link>

      {/* ログイン/会員登録ダイアログ */}
      <LoginDialog isOpenLoginDialog={isOpenLoginDialog} setIsOpenLoginDialog={setIsOpenLoginDialog} />
    </Page>
  );
}

export default Restriction;
