import { initializeApp } from "firebase/app"
import { getMessaging } from "firebase/messaging"
import { getToken, isSupported } from "firebase/messaging"
import { API, ServiceWorker } from "aws-amplify"
import { Analytics } from "@aws-amplify/analytics"
import { Auth } from "@aws-amplify/auth"

import awsconfig from "../aws-exports"
import { createNotificationTokenM } from "../graphql/mutations"

Auth.configure(awsconfig) // retrieve temporary AWS credentials and sign requests
Analytics.configure(awsconfig) // send analytics events to Amazon Pinpoint

Analytics.autoTrack("session", {
  enable: false
})

// const firebaseConfig = {
//   apiKey: "AIzaSyAkav0eo0U1Y-tD1eXUrWWglHgVWwYEQAs",
//   authDomain: "test-364cc.firebaseapp.com",
//   projectId: "test-364cc",
//   storageBucket: "test-364cc.appspot.com",
//   messagingSenderId: "802613328483",
//   appId: "1:802613328483:web:48429f46b577cb9413d656",
//   measurementId: "G-7X3TL4K8D1"
// }
const firebaseConfig = {
  apiKey: "AIzaSyCwMz0URbxdmn3nxRTd7AvHgjv86zah0SM",
  authDomain: "jan-no-su.firebaseapp.com",
  projectId: "jan-no-su",
  storageBucket: "jan-no-su.appspot.com",
  messagingSenderId: "637245255596",
  appId: "1:637245255596:web:6db7ecc65c5607e847d409",
  measurementId: "G-XMHR320BH8"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app)

// var vapidKey = "BOEM-uQCFvaR_pM9uK4bqpyBnYbHYQhg6d7sn_o2DX0FQvbCHyb2v0dx1ewmMNoh_3nlj1gjcSW2dfr6ZICpJyw"
var vapidKey = "BDxVVU6i3M2mYcSVKok1DEEgzEbNxcbTQMGqcZ3NxSxF911S8KMra7zxTxGKR9LpipIwA1P3c9AiJlx4fw1C6fU"

const serviceWorker = new ServiceWorker()
serviceWorker
  .register("/firebase-messaging-sw.js", "/")
  .then((registration) => {
    // needed when we are using our custom service worker implementation with firebase messaging
    // also firebase uses the sw registration to fetch the current push subscription during getToken()
    // messaging.useServiceWorker(registration);
  })
  .catch((err) => {
  })

function sendTokenToPinpoint(fcmToken) {
  Analytics.updateEndpoint({
    address: fcmToken,
    channelType: "GCM",
    optOut: "NONE"
  })
}

export function requestPermission(userID) {
  isSupported().then((isSupport) => {
    if (!isSupport) {
      return null
    }

    if (Notification.permission === "granted") {
      return "granted"
    } else {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          getTokenRequest(userID)
        } else if (permission === "denied") {
          return "denied"
        }
      })
    }
  })
}

export async function getCurrentToken() {
  getToken(messaging, {
    vapidKey: vapidKey
  }).then((currentToken) => {
    return currentToken
  })
}

export function getTokenRequest(userID) {
  // Add the public key generated from the console here.
  getToken(messaging, {
    vapidKey: vapidKey
  })
    .then((currentToken) => {
      if (currentToken) {
        sendTokenToPinpoint(currentToken)

        // デバイストークンとユーザIDをDBに登録
        const data = {
          input: {
            usermID: userID,
            token: currentToken,
            enabled: true
          }
        }
        API.graphql({
          query: createNotificationTokenM,
          variables: data
        })
      } else {
      }
    })
    .catch((err) => {
    })
}
