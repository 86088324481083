import { useState,useRef ,useEffect} from "react";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { updateTalkRoomM } from "../../../graphql/mutations";
import { getTalkRoomM} from "../../../graphql/queries";
import { styled } from "@mui/system";
import { deleteDataFromIndexedDB,openIndexedDB,saveToIndexedDB ,fetchFromIndexedDB} from "../../../my-func/indexedDBUtils";


const MAXINPUTTEXTLENGTH=500;

const InputArea = styled('div')`
  background-color: ${(props) => (props.theme.palette.text.white)};
  color: ${(props) => (props.theme.palette.text.main)};
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0.2rem 1.5rem 0.2rem ;
`;
  
const CustomTextField=styled(TextField)`
  width: 100%;
  overflow: hidden;
  resize: none;
`;


const MessageInput = ({ onSend, onFileSelect, talkRoomID }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const textAreaRef = useRef(null);
  const [db,setDb]=useState(null);

  useEffect(() => {
    const fetchDb = async () => {
      openIndexedDB('inputMessage','inputMessage','talkRoomID').then((db)=>{
        setDb(db);
        fetchFromIndexedDB(db,'inputMessage').then((result)=>{
          const filterdResult=result.filter((item)=>item["talkRoomID"]==talkRoomID)
          if(filterdResult.length>0){
            textAreaRef.current.value=filterdResult[0].inputMessage;
            if(textAreaRef.current.value!=""){
              setIsButtonDisabled(false);
            }
          }
        });
      });

    };
    fetchDb();
  }, []); 


  // TalkRoomのメッセージ件数更新 画面更新のトリガー
  const incrementTalkRoomCountMessage=async()=>{

    const opt1={
      id:talkRoomID,
    };
    const values=await API.graphql(graphqlOperation(getTalkRoomM,opt1));
    const roomData=values.data.getTalkRoomM;

    const data = {
      input: {
        id: roomData.id,
        countMessage: roomData.countMessage+1,
        _version: roomData._version,
      }
    }
    API.graphql({
      query: updateTalkRoomM,
      variables: data,
    });

  }

  const handleSend = () => {
    onSend(textAreaRef.current.value);
    incrementTalkRoomCountMessage();
    setIsButtonDisabled(true);
    textAreaRef.current.value="";
    deleteDataFromIndexedDB(db,'inputMessage',talkRoomID);
  };

  const handleTextChange = (e) => {
    const currentLength = textAreaRef.current.value.length;
    if (currentLength >= MAXINPUTTEXTLENGTH) {

    }
    setIsButtonDisabled(!textAreaRef.current.value);
    saveToIndexedDB(db,"inputMessage",{'talkRoomID':talkRoomID,"inputMessage":textAreaRef.current.value});
  };

  return (
    <InputArea>

      <CustomTextField
        placeholder="メッセージを入力..."
        variant="outlined"
        multiline
        onChange={handleTextChange}
        inputRef={textAreaRef}
        maxRows={8}
        inputProps={{style: {fontSize: "0.8rem",lineHeight:"1.2rem",overscrollBehavior:"contain"} , maxLength: MAXINPUTTEXTLENGTH}}
      />
      <Button variant="text" onClick={handleSend} disabled={isButtonDisabled}>
      <span class="material-symbols-rounded">
        send
      </span>
      </Button>
    </InputArea>
  );
};

export default MessageInput;