
/**
 * dateから2023/1/1 12:00:00の文字列を生成する
 * @param {date型} date 
 * @returns 
 */
export function yyyyMMddHHmmssFormatter(date) {
  const yyyy = date.getFullYear().toString();
  const MM = (date.getMonth() + 1).toString();
  const dd = date.getDate().toString();
  const hh = ("00" + date.getHours()).slice(-2);
  const mm = ("00" + date.getMinutes()).slice(-2);
  const ss = ("00" + date.getSeconds()).slice(-2);
  return yyyy + "/" + MM + "/" + dd + " " + hh + ":" + mm + ":" + ss;
}

/**
 * dateから2023/1/1 12:00の文字列を生成する
 * @param {date型} date 
 * @returns 
 */
export function yyyyMMddHHmmFormatter(date) {
  const yyyy = date.getFullYear().toString();
  const MM = (date.getMonth() + 1).toString();
  const dd = date.getDate().toString();
  const hh = ("00" + date.getHours()).slice(-2);
  const mm = ("00" + date.getMinutes()).slice(-2);
  return yyyy + "/" + MM + "/" + dd + " " + hh + ":" + mm;
}

/**
 * dateから2023/1/1の文字列を生成する
 * @param {date型} date 
 * @returns 
 */
export function yyyyMMddFormatter(date) {
  const yyyy = date.getFullYear().toString();
  const MM = (date.getMonth() + 1).toString();
  const dd = date.getDate().toString();
  return yyyy + "/" + MM + "/" + dd ;
}

