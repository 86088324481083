import { useState, useEffect, useContext, useRef } from 'react';
import { createRecruitment, editRecruitment, deleteRecruitment } from "../my-func/RecruitmentManager";
import { getCategoryByRecruitmentListId } from "../my-func/CategoryManager";
import { checkValidWhitespaceOnly, checkValidPassword, checkValidOnlyNumber } from "../my-func/InputValidator";
import UserContext from "../UserContext";
import MessageContext from "../MessageContext";
import '../App.css';
import 'react-datepicker/dist/react-datepicker.css';
import { RecruitmentDetailSetting, CommonFormItems } from '../common-my-ui-components'
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, CircularProgress } from "@mui/material";
import styled from 'styled-components';
import { categoryMSByMasterAndCategoryName } from '../graphql/queries'
import { API, graphqlOperation } from "aws-amplify";
import { Flex } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';

const Page = styled.div`
  width:100%;
  padding: 2rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const Center = styled.table`
margin: auto; /* サークルを中央に配置 */
`;

// ルール詳細
const SubTitle = styled.div`
width:100%;
line-height: 160%;
font-size: 1.6rem;
font-weight: 900;
text-align: center;
margin:auto;
position: relative;
padding: 0.25em 0;

&::after {
  content: "";
  display: block;
  height: 4px;
  background: linear-gradient(90deg, ${(props) => (props.theme.palette.text.green)},
  ${(props) => (props.theme.palette.quinary.main)});
}
`;

const RecruitmentForm = (props) => {
  // 引数にrecruitmentDataが設定されていた場合は編集の画面として処理
  const { recruitmentData } = props;

  // ルール詳細のフォーム取得用
  const childRef = useRef();

  // ログインユーザーを取得
  const { user } = useContext(UserContext);
  const { setErrorMessage } = useContext(MessageContext);

  // 処理後に各画面に遷移させる
  const navigate = useNavigate();

  //現在のフォーム選択値を保存する
  // 現在のカテゴリの選択値を保存
  const [categoryNameFormData, setCategoryNameFormData] = useState([]);

  // 時間系の設定
  // 現在の日時を取得
  const currentDate = new Date();
  // 3日後を計算
  const threeDaysLater = new Date();
  threeDaysLater.setDate(currentDate.getDate() + 3);
  // 3日後の12時に設定
  threeDaysLater.setHours(12, 0, 0, 0);
  const [selectedEndDate, setSelectedEndDate] = useState(threeDaysLater);

  // 各初期値を取得
  // 編集時の募集の初期選択値を保存
  const [editRecruitmentData, setEditRecruitmentData] = useState();
  // カテゴリ選択肢リストを保存
  const [categoryList, setCategoryList] = useState([]);
  // 編集時のカテゴリの初期選択値を保存
  const [editCategoryData, setEditCategoryData] = useState([]);

  // 各状態を保存する
  // 処理中の状態を保存する
  const [isLoading, setIsLoading] = useState(false);
  // 募集終了チェック中
  const [isCloseRecruitment, setIsCloseRecruitment] = useState(false);
  // 募集削除チェック中
  const [isDeleteRecruitment, setIsDeleteRecruitment] = useState(false);
  // 募集自体が終了しているか確認
  const [isEnd, setIsEnd] = useState(false);

  // エラーメッセージ
  // タイトルのエラーメッセージ
  const [titleMessage, setTitleMessage] = useState(null);
  // 募集終了日のエラーメッセージ
  const [endDateMessage, setEndDateMessage] = useState(null);
  // 場所のエラーメッセージ
  const [placeMessage, setPlaceMessage] = useState(null);
  // 募集人数のエラーメッセージ
  const [recruitmentNumbersMessage, setRecruitmentNumbersMessage] = useState(null);
  //参加条件のエラーメッセージ
  const [participationConditionsMessage, setParticipationConditionsMessage] = useState(null);
  // パスワードのエラーメッセージ
  const [passwordMessage, setPasswordMessage] = useState(null);
  // 詳細のエラーメッセージ
  const [detailMessage, setDetailMessage] = useState(null);
  // カテゴリのエラーメッセージ
  const [categoryNameMessage, setCategoryNameMessage] = useState(null);

  // フォームの初期値を保存
  const defaultFormData = {
    id: "",
    title: "",
    startDateTime: new Date().toISOString(),
    endDateTime: selectedEndDate,
    place: "",
    recruitmentNumbers: undefined,
    detail: "",
    isEnd: "0",
    participationConditions: "",
    password: "",
    playNum: "4",
    gameRoundNum: "2",
    gameScore: "25000",
    redFive: "1",
    additionalYaku: "1",
    allSimples: "1",
    yakuBack: "1",
    swapCalling: "0",
    noDrawReach: "1",
    furitenDraw: "1",
    points30Doubles4: "1",
    nearestPlayerWin: "1",
    moreGameRound: "2",
    drawnGame: "0",
    additionalRound: "0",
    lastWinStop: "0",
    minusScore: "0",
    north_3: "0",
    drawLoss_3: "0",
    flower_3: "0"
  };

  // フォーム項目の設定
  const formItems = [
    { name: 'title', label: 'タイトル *', type: "text", message: titleMessage },
    { name: 'endDateTime', label: '募集終了日 *', type: "dateTime", value: selectedEndDate, setValue: setSelectedEndDate, message: endDateMessage },
    { name: 'place', label: '場所 *', type: "text", message: placeMessage },
    { name: 'recruitmentNumbers', label: '募集人数 *', type: "number", message: recruitmentNumbersMessage },
    { name: 'participationConditions', label: '参加条件', type: "text", message: participationConditionsMessage },
    { name: 'password', label: 'パスワード', type: "text", message: passwordMessage },
    { name: 'detail', label: '詳細 *', type: "textarea", message: detailMessage },
    { name: 'categoryName', label: 'カテゴリ名', type: "selectChip", chipFormData: categoryNameFormData, setChipFormData: setCategoryNameFormData, chipOptions: categoryList, message: categoryNameMessage }
  ];

  // 現在のフォームの選択値を保存
  const [formData, setFormData] = useState(defaultFormData);

  /**
   * 募集作成・編集・削除の処理を行う
   */
  const handleSubmit = async (e) => {
    // 画面のリロードを防ぐ
    e.preventDefault();

    // 押されたボタンを判別
    const submitButton = e.nativeEvent.submitter?.name;

    // 募集削除
    if (submitButton === "delete") {
      handleDeleteRecruitmentDialog();
      return;
    }

    //入力チェック
    if (!checkInputOK()) {
      return;
    }

    // 募集終了
    if (submitButton === "close") {
      handleCloseRecruitmentDialog();
      return;
    }

    // ボタンの二度押しを防ぐ
    setIsLoading(true);

    // 募集再開
    if (submitButton === "open") {
      const copyFormData = { ...formData, isEnd: '0', ...childRef.current.getDetailForm() };
      if (await editRecruitment(copyFormData, [], [], setErrorMessage)) {
        navigate(`/Recruitment/${recruitmentData.id}`);
      }
      setIsLoading(false);
    }

    // 募集作成
    if (submitButton === "register") {
      var copyFormData = { ...formData, ...childRef.current.getDetailForm() };
      const recruitmentId = await createRecruitment(copyFormData, categoryNameFormData, user.id, setErrorMessage);
      if (recruitmentId) {
        navigate(`/Recruitment/${recruitmentId}`);
      }
      setIsLoading(false);
    }

    // 募集編集
    if (submitButton === "edit") {
      const copyFormData = { ...formData, ...childRef.current.getDetailForm() };
      const addCategorys = [];
      const deleteCategorys = [];

      // 選択しているカテゴリが編集前のカテゴリに含まれていない場合は追加
      categoryNameFormData.map((categoryName) => {
        if (!editCategoryData.includes(categoryName)) addCategorys.push(categoryName);
      })
      // 編集前のカテゴリが選択しているカテゴリに含まれていない場合は削除
      editCategoryData.map((categoryName) => {
        if (!categoryNameFormData.includes(categoryName)) deleteCategorys.push(categoryName);
      })
      if (await editRecruitment(copyFormData, addCategorys, deleteCategorys, setErrorMessage)) {
        navigate(`/Recruitment/${recruitmentData.id}`);
      }
      setIsLoading(false);
    }
  };

  // 募集終了ボタン押下時に終了確認のダイアログを開く
  const handleCloseRecruitmentDialog = () => {
    setIsCloseRecruitment(!isCloseRecruitment);
  }

  // 募集終了処理を実施する
  const closeRecruitment = async () => {
    const copyFormData = { ...formData, isEnd: '1', ...childRef.current.getDetailForm() };
    if (await editRecruitment(copyFormData, [], [], setErrorMessage)) {
      navigate(`/Recruitment/${recruitmentData.id}`);
    }
    setIsLoading(false);
  }

  // 募集削除ボタン押下時に削除確認のダイアログを開く
  const handleDeleteRecruitmentDialog = () => {
    setIsDeleteRecruitment(!isDeleteRecruitment);
  }

  // 募集削除処理を実施する
  const doDeleteRecruitment = async () => {
    setIsLoading(true);
    if (await deleteRecruitment(formData, setErrorMessage)) {
      navigate(`/RecruitmentList`);
    }
    setIsLoading(false);
  }

  // 編集時には初期値を更新する
  const setEditRecruitment = async () => {
    // 編集の募集がある場合のみ処理を行う
    if (!recruitmentData || !user) return;
    // 編集用の募集情報を上書きする
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...recruitmentData
    }));
    setEditRecruitmentData(recruitmentData);
    setIsEnd(recruitmentData.isEnd === '1')
    setSelectedEndDate(new Date(recruitmentData.endDateTime));

    const editCategoryList = await getCategoryByRecruitmentListId(recruitmentData.id, setErrorMessage);
    const categoryNameData = [];
    editCategoryList.map((editCategory) => {
      categoryNameData.push(editCategory.categoryName);
    });
    setEditCategoryData(categoryNameData);
    setCategoryNameFormData(categoryNameData);
  };

  // 入力チェック
  const checkInputOK = () => {
    // 入力チェック
    var checkOK = true;

    // タイトル
    if (!formData.title) {
      setTitleMessage("タイトルを入力してください。")
      checkOK = false;
    } else if (checkValidWhitespaceOnly(formData.title)) {
      setTitleMessage("タイトルは空白のみで入力できません。")
      checkOK = false;
    } else {
      setTitleMessage(null);
    }

    // 募集終了日
    const today = new Date();
    //today.setUTCHours(15, 0, 0, 0);
    const todayISOString = today.toISOString();
    if (formData.endDateTime < todayISOString) {
      setEndDateMessage("募集終了日時は本日以降の日時を入力してください。")
      checkOK = false;
    } else {
      setEndDateMessage(null);
    }

    // 場所
    if (!formData.place) {
      setPlaceMessage("場所を入力してください。")
      checkOK = false;
    } else if (checkValidWhitespaceOnly(formData.place)) {
      setPlaceMessage("場所は空白のみで入力できません。")
      checkOK = false;
    } else {
      setPlaceMessage(null);
    }

    // 参加条件
    if (checkValidWhitespaceOnly(formData.participationConditions)) {
      setParticipationConditionsMessage("参加条件は空白のみで入力できません。")
      checkOK = false;
    } else {
      setParticipationConditionsMessage(null);
    }

    // パスワード
    if (formData.password && !checkValidPassword(formData.password)) {
      setPasswordMessage("パスワードは半角英数字・記号(@$!%*?&)で入力してください。")
      checkOK = false;
    } else {
      setPasswordMessage(null);
    }

    // 募集人数
    if (!formData.recruitmentNumbers) {
      setRecruitmentNumbersMessage("募集人数を入力してください。")
      checkOK = false;
    } else if (!checkValidOnlyNumber(formData.recruitmentNumbers)) {
      setRecruitmentNumbersMessage("募集人数は整数で入力してください。")
      checkOK = false;
    } else if (formData.recruitmentNumbers < 2) {
      setRecruitmentNumbersMessage("募集人数は2人以上で入力してください。")
      checkOK = false;
    } else {
      setRecruitmentNumbersMessage(null);
    }

    // 詳細
    if (!formData.detail) {
      setDetailMessage("詳細を入力してください。")
      checkOK = false;
    } else if (checkValidWhitespaceOnly(formData.detail)) {
      setDetailMessage("詳細は空白のみで入力できません。")
      checkOK = false;
    } else {
      setDetailMessage(null);
    }

    if (!checkOK) setErrorMessage(<div>入力内容に誤りがあります。</div>);
    return checkOK;
  }

  // ページアクセス時に作成か編集か判定する
  useEffect(() => {
    setEditRecruitment();
  }, [recruitmentData, user]);

  //  ページアクセス時にカテゴリ情報を取得し選択肢に追加する
  useEffect(() => {
    const opt = {
      master: '1',
      sortDirection: "ASC"
    };
    // 選択値用のマスタ1のデータを取得する
    API.graphql(graphqlOperation(categoryMSByMasterAndCategoryName, opt)).then((result) => {
      const data = result.data.categoryMSByMasterAndCategoryName.items;
      const categorys = [];
      data.map((item) => {
        item.value = item.categoryName;
        categorys.push(item)
      })
      setCategoryList(categorys)
    });
  }, []);

  return (
    <Page>
      <Flex direction={"column"} alignItems={'center'}>
        <SubTitle>募集内容</SubTitle>
      </Flex>

      <form onSubmit={handleSubmit}>
        <CommonFormItems inputformItems={formItems} formData={formData} setFormData={setFormData} />

        <Flex direction={"column"} alignItems={'center'}>
          <SubTitle>ルール詳細</SubTitle>
          <br></br>
        </Flex>

        <RecruitmentDetailSetting defaultRecruitmentData={editRecruitmentData} ref={childRef} />

        {recruitmentData && !isEnd ? (
          /** 編集時に表示されるボタン **/
          <div>
            <Flex direction={"row"} alignItems={'center'} justifyContent="center">
              <Button variant="contained" color="primary" type="submit" name="edit">保存</Button>
              <Button variant="contained" color="quaternary" type="submit" name="close">募集終了</Button>
            </Flex>
            <Flex direction={"row"} alignItems={'right'} justifyContent="right">
              <Button type="submit" name="delete">
                <span className="material-symbols-rounded">
                  delete
                </span>
                募集削除
              </Button>
            </Flex>
          </div>

        )
          : recruitmentData && isEnd ? (
            /** 終了している募集の編集時に表示されるボタン **/
            <div>
              <Flex direction={"row"} alignItems={'center'} justifyContent="center">
                <Button variant="contained" color="primary" type="submit" name="edit">保存</Button>
                <Button variant="contained" color="quaternary" type="submit" name="open">募集再開</Button>
              </Flex>
              <Flex direction={"row"} alignItems={'right'} justifyContent="right">
                <Button type="submit" name="delete">
                  <span className="material-symbols-rounded">
                    delete
                  </span>
                  募集削除
                </Button>
              </Flex>
            </div>
          )
            : (
              /** 新規登録時に表示されるボタン **/
              <Flex direction={"row"} alignItems={'center'} justifyContent="center">
                <Button variant="contained" color="primary" type="submit" name="register">募集登録</Button>
              </Flex>
            )}
      </form>

      {/* 更新処理ダイアログ */}
      <Dialog open={isLoading}>
        <DialogTitle>処理中</DialogTitle>
        <DialogContent>
          <Center>
            <CircularProgress />
          </Center>
        </DialogContent>
      </Dialog>

      {/* 募集終了ダイアログ */}
      <Dialog open={isCloseRecruitment} >
        <DialogTitle>募集終了</DialogTitle>
        <DialogContent>
          <DialogContentText>
            この募集を本当に終了しますか？<br></br>
            募集を終了しても再開することができます。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRecruitmentDialog}>キャンセル</Button>
          <Button onClick={closeRecruitment}>終了する</Button>
        </DialogActions>
      </Dialog>

      {/* 募集削除ダイアログ */}
      <Dialog open={isDeleteRecruitment} >
        <DialogTitle>募集削除</DialogTitle>
        <DialogContent>
          <DialogContentText>
            この募集を本当に削除しますか？<br></br>
            募集を削除すると復元することができません。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteRecruitmentDialog}>キャンセル</Button>
          <Button onClick={doDeleteRecruitment}>削除する</Button>
        </DialogActions>
      </Dialog>

    </Page>
  );
};

export default RecruitmentForm;