import { Grid } from "@aws-amplify/ui-react";
import { useState, useEffect, useContext } from "react";
import {
  UserIconListCol,
  GoBackButton,
} from "../../../common-my-ui-components";
import { Link } from "react-router-dom";
import { getRoomName } from "../../../my-func/getRoomName";
import UserContext from "../../../UserContext";
import { API } from "aws-amplify";
import {
  listRecruitmentListMS,
  recruitmentListMSByTalkRoomMIdAndId,
} from "../../../graphql/queries";
import styled from "styled-components";
import { HEADER_HEIGHT } from "../../../constants";

const HeaderGrid = styled(Grid)`
  height: ${HEADER_HEIGHT};
  background-color: ${(props) => props.theme.palette.tertiary.main};
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.span`
  cursor: pointer;
`;

const RoomNameContainer = styled.div`
  text-align: center;
  cursor: pointer;
  font-size: 0.8rem;
  margin: 0.5rem;
  align-self: center;
`;

const RoomLink = styled(Link)`
  color: ${(props) => props.theme.palette.text.green};
  text-decoration: none;
`;

const UserGrid = styled(Grid)`
  align-items: center;
  background-color: ${(props) => props.theme.palette.quaternary.main};
`;

const TalkRoomHeader = ({ talkRoomID, userMs, openModal }) => {
  const [roomName, setRoomName] = useState("Loading...");
  const [recruitmentM, setRecruitmentM] = useState(null);
  const { user } = useContext(UserContext);

  let limitedRoomName = roomName || "";
  if (limitedRoomName.length > 20) {
    limitedRoomName = limitedRoomName.substring(0, 20) + " …";
  }
  if (limitedRoomName.split("\n").length > 1) {
    limitedRoomName = limitedRoomName.split("\n")[0] + " …";
  }

  // トークルームに紐づく募集を取得
  useEffect(() => {
    if (user == null) {
      return;
    }
    const opt1 = {
      talkRoomMId: talkRoomID,
    };
    API.graphql({
      query: recruitmentListMSByTalkRoomMIdAndId,
      variables: opt1,
    }).then((result) => {
      let recruitmentMs = result.data.recruitmentListMSByTalkRoomMIdAndId.items;
      if (recruitmentMs.length > 0) {
        setRecruitmentM(recruitmentMs[0]);
      }
    });

    getRoomName(user.id, talkRoomID).then((result) => {
      setRoomName(result);
    });
  }, []);

  return (
    <>
      <HeaderGrid templateColumns="1fr 8fr 1fr">
        <GoBackButton />

        <RoomNameContainer>
          {recruitmentM ? (
            <RoomLink to={`/Recruitment/${recruitmentM.id}`}>
              {limitedRoomName}
            </RoomLink>
          ) : (
            <RoomLink>{limitedRoomName}</RoomLink>
          )}
        </RoomNameContainer>

        {/* <IconContainer>
          <Icon className="material-symbols-rounded">settings</Icon>
        </IconContainer> */}
      </HeaderGrid>

      <UserGrid templateColumns="9fr 1fr">
        <div className="d-flex overflow-auto">
          <UserIconListCol userMs={userMs} />
        </div>

        <IconContainer>
          <Icon className="material-symbols-rounded" onClick={openModal}>
            group
          </Icon>
        </IconContainer>
      </UserGrid>
    </>
  );
};

export default TalkRoomHeader;
