import React, { useState, useContext } from "react";
import { TextField, Button, Typography } from "@mui/material";
import { styled } from "styled-components";
import { createContactM } from "../../../graphql/mutations";
import { API } from "aws-amplify";
import UserContext from "../../../UserContext";
import MessageContext from "../../../MessageContext";

const MAXEMAILLENGTH = 100;
const MAXSUBJECTLENGTH = 100;
const MAXCONTENTLLENGTH = 1000;

const Container = styled.div`
  text-align: center;
  margin: 1rem;
  padding: 1rem;
  border-radius: 1%;
  font-size: 0.8rem;
  max-width: 50rem;
  align-self: center;
`;

const TableStyle = styled.table`
  text-align: center;
  margin: 0.5rem 0;
`;

const TdStyle = styled.td`
  text-align: left;
  padding: 0.1rem;
`;

const isEmailValid = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

const ContactForm = () => {
  const { user } = useContext(UserContext);
  const { setErrorMessage, setSuccessMessage } = useContext(MessageContext);

  const [formData, setFormData] = useState({
    subject: "",
    email: "",
    content: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    // If the field being changed is 'email', remove spaces
    if (name === 'email') {
      newValue = value.replace(/\s+/g, '').replace(/[^\x00-\x7F]/g, "");
    }
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isEmailValid(formData.email)) {
      setErrorMessage("無効なメールアドレスです。");
      return;
    }
    const usermIDValue = user && user.id ? user.id : "UnregisteredUser";

    try {
      API.graphql({ query: createContactM, variables: { input: { ...formData, usermID: usermIDValue } } });

      setFormData({
        subject: "",
        email: "",
        content: "",
      });

      setSuccessMessage("問い合わせが完了しました。");

    } catch (error) {
      setErrorMessage("問い合わせが失敗しました。");
    }
  };

  return (
    <Container>
      <TableStyle>
        <tr><th>雀の巣に関するご質問やご相談を幅広くお受けしています。</th></tr>
        <tr><TdStyle>サービスに関するご質問</TdStyle></tr>
        <tr><TdStyle>サービス向上のためのご意見</TdStyle></tr>
        <tr><TdStyle>違反ユーザーの報告</TdStyle></tr>
        <tr><TdStyle>その他お問い合わせ</TdStyle></tr>
      </TableStyle>

      <Typography variant="h5" align="center">
        お問い合わせ
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="メールアドレス"
          name="email"
          autoComplete="email"
          onChange={handleChange}
          value={formData.email}
          inputProps={{ maxLength: MAXEMAILLENGTH }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="subject"
          label="件名"
          name="subject"
          autoComplete="subject"
          onChange={handleChange}
          value={formData.subject}
          inputProps={{ maxLength: MAXSUBJECTLENGTH }}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          multiline
          rows={10}
          id="content"
          label="お問い合わせ内容"
          name="content"
          autoComplete="content"
          onChange={handleChange}
          value={formData.content}
          inputProps={{ maxLength: MAXCONTENTLLENGTH }}
        />
        <Button type="submit" fullWidth variant="contained" color="primary">
          送信
        </Button>
      </form>
    </Container>
  );
};

export default ContactForm;
