import '@aws-amplify/ui-react/styles.css';
import '../../../App.css';
import { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { exitRecruitment, entryRecruitment } from "../../../my-func/RecruitmentManager"
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, CircularProgress, TextField, Alert } from '@mui/material';
import styled from 'styled-components';
import { Flex } from '@aws-amplify/ui-react';
import MessageContext from "../../../MessageContext";

const LinkHover = styled.div`
a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
}
`;

const Center = styled.table`
margin: auto; /* サークルを中央に配置 */
`;


const RecruitmentButtons = (props) => {

  // 引数を受け取る
  // user:ログインユーザー情報
  // recruitment:募集情報
  // userIdList:参加ユーザーリスト
  // keyTime:ボタン作成時間 更新用
  // setKeyTime:募集内容ページの更新用ステート
  const { user, recruitment, userIdList, keyTime, setKeyTime } = props;

  const { setErrorMessage } = useContext(MessageContext);

  // チャットルームボタン
  const [chatButton, setChatButton] = useState("");
  // 編集・参加・退出ボタン
  const [entryButton, setEntryButton] = useState("");
  // パスワード入力値
  const [inputPassword, setInputPassword] = useState("");
  // パスワード入力値の正誤判定
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);

  // ロード中
  const [isLoading, setIsLoading] = useState(false);
  // 募集参加チェック中
  const [isEntryCheck, setIsEntryCheck] = useState(false);
  // 募集退出チェック中
  const [isExitCheck, setIsExitCheck] = useState(false);

  // ボタンを描画する
  useEffect(() => {
    drawButtons();
  }, [keyTime]);

  // 参加退出ボタンが変更されたことを確認してから処理中モーダルを閉じる
  useEffect(() => {
    setIsLoading(false);
  }, [entryButton]);

  // 募集登録処理
  const entryRecruitmentHandle = async () => {
    setIsEntryCheck(false)
    setIsLoading(true)
    await entryRecruitment(recruitment, user.id, setKeyTime, setErrorMessage);
  }

  // 募集退出処理
  const exitRecruitmentHandle = async () => {
    setIsExitCheck(false);
    setIsLoading(true);
    await exitRecruitment(recruitment, user.id, setKeyTime, setErrorMessage);
  }

  // 募集参加ボタン押下時に参加確認のダイアログを開く
  const openEntryDialog = () => {
    if (recruitment.password) {
      setIsEntryCheck(true);
    } else {
      entryRecruitmentHandle();
    }
  }

  // 参加確認ダイアログを閉じる
  const closeEntryDialog = () => {
    setIsInvalidPassword(false);
    setIsEntryCheck(false);
  }

  // 募集参加ボタン押下時に参加資格の有無をチェックする
  const checkEntry = () => {
    if (recruitment.password === inputPassword) {
      setIsEntryCheck(false);
      entryRecruitmentHandle();
    } else {
      setIsInvalidPassword(true);
    }
  }

  // パスワード入力値を更新する
  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputPassword(value);
  };

  // 募集退出ボタン押下時に退出確認のダイアログを開く
  const openExitDialog = () => {
    setIsExitCheck(true);
  }

  // 退出確認ダイアログを閉じる
  const closeExitDialog = () => {
    setIsExitCheck(false);
  }

  async function drawButtons() {
    // ユーザー属性によって表示するボタンを制御
    setEntryButton("");
    setChatButton("");

    if (!user) {
      setEntryButton("募集に参加するにはログインしてください。")
    } else if (user.id == recruitment.userID) {
      // 募集作成者
      setEntryButton(<LinkHover><Link to={`/RecruitmentEdit/${recruitment.id}`}>
        <Button variant="contained" color="quaternary" >募集編集</Button></Link></LinkHover>)
      setChatButton(<LinkHover><Link to={`/TalkRoom/${recruitment.talkRoomMId}`}>
        <Button variant="contained" color="quaternary" >トークルーム</Button></Link></LinkHover>)
    } else if (userIdList.includes(user.id)) {
      // ログインユーザーが参加済み
      setEntryButton(<Button variant="contained" color="quaternary"
        onClick={() => openExitDialog()} >募集退出</Button>)
      setChatButton(<LinkHover><Link to={`/TalkRoom/${recruitment.talkRoomMId}`}>
        <Button variant="contained" color="quaternary">トークルーム</Button></Link></LinkHover>)
    } else if (!user.isOver18) {
      setEntryButton("18歳未満は募集に参加することができません。")
    } else if (recruitment.isEnd === "1") {
      setEntryButton("募集が終了されているため参加できません。")
    } else if (recruitment.isEnd != "1" && userIdList.length + 1 < recruitment.recruitmentNumbers) {
      // 参加可能ユーザー 募集が終了しておらず、参加人数が定員に達していない
      // 条件で1を足しているのはuserIdListが募集作成者を含めていないため
      setEntryButton(<Button variant="contained" color="quaternary"
        onClick={() => openEntryDialog()} > 募集参加</Button>)
    }

  }

  return (
    <div>
      <Flex direction="row" justifyContent="center">
        {entryButton}
        {chatButton}
      </Flex>

      {/* 処理中ダイアログ */}
      <Dialog open={isLoading}>
        <DialogTitle>処理中</DialogTitle>
        <DialogContent>
          <Center>
            <CircularProgress />
          </Center>
        </DialogContent>
      </Dialog>

      {/* 募集退出ダイアログ */}
      <Dialog open={isExitCheck} >
        <DialogTitle>募集退出</DialogTitle>
        <DialogContent>
          <DialogContentText>
            この募集から本当に退出しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeExitDialog}>キャンセル</Button>
          <Button onClick={exitRecruitmentHandle}>退出する</Button>
        </DialogActions>
      </Dialog>

      {/* 募集参加ダイアログ */}
      <Dialog open={isEntryCheck} >
        <DialogTitle>募集参加</DialogTitle>
        <DialogContent>
          <DialogContentText>
            この募集にはパスワードが設定されています。
          </DialogContentText>
          <TextField
            margin="dense"
            id="name"
            label="パスワード入力"
            type="password"
            fullWidth
            variant="standard"
            onChange={handleInputChange}
          />
          <Alert severity="warning" hidden={!isInvalidPassword}>パスワードが正しくありません。</Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEntryDialog}>キャンセル</Button>
          <Button onClick={checkEntry}>完了</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default RecruitmentButtons;
