import { API, graphqlOperation } from "aws-amplify";
import { RecruitmentListM } from '../models';
import { matchingReserveMSByUsermID, getMatchingM, matchingReserveMSByStatusAndRegisterDate } from '../graphql/queries'
import {
  createMatchingReserveM, updateMatchingReserveM, deleteMatchingReserveM
  , updateMatchingM
} from '../graphql/mutations';

/**
 * ユーザーIDから終了していないマッチング予約を取得する
 * @param {String} usermID ユーザーID
 * @returns {MatchingReserveM} MatchingReserveM マッチング予約情報
 * 
 * @async
 * @function getMatchingReserveMByUsermID
 */
const getMatchingReserveMByUsermID = async (usermID, setErrorMessage) => {
  try {
    // マッチング予約取得
    const opt = {
      usermID: usermID,
      filter: {
        and: [
          { status: { ne: 9 } }
        ]
      },
    };
    var matchingReserveM = (await API.graphql(graphqlOperation(matchingReserveMSByUsermID, opt)))
      .data.matchingReserveMSByUsermID.items;
    matchingReserveM = matchingReserveM.filter((item) => item._deleted != true);
    return matchingReserveM;
  } catch (error) {
    setErrorMessage(<div>
      マッチング情報取得時にエラーが発生しました。<br />
      しばらくしてからもう一度お試しください。
    </div>);
  }
};

/**
 * マッチング情報を取得する
 * @returns {MatchingReserveM} MatchingM マッチング情報
 * 
 * @async
 * @function getMatchingList
 */
const getMatchingReserveMByRegisterDateandUsrMId = async (usermID, setErrorMessage) => {
  try {
    var nextToken = null
    var matchingReserveList = [];
    do {
      // マッチング取得
      const opt = {
        status: "9",
        filter: {
          and: [
            { usermID: { eq: usermID } }
          ]
        },
        nextToken: nextToken,
        sortDirection: "DESC"
      };

      var getDataList = (await API.graphql(graphqlOperation(
        matchingReserveMSByStatusAndRegisterDate, opt))).data.matchingReserveMSByStatusAndRegisterDate;

      matchingReserveList = matchingReserveList.concat(getDataList.items.filter((item) => item._deleted != true));
      nextToken = getDataList.nextToken;
    } while (nextToken)
    return matchingReserveList;
  } catch (error) {
    setErrorMessage(<div>
      マッチング情報取得時にエラーが発生しました。<br />
      しばらくしてからもう一度お試しください。
    </div>);
  }
};

/**
 * マッチングIDからマッチング情報を取得する
 * @param {String} id マッチングid
 * @returns {MatchingM} MatchingM マッチング情報
 * 
 * @async
 * @function createCategory
 */
const getMatching = async (id, setErrorMessage) => {
  try {
    // マッチング取得
    const opt = {
      id: id
    };
    return (await API.graphql(graphqlOperation(
      getMatchingM, opt))).data.getMatchingM;
  } catch (error) {
    setErrorMessage(<div>
      マッチング情報取得時にエラーが発生しました。<br />
      しばらくしてからもう一度お試しください。
    </div>);
  }
};



/**
 * マッチング予約を登録する
 * @param {String} usermID ユーザーID
 * @returns {RecruitmentListM} MatchingReserveM マッチング予約情報
 * 
 * @async
 * @function getMatchingReserveMByUsermID
 */
const registerMatchingReserve = async (matchingReserveM, user, setErrorMessage) => {
  try {
    // 1人につきマッチング登録は1つとするため、重複して登録できないようにチェック
    const nowMatchingReserve = await getMatchingReserveMByUsermID(user.id);
    if (nowMatchingReserve[0]) {
      setErrorMessage(<div>
        すでにマッチング登録済みです。
      </div>);
      return;
    }
    const nowDate = new Date();
    // マッチング予約
    const matchingData = {
      input: {
        usermID: user.id,
        age: matchingReserveM.age,
        prefecture: matchingReserveM.prefecture,
        place: matchingReserveM.place,
        minAge: matchingReserveM.minAge,
        maxAge: matchingReserveM.maxAge,
        playNum: matchingReserveM.playNum,
        skill: matchingReserveM.skill,
        requiredSkill: matchingReserveM.requiredSkill,
        desiredDate: matchingReserveM.desiredDate,
        registerDate: nowDate,
        status: "1",
        matchingMId: "dummyId",
        noticeMail: matchingReserveM.noticeMail,
      }
    }
    await API.graphql({
      query: createMatchingReserveM,
      variables: matchingData,
    }).then((result) => {
      return result.data.createMatchingReserveM;
    });

  } catch (error) {
    setErrorMessage(<div>
      マッチング情報登録時にエラーが発生しました。<br />
      しばらくしてからもう一度お試しください。
    </div>);
  }
};

/**
 * マッチング予約を編集する
 * @param {Object} matchingReserve 既存のマッチング情報
 * @param {Object} updateMatchingReserveM 更新対象マッチング情報
 * @returns {RecruitmentListM} MatchingReserveM 更新済みマッチング予約情報
 * 
 * @async
 * @function editMatchingReserveM
 */
const editMatchingReserve = async (matchingReserve, updateMatchingReserve, setErrorMessage) => {
  try {
    // マッチング予約
    const matchingData = {
      input: {
        id: matchingReserve.id,
        age: updateMatchingReserve.age,
        prefecture: updateMatchingReserve.prefecture,
        place: updateMatchingReserve.place,
        minAge: updateMatchingReserve.minAge,
        maxAge: updateMatchingReserve.maxAge,
        playNum: updateMatchingReserve.playNum,
        skill: updateMatchingReserve.skill,
        status: "1",
        matchingMId: "dummyId",
        requiredSkill: updateMatchingReserve.requiredSkill,
        desiredDate: updateMatchingReserve.desiredDate,
        noticeMail: updateMatchingReserve.noticeMail,
        _version: updateMatchingReserve._version
      }
    }
    await API.graphql({
      query: updateMatchingReserveM,
      variables: matchingData,
    }).then((result) => {
      return result.data.updateMatchingReserveM;
    });

  } catch (error) {
    setErrorMessage(<div>
      マッチング情報登録時にエラーが発生しました。<br />
      しばらくしてからもう一度お試しください。
    </div>);
  }
};

/**
 * マッチング予約を削除する
 * @returns {RecruitmentListM} MatchingReserveM マッチング予約情報
 * @async
 * @function deleteMatchingReserveM
 */
const deleteMatchingReserve = async (matchingReserve, setErrorMessage) => {
  try {
    // レコード削除
    API.graphql({
      query: deleteMatchingReserveM,
      variables: {
        input: {
          id: matchingReserve.id,
          _version: matchingReserve._version
        }
      }
    });

  } catch (error) {
    setErrorMessage(<div>
      マッチング情報削除時にエラーが発生しました。<br />
      しばらくしてからもう一度お試しください。
    </div>);
  }
};

/**
 * 破棄されたマッチング予約を再登録する
 * @param {Object} matchingReserve 既存のマッチング情報
 * @param {Object} updateMatchingReserveM 更新対象マッチング情報
 * @returns {RecruitmentListM} MatchingReserveM 更新済みマッチング予約情報
 * 
 * @async
 * @function editMatchingReserveM
 */
const reEntryMatchingReserve = async (matchingReserve, updateMatchingReserve, setErrorMessage) => {
  try {
    const nowDate = new Date();
    // マッチング予約
    const matchingData = {
      input: {
        id: matchingReserve.id,
        age: updateMatchingReserve.age,
        prefecture: updateMatchingReserve.prefecture,
        place: updateMatchingReserve.place,
        minAge: updateMatchingReserve.minAge,
        maxAge: updateMatchingReserve.maxAge,
        playNum: updateMatchingReserve.playNum,
        skill: updateMatchingReserve.skill,
        requiredSkill: updateMatchingReserve.requiredSkill,
        desiredDate: updateMatchingReserve.desiredDate,
        registerDate: nowDate,
        status: "1",
        matchingMId: "dummyId",
        noticeMail: updateMatchingReserve.noticeMail,
        _version: updateMatchingReserve._version
      }
    }
    await API.graphql({
      query: updateMatchingReserveM,
      variables: matchingData,
    }).then((result) => {
      return result.data.updateMatchingReserveM;
    });

  } catch (error) {
    setErrorMessage(<div>
      マッチング情報登録時にエラーが発生しました。<br />
      しばらくしてからもう一度お試しください。
    </div>);
  }
};

/**
 * マッチング予約の入力チェック
 * @param {Object} formData マッチング予約フォームデータ
 * @param {Object} setMessaages 各エラーメッセージsetter配列
 * @returns {boolean} checkOK 入力チェック判定結果
 * 
 * @function checkInputOK
 */
const checkInputOK = (formData, setMessaages) => {
  // 入力チェック
  var checkOK = true;

  // 年齢
  if (formData.age === "0") {
    setMessaages.age("あなたの年齢を選択してください。")
    checkOK = false;
  } else {
    setMessaages.age(null);
  }

  // 腕前
  if (formData.skill === "0") {
    setMessaages.skill("あなたの腕前を選択してください。")
    checkOK = false;
  } else {
    setMessaages.skill(null);
  }

  // プレイ人数
  if (formData.playNum === "0") {
    setMessaages.playNum("プレイ人数を選択してください。")
    checkOK = false;
  } else {
    setMessaages.playNum(null);
  }

  // 場所
  if (formData.place === "") {
    setMessaages.place("場所を1つ以上選択してください。")
    checkOK = false;
  } else {
    setMessaages.place(null);
  }

  // 年齢制限
  if (parseInt(formData.maxAge) * parseInt(formData.minAge) != 0 &&
    parseInt(formData.maxAge) < parseInt(formData.minAge)) {
    setMessaages.minAge("年齢下限は上限を超えないように選択してください。")
    setMessaages.maxAge("年齢上限は下限を超えないように選択してください。")
    checkOK = false;
  } else {
    setMessaages.minAge(null)
    setMessaages.maxAge(null)
  }

  // 希望日
  if (!formData.desiredDate) {
    checkOK = false;
    setMessaages.desiredDate("希望日を入力してください。");
  } else {
    const isWithoutRange = (dateString) => {
      const today = new Date();
      const targetDate = new Date(dateString);
      // 3日後の日付を計算
      const threeDaysLater = new Date(today.getTime() + 3 * 24 * 60 * 60 * 1000);
      // 3ヶ月後の1日の日付を取得
      const threeMonthsLater = new Date(today.getFullYear(), today.getMonth() + 3, 1);
      // 本日から3日後以降かつ3ヶ月未満であるかを確認
      return targetDate < threeDaysLater || threeMonthsLater <= targetDate;
    }

    setMessaages.desiredDate(null);
    // カンマで分割して希望日の配列を作成
    const dates = formData.desiredDate.split(',');
    dates.forEach(dateString => {
      if (isWithoutRange(dateString)) {
        checkOK = false;
        setMessaages.desiredDate("希望日は3日後以降・2ヶ月後の月末まで、最大10日まで選択できます");
      }
    });
  }

  if (!checkOK) {
    setMessaages.errorMessage(<div>入力内容に誤りがあります。</div>)
  }

  return checkOK;
};

/**
 * マッチングのステータスを更新する
 * @param {Object} matching 既存のマッチング情報
 * @returns {Object} user 承認ユーザー
 * 
 * @async
 * @function updateMatchingStatus
 */
const updateMatchingStatus = async (matching, user, status, setErrorMessage) => {
  try {
    const matchingData = {
      input: {
        id: matching.id,
        _version: matching._version
      }
    }
    if (matching.userm1ID === user.id) {
      matchingData.input.user1Confirm = status;
    } else if (matching.userm2ID === user.id) {
      matchingData.input.user2Confirm = status;
    } else if (matching.userm3ID === user.id) {
      matchingData.input.user3Confirm = status;
    } else if (matching.userm4ID === user.id) {
      matchingData.input.user4Confirm = status;
    }
    await API.graphql({
      query: updateMatchingM,
      variables: matchingData,
    }).then((result) => {
      return result.data.updateMatchingM;
    });

  } catch (error) {
    setErrorMessage(<div>
      マッチング承認時にエラーが発生しました。<br />
      しばらくしてからもう一度お試しください。
    </div>);
  }
};

/**
 * マッチング予約を編集する
 * @param {Object} matchingReserve 既存のマッチング情報
 * @param {String} status 更新ステータス
 * @returns {RecruitmentListM} MatchingReserveM 更新済みマッチング予約情報
 * 
 * @async
 * @function updateMatchingReserveStatus
 */
const updateMatchingReserveStatus = async (matchingReserve, status, setErrorMessage) => {
  try {

    // マッチング予約
    const matchingData = {
      input: {
        id: matchingReserve.id,
        status: status,
        _version: matchingReserve._version
      }
    }
    await API.graphql({
      query: updateMatchingReserveM,
      variables: matchingData,
    }).then((result) => {
      return result.data.updateMatchingReserveM;
    });

  } catch (error) {
    setErrorMessage(<div>
      マッチング情報更新時にエラーが発生しました。<br />
      しばらくしてからもう一度お試しください。
    </div>);
  }
};

export {
  getMatchingReserveMByUsermID,
  getMatchingReserveMByRegisterDateandUsrMId,
  getMatching,
  registerMatchingReserve,
  editMatchingReserve,
  deleteMatchingReserve,
  checkInputOK,
  updateMatchingStatus,
  reEntryMatchingReserve,
  updateMatchingReserveStatus
};
