import { Auth, DataStore } from "aws-amplify"
import { graphqlOperation, API } from "aws-amplify"
import { listUserMS, userMSByCognitoUsername } from "../graphql/queries"

export async function getUserByCognitoUser(cognitoUser, setErrorMessage) {
  if (cognitoUser) {
    // 繰り返し処理が必要
    try {
      const opt1 = {
        cognitoUsername: cognitoUser.username
      }
      const userMQLvalues = await API.graphql(graphqlOperation(userMSByCognitoUsername, opt1))
      const values = userMQLvalues.data.userMSByCognitoUsername.items
      return values.length !== 0 ? values[0] : null
    } catch (error) {
      setErrorMessage(
        <div>
          ユーザー情報取得時にエラーが発生しました。
          <br />
          しばらくしてからもう一度お試しください。
        </div>
      )
      return null
    }
  }
}

export async function getCognitoUser() {
  try {
    const currentUser = await Auth.currentAuthenticatedUser()
    return currentUser
  } catch (error) {
    // ユーザーが取得できなかった場合は未ログインユーザーとして処理
    return null
  }
}
