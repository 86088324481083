import { listMessageMSByTalkroommID } from '../graphql/queries';
import { API } from "aws-amplify";
import { openIndexedDB,saveToIndexedDB,deleteOldDataFromIndexedDB,fetchFromIndexedDB } from "../my-func/indexedDBUtils";

/**
 * 表示中のデータよりも新しいデータを取得する。
 *
 * @param {string} talkRoomID - トークルームID
 * @param {Array} talkData - トークデータ
 * @return {Promise<Array>} 更新されたトークデータの配列
 */
const getNewTalkData = async (talkRoomID, currntTalkData, MESSAGE_FETCH_LIMIT) => {
  let talkData=[...currntTalkData]
  talkData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const latestMessageDatetime = talkData.length !== 0 ? talkData[0].createdAt : "2023-01-01T00:00:00.000Z";
  
  let nextToken = null, newTalkData = [], newMessages = [];

  do {
    const opt = {
      talkroommID: talkRoomID,
      limit: MESSAGE_FETCH_LIMIT,
      nextToken: nextToken,
      sortDirection: "DESC",
    };
    await API.graphql({ query: listMessageMSByTalkroommID, variables: opt }).then((result) => {
      newMessages = result.data.listMessageMSByTalkroommID.items;
      newMessages = newMessages.filter(item => new Date(item.createdAt) > new Date(latestMessageDatetime));
      nextToken = result.data.listMessageMSByTalkroommID.nextToken;
    });

    if (newMessages.length === 0) {
      break;
    }

    newTalkData = newTalkData.concat(newMessages);

  } while (nextToken);

  talkData = newTalkData.concat(talkData);
  // 表示のために昇順にソート
  talkData.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  return talkData;

};

/**
 * talkDataの一番古いデータよりも古いデータを取得します。
 * 
 * @param {string} talkRoomID - トークルームID
 * @param {Array} talkData - トークデータ
 * @return {Promise<Array>} 取得したトークデータ
 */
const getOldTalkData = async (talkRoomID, currntTalkData, MESSAGE_FETCH_LIMIT) => {
  let talkData=[...currntTalkData]
  // トークデータの中で最古の日付を取得
  talkData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const oldestDispMessageCDatetime = talkData.length !== 0 ? talkData[talkData.length - 1].createdAt : new Date().toISOString();

  // indexedDBからメッセージを取得する。
  const db = await openIndexedDB("talkDataDB", talkRoomID, "id");
  const storedTalkData = await fetchFromIndexedDB(db, talkRoomID);
  const filterdTalkData=storedTalkData.filter(item => new Date(item.createdAt) < new Date(oldestDispMessageCDatetime));
  if (filterdTalkData.length>=MESSAGE_FETCH_LIMIT){
    const sortedStoredTalkData = filterdTalkData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    // sortedStoredTalkDataを先頭に20件に絞り込む
    const additionalTalkData= sortedStoredTalkData.slice(0, MESSAGE_FETCH_LIMIT);
    talkData = talkData.concat(additionalTalkData);
  }else{
    let newMessages=[];
  
    const opt = {
      talkroommID: talkRoomID,
      limit: MESSAGE_FETCH_LIMIT,
      // nextToken: nextToken,
      sortDirection: "DESC",
      createdAt: {
        lt: oldestDispMessageCDatetime  // createdAtがoldestDispMessageCDatetimeより前のデータだけを取得
      }
    };
    
    await API.graphql({ query: listMessageMSByTalkroommID, variables: opt }).then((result)=>{
      newMessages = result.data.listMessageMSByTalkroommID.items;
      talkData = talkData.concat(newMessages);
      // setNextToken(result.data.listMessageMSByTalkroommID.nextToken); //次回実行時に続きからデータを取得するために親要素のステートフックに値をセット
    });
    await saveToIndexedDB(db, talkRoomID, talkData);
  }

  talkData.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  return talkData;
}


export { getNewTalkData, getOldTalkData };