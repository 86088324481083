import React from "react"
import { Link } from "react-router-dom"
import { TalkRoomInfo } from "./"
import styled from 'styled-components';

const CustomLink = styled(Link)`
text-decoration: none;
color: inherit;
`;

export default function TalkRoomItem({ talkRoomM, latestMessageM, countNewMessage, onPointerUp, onPointerDown }) {
  return (
    <div key={talkRoomM.id}>
      <CustomLink
        to={`/TalkRoom/${talkRoomM.id}`}
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
        onPointerCancel={onPointerUp}
      >
        <TalkRoomInfo talkRoomM={talkRoomM} messageM={latestMessageM} countNewMessage={countNewMessage} />
      </CustomLink>
      <hr style={{ margin: "0" }}></hr>
    </div>
  )
}
