/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRecruitmentHistoryM = /* GraphQL */ `
  mutation CreateRecruitmentHistoryM(
    $input: CreateRecruitmentHistoryMInput!
    $condition: ModelRecruitmentHistoryMConditionInput
  ) {
    createRecruitmentHistoryM(input: $input, condition: $condition) {
      id
      usermID
      recruitmentlistmID
      startDateTime
      entryDateTime
      exitDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRecruitmentHistoryM = /* GraphQL */ `
  mutation UpdateRecruitmentHistoryM(
    $input: UpdateRecruitmentHistoryMInput!
    $condition: ModelRecruitmentHistoryMConditionInput
  ) {
    updateRecruitmentHistoryM(input: $input, condition: $condition) {
      id
      usermID
      recruitmentlistmID
      startDateTime
      entryDateTime
      exitDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRecruitmentHistoryM = /* GraphQL */ `
  mutation DeleteRecruitmentHistoryM(
    $input: DeleteRecruitmentHistoryMInput!
    $condition: ModelRecruitmentHistoryMConditionInput
  ) {
    deleteRecruitmentHistoryM(input: $input, condition: $condition) {
      id
      usermID
      recruitmentlistmID
      startDateTime
      entryDateTime
      exitDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserTalkRoomM = /* GraphQL */ `
  mutation CreateUserTalkRoomM(
    $input: CreateUserTalkRoomMInput!
    $condition: ModelUserTalkRoomMConditionInput
  ) {
    createUserTalkRoomM(input: $input, condition: $condition) {
      id
      talkroommID
      usermID
      hidden
      lastViewTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserTalkRoomM = /* GraphQL */ `
  mutation UpdateUserTalkRoomM(
    $input: UpdateUserTalkRoomMInput!
    $condition: ModelUserTalkRoomMConditionInput
  ) {
    updateUserTalkRoomM(input: $input, condition: $condition) {
      id
      talkroommID
      usermID
      hidden
      lastViewTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserTalkRoomM = /* GraphQL */ `
  mutation DeleteUserTalkRoomM(
    $input: DeleteUserTalkRoomMInput!
    $condition: ModelUserTalkRoomMConditionInput
  ) {
    deleteUserTalkRoomM(input: $input, condition: $condition) {
      id
      talkroommID
      usermID
      hidden
      lastViewTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createMessageM = /* GraphQL */ `
  mutation CreateMessageM(
    $input: CreateMessageMInput!
    $condition: ModelMessageMConditionInput
  ) {
    createMessageM(input: $input, condition: $condition) {
      id
      content
      talkroommID
      usermID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateMessageM = /* GraphQL */ `
  mutation UpdateMessageM(
    $input: UpdateMessageMInput!
    $condition: ModelMessageMConditionInput
  ) {
    updateMessageM(input: $input, condition: $condition) {
      id
      content
      talkroommID
      usermID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteMessageM = /* GraphQL */ `
  mutation DeleteMessageM(
    $input: DeleteMessageMInput!
    $condition: ModelMessageMConditionInput
  ) {
    deleteMessageM(input: $input, condition: $condition) {
      id
      content
      talkroommID
      usermID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createNotificationTokenM = /* GraphQL */ `
  mutation CreateNotificationTokenM(
    $input: CreateNotificationTokenMInput!
    $condition: ModelNotificationTokenMConditionInput
  ) {
    createNotificationTokenM(input: $input, condition: $condition) {
      id
      usermID
      token
      enabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateNotificationTokenM = /* GraphQL */ `
  mutation UpdateNotificationTokenM(
    $input: UpdateNotificationTokenMInput!
    $condition: ModelNotificationTokenMConditionInput
  ) {
    updateNotificationTokenM(input: $input, condition: $condition) {
      id
      usermID
      token
      enabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteNotificationTokenM = /* GraphQL */ `
  mutation DeleteNotificationTokenM(
    $input: DeleteNotificationTokenMInput!
    $condition: ModelNotificationTokenMConditionInput
  ) {
    deleteNotificationTokenM(input: $input, condition: $condition) {
      id
      usermID
      token
      enabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createRecruitmentListM = /* GraphQL */ `
  mutation CreateRecruitmentListM(
    $input: CreateRecruitmentListMInput!
    $condition: ModelRecruitmentListMConditionInput
  ) {
    createRecruitmentListM(input: $input, condition: $condition) {
      id
      title
      startDateTime
      place
      recruitmentNumbers
      detail
      userID
      isEnd
      endDateTime
      participationConditions
      password
      playNum
      gameRoundNum
      gameScore
      redFive
      additionalYaku
      allSimples
      yakuBack
      swapCalling
      noDrawReach
      furitenDraw
      points30Doubles4
      nearestPlayerWin
      moreGameRound
      drawnGame
      additionalRound
      lastWinStop
      minusScore
      north_3
      drawLoss_3
      flower_3
      talkRoomMId
      TalkRoomM {
        id
        roomName
        MessageMS {
          nextToken
          startedAt
        }
        UserTalkRoomM {
          nextToken
          startedAt
        }
        countMessage
        iconSrc
        isDirect
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      RecruitmentHistoryMS {
        items {
          id
          usermID
          recruitmentlistmID
          startDateTime
          entryDateTime
          exitDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRecruitmentListM = /* GraphQL */ `
  mutation UpdateRecruitmentListM(
    $input: UpdateRecruitmentListMInput!
    $condition: ModelRecruitmentListMConditionInput
  ) {
    updateRecruitmentListM(input: $input, condition: $condition) {
      id
      title
      startDateTime
      place
      recruitmentNumbers
      detail
      userID
      isEnd
      endDateTime
      participationConditions
      password
      playNum
      gameRoundNum
      gameScore
      redFive
      additionalYaku
      allSimples
      yakuBack
      swapCalling
      noDrawReach
      furitenDraw
      points30Doubles4
      nearestPlayerWin
      moreGameRound
      drawnGame
      additionalRound
      lastWinStop
      minusScore
      north_3
      drawLoss_3
      flower_3
      talkRoomMId
      TalkRoomM {
        id
        roomName
        MessageMS {
          nextToken
          startedAt
        }
        UserTalkRoomM {
          nextToken
          startedAt
        }
        countMessage
        iconSrc
        isDirect
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      RecruitmentHistoryMS {
        items {
          id
          usermID
          recruitmentlistmID
          startDateTime
          entryDateTime
          exitDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRecruitmentListM = /* GraphQL */ `
  mutation DeleteRecruitmentListM(
    $input: DeleteRecruitmentListMInput!
    $condition: ModelRecruitmentListMConditionInput
  ) {
    deleteRecruitmentListM(input: $input, condition: $condition) {
      id
      title
      startDateTime
      place
      recruitmentNumbers
      detail
      userID
      isEnd
      endDateTime
      participationConditions
      password
      playNum
      gameRoundNum
      gameScore
      redFive
      additionalYaku
      allSimples
      yakuBack
      swapCalling
      noDrawReach
      furitenDraw
      points30Doubles4
      nearestPlayerWin
      moreGameRound
      drawnGame
      additionalRound
      lastWinStop
      minusScore
      north_3
      drawLoss_3
      flower_3
      talkRoomMId
      TalkRoomM {
        id
        roomName
        MessageMS {
          nextToken
          startedAt
        }
        UserTalkRoomM {
          nextToken
          startedAt
        }
        countMessage
        iconSrc
        isDirect
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      RecruitmentHistoryMS {
        items {
          id
          usermID
          recruitmentlistmID
          startDateTime
          entryDateTime
          exitDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCategoryM = /* GraphQL */ `
  mutation CreateCategoryM(
    $input: CreateCategoryMInput!
    $condition: ModelCategoryMConditionInput
  ) {
    createCategoryM(input: $input, condition: $condition) {
      id
      categoryName
      recruitmentListId
      master
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCategoryM = /* GraphQL */ `
  mutation UpdateCategoryM(
    $input: UpdateCategoryMInput!
    $condition: ModelCategoryMConditionInput
  ) {
    updateCategoryM(input: $input, condition: $condition) {
      id
      categoryName
      recruitmentListId
      master
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCategoryM = /* GraphQL */ `
  mutation DeleteCategoryM(
    $input: DeleteCategoryMInput!
    $condition: ModelCategoryMConditionInput
  ) {
    deleteCategoryM(input: $input, condition: $condition) {
      id
      categoryName
      recruitmentListId
      master
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserM = /* GraphQL */ `
  mutation CreateUserM(
    $input: CreateUserMInput!
    $condition: ModelUserMConditionInput
  ) {
    createUserM(input: $input, condition: $condition) {
      id
      cognitoUsername
      name
      prefecture
      age
      hobby
      introduction
      skill
      canCalculateScore
      mainMahjongType
      canOperateTable
      favoriteRole
      mahjongStartReason
      mahjongStudyMethod
      RecruitmentLists {
        items {
          id
          title
          startDateTime
          place
          recruitmentNumbers
          detail
          userID
          isEnd
          endDateTime
          participationConditions
          password
          playNum
          gameRoundNum
          gameScore
          redFive
          additionalYaku
          allSimples
          yakuBack
          swapCalling
          noDrawReach
          furitenDraw
          points30Doubles4
          nearestPlayerWin
          moreGameRound
          drawnGame
          additionalRound
          lastWinStop
          minusScore
          north_3
          drawLoss_3
          flower_3
          talkRoomMId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      MessageM {
        items {
          id
          title
          startDateTime
          place
          recruitmentNumbers
          detail
          userID
          isEnd
          endDateTime
          participationConditions
          password
          playNum
          gameRoundNum
          gameScore
          redFive
          additionalYaku
          allSimples
          yakuBack
          swapCalling
          noDrawReach
          furitenDraw
          points30Doubles4
          nearestPlayerWin
          moreGameRound
          drawnGame
          additionalRound
          lastWinStop
          minusScore
          north_3
          drawLoss_3
          flower_3
          talkRoomMId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      UserTalkRoomM {
        items {
          id
          talkroommID
          usermID
          hidden
          lastViewTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      NotificationTokenM {
        items {
          id
          usermID
          token
          enabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      iconSrc
      RecruitmentHistoryMS {
        items {
          id
          usermID
          recruitmentlistmID
          startDateTime
          entryDateTime
          exitDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      identityId
      isOver18
      isAgreeTerms
      isSuspended
      lastLoginAt
      loginDays
      feedbackSent
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserM = /* GraphQL */ `
  mutation UpdateUserM(
    $input: UpdateUserMInput!
    $condition: ModelUserMConditionInput
  ) {
    updateUserM(input: $input, condition: $condition) {
      id
      cognitoUsername
      name
      prefecture
      age
      hobby
      introduction
      skill
      canCalculateScore
      mainMahjongType
      canOperateTable
      favoriteRole
      mahjongStartReason
      mahjongStudyMethod
      RecruitmentLists {
        items {
          id
          title
          startDateTime
          place
          recruitmentNumbers
          detail
          userID
          isEnd
          endDateTime
          participationConditions
          password
          playNum
          gameRoundNum
          gameScore
          redFive
          additionalYaku
          allSimples
          yakuBack
          swapCalling
          noDrawReach
          furitenDraw
          points30Doubles4
          nearestPlayerWin
          moreGameRound
          drawnGame
          additionalRound
          lastWinStop
          minusScore
          north_3
          drawLoss_3
          flower_3
          talkRoomMId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      MessageM {
        items {
          id
          title
          startDateTime
          place
          recruitmentNumbers
          detail
          userID
          isEnd
          endDateTime
          participationConditions
          password
          playNum
          gameRoundNum
          gameScore
          redFive
          additionalYaku
          allSimples
          yakuBack
          swapCalling
          noDrawReach
          furitenDraw
          points30Doubles4
          nearestPlayerWin
          moreGameRound
          drawnGame
          additionalRound
          lastWinStop
          minusScore
          north_3
          drawLoss_3
          flower_3
          talkRoomMId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      UserTalkRoomM {
        items {
          id
          talkroommID
          usermID
          hidden
          lastViewTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      NotificationTokenM {
        items {
          id
          usermID
          token
          enabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      iconSrc
      RecruitmentHistoryMS {
        items {
          id
          usermID
          recruitmentlistmID
          startDateTime
          entryDateTime
          exitDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      identityId
      isOver18
      isAgreeTerms
      isSuspended
      lastLoginAt
      loginDays
      feedbackSent
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserM = /* GraphQL */ `
  mutation DeleteUserM(
    $input: DeleteUserMInput!
    $condition: ModelUserMConditionInput
  ) {
    deleteUserM(input: $input, condition: $condition) {
      id
      cognitoUsername
      name
      prefecture
      age
      hobby
      introduction
      skill
      canCalculateScore
      mainMahjongType
      canOperateTable
      favoriteRole
      mahjongStartReason
      mahjongStudyMethod
      RecruitmentLists {
        items {
          id
          title
          startDateTime
          place
          recruitmentNumbers
          detail
          userID
          isEnd
          endDateTime
          participationConditions
          password
          playNum
          gameRoundNum
          gameScore
          redFive
          additionalYaku
          allSimples
          yakuBack
          swapCalling
          noDrawReach
          furitenDraw
          points30Doubles4
          nearestPlayerWin
          moreGameRound
          drawnGame
          additionalRound
          lastWinStop
          minusScore
          north_3
          drawLoss_3
          flower_3
          talkRoomMId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      MessageM {
        items {
          id
          title
          startDateTime
          place
          recruitmentNumbers
          detail
          userID
          isEnd
          endDateTime
          participationConditions
          password
          playNum
          gameRoundNum
          gameScore
          redFive
          additionalYaku
          allSimples
          yakuBack
          swapCalling
          noDrawReach
          furitenDraw
          points30Doubles4
          nearestPlayerWin
          moreGameRound
          drawnGame
          additionalRound
          lastWinStop
          minusScore
          north_3
          drawLoss_3
          flower_3
          talkRoomMId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      UserTalkRoomM {
        items {
          id
          talkroommID
          usermID
          hidden
          lastViewTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      NotificationTokenM {
        items {
          id
          usermID
          token
          enabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      iconSrc
      RecruitmentHistoryMS {
        items {
          id
          usermID
          recruitmentlistmID
          startDateTime
          entryDateTime
          exitDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      identityId
      isOver18
      isAgreeTerms
      isSuspended
      lastLoginAt
      loginDays
      feedbackSent
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTalkRoomM = /* GraphQL */ `
  mutation CreateTalkRoomM(
    $input: CreateTalkRoomMInput!
    $condition: ModelTalkRoomMConditionInput
  ) {
    createTalkRoomM(input: $input, condition: $condition) {
      id
      roomName
      MessageMS {
        items {
          id
          content
          talkroommID
          usermID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      UserTalkRoomM {
        items {
          id
          talkroommID
          usermID
          hidden
          lastViewTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      countMessage
      iconSrc
      isDirect
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTalkRoomM = /* GraphQL */ `
  mutation UpdateTalkRoomM(
    $input: UpdateTalkRoomMInput!
    $condition: ModelTalkRoomMConditionInput
  ) {
    updateTalkRoomM(input: $input, condition: $condition) {
      id
      roomName
      MessageMS {
        items {
          id
          content
          talkroommID
          usermID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      UserTalkRoomM {
        items {
          id
          talkroommID
          usermID
          hidden
          lastViewTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      countMessage
      iconSrc
      isDirect
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTalkRoomM = /* GraphQL */ `
  mutation DeleteTalkRoomM(
    $input: DeleteTalkRoomMInput!
    $condition: ModelTalkRoomMConditionInput
  ) {
    deleteTalkRoomM(input: $input, condition: $condition) {
      id
      roomName
      MessageMS {
        items {
          id
          content
          talkroommID
          usermID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      UserTalkRoomM {
        items {
          id
          talkroommID
          usermID
          hidden
          lastViewTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      countMessage
      iconSrc
      isDirect
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createContactM = /* GraphQL */ `
  mutation CreateContactM(
    $input: CreateContactMInput!
    $condition: ModelContactMConditionInput
  ) {
    createContactM(input: $input, condition: $condition) {
      id
      usermID
      email
      subject
      content
      status
      responseMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateContactM = /* GraphQL */ `
  mutation UpdateContactM(
    $input: UpdateContactMInput!
    $condition: ModelContactMConditionInput
  ) {
    updateContactM(input: $input, condition: $condition) {
      id
      usermID
      email
      subject
      content
      status
      responseMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteContactM = /* GraphQL */ `
  mutation DeleteContactM(
    $input: DeleteContactMInput!
    $condition: ModelContactMConditionInput
  ) {
    deleteContactM(input: $input, condition: $condition) {
      id
      usermID
      email
      subject
      content
      status
      responseMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFeedbackM = /* GraphQL */ `
  mutation CreateFeedbackM(
    $input: CreateFeedbackMInput!
    $condition: ModelFeedbackMConditionInput
  ) {
    createFeedbackM(input: $input, condition: $condition) {
      id
      usermID
      rating
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFeedbackM = /* GraphQL */ `
  mutation UpdateFeedbackM(
    $input: UpdateFeedbackMInput!
    $condition: ModelFeedbackMConditionInput
  ) {
    updateFeedbackM(input: $input, condition: $condition) {
      id
      usermID
      rating
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFeedbackM = /* GraphQL */ `
  mutation DeleteFeedbackM(
    $input: DeleteFeedbackMInput!
    $condition: ModelFeedbackMConditionInput
  ) {
    deleteFeedbackM(input: $input, condition: $condition) {
      id
      usermID
      rating
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createMatchingReserveM = /* GraphQL */ `
  mutation CreateMatchingReserveM(
    $input: CreateMatchingReserveMInput!
    $condition: ModelMatchingReserveMConditionInput
  ) {
    createMatchingReserveM(input: $input, condition: $condition) {
      id
      usermID
      age
      prefecture
      place
      minAge
      maxAge
      playNum
      skill
      requiredSkill
      desiredDate
      registerDate
      status
      matchingMId
      noticeMail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateMatchingReserveM = /* GraphQL */ `
  mutation UpdateMatchingReserveM(
    $input: UpdateMatchingReserveMInput!
    $condition: ModelMatchingReserveMConditionInput
  ) {
    updateMatchingReserveM(input: $input, condition: $condition) {
      id
      usermID
      age
      prefecture
      place
      minAge
      maxAge
      playNum
      skill
      requiredSkill
      desiredDate
      registerDate
      status
      matchingMId
      noticeMail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteMatchingReserveM = /* GraphQL */ `
  mutation DeleteMatchingReserveM(
    $input: DeleteMatchingReserveMInput!
    $condition: ModelMatchingReserveMConditionInput
  ) {
    deleteMatchingReserveM(input: $input, condition: $condition) {
      id
      usermID
      age
      prefecture
      place
      minAge
      maxAge
      playNum
      skill
      requiredSkill
      desiredDate
      registerDate
      status
      matchingMId
      noticeMail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createMatchingM = /* GraphQL */ `
  mutation CreateMatchingM(
    $input: CreateMatchingMInput!
    $condition: ModelMatchingMConditionInput
  ) {
    createMatchingM(input: $input, condition: $condition) {
      id
      userm1ID
      user1Confirm
      userm2ID
      user2Confirm
      userm3ID
      user3Confirm
      userm4ID
      user4Confirm
      dueDate
      matchingDate
      matchingPlace
      status
      talkRoomMId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateMatchingM = /* GraphQL */ `
  mutation UpdateMatchingM(
    $input: UpdateMatchingMInput!
    $condition: ModelMatchingMConditionInput
  ) {
    updateMatchingM(input: $input, condition: $condition) {
      id
      userm1ID
      user1Confirm
      userm2ID
      user2Confirm
      userm3ID
      user3Confirm
      userm4ID
      user4Confirm
      dueDate
      matchingDate
      matchingPlace
      status
      talkRoomMId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteMatchingM = /* GraphQL */ `
  mutation DeleteMatchingM(
    $input: DeleteMatchingMInput!
    $condition: ModelMatchingMConditionInput
  ) {
    deleteMatchingM(input: $input, condition: $condition) {
      id
      userm1ID
      user1Confirm
      userm2ID
      user2Confirm
      userm3ID
      user3Confirm
      userm4ID
      user4Confirm
      dueDate
      matchingDate
      matchingPlace
      status
      talkRoomMId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBlockedUsersM = /* GraphQL */ `
  mutation CreateBlockedUsersM(
    $input: CreateBlockedUsersMInput!
    $condition: ModelBlockedUsersMConditionInput
  ) {
    createBlockedUsersM(input: $input, condition: $condition) {
      id
      usermID
      blockedUsermID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBlockedUsersM = /* GraphQL */ `
  mutation UpdateBlockedUsersM(
    $input: UpdateBlockedUsersMInput!
    $condition: ModelBlockedUsersMConditionInput
  ) {
    updateBlockedUsersM(input: $input, condition: $condition) {
      id
      usermID
      blockedUsermID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBlockedUsersM = /* GraphQL */ `
  mutation DeleteBlockedUsersM(
    $input: DeleteBlockedUsersMInput!
    $condition: ModelBlockedUsersMConditionInput
  ) {
    deleteBlockedUsersM(input: $input, condition: $condition) {
      id
      usermID
      blockedUsermID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
