import '@aws-amplify/ui-react/styles.css';
import '../../App.css';
import { useParams } from "react-router-dom";
import UserContext from "../../UserContext";
import MessageContext from "../../MessageContext";
import { useState, useEffect, useContext } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { getRecruitmentListM, recruitmentHistoryMSByRecruitmentlistmIDAndEntryDateTime } from '../../graphql/queries'
import { getCategoryByRecruitmentListId } from '../../my-func/CategoryManager'
import { RecruitmentDetail, EntryUserList, RecruitmentButtons } from './my-ui-components';
import { Flex, View, Grid } from '@aws-amplify/ui-react';
import { styled } from 'styled-components';
import useSaveLastViewRecruitmentPage from '../../my-func/useSaveLastViewRecruitmentPage';
import { Link } from "react-router-dom";

const Recruitment = styled.div`
width: 100%; 
`;

const Content = styled.div`
margin: 1rem 1rem;
`;

const RecruitmentDisp = () => {
  useSaveLastViewRecruitmentPage();
  // コンテキストに保存したユーザー情報を読み込み
  const { user } = useContext(UserContext);
  const { setErrorMessage } = useContext(MessageContext);
  // urlから募集IDを取得
  const { recruitmentId } = useParams();

  // 募集内容表示
  const [dispData, setDispData] = useState(null);
  // 各種ボタンの表示
  const [buttons, setButtons] = useState("");
  // 募集作成ユーザー表示
  const [createUser, setCreateUser] = useState("");
  // 参加ユーザーリスト表示
  const [entryUserList, setEntryUserList] = useState("");
  // 更新処理用ステート
  const [keyTime, setKeyTime] = useState(Date.now());

  /**
   * 募集IDから募集情報と募集参加ユーザーIDを取得する
   * 募集詳細カードを作成する
   * @param {urlから取得した募集ID} id 
   */
  async function drowDisplay(id) {
    try {
      //募集情報を取得する
      const recOpt = {
        id: id
      };
      const recruitment = (await API.graphql(graphqlOperation(
        getRecruitmentListM, recOpt))).data.getRecruitmentListM;

      // 募集の状態
      if (new Date(recruitment.endDateTime) < new Date()) {
        recruitment.isEnd = "1";
      }

      // 募集が存在しない・削除済みであれば表示しない
      // 本来は削除済みの募集は表示されることはない想定だが、
      // URLを直接指定するなどに備えてガードをかける
      if (!recruitment) {
        setDispData(<div>
          この募集は存在しません
          <Link to="/RecruitmentList">
            <p>募集一覧に戻る</p>
          </Link>
        </div>)
        return;
      }
      if (recruitment._deleted) {
        setDispData(<div>
          この募集は削除済みです
          <Link to="/RecruitmentList">
            <p>募集一覧に戻る</p>
          </Link>
        </div>)
        return;
      }

      //募集に参加しているユーザーIDを取得
      let nextToken = null
      var userIdList = []
      do {
        const recHisOpt = {
          recruitmentlistmID: id,
          sortDirection: "ASC",
          nextToken: nextToken,
          filter: { exitDateTime: { attributeExists: false } }
        };
        const recruitmentHistory = (await API.graphql(graphqlOperation(
          recruitmentHistoryMSByRecruitmentlistmIDAndEntryDateTime, recHisOpt)))
          .data.recruitmentHistoryMSByRecruitmentlistmIDAndEntryDateTime;
        for (let item of recruitmentHistory.items) {
          if (item._deleted != true && item.usermID !== recruitment.userID) {
            // 募集作成者は参加しているユーザーIDに含まない
            userIdList.push(item.usermID)
          }
        }
        nextToken = recruitmentHistory.nextToken;
      } while (nextToken);

      // 募集に紐づいたカテゴリを取得する
      const categoryList = await getCategoryByRecruitmentListId(id, setErrorMessage);

      // 募集詳細を描画する
      setDispData(<Flex direction={"column"} alignItems={'center'} >
        <Recruitment>
          <RecruitmentDetail recruitment={recruitment} entryUserList={userIdList}
            categoryList={categoryList} keyTime={Date.now()} />
        </Recruitment>
      </Flex>)

      // 各種ボタンを作成
      setButtons(<RecruitmentButtons user={user} recruitment={recruitment} userIdList={userIdList} keyTime={Date.now()} setKeyTime={setKeyTime}></RecruitmentButtons>)

      // 募集作成ユーザーを作成
      var createUser = [recruitment.userID]
      setCreateUser(<EntryUserList entryUserList={createUser} message={"募集作成ユーザー"} keyTime={Date.now()}></EntryUserList>)

      // 参加ユーザーのリストを作成
      setEntryUserList(<EntryUserList entryUserList={userIdList} message={"参加ユーザー"} keyTime={Date.now()}></EntryUserList>)

    } catch (error) {
      setErrorMessage(<div>
        募集情報取得時にエラーが発生しました。<br />
        しばらくしてからもう一度お試しください。
      </div>);
    }
  }

  useEffect(() => {
    if (recruitmentId == null) { return; }
    drowDisplay(recruitmentId);
  }, [user, recruitmentId, keyTime]);

  return (
    <div >
      <Grid
        templateColumns={{ base: '1fr', large: "2fr 7fr 2fr" }}
        templateRows={{ base: '0.5fr 9fr 0.5fr', large: "1fr" }}
      >
        <View rowSpan={1}>{/* ここに広告？ */}</View>
        <View rowSpan={1}>
          {dispData}
          {buttons}
          <Content>
            <Flex direction="column" justifyContent="center">
              {createUser}
              {entryUserList}
            </Flex>
          </Content>
        </View>
        <View rowSpan={1}>{/* ここに広告？ */}</View>
      </Grid>
    </div>
  );
};

export default RecruitmentDisp;