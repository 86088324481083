/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateRecruitmentHistoryM = /* GraphQL */ `
  subscription OnCreateRecruitmentHistoryM(
    $filter: ModelSubscriptionRecruitmentHistoryMFilterInput
  ) {
    onCreateRecruitmentHistoryM(filter: $filter) {
      id
      usermID
      recruitmentlistmID
      startDateTime
      entryDateTime
      exitDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateRecruitmentHistoryM = /* GraphQL */ `
  subscription OnUpdateRecruitmentHistoryM(
    $filter: ModelSubscriptionRecruitmentHistoryMFilterInput
  ) {
    onUpdateRecruitmentHistoryM(filter: $filter) {
      id
      usermID
      recruitmentlistmID
      startDateTime
      entryDateTime
      exitDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteRecruitmentHistoryM = /* GraphQL */ `
  subscription OnDeleteRecruitmentHistoryM(
    $filter: ModelSubscriptionRecruitmentHistoryMFilterInput
  ) {
    onDeleteRecruitmentHistoryM(filter: $filter) {
      id
      usermID
      recruitmentlistmID
      startDateTime
      entryDateTime
      exitDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUserTalkRoomM = /* GraphQL */ `
  subscription OnCreateUserTalkRoomM(
    $filter: ModelSubscriptionUserTalkRoomMFilterInput
  ) {
    onCreateUserTalkRoomM(filter: $filter) {
      id
      talkroommID
      usermID
      hidden
      lastViewTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateUserTalkRoomM = /* GraphQL */ `
  subscription OnUpdateUserTalkRoomM(
    $filter: ModelSubscriptionUserTalkRoomMFilterInput
  ) {
    onUpdateUserTalkRoomM(filter: $filter) {
      id
      talkroommID
      usermID
      hidden
      lastViewTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteUserTalkRoomM = /* GraphQL */ `
  subscription OnDeleteUserTalkRoomM(
    $filter: ModelSubscriptionUserTalkRoomMFilterInput
  ) {
    onDeleteUserTalkRoomM(filter: $filter) {
      id
      talkroommID
      usermID
      hidden
      lastViewTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateMessageM = /* GraphQL */ `
  subscription OnCreateMessageM($filter: ModelSubscriptionMessageMFilterInput) {
    onCreateMessageM(filter: $filter) {
      id
      content
      talkroommID
      usermID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateMessageM = /* GraphQL */ `
  subscription OnUpdateMessageM($filter: ModelSubscriptionMessageMFilterInput) {
    onUpdateMessageM(filter: $filter) {
      id
      content
      talkroommID
      usermID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteMessageM = /* GraphQL */ `
  subscription OnDeleteMessageM($filter: ModelSubscriptionMessageMFilterInput) {
    onDeleteMessageM(filter: $filter) {
      id
      content
      talkroommID
      usermID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateNotificationTokenM = /* GraphQL */ `
  subscription OnCreateNotificationTokenM(
    $filter: ModelSubscriptionNotificationTokenMFilterInput
  ) {
    onCreateNotificationTokenM(filter: $filter) {
      id
      usermID
      token
      enabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateNotificationTokenM = /* GraphQL */ `
  subscription OnUpdateNotificationTokenM(
    $filter: ModelSubscriptionNotificationTokenMFilterInput
  ) {
    onUpdateNotificationTokenM(filter: $filter) {
      id
      usermID
      token
      enabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteNotificationTokenM = /* GraphQL */ `
  subscription OnDeleteNotificationTokenM(
    $filter: ModelSubscriptionNotificationTokenMFilterInput
  ) {
    onDeleteNotificationTokenM(filter: $filter) {
      id
      usermID
      token
      enabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateRecruitmentListM = /* GraphQL */ `
  subscription OnCreateRecruitmentListM(
    $filter: ModelSubscriptionRecruitmentListMFilterInput
  ) {
    onCreateRecruitmentListM(filter: $filter) {
      id
      title
      startDateTime
      place
      recruitmentNumbers
      detail
      userID
      isEnd
      endDateTime
      participationConditions
      password
      playNum
      gameRoundNum
      gameScore
      redFive
      additionalYaku
      allSimples
      yakuBack
      swapCalling
      noDrawReach
      furitenDraw
      points30Doubles4
      nearestPlayerWin
      moreGameRound
      drawnGame
      additionalRound
      lastWinStop
      minusScore
      north_3
      drawLoss_3
      flower_3
      talkRoomMId
      TalkRoomM {
        id
        roomName
        MessageMS {
          nextToken
          startedAt
        }
        UserTalkRoomM {
          nextToken
          startedAt
        }
        countMessage
        iconSrc
        isDirect
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      RecruitmentHistoryMS {
        items {
          id
          usermID
          recruitmentlistmID
          startDateTime
          entryDateTime
          exitDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateRecruitmentListM = /* GraphQL */ `
  subscription OnUpdateRecruitmentListM(
    $filter: ModelSubscriptionRecruitmentListMFilterInput
  ) {
    onUpdateRecruitmentListM(filter: $filter) {
      id
      title
      startDateTime
      place
      recruitmentNumbers
      detail
      userID
      isEnd
      endDateTime
      participationConditions
      password
      playNum
      gameRoundNum
      gameScore
      redFive
      additionalYaku
      allSimples
      yakuBack
      swapCalling
      noDrawReach
      furitenDraw
      points30Doubles4
      nearestPlayerWin
      moreGameRound
      drawnGame
      additionalRound
      lastWinStop
      minusScore
      north_3
      drawLoss_3
      flower_3
      talkRoomMId
      TalkRoomM {
        id
        roomName
        MessageMS {
          nextToken
          startedAt
        }
        UserTalkRoomM {
          nextToken
          startedAt
        }
        countMessage
        iconSrc
        isDirect
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      RecruitmentHistoryMS {
        items {
          id
          usermID
          recruitmentlistmID
          startDateTime
          entryDateTime
          exitDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteRecruitmentListM = /* GraphQL */ `
  subscription OnDeleteRecruitmentListM(
    $filter: ModelSubscriptionRecruitmentListMFilterInput
  ) {
    onDeleteRecruitmentListM(filter: $filter) {
      id
      title
      startDateTime
      place
      recruitmentNumbers
      detail
      userID
      isEnd
      endDateTime
      participationConditions
      password
      playNum
      gameRoundNum
      gameScore
      redFive
      additionalYaku
      allSimples
      yakuBack
      swapCalling
      noDrawReach
      furitenDraw
      points30Doubles4
      nearestPlayerWin
      moreGameRound
      drawnGame
      additionalRound
      lastWinStop
      minusScore
      north_3
      drawLoss_3
      flower_3
      talkRoomMId
      TalkRoomM {
        id
        roomName
        MessageMS {
          nextToken
          startedAt
        }
        UserTalkRoomM {
          nextToken
          startedAt
        }
        countMessage
        iconSrc
        isDirect
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      RecruitmentHistoryMS {
        items {
          id
          usermID
          recruitmentlistmID
          startDateTime
          entryDateTime
          exitDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateCategoryM = /* GraphQL */ `
  subscription OnCreateCategoryM(
    $filter: ModelSubscriptionCategoryMFilterInput
  ) {
    onCreateCategoryM(filter: $filter) {
      id
      categoryName
      recruitmentListId
      master
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateCategoryM = /* GraphQL */ `
  subscription OnUpdateCategoryM(
    $filter: ModelSubscriptionCategoryMFilterInput
  ) {
    onUpdateCategoryM(filter: $filter) {
      id
      categoryName
      recruitmentListId
      master
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteCategoryM = /* GraphQL */ `
  subscription OnDeleteCategoryM(
    $filter: ModelSubscriptionCategoryMFilterInput
  ) {
    onDeleteCategoryM(filter: $filter) {
      id
      categoryName
      recruitmentListId
      master
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUserM = /* GraphQL */ `
  subscription OnCreateUserM($filter: ModelSubscriptionUserMFilterInput) {
    onCreateUserM(filter: $filter) {
      id
      cognitoUsername
      name
      prefecture
      age
      hobby
      introduction
      skill
      canCalculateScore
      mainMahjongType
      canOperateTable
      favoriteRole
      mahjongStartReason
      mahjongStudyMethod
      RecruitmentLists {
        items {
          id
          title
          startDateTime
          place
          recruitmentNumbers
          detail
          userID
          isEnd
          endDateTime
          participationConditions
          password
          playNum
          gameRoundNum
          gameScore
          redFive
          additionalYaku
          allSimples
          yakuBack
          swapCalling
          noDrawReach
          furitenDraw
          points30Doubles4
          nearestPlayerWin
          moreGameRound
          drawnGame
          additionalRound
          lastWinStop
          minusScore
          north_3
          drawLoss_3
          flower_3
          talkRoomMId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      MessageM {
        items {
          id
          title
          startDateTime
          place
          recruitmentNumbers
          detail
          userID
          isEnd
          endDateTime
          participationConditions
          password
          playNum
          gameRoundNum
          gameScore
          redFive
          additionalYaku
          allSimples
          yakuBack
          swapCalling
          noDrawReach
          furitenDraw
          points30Doubles4
          nearestPlayerWin
          moreGameRound
          drawnGame
          additionalRound
          lastWinStop
          minusScore
          north_3
          drawLoss_3
          flower_3
          talkRoomMId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      UserTalkRoomM {
        items {
          id
          talkroommID
          usermID
          hidden
          lastViewTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      NotificationTokenM {
        items {
          id
          usermID
          token
          enabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      iconSrc
      RecruitmentHistoryMS {
        items {
          id
          usermID
          recruitmentlistmID
          startDateTime
          entryDateTime
          exitDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      identityId
      isOver18
      isAgreeTerms
      isSuspended
      lastLoginAt
      loginDays
      feedbackSent
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateUserM = /* GraphQL */ `
  subscription OnUpdateUserM($filter: ModelSubscriptionUserMFilterInput) {
    onUpdateUserM(filter: $filter) {
      id
      cognitoUsername
      name
      prefecture
      age
      hobby
      introduction
      skill
      canCalculateScore
      mainMahjongType
      canOperateTable
      favoriteRole
      mahjongStartReason
      mahjongStudyMethod
      RecruitmentLists {
        items {
          id
          title
          startDateTime
          place
          recruitmentNumbers
          detail
          userID
          isEnd
          endDateTime
          participationConditions
          password
          playNum
          gameRoundNum
          gameScore
          redFive
          additionalYaku
          allSimples
          yakuBack
          swapCalling
          noDrawReach
          furitenDraw
          points30Doubles4
          nearestPlayerWin
          moreGameRound
          drawnGame
          additionalRound
          lastWinStop
          minusScore
          north_3
          drawLoss_3
          flower_3
          talkRoomMId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      MessageM {
        items {
          id
          title
          startDateTime
          place
          recruitmentNumbers
          detail
          userID
          isEnd
          endDateTime
          participationConditions
          password
          playNum
          gameRoundNum
          gameScore
          redFive
          additionalYaku
          allSimples
          yakuBack
          swapCalling
          noDrawReach
          furitenDraw
          points30Doubles4
          nearestPlayerWin
          moreGameRound
          drawnGame
          additionalRound
          lastWinStop
          minusScore
          north_3
          drawLoss_3
          flower_3
          talkRoomMId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      UserTalkRoomM {
        items {
          id
          talkroommID
          usermID
          hidden
          lastViewTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      NotificationTokenM {
        items {
          id
          usermID
          token
          enabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      iconSrc
      RecruitmentHistoryMS {
        items {
          id
          usermID
          recruitmentlistmID
          startDateTime
          entryDateTime
          exitDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      identityId
      isOver18
      isAgreeTerms
      isSuspended
      lastLoginAt
      loginDays
      feedbackSent
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteUserM = /* GraphQL */ `
  subscription OnDeleteUserM($filter: ModelSubscriptionUserMFilterInput) {
    onDeleteUserM(filter: $filter) {
      id
      cognitoUsername
      name
      prefecture
      age
      hobby
      introduction
      skill
      canCalculateScore
      mainMahjongType
      canOperateTable
      favoriteRole
      mahjongStartReason
      mahjongStudyMethod
      RecruitmentLists {
        items {
          id
          title
          startDateTime
          place
          recruitmentNumbers
          detail
          userID
          isEnd
          endDateTime
          participationConditions
          password
          playNum
          gameRoundNum
          gameScore
          redFive
          additionalYaku
          allSimples
          yakuBack
          swapCalling
          noDrawReach
          furitenDraw
          points30Doubles4
          nearestPlayerWin
          moreGameRound
          drawnGame
          additionalRound
          lastWinStop
          minusScore
          north_3
          drawLoss_3
          flower_3
          talkRoomMId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      MessageM {
        items {
          id
          title
          startDateTime
          place
          recruitmentNumbers
          detail
          userID
          isEnd
          endDateTime
          participationConditions
          password
          playNum
          gameRoundNum
          gameScore
          redFive
          additionalYaku
          allSimples
          yakuBack
          swapCalling
          noDrawReach
          furitenDraw
          points30Doubles4
          nearestPlayerWin
          moreGameRound
          drawnGame
          additionalRound
          lastWinStop
          minusScore
          north_3
          drawLoss_3
          flower_3
          talkRoomMId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      UserTalkRoomM {
        items {
          id
          talkroommID
          usermID
          hidden
          lastViewTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      NotificationTokenM {
        items {
          id
          usermID
          token
          enabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      iconSrc
      RecruitmentHistoryMS {
        items {
          id
          usermID
          recruitmentlistmID
          startDateTime
          entryDateTime
          exitDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      identityId
      isOver18
      isAgreeTerms
      isSuspended
      lastLoginAt
      loginDays
      feedbackSent
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateTalkRoomM = /* GraphQL */ `
  subscription OnCreateTalkRoomM(
    $filter: ModelSubscriptionTalkRoomMFilterInput
  ) {
    onCreateTalkRoomM(filter: $filter) {
      id
      roomName
      MessageMS {
        items {
          id
          content
          talkroommID
          usermID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      UserTalkRoomM {
        items {
          id
          talkroommID
          usermID
          hidden
          lastViewTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      countMessage
      iconSrc
      isDirect
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateTalkRoomM = /* GraphQL */ `
  subscription OnUpdateTalkRoomM(
    $filter: ModelSubscriptionTalkRoomMFilterInput
  ) {
    onUpdateTalkRoomM(filter: $filter) {
      id
      roomName
      MessageMS {
        items {
          id
          content
          talkroommID
          usermID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      UserTalkRoomM {
        items {
          id
          talkroommID
          usermID
          hidden
          lastViewTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      countMessage
      iconSrc
      isDirect
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteTalkRoomM = /* GraphQL */ `
  subscription OnDeleteTalkRoomM(
    $filter: ModelSubscriptionTalkRoomMFilterInput
  ) {
    onDeleteTalkRoomM(filter: $filter) {
      id
      roomName
      MessageMS {
        items {
          id
          content
          talkroommID
          usermID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      UserTalkRoomM {
        items {
          id
          talkroommID
          usermID
          hidden
          lastViewTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      countMessage
      iconSrc
      isDirect
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateContactM = /* GraphQL */ `
  subscription OnCreateContactM($filter: ModelSubscriptionContactMFilterInput) {
    onCreateContactM(filter: $filter) {
      id
      usermID
      email
      subject
      content
      status
      responseMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateContactM = /* GraphQL */ `
  subscription OnUpdateContactM($filter: ModelSubscriptionContactMFilterInput) {
    onUpdateContactM(filter: $filter) {
      id
      usermID
      email
      subject
      content
      status
      responseMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteContactM = /* GraphQL */ `
  subscription OnDeleteContactM($filter: ModelSubscriptionContactMFilterInput) {
    onDeleteContactM(filter: $filter) {
      id
      usermID
      email
      subject
      content
      status
      responseMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateFeedbackM = /* GraphQL */ `
  subscription OnCreateFeedbackM(
    $filter: ModelSubscriptionFeedbackMFilterInput
  ) {
    onCreateFeedbackM(filter: $filter) {
      id
      usermID
      rating
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateFeedbackM = /* GraphQL */ `
  subscription OnUpdateFeedbackM(
    $filter: ModelSubscriptionFeedbackMFilterInput
  ) {
    onUpdateFeedbackM(filter: $filter) {
      id
      usermID
      rating
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteFeedbackM = /* GraphQL */ `
  subscription OnDeleteFeedbackM(
    $filter: ModelSubscriptionFeedbackMFilterInput
  ) {
    onDeleteFeedbackM(filter: $filter) {
      id
      usermID
      rating
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateMatchingReserveM = /* GraphQL */ `
  subscription OnCreateMatchingReserveM(
    $filter: ModelSubscriptionMatchingReserveMFilterInput
  ) {
    onCreateMatchingReserveM(filter: $filter) {
      id
      usermID
      age
      prefecture
      place
      minAge
      maxAge
      playNum
      skill
      requiredSkill
      desiredDate
      registerDate
      status
      matchingMId
      noticeMail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateMatchingReserveM = /* GraphQL */ `
  subscription OnUpdateMatchingReserveM(
    $filter: ModelSubscriptionMatchingReserveMFilterInput
  ) {
    onUpdateMatchingReserveM(filter: $filter) {
      id
      usermID
      age
      prefecture
      place
      minAge
      maxAge
      playNum
      skill
      requiredSkill
      desiredDate
      registerDate
      status
      matchingMId
      noticeMail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteMatchingReserveM = /* GraphQL */ `
  subscription OnDeleteMatchingReserveM(
    $filter: ModelSubscriptionMatchingReserveMFilterInput
  ) {
    onDeleteMatchingReserveM(filter: $filter) {
      id
      usermID
      age
      prefecture
      place
      minAge
      maxAge
      playNum
      skill
      requiredSkill
      desiredDate
      registerDate
      status
      matchingMId
      noticeMail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateMatchingM = /* GraphQL */ `
  subscription OnCreateMatchingM(
    $filter: ModelSubscriptionMatchingMFilterInput
  ) {
    onCreateMatchingM(filter: $filter) {
      id
      userm1ID
      user1Confirm
      userm2ID
      user2Confirm
      userm3ID
      user3Confirm
      userm4ID
      user4Confirm
      dueDate
      matchingDate
      matchingPlace
      status
      talkRoomMId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateMatchingM = /* GraphQL */ `
  subscription OnUpdateMatchingM(
    $filter: ModelSubscriptionMatchingMFilterInput
  ) {
    onUpdateMatchingM(filter: $filter) {
      id
      userm1ID
      user1Confirm
      userm2ID
      user2Confirm
      userm3ID
      user3Confirm
      userm4ID
      user4Confirm
      dueDate
      matchingDate
      matchingPlace
      status
      talkRoomMId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteMatchingM = /* GraphQL */ `
  subscription OnDeleteMatchingM(
    $filter: ModelSubscriptionMatchingMFilterInput
  ) {
    onDeleteMatchingM(filter: $filter) {
      id
      userm1ID
      user1Confirm
      userm2ID
      user2Confirm
      userm3ID
      user3Confirm
      userm4ID
      user4Confirm
      dueDate
      matchingDate
      matchingPlace
      status
      talkRoomMId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateBlockedUsersM = /* GraphQL */ `
  subscription OnCreateBlockedUsersM(
    $filter: ModelSubscriptionBlockedUsersMFilterInput
  ) {
    onCreateBlockedUsersM(filter: $filter) {
      id
      usermID
      blockedUsermID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateBlockedUsersM = /* GraphQL */ `
  subscription OnUpdateBlockedUsersM(
    $filter: ModelSubscriptionBlockedUsersMFilterInput
  ) {
    onUpdateBlockedUsersM(filter: $filter) {
      id
      usermID
      blockedUsermID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteBlockedUsersM = /* GraphQL */ `
  subscription OnDeleteBlockedUsersM(
    $filter: ModelSubscriptionBlockedUsersMFilterInput
  ) {
    onDeleteBlockedUsersM(filter: $filter) {
      id
      usermID
      blockedUsermID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
