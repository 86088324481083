/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_mobile_analytics_app_id": "63e881b6e726419384d7781854e58a76",
    "aws_mobile_analytics_app_region": "ap-northeast-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "63e881b6e726419384d7781854e58a76",
            "region": "ap-northeast-1"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://lgacb3cajzc7dctz43gh7uz2be.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "ap-northeast-1:b275fe43-7455-4b20-83b3-0971fdc4c4f1",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_9nQ7vNlWe",
    "aws_user_pools_web_client_id": "g7f3n60o2bv8inh1rd1rpgjqf",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "mahjong-web-storage-51749429100648-main",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
