import UserContext from "../../UserContext"
import { useState, useEffect, useContext } from "react"
import { openIndexedDB, fetchFromIndexedDB } from "../../my-func/indexedDBUtils"
import { setHiddenUserTalkRoom } from "../../my-func/userTalkRoomManager"
import {
  formatTalkRoomInfo,
  getTalkRoom
} from "./talkRoomHelpers"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Button from "@mui/material/Button"
import { API } from "aws-amplify"
import { listUserTalkRoomMByTalkroommID } from "../../graphql/queries"
import useSaveLastViewTalkRoomPage from "../../my-func/useSaveLastViewTalkRoomPage"
import { styled } from "styled-components"
import { graphqlOperation } from "aws-amplify"
import { onUpdateTalkRoomM } from "../../graphql/subscriptions"
import { getJoinedTalkRooms } from "../../my-func/getJoinedTalkRooms"

const DialogWrap = styled(Dialog)`
  user-select: none;
  -webkit-user-select: none; /* iOS Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer 10+ */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-tap-highlight-color: transparent; /* Android Chrome */
`

const TalkRoomDiv = styled.div`
  & * {
    user-select: none;
    -webkit-user-select: none; /* iOS Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer 10+ */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-tap-highlight-color: transparent; /* Android Chrome */
  }
`

const dbName = "TalkRoomList"
const storeName = "TalkRoomList"
const keyPath = "talkRoomId"

function updateTalkRoomInList(talkRoomList, updatedTalkRoom) {
  return talkRoomList.map((talkRoom) => {
    if (talkRoom.talkRoomM.id === updatedTalkRoom.talkRoomM.id) {
      return updatedTalkRoom // 新しいデータで置き換え
    }
    return talkRoom // そのままのデータを返す
  })
}

// ユーザーが所属しているトークルームの一覧を表示するコンポーネント
const TalkRoomList = () => {
  const [dispTalkRoomList, setDispTalkRoomList] = useState([])
  const [talkRoomList, setTalkRoomList] = useState([])
  const [isSubscribe, setIsSubscribe] = useState(false)
  const [joinedTalkRoomList, setJoinedTalkRoomList] = useState([])
  const { user, setUser } = useContext(UserContext)
  const [showModal, setShowModal] = useState(false)
  const [deleteTalkRoomID, setDeleteTalkRoomID] = useState("")
  const [deleteTalkRoomName, setDeleteTalkRoomName] = useState("")

  useSaveLastViewTalkRoomPage()

  const refleshData = async () => {
    const joinedTalkRooms = await getJoinedTalkRooms(user.id)
    setJoinedTalkRoomList(joinedTalkRooms)

    //自分が所属しているトークルーム一覧取得 非表示にしているトークルームを除外  const content = await Promise.all(
    const content = await Promise.all(
      joinedTalkRooms.map(async (joinedTalkRoom) => {
        return getTalkRoom(user.id, joinedTalkRoom)
      })
    )
    const filterdContent = content.filter((item) => item != null)
    setTalkRoomList(filterdContent)
    const formatedContent = filterdContent.map((item) => {
      return formatTalkRoomInfo(
        item.talkRoomM,
        item.recentMessages,
        item.countUnreadMessage,
        setShowModal,
        setDeleteTalkRoomID,
        setDeleteTalkRoomName
      )
    })

    const contentRendered = formatedContent.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((item) => item.body)

    setDispTalkRoomList(contentRendered)
    if (!isSubscribe) {
      setIsSubscribe(true)
    }
  }

  const partialRefreshData = async (updatedTalkRoom) => {
    const joinedTalkRoom = joinedTalkRoomList.filter((item) => item.talkroommID == updatedTalkRoom.id)
    // 更新を検知したトークルームのデータのみを再取得し、setTalkRoomListとsetDispTalkRoomListで更新する。
    const content = await Promise.all(
      joinedTalkRoom.map(async (temp) => {
        return getTalkRoom(user.id, temp)
      })
    )

    const filterdContent = content.filter((item) => item != null)
    //既存のデータと連結
    const updatedTalkRoomList = updateTalkRoomInList(talkRoomList, filterdContent[0])

    setTalkRoomList(updatedTalkRoomList)
    const formatedContent = updatedTalkRoomList.map((item) => {
      return formatTalkRoomInfo(
        item.talkRoomM,
        item.recentMessages,
        item.countUnreadMessage,
        setShowModal,
        setDeleteTalkRoomID,
        setDeleteTalkRoomName
      )
    })
    const contentRendered = formatedContent.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((item) => item.body)

    setDispTalkRoomList(contentRendered)
  }

  const handleHiddenButton = async () => {
    // userTalkRoomを取得
    const opt1 = {
      talkroommID: deleteTalkRoomID,
      filter: { usermID: { eq: user.id } }
    }

    API.graphql({
      query: listUserTalkRoomMByTalkroommID,
      variables: opt1
    }).then((result) => {
      const usertalkRoomMID = result.data.listUserTalkRoomMByTalkroommID.items[0].id
      setHiddenUserTalkRoom(usertalkRoomMID, true).then(() => {
        //表示を更新
        refleshData()
      })
    })
    setShowModal(false)
  }

  useEffect(() => {
    if (user == null) {
      return
    }
    // IndexedDBに保存してあるトークルーム一覧を先に表示する
    openIndexedDB(dbName, user.id, keyPath)
      .then((db) => {
        return fetchFromIndexedDB(db, user.id)
      })
      .then((dataFromDB) => {
        if (dataFromDB && dataFromDB.length > 0) {
          const restoredContent = dataFromDB.map((data) =>
            formatTalkRoomInfo(
              data.talkRoomM,
              data.recentMessages,
              data.countUnreadMessage,
              setShowModal,
              setDeleteTalkRoomID,
              setDeleteTalkRoomName
            )
          )
          const contentRendered = restoredContent.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((item) => item.body)
          setDispTalkRoomList(contentRendered)
        }
      })

    // サーバから最新のトークルーム一覧を取得して表示する
    refleshData()
  }, [user])

  useEffect(() => {
    if (!user || talkRoomList.length === 0) {
      return
    }

    // すべてのトークルームのIDを取得
    const talkRoomIds = talkRoomList.map((talkRoom) => talkRoom.talkRoomM.id)

    // サブスクリプションの設定
    const subscription = API.graphql(
      graphqlOperation(onUpdateTalkRoomM, {
        id: { in: talkRoomIds }
      })
    ).subscribe({
      next: (eventData) => {
        const updatedTalkRoom = eventData.value.data.onUpdateTalkRoomM
        // 更新されたトークルームのデータでリストを更新する
        partialRefreshData(updatedTalkRoom)
      }
    })

    // サブスクリプションの解除
    return () => {
      subscription.unsubscribe()
    }
  }, [user, talkRoomList]) // 依存配列にユーザーとトークルームリストを追加

  return (
    <>
      <TalkRoomDiv>
        <div className="talk-room">{dispTalkRoomList}</div>
        <DialogWrap open={showModal} onClose={() => setShowModal(false)}>
          <DialogTitle>トークルームの削除</DialogTitle>
          <DialogContent>
            <DialogContentText>「{deleteTalkRoomName}」を削除しますか？</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowModal(false)} color="primary">
              キャンセル
            </Button>
            <Button onClick={handleHiddenButton} color="primary">
              削除
            </Button>
          </DialogActions>
        </DialogWrap>
      </TalkRoomDiv>
    </>
  )
}

export default TalkRoomList
