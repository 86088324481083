import { styled } from "styled-components";
import { Flex } from '@aws-amplify/ui-react';
import {
  MainTitle,
  SubTitle,
  FullLineSubTitle,
  LineSubTitle,
  CommonLink,
  Ul,
  Li,
  LeftText,
  LeftChat,
  RightChat,
  BoldText,
  Kugiri10000,
  Table,
  Th,
  Td
} from "../../../../common-my-ui-components";
import LearnScore1 from '../../../../common-my-ui-components/img/introduction/LearnScore1.png';
import LearnScore2 from '../../../../common-my-ui-components/img/introduction/LearnScore2.png';
import LearnScore3 from '../../../../common-my-ui-components/img/introduction/LearnScore3.png';

import teacherSmile from '../../../../common-my-ui-components/img/miniChar/teacherSmile.png';
import teacherPoint from '../../../../common-my-ui-components/img/miniChar/teacherPoint.png';
import teacherHappy from '../../../../common-my-ui-components/img/miniChar/teacherHappy.png';
import girlQuestion from '../../../../common-my-ui-components/img/miniChar/girlQuestion.png';
import girlSmile from '../../../../common-my-ui-components/img/miniChar/girlSmile.png';
import girlHappy from '../../../../common-my-ui-components/img/miniChar/girlHappy.png';

// ページ全体の設定
const Page = styled.div`
width: 100%; 
max-width: 100vw;
`;

const Contents = styled.div`
width: 100%;
padding: 1rem;
margin: 0 auto;
text-align: center;
`;

const ScoreTable = styled.table`
width: 100%;
min-width: 700px;
text-align: center;
border-collapse: collapse;
border-spacing: 0;
table-layout: fixed;
`;

const Scroll = styled.div`
overflow: auto;
`;

const LearnScore = () => {
  return (
    <Page>
      <Contents>
        <MainTitle text={"点数を知ろう！"} tabText={"麻雀の基本"} textAlign={"left"} />
        <RightChat src={girlQuestion} alt="girlQuestion" text={(<>
          ロン！<br />
          えーっと……これは何点かな？<br />
          わからないよー！
        </>)} />
        <LeftChat src={teacherPoint} alt="teacherPoint" text={(<>
          麻雀の点数は難しいですからね。<br />
          この章では点数について概要を説明をしますが、一度に理解するのは大変なのでゲームに慣れてから繰り返し確認してみてください。
        </>)} />

        <SubTitle text={"持ち点と点棒の種類"} textAlign={"left"} />
        <LeftText text={<>
          麻雀は一般的に25,000点持ちでスタートします。<br />
          点数のやり取りは<BoldText text={"点棒"} />で行います。<br />
          100点棒から10,000点棒まであり、この授受で点数をやり取りします。<br />
          点棒には直接点数は書かれていないため、色や模様で見分けます。<br />
          持ち点が25,000点の場合、通常は画像にある個数の点棒を用意します。
        </>} />
        <img src={LearnScore1} style={{ width: '100%', maxWidth: '700px' }} alt="点棒の説明" />
        <RightChat src={girlSmile} alt="girlSmile" text={(<>
          えーっと……2,900点か！<br />
          じゃあ、青を2本と白を9本ください！
        </>)} />
        <LeftChat src={teacherSmile} alt="teacherSmile" text={(<>
          点数を正確に渡しても構いませんが、本数が多くなるとやり取りが大変になります。<br />
          青を3本で3,000点渡しますので、白1本で100点お釣りをください。
        </>)} />
        <RightChat src={girlHappy} alt="girlHappy" text={(<>
          そっか！<br />
          難しく考えていたけど、普段の買い物と同じなんだね！
        </>)} />

        <SubTitle text={"順位点"} textAlign={"left"} />
        <LeftText text={<>
          麻雀ではゲーム終了時に順位に応じた順位点を加算して最終ポイントを計算することがあります。<br />
          ここでは、Mリーグを例にして順位点の考え方を学んでみましょう。<br />
          Mリーグでは、プレイヤーは25,000点を持ち点としてスタートしますが、基準点は30,000点に設定されています。<br />
          つまり、全員がゲーム開始時点で5,000点のマイナスからスタートし、ゲーム終了後に30,000点よりもどれだけ上か下かを計算します。<br />
          このマイナス5,000点×4人分、合計20,000点は<BoldText text={"トップ賞"} />として、ゲーム終了後に1位の人が受け取ります。<br />
          また順位によって、以下の表のように<BoldText text={"順位点"} />が加算されます。<br />
        </>} />
        <Table text={(<>
          <tr><Th text={"順位"} /><Th text={"順位点"} /></tr>
          <tr><Td text={"1位"} /><Td text={"+30,000"} /></tr>
          <tr><Td text={"2位"} /><Td text={"+10,000"} /></tr>
          <tr><Td text={"3位"} /><Td text={"-10,000"} /></tr>
          <tr><Td text={"4位"} /><Td text={"-30,000"} /></tr>
        </>)} />
        <LeftText text={<>
          また桁が多く見づらくなるので1,000点を1ポイントとして表記することが多いです。<br />
          例えば、1位が38,500点、2位が35,500点、3位が15,300点、4位が10,700点の場合、次のように計算します。<br />
        </>} />
        <Scroll>
          <ScoreTable >
            <tr><Th text={"順位"} /><Th text={"点数"} /><Th text={"基準点との比較"} /><Th text={"トップ賞"} /><Th text={"順位点"} /><Th text={"最終得点"} /><Th text={"ポイント"} /></tr>
            <tr><Td text={"1位"} /><Td text={"38,500"} /><Td text={"+8,500"} /><Td text={"+20,000"} /><Td text={"+30,000"} /><Td text={"+58,500"} /><Td text={"+58.5"} /></tr>
            <tr><Td text={"2位"} /><Td text={"35,500"} /><Td text={"+5,500"} /><Td text={"-"} /><Td text={"+10,000"} /><Td text={"+15,500"} /><Td text={"+15.5"} /></tr>
            <tr><Td text={"3位"} /><Td text={"15,300"} /><Td text={"-14,700"} /><Td text={"-"} /><Td text={"-10,000"} /><Td text={"-24,700"} /><Td text={"-24.7"} /></tr>
            <tr><Td text={"4位"} /><Td text={"10,700"} /><Td text={"-19,300"} /><Td text={"-"} /><Td text={"-30,000"} /><Td text={"-49,300"} /><Td text={"-49.3"} /></tr>
          </ScoreTable>
        </Scroll>

        <SubTitle text={"点数の決め方"} textAlign={"left"} />
        <LeftText text={<>
          麻雀の点数は<BoldText text={"飜(ハン)"} />と<BoldText text={"符(フ)"} />の2つの要素で決まります。<br />
          飜は役やドラの枚数によって決まり、符は手牌の構成によって決まります。<br />
          例えばこの２つの和了はどちらも<BoldText text={"断么九(タンヤオ)"} />とドラで2飜になります。<br />
          しかし上の和了は40符なので親：3,900点/子：2,600点に、下の和了は30符なので親：2,900点/子：2,000点になり点数が異なります。
        </>} />
        <img src={LearnScore2} style={{ width: '100%' }} alt="40符和了" />
        <img src={LearnScore3} style={{ width: '100%' }} alt="30符和了" />
        <RightChat src={girlQuestion} alt="girlQuestion" text={(<>
          おんなじ飜数でも点数が変わるの！？<br />
          青2本でちょうど2,000点の時と、白の100点を使う時があるから変だと思ってたよ。
        </>)} />
        <LeftChat src={teacherHappy} alt="teacherHappy" text={(<>
          符計算は複雑で一気に覚えようとすると混乱すると思います。<br />
          この章では、まずはおさえてほしいというポイントを説明します。<br />
          説明では役が出てくるので<CommonLink to="/YakuList#start" text={"麻雀の役一覧"} />もチェックしてみてください！
        </>)} />

        <FullLineSubTitle text={"平和・七対子の点数"} />
        <LeftText text={<>
          和了時の役に<BoldText text={"平和(ピンフ)"} />または<BoldText text={"七対子(チートイツ)"} />がついているときは符が固定になり点数は以下の表の通りになります。<br />
        </>} />
        <LineSubTitle text={<>平和ツモ 20符</>} />
        <Table text={(<>
          <tr><Th text={""} /><Th text={"親"} /><Th text={"子"} /></tr>
          <tr><Td text={"1飜"} /><Td text={"-"} /><Td text={"-"} /></tr>
          <tr><Td text={"2飜"} /><Td text={"700オール"} /><Td text={"400/700"} /></tr>
          <tr><Td text={"3飜"} /><Td text={"1,300オール"} /><Td text={"700/1,300"} /></tr>
          <tr><Td text={"4飜"} /><Td text={"2,600オール"} /><Td text={"1,300/2,600"} /></tr>
        </>)} />

        <LineSubTitle text={<>平和ロン 30符</>} />
        <Table text={(<>
          <tr><Th text={""} /><Th text={"親"} /><Th text={"子"} /></tr>
          <tr><Td text={"1飜"} /><Td text={"1,500"} /><Td text={"1,000"} /></tr>
          <tr><Td text={"2飜"} /><Td text={"2,900"} /><Td text={"2,000"} /></tr>
          <tr><Td text={"3飜"} /><Td text={"5,800"} /><Td text={"3,900"} /></tr>
          <tr><Td text={"4飜"} /><Td text={"12,000"} /><Td text={"8,000"} /></tr>
        </>)} />

        <LineSubTitle text={<>七対子 25符</>} />
        <Table text={(<>
          <tr><Th text={""} /><Th text={"親"} /><Th text={"子"} /></tr>
          <tr><Td text={"1飜"} /><Td text={"-"} /><Td text={"-"} /></tr>
          <tr><Td text={"2飜"} /><Td text={"2,400"} /><Td text={"1,600"} /></tr>
          <tr><Td text={"3飜"} /><Td text={<>4,800<br />1,600オール</>} /><Td text={<>3,200<br />800/1,600</>} /></tr>
          <tr><Td text={"4飜"} /><Td text={<>9,600<br />3,200オール</>} /><Td text={<>6,400<br />1,600/3,200</>} /></tr>
        </>)} />

        <LeftChat src={teacherPoint} alt="teacherPoint" text={(<>
          「オール」とついているのは親のツモで、全員からその点数だけもらいます<br />
          「/」で区切られているものは子のツモで、左の数字を子から、右の数字を親からそれぞれもらいます。
        </>)} />

        <FullLineSubTitle text={"30符・40符の点数"} />
        <LeftText text={<>
          和了時の役に平和または七対子がないとき、点数は多くの場合以下のようになります。<br />
          <Ul text={(
            <>
              <Li text={"副露でツモ/ロン：30符"} />
              <Li text={"門前でツモ：30符"} />
              <Li text={"門前でロン：40符"} />
            </>)} />
        </>} />
        <LineSubTitle text={<>30符</>} />
        <Table text={(<>
          <tr><Th text={""} /><Th text={"親"} /><Th text={"子"} /></tr>
          <tr><Td text={"1飜"} /><Td text={<>1,500<br />500オール</>} /><Td text={<>1,000<br />300/500</>} /></tr>
          <tr><Td text={"2飜"} /><Td text={<>2,900<br />1,000オール</>} /><Td text={<>2,000<br />500/1,000</>} /></tr>
          <tr><Td text={"3飜"} /><Td text={<>5,800<br />2,000オール</>} /><Td text={<>3,900<br />1,000/2,000</>} /></tr>
          <tr><Td text={"4飜"} /><Td text={<>12,000<br />4,000オール</>} /><Td text={<>8,000<br />2,000/4,000</>} /></tr>
        </>)} />

        <LineSubTitle text={<>40符</>} />
        <Table text={(<>
          <tr><Th text={""} /><Th text={"親"} /><Th text={"子"} /></tr>
          <tr><Td text={"1飜"} /><Td text={<>2,000<br />700オール</>} /><Td text={<>1,300<br />400/700</>} /></tr>
          <tr><Td text={"2飜"} /><Td text={<>3,900<br />1,300オール</>} /><Td text={<>2,600<br />700/1,300</>} /></tr>
          <tr><Td text={"3飜"} /><Td text={<>7,700<br />2,600オール</>} /><Td text={<>5,200<br />1,300/2,600</>} /></tr>
          <tr><Td text={"4飜"} /><Td text={<>12,000<br />4,000オール</>} /><Td text={<>8,000<br />2,000/4,000</>} /></tr>
        </>)} />
        <RightChat src={girlQuestion} alt="girlQuestion" text={(<>
          多くの場合って……そんな適当でいいの？
        </>)} />
        <LeftChat src={teacherHappy} alt="teacherHappy" text={(<>
          実は、麻雀の和了はほとんどが40符以下なんです。<br />
          まずは40符までをしっかり覚えることが大切です。
        </>)} />

        <FullLineSubTitle text={"満貫以上の点数"} />
        <LeftText text={<>
          <BoldText text={"満貫(マンガン)"} />以上の飜数の場合は符計算は不要で、固定の点数となります。<br />
          和了時の役に平和がなく、飜を数えて4飜以上あった場合は以下の表の通りの点数になります。<br />
          平和がある場合は5飜から満貫となり、それ以上の飜数は表の通りの点数になります。
        </>} />
        <LineSubTitle text={<>満貫以上</>} />
        <Table text={(<>
          <tr><Th text={""} /><Th text={"親"} /><Th text={"子"} /></tr>
          <tr><Td text={"満貫(4/5飜)"} /><Td text={<>12,000<br />4,000オール</>} /><Td text={<>8,000<br />2,000/4,000</>} /></tr>
          <tr><Td text={"跳満(6/7飜)"} /><Td text={<>18,000<br />6,000オール</>} /><Td text={<>12,000<br />3,000/6,000</>} /></tr>
          <tr><Td text={"倍満(8/9/10飜)"} /><Td text={<>24,000<br />8,000オール</>} /><Td text={<>16,000<br />4,000/8,000</>} /></tr>
          <tr><Td text={"3倍満(11/12飜)"} /><Td text={<>36,000<br />12,000オール</>} /><Td text={<>24,000<br />6,000/12,000</>} /></tr>
          <tr><Td text={"役満(13飜〜)"} /><Td text={<>48,000<br />16,000オール</>} /><Td text={<>32,000<br />8,000/16,000</>} /></tr>
        </>)} />
        <RightChat src={girlHappy} alt="girlHappy" text={(<>
          満貫以上だと点数がすごい高いね。<br />
          やっぱり高い点数を狙いたくなる！
        </>)} />
        <LeftChat src={teacherHappy} alt="teacherHappy" text={(<>
          特に親番の時に高い点数をアガることができれば、一気にゲームの主導権を握ることができます。<br />
        </>)} />

        <br />
        <Kugiri10000 />
        <br />

        <RightChat src={girlQuestion} alt="girlQuestion" text={(<>
          やっぱり点数計算は複雑だね。
        </>)} />
        <LeftChat src={teacherHappy} alt="teacherHappy" text={(<>
          慣れてくると自然と計算できるようになりますよ。<br />
          特に満貫以上の点数は覚えやすいので、まずはそこを重点的に覚えるといいですね。<br />
          間違えてもいいので最初は積極的に点数を申告してみてください！
        </>)} />
        <RightChat src={girlHappy} alt="girlHappy" text={(<>
          習うより慣れろってことだね！<br />
          がんばるぞー！
        </>)} />
        <LeftChat src={teacherSmile} alt="teacherSmile" text={(<>
          計算の解説や、やってはいけないこと、その他のルールはまた後で解説するので、お待ちくださいね！
        </>)} />

        <Flex direction={"column"} alignItems={'center'}>
          <Ul text={(
            <>
              <Li text={<CommonLink to="/RuleIntroduction/MahjongIntroduction#start" text={"麻雀ルールTOP"} />} />
              <Li text={<CommonLink to="/RuleIntroduction/LetsReach#start" text={"前ページ リーチをしよう！"} />} />
            </>
          )} />
        </Flex>
      </Contents>
    </Page>
  );
};

export default LearnScore;
