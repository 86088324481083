import '@aws-amplify/ui-react/styles.css';
import '../App.css';
import React from 'react';
import { Button } from '@mui/material';
import styled from "styled-components";
import { Flex } from "@aws-amplify/ui-react"

const CustomIcon = styled.span`
  && {
    font-size: 8rem; 
    color: ${(props) => (props.theme.palette.primary.main)}; 
  }
`;

const CustomStepLabel = styled.p`
  && {
    color: ${(props) => (props.theme.palette.primary.main)}; 
    font-size: 2rem;
  }
`;

const Over18 = ({ handleOver18, isOver18 }) => {
  return (
    <div>
      {isOver18 ?
        <div>
          <CustomIcon className="material-symbols-rounded" >
            <span class="material-symbols-outlined">
              task_alt
            </span>
          </CustomIcon>
          <CustomStepLabel>認証済み</CustomStepLabel>
          <p>
            18歳以上の年齢確認は認証済みです。<br />
          </p>
        </div>
        : <div>
          <CustomIcon className="material-symbols-rounded" >
            Warning
          </CustomIcon>
          <CustomStepLabel>年齢確認</CustomStepLabel>
          <p>
            18歳未満の方は当サイトの一部機能をご利用できません。<br />
            あなたは18歳以上ですか？
          </p>
          <Flex direction={"column"} justifyContent="center">
            <Flex direction={"row"} alignItems={'center'} justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleOver18}
                name="isOver18"
                disabled={isOver18}
              >
                はい、18歳以上です
              </Button>
            </Flex>
            <Flex direction={"row"} alignItems={'center'} justifyContent="center">
              <Button
                variant="contained"
                color="quaternary"
                onClick={handleOver18}
                name="isNotOver18"
              >
                いいえ、違います
              </Button>
            </Flex>
          </Flex>
        </div>}
    </div>
  );
};

export default Over18;
