
import '@aws-amplify/ui-react/styles.css';
import '../App.css';
import { useState, useContext } from 'react';
import React from 'react';
import MessageContext from "../MessageContext";
import { AccountSettings } from '@aws-amplify/ui-react';
import { Text } from '@aws-amplify/ui-react';
import styled from 'styled-components';
const PasswordForm = styled.div`
padding: 2rem;
display: flex;
flex-direction: column;
gap: 1rem;
`;


const ChangePasswordForm = (props) => {
  const { setSuccessMessage } = useContext(MessageContext);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSuccess = () => {
    setSuccessMessage("パスワードを更新しました。")
  }

  // パスワード変更フォームの上書き
  const components = {
    CurrentPasswordField: (props) => (
      <AccountSettings.ChangePassword.CurrentPasswordField
        {...props}
        label="現在のパスワード"
      />
    ),
    NewPasswordField: (props) => (
      <AccountSettings.ChangePassword.NewPasswordField
        {...props}
        label="新しいパスワード"
      />
    ),
    ConfirmPasswordField: (props) => {
      // 仮のエラーメッセージ
      const customErrorMessage = "パスワードが一致しません";
      // fieldValidationErrors にエラーメッセージが含まれているか確認
      const hasError = props.fieldValidationErrors && props.fieldValidationErrors.length > 0;
      // もしエラーメッセージがあればそれを使い、そうでなければ元のメッセージを表示
      const errorMessage = hasError ? customErrorMessage : props.errorMessage;
      return (
        <AccountSettings.ChangePassword.ConfirmPasswordField
          {...props}
          label="パスワード確認"
          fieldValidationErrors={[errorMessage]}
        />
      );
    },
    SubmitButton: (props) => (
      <AccountSettings.ChangePassword.SubmitButton {...props}>
        更新
      </AccountSettings.ChangePassword.SubmitButton>
    ),
    ErrorMessage: ({ children }) => (
      <>
        
        <Text variation="warning">{errorMessage}</Text>
      </>
    )
  }
  //errormessageもConfirmPasswordFieldのように修正したい

  const minLength = {
    validationMode: 'onChange',
    validator: (password) => password.length >= 8,
    message: 'パスワードは8文字以上で入力してください',
  };

  const requireUppercase = {
    validationMode: 'onChange',
    validator: (password) => /[A-Z]/.test(password),
    message: 'パスワードには大文字を含めてください',
  };

  const requireLowwercase = {
    validationMode: 'onChange',
    validator: (password) => /[a-z]/.test(password),
    message: 'パスワードには小文字を含めてください',
  };

  const requireDigit = {
    validationMode: 'onChange',
    validator: (password) => /[0-9]/.test(password),
    message: 'パスワードには数字を含めてください',
  };

  const requireSymbols = {
    validationMode: 'onChange',
    validator: (password) => /[@$!%*?&]/.test(password),
    message: 'パスワードには記号を含めてください',
  };

  return (
    <PasswordForm>
      <AccountSettings.ChangePassword
        onSuccess={handleSuccess}
        components={components}
        validators={[minLength, requireLowwercase, requireUppercase, requireDigit, requireSymbols]}
        onError={(e) => {
          //現状でエラーメッセージを変更できないため手動で変更する
          if (e.message.includes("Incorrect username or password")) {
            setErrorMessage("現在のパスワードが間違っています。")
          } else if (e.message.includes("Attempt limit exceeded, please try after some time.")) {
            setErrorMessage("パスワード変更回数が上限に達しました。しばらくしてからお試しください。")
          }

        }}
      />
    </PasswordForm>
  );
};

export default ChangePasswordForm;
