import '@aws-amplify/ui-react/styles.css';
import '../../App.css';
import { useState, useContext, useEffect } from 'react';
import { styled } from 'styled-components';
import { ChangePasswordForm, Over18, DeleteCognitoUserForm } from '../../common-my-ui-components';
import { Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { API } from 'aws-amplify';
import { updateUserM } from '../../graphql/mutations'
import UserContext from "../../UserContext";

const AccordionSetting = styled.div`
box-shadow: 0 0 2px ${(props) => props.theme.palette.disabled.main};
width: 100%
`
const Page = styled(Box)`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const PrivacySetting = ({ deleteUser }) => {

  const { user } = useContext(UserContext);
  // 年齢確認
  const [isOver18, setIsOver18] = useState(false);

  useEffect(() => {
    setIsOver18(user.isOver18);
  }, []);

  const MyAccordion = ({ title, content }) => (
    <AccordionSetting>
      <Accordion>
        <AccordionSummary
          expandIcon={<span className="material-symbols-rounded">
            expand_more
          </span>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div>{title}</div>
        </AccordionSummary>
        <AccordionDetails >
          {content}
        </AccordionDetails>
      </Accordion>
    </AccordionSetting>
  );

  // 年齢確認押下の操作
  const handleOver18 = async (e) => {
    const data = {
      input: {
        id: user.id,
        isOver18: e.target.name === "isOver18",
        _version: user._version,
      }
    }
    await API.graphql({
      query: updateUserM,
      variables: data,
    });
    //アプリケーションに関わるユーザー情報修正のためリロード
    window.location.reload();
  };
  return (
    <Page>
      <MyAccordion title={"パスワードを変更する"} content={<ChangePasswordForm />} />
      <MyAccordion title={"年齢確認をする"} content={<Over18 handleOver18={handleOver18} isOver18={isOver18} />} />
      <MyAccordion title={"退会をする "} content={<DeleteCognitoUserForm deleteUser={deleteUser} />} />
    </Page>

  );
};

export default PrivacySetting;