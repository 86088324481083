import {
  MahjongIntroduction,
  WhatIsMahjong,
  RememberBasic,
  LearnGameFlow,
  LetsWin,
  LetsReach,
  LetsCall,
  LearnScore
} from "./Page"

const PageSelecter = (props) => {
  var { page } = props;

  // ページ名に応じて適切なコンポーネントをレンダリング
  switch (page) {
    case "MahjongIntroduction":
      return <MahjongIntroduction />;
    case "WhatIsMahjong":
      return <WhatIsMahjong />;
    case "RememberBasic":
      return <RememberBasic />
    case "LearnGameFlow":
      return <LearnGameFlow />
    case "LetsWin":
      return <LetsWin />
    case "LetsReach":
      return <LetsReach />
    case "LetsCall":
      return <LetsCall />
    case "LearnScore":
      return <LearnScore />
    default:
      return <MahjongIntroduction />;
  }
};

export default PageSelecter;
