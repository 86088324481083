import React from "react";
import styled from "styled-components";

const StyledFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(221,221,221,1);
  width: ${props => props.size || '2rem'};
  height: ${props => props.size || '2rem'};
  font-size: 0.2rem;
  cursor: pointer;
`;

const StyledText = styled.span`
  font-size: 1rem;
  font-weight: 400;
  color: rgba(0,0,0,1);
  white-space: pre-wrap;
`;

export default function UserIconDefault(props) {
  const { iconText,size, ...rest } = props;
  return (
    <StyledFlex size={size} {...rest}>
      <StyledText>{iconText}</StyledText>
    </StyledFlex>
  );
}
