import React from 'react';
import { styled } from 'styled-components';
import { Link } from "react-router-dom";

const Page = styled.div`
padding: 1rem;
margin: 0 auto;
`;

const CustomIcon = styled.span`
  && {
    font-size: 8rem; 
    color: ${(props) => (props.theme.palette.primary.main)}; 
  }
`;

const CustomStepLabel = styled.p`
  && {
    color: ${(props) => (props.theme.palette.primary.main)}; 
    font-size: 2rem;
  }
`;

const Contents = styled.div`
margin: 2rem;
`;

const Suspension = () => {

  return (
    <Page>
      <CustomIcon className="material-symbols-rounded" >
        <span class="material-symbols-outlined">
          person_cancel
        </span>
      </CustomIcon>
      <CustomStepLabel>アカウントは凍結されています</CustomStepLabel>
      <Contents>
        雀の巣では雀の巣ルールに違反しているアカウントを凍結しています。<br />
        <Link to="/Terms" >
          詳細はこちら
        </Link>
      </Contents>

      <Contents>
        アカウント凍結に異議を申し立てる場合は、
        <Link to="/Contact" >
          問い合わせフォーム
        </Link>
        よりお問い合わせください。<br />
      </Contents>

    </Page>
  );
}

export default Suspension;
