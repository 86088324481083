import styled from "styled-components";
import { useLocation, useNavigate } from 'react-router-dom';
import { useGoBack } from "../my-func/useGoBack";
import { useEffect } from "react";

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.span`
  cursor: pointer;
`;

const GoBackButton = () => {
  const goBack = useGoBack(); 
  const location = useLocation();
  
  const noGoBackPages = ['/', '/TalkRoomList','/Contact','/PrivacyPolicy','/Terms','/RecruitmentList'];
  const showGoBackButton = !noGoBackPages.includes(location.pathname);

  // useEffect(() => {
  //   const handlePopstate = (event) => {
  //     event.preventDefault();
  //     goBack();
  //   };

  //   window.addEventListener('popstate', handlePopstate);
  
  //   return () => {
  //     // ブラウザバックボタンを押すと、popstateを検知する前にアンマウントされてしまうために、locationを依存配列に入れるとブラウザバックを押したときにhandlePopstateが実行されない。
  //     window.removeEventListener('popstate', handlePopstate);
  //   };
  // }, [navigate]);

  return (
    <IconContainer>
      {showGoBackButton &&
        <Icon className="material-symbols-rounded" onClick={goBack}>
          arrow_back_ios
        </Icon>
      }
    </IconContainer>
  );
};


export default GoBackButton;
