import { styled } from "styled-components";
import { Flex } from '@aws-amplify/ui-react';
import {
  MainTitle,
  SubTitle,
  FullLineSubTitle,
  LineSubTitle,
  CommonLink,
  Ul,
  Li,
  LeftText,
  LeftChat,
  RightChat,
  BoldText,
  Kugiri10000
} from "../../../../common-my-ui-components";
import LetsCall1 from '../../../../common-my-ui-components/img/introduction/LetsCall1.png';
import LetsCall2 from '../../../../common-my-ui-components/img/introduction/LetsCall2.png';
import LetsCall3 from '../../../../common-my-ui-components/img/introduction/LetsCall3.png';
import LetsCall4 from '../../../../common-my-ui-components/img/introduction/LetsCall4.png';
import LetsCall5 from '../../../../common-my-ui-components/img/introduction/LetsCall5.png';
import LetsCall6 from '../../../../common-my-ui-components/img/introduction/LetsCall6.png';

import teacherSmile from '../../../../common-my-ui-components/img/miniChar/teacherSmile.png';
import teacherPoint from '../../../../common-my-ui-components/img/miniChar/teacherPoint.png';
import teacherHappy from '../../../../common-my-ui-components/img/miniChar/teacherHappy.png';
import girlQuestion from '../../../../common-my-ui-components/img/miniChar/girlQuestion.png';
import girlSmile from '../../../../common-my-ui-components/img/miniChar/girlSmile.png';
import girlHappy from '../../../../common-my-ui-components/img/miniChar/girlHappy.png';

// ページ全体の設定
const Page = styled.div`
width: 100%; 
`;

const Contents = styled.div`
width: 100%;
padding: 1rem;
margin: 0 auto;
text-align: center;
`;

const LetsCall = () => {
  return (
    <Page>
      <Contents>
        <MainTitle text={"鳴いてみよう！"} tabText={"麻雀の基本"} textAlign={"left"} />
        <RightChat src={girlQuestion} alt="girlQuestion" text={(<>
          うーん……聴牌しないなぁ。<br />
          全然いい牌をツモれないよ！
        </>)} />
        <LeftChat src={teacherPoint} alt="teacherPoint" text={(<>
          そんなときは鳴いてみましょう。<br />
          相手の人が捨てた牌をもらうことができますよ。
        </>)} />

        <SubTitle text={"副露/鳴きって何？"} textAlign={"left"} />
        <LeftText text={<>
          相手の捨てた牌をもらうことを<BoldText text={"副露(フーロ)"} />、または<BoldText text={"鳴き"} />といいます。<br />
          鳴きは<BoldText text={"ポン"} />、<BoldText text={"チー"} />、<BoldText text={"カン"} />の3種類あります。
        </>} />
        <RightChat src={girlSmile} alt="girlSmile" text={(<>
          どんな違いがあるんだろう！
        </>)} />
        <LeftChat src={teacherSmile} alt="teacherSmile" text={(<>
          違いを覚えてしっかり実戦で使えるようにしましょう！
        </>)} />

        <SubTitle text={"ポンとは？"} textAlign={"left"} />
        <LeftText text={<>
          ポンは刻子を作るための鳴きです。<br />
          手牌に対子があり、他の人が同じ牌を捨てたときにポンすることができます。
        </>} />
        <FullLineSubTitle text={"ポンの手順"} />
        <LeftText text={<>
          ポンしたい牌が出たときにすぐに「ポン」と<BoldText text={"発声"} />します。<br />
          手牌の2枚をさらし、ポンした牌と一緒に卓の右側に並べます。<br />
          そのあと不要牌を1枚捨てます。
        </>} />
        <img src={LetsCall1} style={{ width: '100%', maxWidth: '500px' }} alt="ポン手順説明図" />
        <RightChat src={girlSmile} alt="girlSmile" text={(<>
          だれからポンをしたのかで並べ方が変わるんだね！
        </>)} />
        <LeftChat src={teacherHappy} alt="teacherHappy" text={(<>
          はい、だれが何の牌を捨てたのかは重要な情報になるので、一目でわかるように並べます。
        </>)} />

        <SubTitle text={"チーとは？"} textAlign={"left"} />
        <LeftText text={<>
          チーは順子を作るための鳴きです。<br />
          順子が完成する牌を<BoldText text={"上家"} />が捨てたときにチーすることができます。
        </>} />
        <FullLineSubTitle text={"チーの手順"} />
        <LeftText text={<>
          チーをしたい牌が出たときに「チー」と<BoldText text={"発声"} />します。<br />
          手牌の2枚をさらし、チーした牌と一緒に卓の右側に並べます。<br />
          そのあと不要牌を1枚捨てます。
        </>} />
        <img src={LetsCall2} style={{ width: '100%', maxWidth: '500px' }} alt="チー手順説明図" />
        <LeftChat src={teacherSmile} alt="teacherSmile" text={(<>
          ポンとチーの発声が同時の場合、ポンが優先されます。<br />
          チーをするときは一呼吸おいて発声すると良いでしょう！
        </>)} />

        <SubTitle text={"槓とは？"} textAlign={"left"} />
        <LeftText text={<>
          <BoldText text={"槓(カン)"} />は同じ牌を4枚揃えたときにすることができます。<br />
          カンが成立するとドラ表示牌をめくりドラが増えます。このドラを<BoldText text={"槓ドラ"} />といいます。<br />
          以下の3種類のカンが存在します。
          <Ul text={(
            <>
              <Li text={"暗槓/アンカン"} />
              <Li text={"大明槓/大ミンカン"} />
              <Li text={"小明槓/小ミンカン(加槓/加カン)"} />
            </>)} />
          カンでつくった面子のことを<BoldText text={"槓子(カンツ)"} />といいます。
        </>} />

        <FullLineSubTitle text={"カンの手順"} />
        <LineSubTitle text={<>暗槓</>} />
        <LeftText text={<>
          暗槓は手牌に4枚同じ牌があるときにできるカンです。<br />
          ツモ番で「カン」と<BoldText text={"発声"} />します。<br />
          手牌の中の同一の4牌をさらし、卓の右側に並べます。<br />
          槓ドラ表示牌をめくり、<BoldText text={"嶺上牌(リンシャン牌)"} />から一枚ツモります。<br />
          そのあと不要牌を1枚捨てます。
        </>} />
        <img src={LetsCall3} style={{ width: '100%', maxWidth: '500px' }} alt="暗槓手順説明図" />
        <br />

        <LineSubTitle text={<>大明槓</>} />
        <LeftText text={<>
          大明槓は手牌に3枚同じ牌があるときにできるカンです。<br />
          4枚目の牌が出たにすぐに「カン」と<BoldText text={"発声"} />します。<br />
          手牌の3枚をさらし、大明槓した牌と一緒に卓の右側に並べます。<br />
          槓ドラ表示牌をめくり、<BoldText text={"嶺上牌(リンシャン牌)"} />から一枚ツモります。<br />
          そのあと不要牌を1枚捨てます。
        </>} />
        <img src={LetsCall4} style={{ width: '100%', maxWidth: '500px' }} alt="大明槓手順説明図" />
        <br />

        <LineSubTitle text={<>小明槓</>} />
        <LeftText text={<>
          小明槓はポンした牌と同じ牌をツモったときにできるカンです。<br />
          4枚目の牌をツモったとき「カン」と<BoldText text={"発声"} />します。<br />
          ポンしたときに右側に並べた牌に加え、4枚目を一緒に並べます。<br />
          槓ドラ表示牌をめくり、<BoldText text={"嶺上牌(リンシャン牌)"} />から一枚ツモります。<br />
          そのあと不要牌を1枚捨てます。
        </>} />
        <img src={LetsCall5} style={{ width: '100%', maxWidth: '500px' }} alt="小明槓手順説明図" />
        <br />
        <RightChat src={girlQuestion} alt="girlQuestion" text={(<>
          新しいドラもリーチのあと裏をみれるの？
        </>)} />
        <LeftChat src={teacherHappy} alt="teacherHappy" text={(<>
          見ることができます。<br />
          1回カンが入った状態でリーチで上がると、ドラは合計4枚になります。<br />
          大きな点数がほしいときに有効ですが、相手のドラも乗ってしまうので注意してくださいね。
        </>)} />

        <SubTitle text={"嶺上牌とは？"} textAlign={"left"} />
        <LeftText text={<>
          <BoldText text={"嶺上牌(リンシャン牌)"} />とは王牌のドラ表示牌から横2列4牌の部分をいいます。<br />
          カンをすると手牌が1枚足りなくなるため、嶺上牌からツモって補充します。<br />
          また、王牌は必ず14枚残す必要があるので、嶺上牌が1枚減った分を山から補充します。<br />
          そのためカンにより嶺上牌をツモるたび、海底牌が1枚ずつずれていきます。
        </>} />
        <img src={LetsCall6} style={{ width: '100%', maxWidth: '700px' }} alt="嶺上牌手順説明図" />
        <LeftChat src={teacherPoint} alt="teacherPoint" text={(<>
          画像では海底牌を移動していますが、実際には王牌に移動せずそのままにすることが多いです。<br />
          また、嶺上牌でアガると<BoldText text={"嶺上開花(リンシャンカイホウ)"} />といって1飜の役がつきます。
        </>)} />

        <SubTitle text={"鳴きのデメリット"} textAlign={"left"} />
        <FullLineSubTitle text={"役が必要"} />
        <LeftText text={<>
          麻雀ではアガるために役が必要です。<br />
          門前でテンパイしたときに役がない場合は、リーチをかけることで役をつけることができます。<br />
          しかし、鳴いてしまうとリーチができないので、リーチ以外の役を考える必要があります。<br />
        </>} />

        <FullLineSubTitle text={"点数が低くなりやすい"} />
        <LeftText text={<>
          鳴いていないことを<BoldText text={"門前(メンゼン)"} />といいますが、麻雀には門前限定の役や副露時に飜が下がる役があります。<br/>
          また、リーチができないので一発や裏ドラをみることもできません。
        </>} />

        <FullLineSubTitle text={"手牌をさらしてしまう"} />
        <LeftText text={<>
          鳴くときには他家に面子を見せる必要があります。<br />
          そのため手牌の情報が相手にわかり、自分の狙いが読まれてしまうことがあります。
        </>} />

        <br />
        <Kugiri10000 />
        <br />

        <RightChat src={girlHappy} alt="girlHappy" text={(<>
          相手の捨てた牌をもらうことで手牌がはやく進むんだね。これなら和了のチャンスが増えそう！
        </>)} />
        <LeftChat src={teacherSmile} alt="teacherSmile" text={(<>
          メリットとデメリットを理解して戦略の幅を広げましょう。
        </>)} />

        <Flex direction={"column"} alignItems={'center'}>
          <Ul text={(
            <>
              <Li text={<CommonLink to="/RuleIntroduction/MahjongIntroduction#start" text={"麻雀ルールTOP"} />} />
              <Li text={<CommonLink to="/RuleIntroduction/LearnScore#start" text={"次ページ 点数を知ろう！"} />} />
              <Li text={<CommonLink to="/RuleIntroduction/LetsReach#start" text={"前ページ リーチをしよう！"} />} />
            </>
          )} />
        </Flex>
      </Contents>
    </Page>
  );
};

export default LetsCall;
