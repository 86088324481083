import '@aws-amplify/ui-react/styles.css';
import '../../App.css';
import { Grid, Flex, View } from '@aws-amplify/ui-react';
import { styled } from 'styled-components';
import { useState, useEffect, useContext } from 'react';
import { getMatchingReserveMByRegisterDateandUsrMId, getMatching } from '../../my-func/MatchingManager'
import MessageContext from "../../MessageContext";
import { MatchingInfo } from "../Matching/my-ui-components";
import UserContext from "../../UserContext";
import { Pagenation ,CommonAdSense} from '../../common-my-ui-components';

// ページ全体の設定
const Page = styled.div`
width: 100%;  
padding: 1rem;
margin-bottom: 1rem;
margin-top: 1rem;
`;

const SubTitle = styled.div`
width:100%;
line-height: 160%;
font-size: 1.6rem;
font-weight: 900;
text-align: center;
margin:auto;
position: relative;
padding: 0.25em 0;

&::after {
  content: "";
  display: block;
  height: 4px;
  background: linear-gradient(90deg, ${(props) => (props.theme.palette.text.green)},
  ${(props) => (props.theme.palette.quinary.main)});
}
`;


const MatchingHistory = (props) => {

  const { user, cognitoUser } = useContext(UserContext);
  const { setErrorMessage } = useContext(MessageContext);

  // 現在のページの表示を保存
  const [matchingHistoryList, setMatchingHistoryList] = useState();

  // マッチング情報を取得する
  const getMatchingList = async () => {
    const matchingReserveList = await getMatchingReserveMByRegisterDateandUsrMId(user.id, setErrorMessage);
    const dispData = []
    for (let item of matchingReserveList) {
      // マッチング成立済みの予約だけを表示
      if (!item.matchingMId || item.matchingMId ==="dummyId") continue;
      const matching = await getMatching(item.matchingMId, setErrorMessage);
      if (!matching.talkRoomMId) continue;
      dispData.push(
        <MatchingInfo matchingReserve={item} matching={matching} />
      )
    }
    setMatchingHistoryList(
      <Pagenation dispComponentList={dispData} dispComponentCount={20} siblingCount={1} keyTime={Date.now()} />
    )
  }

  useEffect(() => {
    getMatchingList();
  }, []);


  return (
    <Page>
      <Grid
        templateColumns={{ base: '1fr', large: "2fr 8fr 2fr" }}
      >
        <View rowSpan={1}>{/* PC時の両脇 空白スペース */}<CommonAdSense position="left" /></View>
        <View rowSpan={1}>
          <Flex direction={"column"} alignItems={'center'} >
            <SubTitle>マッチング履歴</SubTitle>
            {matchingHistoryList}
          </Flex>
        </View>
        <View rowSpan={1}>{/* PC時の両脇 空白スペース */}<CommonAdSense position="right" /></View>
      </Grid>
    </Page>
  );
};

export default MatchingHistory;
