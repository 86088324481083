
import '../App.css';
import 'react-datepicker/dist/react-datepicker.css';
import {
  FormControl, InputLabel, Select, MenuItem, TextField, Box, Chip, FormHelperText
} from "@mui/material";
import styled from 'styled-components';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import { jaJP } from '@mui/x-date-pickers/locales';

const TEXTAREAMAXLENGTH = 1000;
const TEXTFIELDMAXLENGTH = 50;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Page = styled.div`
  width:100%;
`;

const FlexColumn = styled(Box)`
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

/**
 * フォーム入力項目を作成する
 * formItems = [
 *   { name: 'text', label: 'テキスト', type: "text", message: textErrorMessage },
 *   { name: 'dateTime', label: '日時 *', type: "dateTime", value: valueDateTime, setValue: setValueDateTime, message: dateTimeErrorMessage },
 *   { name: 'number', label: '数字', type: "number", message: numberErrorMessage },
 *   { name: 'textarea', label: 'テキストエリア', type: "textarea", message: textareaErrorMessage },
 *   { name: 'select', 
 *   label: 'セレクト', 
 *   type: "select", 
 *   options: options,  // [{value:"value",label:"label"}]の連想配列
 *   message: selectErrorMessage },
 *   { 
 *     name: 'selectChip', 
 *     label: 'セレクトチップ', 
 *     type: "selectChip", 
 *     chipFormData: chipFormData, // 選択済みの選択肢の文字列配列
 *     setChipFormData: setChipFormData, 
 *     chipOptions: chipOptions, // チップセレクトボックスの全選択肢の文字列配列
 *     message: selectChipErrorMessage }
 * ];
 * 
 * 初期データを投入する際はformDataで渡す
 * nameが同一の場合該当するフォームに初期値が設定される
 * @param {*} props 
 * @returns 
 */
const CommonFormItems = (props) => {
  // 引数を受け取る
  const { inputformItems, formData, setFormData } = props;

  // フォームに変更があった場合選択値を更新する
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // chipセレクトフォームに変更があった場合選択値を更新する
  const handlechipSelectChange = (e, item) => {
    const { value } = e.target;
    item.setChipFormData(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  // dateフォームに変更があった場合に更新する
  const handleDateChange = (date, setValue) => {
    try {
      setValue(new Date(date.toISOString()));
      setFormData((prevFormData) => ({
        ...prevFormData,
        endDateTime: date.toISOString(), // dateをISO文字列に変換して保存
      }));
    } catch (e) {
    }
  };

  return (
    <Page>
      {inputformItems.map((item) => {
        if (item.type === "select") {
          return (
            <FlexColumn>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id={item.name}>{item.label}</InputLabel>
                <Select
                  name={item.name}
                  value={formData[item.name]}
                  label={item.label}
                  labelId={item.name}
                  onChange={handleInputChange}
                  error={item.message} >
                  {item.options.map((opt) => (
                    <MenuItem value={opt.value}>
                      {opt.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{item.message}</FormHelperText>
              </FormControl>
            </FlexColumn>
          )
        } else if (item.type === "selectChip") {
          return (
            <FlexColumn>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id={item.name}>{item.label}</InputLabel>
                <Select
                  multiple
                  name={item.name}
                  value={item.chipFormData}
                  label={item.label}
                  onChange={(e) => handlechipSelectChange(e, item)}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} color="primary" />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                  error={item.message}>
                  {item.chipOptions.map((opt) => (
                    <MenuItem value={opt.value} key={opt.id}>
                      {opt.value}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{item.message}</FormHelperText>
              </FormControl>
            </FlexColumn>
          )
        } else if (item.type === 'dateTime') {
          return (
            <FlexColumn>
              <FormControl fullWidth variant="outlined" size="small">
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  localeText={jaJP.components.MuiLocalizationProvider.defaultProps.localeText}
                  dateFormats={{ monthAndYear: 'YYYY年 MM月' }}
                >
                  <DateTimePicker
                    label="募集終了日"
                    value={dayjs(item.value)}
                    onChange={(date) => handleDateChange(date, item.setValue)}
                    format="YYYY/MM/DD HH:mm"
                    ampm={false}
                    slotProps={{
                      textField: {
                        helperText: item.message,
                        error: item.message,
                      },
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </FlexColumn>
          )
        } else if (item.type === 'number') {
          return (
            <FlexColumn>
              <TextField
                fullWidth
                size="small"
                id="outlined-basic"
                label={item.label}
                name={item.name}
                value={formData[item.name]}
                onChange={handleInputChange}
                error={item.message}
                helperText={item.message} />
            </FlexColumn>
          )
        } else if (item.type === 'text') {
          return (
            <FlexColumn>
              <TextField
                variant="outlined"
                fullWidth
                type='text'
                size="small"
                inputProps={{ maxLength: TEXTFIELDMAXLENGTH }}
                label={item.label}
                name={item.name}
                value={formData[item.name]}
                onChange={handleInputChange}
                error={item.message}
                helperText={item.message} />
            </FlexColumn>
          )
        } else if (item.type === 'textarea') {
          return (
            <FlexColumn>
              <TextField
                variant="outlined"
                fullWidth
                type='text'
                size="small"
                multiline
                rows={10}
                inputProps={{ maxLength: TEXTAREAMAXLENGTH }}
                label={item.label}
                name={item.name}
                value={formData[item.name]}
                onChange={handleInputChange}
                error={item.message}
                helperText={item.message} />
            </FlexColumn>
          )
        }
      })}
    </Page>
  );
};

export default CommonFormItems;