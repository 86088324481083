import { I18n } from "aws-amplify"
import { translations } from "@aws-amplify/ui"

const loginFormLocalize = () => {
  I18n.putVocabularies(translations);
  I18n.setLanguage('ja');

  I18n.putVocabularies({
    ja: {
      'Account recovery requires verified contact information': 'アカウントの回復には検証された連絡先情報が必要です',
      'Add your Profile': 'プロフィールを追加する',
      'Add your Website': 'ウェブサイトを追加する',
      'Back to Sign In': 'ログインに戻る',
      'Change Password': 'パスワードを変更する',
      Changing: '変更中',
      Code: 'コード',
      'Confirm Password': '確認用パスワード',
      'Confirm Sign Up': '会員登録を確認する',
      'Confirm SMS Code': 'SMSコードを確認する',
      'Confirm TOTP Code': 'TOTPコードを確認する',
      Confirm: '確認する',
      'Confirmation Code': '確認コード',
      Confirming: '確認中',
      'Create a new account': '会員登録',
      'Create Account': '会員登録',
      'Creating Account': '会員登録中',
      'Dismiss alert': 'アラートを閉じる',
      Email: 'メールアドレス',
      'Enter your Birthdate': '誕生日を入力',
      'Enter your code': 'コードを入力',
      'Enter your Confirmation Code': '確認コードを入力',
      'Enter your Email': 'メールアドレスを入力',
      'Enter your Family Name': '姓を入力',
      'Enter your Given Name': '名を入力',
      'Enter your Middle Name': 'ミドルネームを入力',
      'Enter your Name': '名前を入力',
      'Enter your Nickname': 'ニックネームを入力',
      'Enter your Password': 'パスワードを入力',
      'Enter your phone number': '電話番号を入力',
      'Enter your Preferred Username': '希望のユーザー名を入力',
      'Enter your username': 'ユーザー名を入力',
      'Forgot password?': 'パスワードを忘れた場合',
      'Forgot your password?': 'パスワードを忘れた場合',
      'Hide password': 'パスワードを非表示にする',
      'It may take a minute to arrive': '到着まで数分かかる場合があります',
      Loading: '読み込み中',
      'New password': '新しいパスワード',
      or: 'または',
      Password: 'パスワード',
      'Phone Number': '電話番号',
      'Please confirm your Password': '確認用パスワードを入力',
      'Resend Code': 'コードを再送信する',
      'Reset your password': 'パスワードをリセットする',
      'Reset your Password': 'パスワードをリセットする',
      'Send code': 'コードを送信する',
      'Send Code': 'コードを送信する',
      Sending: '送信中',
      'Setup TOTP': 'TOTPの設定',
      'Show password': 'パスワードを表示する',
      'Sign in to your account': 'ログインする',
      'Sign In with Amazon': 'Amazonでログイン',
      'Sign In with Apple': 'Appleでログイン',
      'Sign In with Facebook': 'Facebookでログイン',
      'Sign In with Google': 'Googleでログイン',
      'Sign in': 'ログイン',
      'Sign In': 'ログイン',
      'Signing in': 'ログイン中',
      Skip: 'スキップ',
      Submit: '送信',
      Submitting: '送信中',
      Username: 'ユーザー名',
      'Verify Contact': '連絡先を検証する',
      Verify: '検証する',
      'We Emailed You': 'メールを送信しました',
      'We Sent A Code': 'コードを送信しました',
      'We Texted You': 'SMSを送信しました',
      'Your code is on the way. To log in, enter the code we emailed to': 'コードが送信されています。ログインするには、メールで送られたコードを入力してください',
      'Your code is on the way. To log in, enter the code we sent you': 'コードが送信されています。ログインするには、送信されたコードを入力してください',
      'Your code is on the way. To log in, enter the code we texted to': 'コードが送信されています。ログインするには、SMSで送られたコードを入力してください',
      // エラー文の日本語化
      'User does not exist.': 'ユーザー名またはパスワードが違います',
      'Incorrect username or password.': 'ユーザー名またはパスワードが違います',
      'User is not confirmed.': 'ユーザーは検証されていません',
      'User already exists': 'ユーザーは既に存在します',
      'Invalid verification code provided, please try again.': '指定された確認コードが無効です。もう一度お試しください',
      'Invalid password format': 'パスワードのフォーマットが不正です',
      'Account recovery requires verified contact information': 'アカウントの復旧には確認済みの連絡先情報が必要です',
      'Invalid phone number format': '不正な電話番号フォーマットです。 電話番号は次のフォーマットで入力してください: +12345678900',
      'An account with the given email already exists.': 'そのメールアドレスは既に存在します',
      'Username cannot be empty': 'ユーザー名は必須です',
      'Password attempts exceeded': 'ログイン試行回数が上限に達しました',
      'Attempt limit exceeded, please try after some time.': '試行制限を超過しました。しばらくしてからもう一度お試しください',
      'Username/client id combination not found.': 'ユーザーが存在しません',
      'Password must have at least 8 characters': 'パスワードは8文字以上で入力してください',
      'Password must have upper case letters': 'パスワードには大文字を含めてください',
      'Password must have lower case letters': 'パスワードには小文字を含めてください',
      "Password must have numbers": "パスワードには数字を含めてください",
      'Password must have special characters': 'パスワードには記号を含めてください',
      'Your passwords must match': 'パスワードが一致しません',
      'CUSTOM_AUTH is not enabled for the client.': 'パスワードは必須です', // 本来の意味とは異なるが、パスワード未入力時に発生するのでこの訳としている
      'Password does not conform to policy: Password not long enough': 'パスワードは半角英字大文字・小文字と数字、記号を含む8文字以上で入力してください',
      'Password does not conform to policy: Password must have uppercase characters': 'パスワードは半角英字大文字・小文字と数字、記号を含む8文字以上で入力してください',
      'Password does not conform to policy: Password must have lowercase characters': 'パスワードは半角英字大文字・小文字と数字、記号を含む8文字以上で入力してください',
      'Password does not conform to policy: Password must have numeric characters': 'パスワードは半角英字大文字・小文字と数字、記号を含む8文字以上で入力してください',
      "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6": 'パスワードは半角英字大文字・小文字と数字、記号を含む8文字以上で入力してください',
      "2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\S]+.*[\S]+$": 'パスワードは半角英字大文字・小文字と数字、記号を含む8文字以上で入力してください',
      'Temporary password has expired and must be reset by an administrator.': '一時パスワードは無効です。管理者によるリセットが必要です',
      "1 validation error detected: Value null at 'attributeName' failed to satisfy constraint: Member must not be null": '入力チェックエラー、必須項目がNULLです', //アカウント復旧でのメールアドレスのラジオをチェックONにせず、送信した場合
      'Invalid code received for user': '無効なコードです', // TOTPでのトークンに誤りがある
      'Invalid session for the user, session is expired.': '無効なセッション、セッションは有効期限切れです。ログインからやり直してください', // ログインセッション無効です、ログインからやり直し
      'Cannot reset password for the user as there is no registered/verified email or phone_number':'ユーザーのコード認証が完了されていないため、パスワードの変更ができません。'
    },
  });
}

export { loginFormLocalize };