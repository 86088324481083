import { Link } from "react-router-dom"
import styled from "styled-components"
import { Button } from "@aws-amplify/ui-react"
import { useState, useContext } from "react"
import { LoginDialog } from "../common-my-ui-components"
import { useNavigate } from 'react-router-dom';
import SidebarNotificationSetting from "./SidebarNotificationSetting"
import UserContext from "../UserContext";
import texture from "../../src/common-my-ui-components/img/texture/groovepaper.png"

const SideBarContainer = styled.div`
  position: fixed;
  width: fit-content;
  max-width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-image:url(${texture});
  background-color: ${(props) => props.theme.palette.background.default};
  top: 0;
  right: 0;
  transform: translateX(${(props) => (props.isOpen ? "0" : "100%")});
  transition: transform 0.3s ease-out;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  align-items: left;
  text-align: left;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9998;
`

const LogoutButton = styled(Button)`
  margin-top: auto;
  border-radius: 5px;
  padding: 10px;
  font-size: 18px;
`

const Sidebar = ({ isOpen, onSetSidebarOpen, logout }) => {
  // ログインユーザーを取得
  const { user, cognitoUser } = useContext(UserContext);

  // 処理後に各画面に遷移させる
  const navigate = useNavigate();

  // 通知設定の表示
  const [isOpenNotificationSetting, setIsOpenNotificationSetting] = useState(false)
  const openNotificationSetting = () => {
    setIsOpenNotificationSetting(true)
    onSetSidebarOpen(false) // サイドバーを閉じる
  }

  // ログインダイアログ表示
  const [isOpenLoginDialog, setIsOpenLoginDialog] = useState(false)
  // ログインダイアログの開閉
  const dialogHandler = () => {
    onSetSidebarOpen(false)
    setIsOpenLoginDialog(!isOpenLoginDialog)
  }

  // ログアウト処理
  const logoutHandler = () => {
    navigate('/');
    logout();
  }

  return (
    <>
      {isOpen && <Overlay onClick={() => onSetSidebarOpen(false)} />}
      <SideBarContainer isOpen={isOpen}>
        {user == null ? (
          <></>
        ) : (
          <>
            <Link to={`/Profile/${user.id}`} onClick={() => onSetSidebarOpen(false)}>
              <p>プロフィール</p>
            </Link>
            <Link to="/PrivacySetting" onClick={() => onSetSidebarOpen(false)}>
              <p>プライバシー設定</p>
            </Link>
            <Link onClick={() => openNotificationSetting(user.id)}>
              <p>通知設定</p>
            </Link>
          </>
        )}
        <Link to="/RuleIntroduction#start" onClick={() => onSetSidebarOpen(false)}>
          <p>麻雀ルール</p>
        </Link>
        <Link to="/Contact" onClick={() => onSetSidebarOpen(false)}>
          <p>お問い合わせ</p>
        </Link>
        <Link to="/PrivacyPolicy" onClick={() => onSetSidebarOpen(false)}>
          <p>プライバシーポリシー</p>
        </Link>
        <Link to="/Terms" onClick={() => onSetSidebarOpen(false)}>
          <p>利用規約</p>
        </Link>
        {cognitoUser == null ? (
          <LogoutButton onClick={dialogHandler}>ログイン/会員登録</LogoutButton>
        ) : (
          <LogoutButton onClick={logoutHandler}>ログアウト</LogoutButton>
        )}

        {/* ログイン/会員登録ダイアログ */}
        <LoginDialog isOpenLoginDialog={isOpenLoginDialog} setIsOpenLoginDialog={setIsOpenLoginDialog} />
      </SideBarContainer>
      <SidebarNotificationSetting isOpen={isOpenNotificationSetting} onClose={() => setIsOpenNotificationSetting(false)} user={user} />
    </>
  )
}

export default Sidebar
