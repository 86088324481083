import React from 'react';
import { TermsContent } from '../../common-my-ui-components';
import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;


const Terms=()=> {
  return (
    <Container>
      <TermsContent/>
    </Container>
  );
}

export default Terms;
