import { DataStore } from "@aws-amplify/datastore";
// import {MyMessage } from "../../../ui-components";
import { TalkRoomM } from "../../../models";
import { useState, useEffect, useContext, useRef ,useLayoutEffect} from "react";
import UserContext from "../../../UserContext";
import { getNewTalkData, getOldTalkData } from "../../../my-func/getTalkData";
import { yyyyMMddHHmmFormatter } from "../../../my-func/dateTimeFormatter";
import { UserIcon } from "../../../common-my-ui-components";
import { getUserM, listUserTalkRoomMS } from "../../../graphql/queries";
import { updateUserTalkRoomM } from "../../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import styled from 'styled-components';
import getUserTalkRoomMS from "../../../my-func/getUserTalkRoomMS";
import {OtherMessage,MyMessage} from "../my-ui-components";

const MESSAGE_FETCH_LIMIT = 20;
const SENTAT_FONTSIZE = "0.5rem";
const CONTENT_FONTSIZE = "0.8rem";
const LINEHEIGHT = "1rem";
const MAXWIDTH = "75%";

const OtherMessageContainer=styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
`;
const MyMessageContainer=styled.div`
  margin-top: 0.5rem;
`;

const MessageContainer = styled.div`
  overflow: auto;
  overscroll-behavior: contain;
  position: relative;
  height: 100%;
`;


const MessageComponent = ({
  Component,
  message,
  sentAt,
  isMyMessage,
  userM,
}) => (
  <Component
    key={message.id}
    messageM={message}
    userM={isMyMessage ? undefined : userM}
    overrides={{
      Input: { width: "fit-content" },
      Content: { maxWidth: MAXWIDTH },
      content: {
        shrink: "initial",
        fontSize: CONTENT_FONTSIZE,
        lineHeight: LINEHEIGHT,
      },
      sentAt: { children: sentAt, fontSize: SENTAT_FONTSIZE },
    }}
  />
);

// 自分か他人のメッセージかによって使用するコンポーネントを制御する
const createMessageComponent = (message, userM, isMyMessage) => {
  const Component = isMyMessage ? MyMessage : OtherMessage;
  const sentAt = yyyyMMddHHmmFormatter(new Date(message.createdAt));
  return isMyMessage ? (
    <MyMessageContainer>
    <MessageComponent
      key={message.createdAt}
      Component={Component}
      message={message}
      sentAt={sentAt}
      isMyMessage={isMyMessage}
    />
    </MyMessageContainer>
  ) : (
    <OtherMessageContainer
      key={message.createdAt + userM.id}
    >
      <UserIcon userM={userM} />
      <MessageComponent
        Component={Component}
        message={message}
        sentAt={sentAt}
        isMyMessage={isMyMessage}
        userM={userM}
      />
    </OtherMessageContainer>
  );
};

// チャットルームのメッセージ一覧を表示する
const MessageList = ({ talkRoomID, userMs, height }) => {
  const [messageContent, setMessageContent] = useState([]);
  const { user, setUser } = useContext(UserContext);
  const scrollref = useRef(null);
  const [prevScrollHeight, setPrevScrollHeight] = useState(0);
  const [dispTalkData, setDispTalkData] = useState([]);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);
  const [userTalkRoomM, setUserTalkRoomM] = useState(null);
  const [leftUserMs,setleftUserMs]=useState([]);

  // スクロール関連の処理ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  // ビューを最下部にスクロールする
  const scrollToBottom = () => {
  
    if (scrollref.current) {
      scrollref.current.scrollTop = scrollref.current.scrollHeight;
    }
  };

  // スクロールが一番上に来たら、その時のウィンドウの高さ（prevScrollHeight）をセットし、古いメッセージの取得を実行する
  const handleScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    if (scrollTop === 0) {
      const prevScrollHeight = scrollref.current.scrollHeight;
      setPrevScrollHeight(prevScrollHeight);
      getOldTalkData(talkRoomID, dispTalkData, MESSAGE_FETCH_LIMIT,).then((talkData) => {
        setDispTalkData(talkData);
      });
    }
  };

  // ユーザーがビューの最上部にスクロールして新たにメッセージを取得したとき、スクロール位置が最上部に固定されてしまわないように、スクロール位置を再設定
  // スクロール位置は、全体の高さから前回の全体の高さを引いた値に設定
  useLayoutEffect(() => {
    const setNewScrollTop = () => {
      if (scrollref.current) {
        const newScrollTop = scrollref.current.scrollHeight - prevScrollHeight;
        scrollref.current.scrollTop = newScrollTop;
      }
    };
    if (shouldScrollToBottom) {
      scrollToBottom();
      setShouldScrollToBottom(false);
    } else {
      setNewScrollTop();
    }
  }, [messageContent, prevScrollHeight]);

  // ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

  // ページ読み込み時にUserTalkRoomMの情報を取得し、値をセットする。
  // 初回読み込み時に表示するためのメッセージを取得し、dispTalkDataを更新する
  useEffect(() => {
    
    const fetchData = async () => {
      const userTalkRoomMResult = await getUserTalkRoomMS(user.id, talkRoomID);
      setUserTalkRoomM(userTalkRoomMResult);
  
      getOldTalkData(talkRoomID, [], MESSAGE_FETCH_LIMIT).then((result)=>{
        getNewTalkData(talkRoomID, result, MESSAGE_FETCH_LIMIT).then((initialTalkData)=>{
          setShouldScrollToBottom(true);
          setDispTalkData(initialTalkData);
        });
      });
    };
  
    fetchData();
  }, []);

  // 新規メッセージ有無の監視　新規メッセージが送信されるとデータ取得が実行される
  // 表示用データが更新される度に監視（subscribe）を再起動する
  useEffect(() => {
    const subscription = DataStore.observe(TalkRoomM, talkRoomID).subscribe(
      (msg) => {
        getNewTalkData(talkRoomID, dispTalkData, MESSAGE_FETCH_LIMIT).then(
          (talkData) => {
            setShouldScrollToBottom(true);
            setDispTalkData(talkData);
          }
        );
      }
    );
    return () => subscription.unsubscribe();
  }, [dispTalkData]);

  // 表示するメッセージのコンポーネントを作成する
  // Prepare components for each message to be displayed
  useEffect(() => {
    const prepareMessageComponents = async () => {
      const data = await Promise.all(dispTalkData.map(getMessageComponent));
      setMessageContent(data);
    };

    const getMessageComponent = async (message) => {
        let sendUser = findUserFromList(userMs, message.usermID) || 
                       findUserFromList(leftUserMs, message.usermID) || 
                       await fetchUserFromAPIAndAddToLeftList(message.usermID);

        const isMyMessage = sendUser && message.usermID === user.id;

        return createMessageComponent(message, sendUser, isMyMessage);
    };

    const findUserFromList = (userList, userId) => {
        return userList.find(user => user.id === userId);
    };

    const fetchUserFromAPIAndAddToLeftList = async (userId) => {
        const result = await API.graphql({query: getUserM, variables: {id: userId}});
        const fetchedUser = result.data.getUserM;
        setleftUserMs(prevLeftUserMs => [...prevLeftUserMs, fetchedUser]);
        return fetchedUser;
    };

    prepareMessageComponents();
    
}, [dispTalkData]);



  // 表示メッセージが更新されたらuserTalkRoomMのlastViewTimeを更新する
  useEffect(() => {

    if (userTalkRoomM == null || dispTalkData.length == 0) {
      return;
    }
    const data = {
      input: {
        id: userTalkRoomM.id,
        lastViewTime: dispTalkData[dispTalkData.length - 1].createdAt,
        _version: userTalkRoomM._version,
      },
    };
    API.graphql({
      query: updateUserTalkRoomM,
      variables: data,
    }).then((value) => {
      setUserTalkRoomM(value.data.updateUserTalkRoomM);
    });
  }, [messageContent]);

  return (
    <MessageContainer  ref={scrollref} onScroll={handleScroll}>
      {messageContent}
    </MessageContainer>
  );
};

export default MessageList;
