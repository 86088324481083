
import '@aws-amplify/ui-react/styles.css';
import '../../../App.css';
import React from 'react';
import { Grid, Flex, View } from '@aws-amplify/ui-react';
import { styled } from 'styled-components';
import { TopLogoForm } from './';
import { useBreakpointValue } from '@aws-amplify/ui-react';
import PcTopImg from '../../../common-my-ui-components/img/Top/PC_TopImg.png';
import SpTopImg from '../../../common-my-ui-components/img/Top/SP_TopImg.png';
import { API, graphqlOperation } from 'aws-amplify';
import { yyyyMMddHHmmFormatter } from '../../../my-func/dateTimeFormatter'
import { getMatchingReserveMByRegisterDateandUsrMId, getMatching } from '../../../my-func/MatchingManager'
import {
  recruitmentListMByIsEnd, recruitmentHistoryMSByUsermIDAndStartDateTime,
  getRecruitmentListM
} from '../../../graphql/queries'
import { Link } from "react-router-dom";
import { RecruitmentHeadLine } from '../../RecruitmentList/my-ui-components';
import { MatchingInfo } from "../../Matching/my-ui-components";
import { Pagenation } from '../../../common-my-ui-components'
import { useState, useEffect, useContext } from 'react';
import UserContext from "../../../UserContext";
import MessageContext from "../../../MessageContext";


const TopImage = styled.div`
text-align: center;
position: relative;
${(props) => (props.variation === 'base' ? '' : 'max-width: 1920px;')}
margin: auto;
& toplogoform{
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  margin:0;
  padding:0;
  max-width: ${(props) => (props.variation === 'base' ? '300px;' : '33%;')}
  ${(props) => (props.variation === 'base' ? 'width: 65%;' : '')}
}
`;

const Contents = styled.div`
padding:1rem;
`;

const SubTitle = styled.div`
width:100%;
line-height: 160%;
font-size: 1.6rem;
font-weight: 900;
text-align: center;
margin:auto;
position: relative;
padding: 0.25em 0;

&::after {
  content: "";
  display: block;
  height: 4px;
  background: linear-gradient(90deg, ${(props) => (props.theme.palette.text.green)},
  ${(props) => (props.theme.palette.quinary.main)});
}
`;

const LinkHover = styled.div`
width:100%;
a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
  color: inherit;
}
text-decoration: none;
`;

const MemberTop = () => {

  // コンテキストに保存したユーザー情報を読み込み
  const { user } = useContext(UserContext);
  const { setErrorMessage } = useContext(MessageContext);

  // 現在の募集情報を表示
  const [openRecruitmentList, setOpenRecruitmentList] = useState();

  // 参加中の募集情報を表示
  const [entryRecruitmentList, setEntryRecruitmentList] = useState();

  // マッチング履歴を表示
  const [matchingHistoryList, setMatchingHistoryList] = useState();

  // 募集中の募集を取得する
  const getOpenRecruitment = async () => {
    var nextToken = null
    var recruitmentList = [];
    const currentDate = new Date().toISOString();
    do {
      const opt = {
        isEnd: "0",
        sortDirection: "DESC",
        filter: {
          and: [
            { endDateTime: { gt: currentDate } }
          ]
        },
        nextToken: nextToken
      };
      var recList = (await API.graphql(graphqlOperation(recruitmentListMByIsEnd, opt)
      )).data.recruitmentListMByIsEnd;
      recruitmentList = recruitmentList.concat(recList.items.filter((item) => item._deleted != true));
      nextToken = recList.nextToken;
    } while (nextToken)
    const dispData = []
    for (let item of recruitmentList) {
      item.startDateTime = yyyyMMddHHmmFormatter(new Date(item.startDateTime));
      dispData.push(
        <LinkHover>
          <Link to={`/Recruitment/${item.id}`}>
            <RecruitmentHeadLine
              recruitment={item}
              keyTime={Date.now()}
            />
          </Link>
        </LinkHover>
      )
    }
    setOpenRecruitmentList(
      <Pagenation dispComponentList={dispData} dispComponentCount={2} siblingCount={1} keyTime={Date.now()} />
    )
  }

  // 参加中の募集を取得する
  const getMyEntryRecruitment = async () => {
    var nextToken = null
    // 退出時間が入っていない参加履歴を取得する
    var recruitmentHistoryList = []
    do {
      const opt = {
        usermID: user.id,
        nextToken: nextToken,
        sortDirection: "DESC",
        filter: { exitDateTime: { attributeExists: false } }
      };
      const recHisList = (await API.graphql(graphqlOperation(recruitmentHistoryMSByUsermIDAndStartDateTime, opt)))
        .data.recruitmentHistoryMSByUsermIDAndStartDateTime;
      recruitmentHistoryList = recruitmentHistoryList.concat(recHisList.items);
      nextToken = recHisList.nextToken;
    } while (nextToken)
    const dispData = []
    const recruitmentList = Array(recruitmentHistoryList.length);
    await Promise.all(recruitmentHistoryList.map(async (recruitmentHistory, i) => {
      const opt = {
        id: recruitmentHistory.recruitmentlistmID
      };
      const recruitment = await API.graphql(graphqlOperation(getRecruitmentListM, opt));
      if (recruitment.data.getRecruitmentListM) recruitmentList[i] = recruitment;
    }))
    for (let item of recruitmentList) {
      if (!item) continue;
      const recruitment = item.data.getRecruitmentListM;
      // 削除済みの募集は除外する
      if (!recruitment._deleted) {
        recruitment.startDateTime = yyyyMMddHHmmFormatter(new Date(recruitment.startDateTime));
        dispData.push(
          <LinkHover>
            <Link to={`/Recruitment/${recruitment.id}`} >
              <RecruitmentHeadLine
                recruitment={recruitment}
                key={recruitment.id}
              />
            </Link>
          </LinkHover>
        )
      }
    }
    setEntryRecruitmentList(
      <Pagenation dispComponentList={dispData} dispComponentCount={5} siblingCount={1} keyTime={Date.now()} />)
  }

  // 参加中の募集を取得する
  const getMatchingHistory = async () => {
    const matchingReserveList = await getMatchingReserveMByRegisterDateandUsrMId(user.id, setErrorMessage);
    const dispData = []
    for (let item of matchingReserveList) {
      // マッチング成立済みの予約だけを表示
      if (!item.matchingMId) continue;
      const matching = await getMatching(item.matchingMId, setErrorMessage);
      if (!matching.talkRoomMId) continue;
      dispData.push(
        <MatchingInfo matchingReserve={item} matching={matching} />
      )
    }
    setMatchingHistoryList(
      <Pagenation dispComponentList={dispData} dispComponentCount={5} siblingCount={1} keyTime={Date.now()} />
    )
  }

  useEffect(() => {
    getOpenRecruitment();
    getMyEntryRecruitment();
    getMatchingHistory();
  }, []);

  // 画面サイズを取得
  const variation = useBreakpointValue({
    base: 'base',
    large: 'large',
  });

  return (
    <>
      <TopImage variation={variation}>
        <img src={variation === 'base' ? SpTopImg : PcTopImg} style={{ width: '100%' }} alt="トップページ" />
        <toplogoform>
          <TopLogoForm />
        </toplogoform>
      </TopImage>
      {/* PC時に中央の66%にコンテンツを配置する */}
      <Grid templateColumns={{ base: '1fr', large: "2fr 8fr 2fr" }}>
        <View rowSpan={1}>{/* PC時の両脇 空白スペース */}</View>
        <View rowSpan={1}>
          <Contents>
            <Flex direction={"column"} alignItems={'center'} >
              <SubTitle>
                マッチング履歴
              </SubTitle>
              {matchingHistoryList}
              <SubTitle>
                最新の募集
              </SubTitle>
              {openRecruitmentList}
              <SubTitle>
                自分が作成・参加した募集
              </SubTitle>
              {entryRecruitmentList}
            </Flex>
          </Contents>
        </View >
        <View rowSpan={1}>{/* PC時の両脇 空白スペース */}</View>
      </Grid >
    </>
  );
};

export default MemberTop;
