import '@aws-amplify/ui-react/styles.css';
import '../../../App.css';
import { styled } from 'styled-components';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import { useState, useEffect, useContext } from 'react';
import UserContext from "../../../UserContext";
import MessageContext from "../../../MessageContext";
import { Grid, Flex } from '@aws-amplify/ui-react';
import { Button, List } from '@mui/material';
import { UserIconListRow } from "../../../common-my-ui-components";
import { API, graphqlOperation } from 'aws-amplify';
import { getUserM } from "../../../graphql/queries";
import { useNavigate } from 'react-router-dom';
import { yyyyMMddHHmmFormatter, yyyyMMddFormatter } from '../../../my-func/dateTimeFormatter'

// 募集カードの設定
const Matching = styled.div`
width: 100%; 
padding: 0.5rem 1rem;
margin: 0.5rem 0;
background: ${(props) => (props.theme.palette.quinary.main)};
border: solid 1px ${(props) => (props.theme.palette.quaternary.main)};
border-radius: 10px;
`;


// 募集名など見出しに適用
const Title = styled.div`
padding: 0em 0.5em;
width: 100%;
line-height: 160%;
font-size: clamp(16px, 3vw, 20px);
font-weight: 700;
text-align: center;
overflow: hidden;
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 2; /* 任意の行数を指定 */
}
`;

const ItemList = styled(Flex)`
margin: 0.5rem 0 0 0;
`;
const ItemContent = styled.div`
text-align: left;
margin: 0 0 0 0.5rem;
`;

const ItemTitle = styled.div`
text-align: right;
margin: 0 0.5rem 0 0;
`;

//マッチング情報の左半分のスタイル
const MatchingLeft = styled.div`
margin : 0.5rem auto 0 auto;
width: 100%; 
`;

//マッチング情報の左半分のスタイル
const MatchingRight = styled.div`
margin : 0.5rem auto 0 auto;
width: 100%; 
`;

const MatchingInfo = (props) => {
  // 引数で募集情報を受け取る
  const { matchingReserve, matching } = props;
  const { user } = useContext(UserContext);
  const { setErrorMessage } = useContext(MessageContext);
  const [formattedMatching, setFormattedMatching] = useState(null);
  const [dispUserList, setDispUserList] = useState(null);
  const [formattedMatchingReserve, setFormattedMatchingReserve] = useState(null);

  // 各画面に遷移させる
  const navigate = useNavigate();

  // 日付を年月日に変換する関数
  const formatDate = (dateString) => {
    const dateParts = dateString.split('/');
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
    return `${year}年 ${month}月 ${day}日`;
  };

  // マッチングユーザーリストを取得
  const getMatchingUserList = async () => {
    try {
      // マッチングユーザーの情報を表示する
      const entryUserList = [matching.userm1ID, matching.userm2ID, matching.userm3ID, matching.userm4ID];
      const dispUserListData = Array(entryUserList.length);
      await Promise.all(entryUserList.map(async (entryUser, i) => {
        // ユーザーIDが格納されていない場合や3人麻雀のようにダミーの場合は処理を終える
        if (!entryUser || entryUser === "dummyId" || user.id === entryUser) return;
          const opt = {
            id: entryUser,
          };
        const userInfo = (await API.graphql(graphqlOperation(getUserM, opt))).data.getUserM;
        dispUserListData[i] = userInfo;
      }));
      setDispUserList(
        <List>
          <UserIconListRow userMs={dispUserListData} />
        </List>
      );
    } catch (e) {
      setErrorMessage(<div>
        マッチング情報取得時にエラーが発生しました。<br />
        しばらくしてからもう一度お試しください。
      </div>);
    }
  }

  useEffect(() => {
    // マッチング情報の整形
    var formattedMatching = {
      ...matching,
      dueDate: formatDate(yyyyMMddFormatter(new Date(matching.dueDate))),
    };
    formattedMatching.matchingDate = formatDate(formattedMatching.matchingDate);
    setFormattedMatching(formattedMatching);
    getMatchingUserList();

    const formattedMatchingReserve = {
      ...matchingReserve,
      registerDate: yyyyMMddHHmmFormatter(new Date(matchingReserve.registerDate)),

    };

    // マッチング予約情報の整形
    if (formattedMatchingReserve.skill === "1") {
      formattedMatchingReserve.skill = "初心者"
    } else if (formattedMatchingReserve.skill === "2") {
      formattedMatchingReserve.skill = "中級者"
    } else if (formattedMatchingReserve.skill === "3") {
      formattedMatchingReserve.skill = "上級者"
    }

    if (formattedMatchingReserve.requiredSkill === "1") {
      formattedMatchingReserve.requiredSkill = "初心者以上"
    } else if (formattedMatchingReserve.requiredSkill === "2") {
      formattedMatchingReserve.requiredSkill = "中級者以上"
    } else if (formattedMatchingReserve.requiredSkill === "3") {
      formattedMatchingReserve.requiredSkill = "上級者のみ"
    }

    if (formattedMatchingReserve.maxAge === 0 && formattedMatchingReserve.minAge === 0) {
      formattedMatchingReserve.limitAge = "指定しない";
    } else if (formattedMatchingReserve.maxAge === 0 && formattedMatchingReserve.minAge !== 0) {
      formattedMatchingReserve.limitAge = formattedMatchingReserve.minAge + "歳以上";
    } else if (formattedMatchingReserve.maxAge !== 0 && formattedMatchingReserve.minAge === 0) {
      formattedMatchingReserve.limitAge = formattedMatchingReserve.maxAge + "歳以下";
    } else if (formattedMatchingReserve.maxAge !== 0 && formattedMatchingReserve.minAge !== 0) {
      formattedMatchingReserve.limitAge = formattedMatchingReserve.minAge + "-" + formattedMatchingReserve.maxAge + "歳";
    }

    // カンマ区切りの文字列
    const dateString = formattedMatchingReserve.desiredDate;
    var dateMap = [];
    // カンマで分割して日付の配列を作成
    const dates = dateString.split(',');

    // 年と月を保持するための変数
    let currentYear = '';
    let currentMonth = '';
    let stringDate = null;

    // 日付をフォーマットして出力
    dates.forEach(date => {
      const formattedDate = formatDate(date);
      const [year, month, day] = date.split('/');

      // 年または月が変わった場合は新しく行を開始
      if (year !== currentYear || month !== currentMonth) {
        if (stringDate) dateMap.push(stringDate)
        stringDate = formattedDate;
        currentYear = year;
        currentMonth = month;
      } else {
        // 同じ年月の場合はスペースを入れて連結
        stringDate = stringDate + formattedDate.replace(`${year}年`, '').replace(`${month}月`, '')
      }
    });
    dateMap.push(stringDate)
    formattedMatchingReserve.displayDesiredDates = <div>
      {dateMap[0] ? (<>{dateMap[0]}<br /></>) : (<></>)}
      {dateMap[1] ? (<>{dateMap[1]}<br /></>) : (<></>)}
      {dateMap[2] ? (<>{dateMap[2]}<br /></>) : (<></>)}
    </div>

    // 上書きした値をコンポーネントのローカルステートにセット
    setFormattedMatchingReserve(formattedMatchingReserve);

  }, []);

  // formattedMatchingがnullの場合はロード中の表示
  if (!formattedMatching) {
    return <Skeleton variant="rounded" width={'100%'} height={'10em'} />;
  }

  return (
    <>
      <Matching>
        <Grid
          templateColumns={{ base: '1fr', large: "1fr 1fr" }}
        >
          <MatchingLeft>
            <Title>マッチング内容</Title>
            <Divider />
            <ItemList justifyContent="center">
              <Grid alignItems="center" templateColumns={{ base: '3fr 7fr' }} >
                <ItemTitle>日時</ItemTitle><ItemContent>{formattedMatching.matchingDate}</ItemContent>
                <ItemTitle>場所</ItemTitle><ItemContent>{matching.matchingPlace}</ItemContent>
                <ItemTitle>人数</ItemTitle><ItemContent>{matchingReserve.playNum}人</ItemContent>
                {matching.talkRoomMId === 'dummyId' ? (<>
                  <ItemTitle>確認期日</ItemTitle><ItemContent>{formattedMatching.dueDate}</ItemContent>
                </>) :
                  (<></>)}
              </Grid>
            </ItemList>
            {matching.talkRoomMId === 'dummyId' ? (<>
            </>) : (<>
              <ItemList justifyContent="center">
                <Button onClick={() => { navigate(`/TalkRoom/${matching.talkRoomMId}`) }}>
                  <span className="material-symbols-rounded">
                    sms
                  </span>
                  トークルーム
                </Button>
              </ItemList>
            </>)}
          </MatchingLeft>
          <MatchingRight>
            {matching.talkRoomMId === 'dummyId' ? (
              <>
                <Title>マッチング条件</Title>
                <Divider />
                <ItemList justifyContent="center">
                  <Grid alignItems="center" templateColumns={{ base: '3fr 7fr' }} >
                    <ItemTitle>場所</ItemTitle><ItemContent>{formattedMatchingReserve.place}</ItemContent>
                    <ItemTitle>年齢</ItemTitle><ItemContent>{formattedMatchingReserve.age}歳</ItemContent>
                    <ItemTitle>人数</ItemTitle><ItemContent>{formattedMatchingReserve.playNum}人</ItemContent>
                    <ItemTitle>腕前</ItemTitle><ItemContent>{formattedMatchingReserve.skill}</ItemContent>
                    <ItemTitle>希望日</ItemTitle><ItemContent>{formattedMatchingReserve.displayDesiredDates}</ItemContent>
                    <ItemTitle>腕前制限</ItemTitle><ItemContent>{formattedMatchingReserve.requiredSkill}</ItemContent>
                    <ItemTitle>年齢制限</ItemTitle><ItemContent>{formattedMatchingReserve.limitAge}</ItemContent>
                  </Grid>
                </ItemList>
              </>) : (
              <>
                <Title>マッチングユーザー</Title>
                <Divider />
                {dispUserList}
              </>)}
          </MatchingRight>
        </Grid >

      </Matching>
    </>
  );
};

export default MatchingInfo;
