// データベースを開く関数
const openIndexedDB = (dbName, storeName, keyPath) => {
  return new Promise((resolve, reject) => {
    // データベースを開くリクエストを作成
    let openRequest = indexedDB.open(dbName);

    openRequest.onsuccess = function() {
      const db = openRequest.result;
      // 指定されたストアが存在しない場合
      if (!db.objectStoreNames.contains(storeName)) {
        // バージョンを1つ増加させて再度データベースを開く
        const newVersion = db.version + 1;
        db.close();
        openIndexedDBWithNewVersion(dbName, storeName, keyPath, newVersion).then(resolve).catch(reject);
      } else {
        resolve(db);
      }
    };

    openRequest.onerror = function() {
      reject(openRequest.error);
    };
  });
};

const openIndexedDBWithNewVersion = (dbName, storeName, keyPath, version) => {
  return new Promise((resolve, reject) => {
    const openRequest = indexedDB.open(dbName, version);

    openRequest.onupgradeneeded = function() {
      const db = openRequest.result;
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, {keyPath: keyPath});
      }
    };

    openRequest.onsuccess = function() {
      resolve(openRequest.result);
    };

    openRequest.onerror = function() {
      reject(openRequest.error);
    };
  });
};




// IndexedDBからデータを取得する関数
const fetchFromIndexedDB = (db, storeName) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, "readonly");
    const store = transaction.objectStore(storeName);
    const getAllRequest = store.getAll();

    getAllRequest.onsuccess = function() {
      resolve(getAllRequest.result);
    };

    getAllRequest.onerror = function() {
      reject(getAllRequest.error);
    };
  });
};

// IndexedDBにデータを保存する関数
const saveToIndexedDB = (db, storeName, data) => {
  const transaction = db.transaction(storeName, "readwrite");
  const store = transaction.objectStore(storeName);

  // データにタイムスタンプを追加するヘルパー関数
  const saveDataWithTimestamp = (item) => {
    const dataWithTimestamp = { ...item, timestamp: Date.now() };
    store.put(dataWithTimestamp);
  };

  // dataが配列の場合
  if (Array.isArray(data)) {
    data.forEach(item => saveDataWithTimestamp(item));
  } 
  // dataがオブジェクトの場合
  else {
    saveDataWithTimestamp(data);
  }
};


// 一定期間経過したデータを削除する関数
const deleteOldDataFromIndexedDB = async (db, storeName, age) => {
  const transaction = db.transaction(storeName, 'readwrite');
  const store = transaction.objectStore(storeName);

  // 削除の基準となるタイムスタンプを計算
  const cutoff = Date.now() - age;

  // データを順番に取得するカーソルを開く
  const cursor = await store.openCursor();

  while (cursor) {
    // タイムスタンプが基準より古いデータを削除
    if (cursor.value.timestamp < cutoff) {
      await cursor.delete();
    }
    cursor = await cursor.continue();
  }
};

// IndexedDBから指定されたキーのデータを削除する関数
const deleteDataFromIndexedDB = (db, storeName, key) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, "readwrite");
    const store = transaction.objectStore(storeName);
    const deleteRequest = store.delete(key);

    deleteRequest.onsuccess = function() {
      resolve();
    };

    deleteRequest.onerror = function() {
      reject(deleteRequest.error);
    };
  });
};

export { openIndexedDB, fetchFromIndexedDB, saveToIndexedDB, deleteOldDataFromIndexedDB, deleteDataFromIndexedDB };