import { useState, useContext, useRef, useEffect } from "react";
import { Auth, API, Storage } from "aws-amplify";
import { ProfileIcon } from "../my-ui-components";
import { CommonFormItems } from '../../../common-my-ui-components'
import styled from "styled-components";
import {
  Box, TextField, Button, Alert as MUIAlert, Typography,
  Dialog, DialogContent, DialogTitle, CircularProgress
} from "@mui/material";
import UserContext from "../../../UserContext";
import MessageContext from "../../../MessageContext";
import { useNavigate } from 'react-router-dom';
import { updateUserM, createUserM } from "../../../graphql/mutations";
import { openIndexedDB, deleteDataFromIndexedDB } from "../../../my-func/indexedDBUtils";
import Compressor from 'compressorjs';
import { getUserByCognitoUser } from "../../../my-func/userManager";
import { checkValidWhitespaceOnly } from "../../../my-func/InputValidator";
import { PREFECTURES, YES_OR_NO, AGE, MAIN_MAHJONG } from "../../../constants.js";

const FixedButton = styled(Button)`
  position: sticky;
  bottom: 10px;
`;

const StyledForm = styled(Box)`
  width: 100%;
  padding: 2rem;
`;

const FlexColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const Alert = styled(MUIAlert)`
  position: fixed;
  z-index: 9999;
`;

const SubTitle = styled(Typography)`
  display: Flex;
  justify-content: start;
  padding-top: 1.5rem;
  width: 100%;
`;

const Center = styled.table`
margin: auto; /* サークルを中央に配置 */
`;

// GraphQLのミューテーション用の入力データを準備
const prepareInputData = (userData, iconSrc, identityId) => ({
  input: {
    ...(userData && {
      introduction: userData.introduction,
      name: userData.name,
      prefecture: userData.prefecture,
      age: userData.age,
      hobby: userData.hobby,
      skill: userData.skill,
      canCalculateScore: userData.canCalculateScore,
      mainMahjongType: userData.mainMahjongType,
      canOperateTable: userData.canOperateTable,
      favoriteRole: userData.favoriteRole,
      mahjongStartReason: userData.mahjongStartReason,
      mahjongStudyMethod: userData.mahjongStudyMethod,
      id: userData.id,
      _version: userData._version,
      cognitoUsername: userData.cognitoUsername,
      isOver18: userData.isOver18,
      isAgreeTerms: userData.isAgreeTerms
    }),
    iconSrc,
    identityId,
  },
});

const ProfileForm = ({ onFormSubmit, isAgreeTerms, isOver18 }) => {

  const [iconDelete, setIconDelete] = useState(false);
  const fileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const { setErrorMessage, setSuccessMessage } = useContext(MessageContext);
  // ロード中
  const [isLoading, setIsLoading] = useState(false);
  // ログインユーザーを取得
  const { user, setUser, cognitoUser } = useContext(UserContext);

  // 各項目のエラーメッセージを定義
  // 自己紹介のエラーメッセージ
  const [introductionMessage, setIntroductionMessage] = useState(null);
  // 名前のエラーメッセージ
  const [nameMessage, setNameMessage] = useState(null);
  // 居住地のエラーメッセージ
  const [prefectureMessage, setPrefectureMessage] = useState(null);
  // 年齢のエラーメッセージ
  const [ageMessage, setAgeMessage] = useState(null);
  // 趣味のエラーメッセージ
  const [hobbyMessage, setHobbyMessage] = useState(null);
  //腕前のエラーメッセージ
  const [skillMessage, setSkillMessage] = useState(null);
  // 点数計算ができるか？のエラーメッセージ
  const [canCalculateScoreMessage, setCanCalculateScoreMessage] = useState(null);
  // 3人麻雀と4人麻雀、どっちがメインか?のエラーメッセージ
  const [mainMahjongTypeMessage, setMainMahjongType] = useState(null);
  // 麻雀卓操作ができるか？のエラーメッセージ
  const [canOperateTableMessage, setCanOperateTableMessage] = useState(null);
  //好きな役のエラーメッセージ
  const [favoriteRoleMessage, setFavoriteRoleMessage] = useState(null);
  //麻雀を始めたきっかけのエラーメッセージ
  const [mahjongStartReasonMessage, setMahjongStartReasonMessage] = useState(null);
  //麻雀をどうやって勉強したかのエラーメッセージ
  const [mahjongStudyMethodMessage, setMahjongStudyMethodMessage] = useState(null);

  // フォームの初期値を保存
  const defaultFormData = {
    cognitoUsername: cognitoUser.username,
    introduction: "",
    name: "",
    prefecture: "非公開",
    age: "非公開",
    hobby: "",
    skill: "",
    canCalculateScore: "未回答",
    mainMahjongType: "未回答",
    canOperateTable: "未回答",
    favoriteRole: "",
    mahjongStartReason: "",
    mahjongStudyMethod: ""
  };

  // 現在のフォームの選択値を保存
  const [formData, setFormData] = useState(defaultFormData);

  // フォーム項目の設定
  const formItems = [
    { name: 'introduction', label: '自己紹介', type: "textarea", message: introductionMessage },
    { name: 'name', label: '名前', type: "text", message: nameMessage },
    { name: 'prefecture', label: '居住地', type: "select", options: PREFECTURES, message: prefectureMessage },
    { name: 'age', label: '年齢', type: "select", options: AGE, message: ageMessage },
    { name: 'hobby', label: '趣味', type: "text", message: hobbyMessage },
    { name: 'skill', label: '腕前', type: "text", message: skillMessage },
    { name: 'canCalculateScore', label: '点数計算ができるか？', type: "select", options: YES_OR_NO, message: canCalculateScoreMessage },
    { name: 'mainMahjongType', label: '3人麻雀と4人麻雀、どっちがメインか?', type: "select", options: MAIN_MAHJONG, message: mainMahjongTypeMessage },
    { name: 'canOperateTable', label: '麻雀卓操作ができるか？', type: "select", options: YES_OR_NO, message: canOperateTableMessage },
    { name: 'favoriteRole', label: '好きな役', type: "text", message: favoriteRoleMessage },
    { name: 'mahjongStartReason', label: '麻雀を始めたきっかけ', type: "text", message: mahjongStartReasonMessage },
    { name: 'mahjongStudyMethod', label: '麻雀をどうやって勉強したか', type: "text", message: mahjongStudyMethodMessage },
  ];

  // 処理後に各画面に遷移させる
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    // 入力チェック
    if (!checkInputOK()) {
      setIsLoading(false);
      return;
    }

    if (isAgreeTerms !== undefined) {
      formData.isAgreeTerms = isAgreeTerms;
    }
    if (isOver18 !== undefined) {
      formData.isOver18 = isOver18;
    }

    try {
      const credentials = await Auth.currentCredentials();
      const iconSrc = (iconDelete) ? null : await uploadFile(credentials.identityId);
      const data = prepareInputData(formData, iconSrc, credentials.identityId);
      await API.graphql({
        query: user ? updateUserM : createUserM,
        variables: data,
      });

      // ユーザー情報を更新する
      const updateUser = await getUserByCognitoUser(cognitoUser, setErrorMessage);
      setUser(updateUser);

      if (onFormSubmit) {
        onFormSubmit();
      }

      // プロフィール更新画面からアクセスしたときの挙動
      if (isAgreeTerms === undefined || isOver18 === undefined) {
        setSuccessMessage(<div>登録が完了しました</div>);
        navigate(`/Profile/${user.id}`);
      }

    } catch (error) {
      setErrorMessage(<div>登録に失敗しました</div>);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedExtensions = ["jpg", "jpeg", "png", "gif"];

    if (file && validateFileExtension(file, allowedExtensions)) {
      setSelectedFile(file);
      setIconDelete(false);
      generateImagePreview(file);
    }
  };

  const validateFileExtension = (file, allowedExtensions) => {
    const extension = file.name.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes(extension)) {
      setErrorMessage(
        `無効なファイル形式です。${allowedExtensions.join(
          " "
        )}のみ許可されています。`
      );
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      return false;
    }
    return true;
  };

  const generateImagePreview = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const cancelIconChange = () => {
    setImagePreview(null);
    setSelectedFile("");
    setIconDelete(false);
  };

  const deleteIcon = () => {
    setSelectedFile("");
    setIconDelete(true);
  };

  const checkInputOK = () => {
    // 入力チェック
    var checkOK = true;
    // 自己紹介
    if (checkValidWhitespaceOnly(formData.introduction)) {
      setIntroductionMessage("自己紹介は空白のみで入力できません。")
      checkOK = false;
    } else {
      setIntroductionMessage(null);
    }
    // 名前
    if (!formData.name) {
      setNameMessage("名前を入力してください。")
      checkOK = false;
    } else if (checkValidWhitespaceOnly(formData.name)) {
      setNameMessage("名前は空白のみで入力できません。")
      checkOK = false;
    } else {
      setNameMessage(null);
    }
    // 趣味
    if (checkValidWhitespaceOnly(formData.hobby)) {
      setHobbyMessage("趣味は空白のみで入力できません。")
      checkOK = false;
    } else {
      setHobbyMessage(null);
    }
    // 腕前
    if (checkValidWhitespaceOnly(formData.skill)) {
      setSkillMessage("腕前は空白のみで入力できません。")
      checkOK = false;
    } else {
      setSkillMessage(null);
    }
    // 好きな役
    if (checkValidWhitespaceOnly(formData.favoriteRole)) {
      setFavoriteRoleMessage("好きな役は空白のみで入力できません。")
      checkOK = false;
    } else {
      setFavoriteRoleMessage(null);
    }
    // 麻雀を始めたきっかけ
    if (checkValidWhitespaceOnly(formData.mahjongStartReason)) {
      setMahjongStartReasonMessage("麻雀を始めたきっかけは空白のみで入力できません。")
      checkOK = false;
    } else {
      setMahjongStartReasonMessage(null);
    }
    // 麻雀をどうやって勉強したか
    if (checkValidWhitespaceOnly(formData.mahjongStudyMethod)) {
      setMahjongStudyMethodMessage("麻雀をどうやって勉強したかは空白のみで入力できません。")
      checkOK = false;
    } else {
      setMahjongStudyMethodMessage(null);
    }
    if (!checkOK) setErrorMessage(<div>入力内容に誤りがあります。</div>);
    return checkOK;
  }

  // ストレージにファイルをアップロード
  const uploadFile = async (identityId) => {
    if (!selectedFile) return user ? user.iconSrc : null;
    const compressedFile = await compressFile(selectedFile);
    const filename = await saveFileToStorage(compressedFile, formData.cognitoUsername);
    setImagePreview(null);
    await clearIndexedDB(identityId);
    return filename;
  };

  // ファイルを圧縮
  const compressFile = async (file) => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 800,
        maxHeight: 800,
        success: resolve,
        error: reject,
      });
    }).catch((err) => {
      setErrorMessage(<div>画像の圧縮に失敗しました</div>)
      return file;
    });
  };

  // AWS S3ストレージにファイルを保存
  const saveFileToStorage = async (file, username) => {
    const opt = { level: "protected" };
    const extension = file.name.split(".").pop();
    const filename = `user/${username}.${extension}`;
    await Storage.put(filename, file, opt);
    return filename;
  };

  // IndexedDBからユーザーアイコンを削除
  const clearIndexedDB = async (identityId) => {
    const db = await openIndexedDB("userIconsDB", "icons", "identityId");
    await deleteDataFromIndexedDB(db, "icons", identityId);
  };

  // Cognitoからログイン中のユーザ情報の取得
  useEffect(() => {
    if (user) setFormData({ ...formData, ...user })
  }, []);

  return (
    <FlexColumn>
      <StyledForm component="form" onSubmit={handleSubmit}>
        <ProfileIcon
          loginUser={user}
          imagePreview={imagePreview}
          onIconClick={() => fileInputRef.current.click()}
          cancelIconChange={() => cancelIconChange()}
          deleteIcon={() => deleteIcon()}
          iconDelete={iconDelete}
        />
        <TextField
          type="file"
          id="file"
          variant="outlined"
          onChange={handleFileChange}
          InputProps={{ accept: ".jpg, .jpeg, .png, .gif" }}
          inputRef={fileInputRef}
          style={{ display: "none" }}
        />
        <CommonFormItems inputformItems={formItems} formData={formData} setFormData={setFormData} />

        <FixedButton type="submit" variant="contained" color="primary">
          プロフィール保存
        </FixedButton>
      </StyledForm>
      {/* プロフィール処理中ダイアログ */}
      <Dialog open={isLoading}>
        <DialogTitle>処理中</DialogTitle>
        <DialogContent>
          <Center>
            <CircularProgress />
          </Center>
        </DialogContent>
      </Dialog>
    </FlexColumn>
  );
};

export default ProfileForm;
