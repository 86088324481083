import '@aws-amplify/ui-react/styles.css';
import '../App.css';
import { styled } from 'styled-components';
import { useState, useEffect, useContext } from 'react';
import { checkInputOK, registerMatchingReserve, editMatchingReserve, reEntryMatchingReserve } from "../my-func/MatchingManager"
import UserContext from "../UserContext";
import MessageContext from "../MessageContext";
import { Grid, Flex } from '@aws-amplify/ui-react';
import {
  MATCHING_SKILL, MATCHING_REQUIRED_SKILL, MATCHING_AGE, MATCHING_MAHJONG_PLAYNUM,
  MATCHING_PLACE, TEXTFIELDMAXLENGTH, MATCHING_LIMIT_AGE, MATCHING_PREFECTURES
} from "../constants";
import {
  FormControl, InputLabel, Select, MenuItem, TextField, Box, Checkbox, FormHelperText,
  FormControlLabel, Button, Dialog, DialogContent, DialogTitle, CircularProgress, Card,
  Accordion, AccordionSummary, AccordionDetails
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { jaJP } from '@mui/x-date-pickers/locales';

// ページ全体の設定
const Page = styled.div`
width: 100%;  

`;

// ページ全体の設定
const ErrorText = styled.div`
color: #FF0000;
font-size: 13px;
`;

const Center = styled.table`
margin: auto; /* サークルを中央に配置 */
`;

const Left = styled.table`
text-align: left;
`;

const Annotation = styled.div`
text-align: left;
font-size: 0.8rem;
`;

const FlexColumn = styled(Box)`
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const FlexRow = styled(Box)`
  width:100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const FlexCheckbox = styled(Box)`
  width:100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const CustomGrid = styled(Grid)`
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const SubTitle = styled.div`
width:100%;
line-height: 160%;
font-size: 1.6rem;
font-weight: 900;
text-align: center;
margin:auto;
position: relative;
padding: 0.25em 0;

&::after {
  content: "";
  display: block;
  height: 4px;
  background: linear-gradient(90deg, ${(props) => (props.theme.palette.text.green)},
  ${(props) => (props.theme.palette.quinary.main)});
}
`;

const MinTitle = styled.div`
width:100%;
line-height: 120%;
font-size: clamp(14px, 1.1vw, 16px);
font-weight: 600;
text-align: left;
margin:auto;
position: relative;
padding: 0.25em 0;
`;

const AccordionSetting = styled.div`
box-shadow: 0 0 2px ${(props) => props.theme.palette.disabled.main};
width: 100%
`

const MatchingForm = (props) => {
  // 引数で募集情報を受け取る
  const { matchingReserve, isDiscardedReEntry, setKeyTime } = props;
  const { setErrorMessage } = useContext(MessageContext);
  const { user, cognitoUser } = useContext(UserContext);

  // 処理中の状態を保存する
  const [isLoading, setIsLoading] = useState(false);

  // 処理後に各画面に遷移させる
  const navigate = useNavigate();

  // エラーメッセージ
  // 年齢のエラーメッセージ
  const [ageMessage, setAgeMessage] = useState(null);
  // 場所のエラーメッセージ
  const [placeMessage, setPlaceMessage] = useState(null);
  // 都道府県のエラーメッセージ
  const [prefectureMessage, setPrefectureMessage] = useState(null);
  // 年齢下限のエラーメッセージ
  const [minAgeMessage, setMinAgeMessage] = useState(null);
  // 年齢上限のエラーメッセージ
  const [maxAgeMessage, setMaxAgeMessage] = useState(null);
  // 腕前のエラーメッセージ
  const [skillMessage, setSkillMessage] = useState(null);
  // 腕前制限のエラーメッセージ
  const [requiredSkillMessage, setRequiredSkillMessage] = useState(null);
  // 通知用メールアドレスのエラーメッセージ
  const [noticeMailMessage, setNoticeMailMessage] = useState(null);
  // プレイ人数のエラーメッセージ
  const [playNumMessage, setPlayNumMessage] = useState(null);
  // 希望日のエラーメッセージ
  const [desiredDateMessage, setDesiredDateMessage] = useState(null);
  const setMessaages = {
    errorMessage: setErrorMessage,
    age: setAgeMessage,
    place: setPlaceMessage,
    prefecture: setPrefectureMessage,
    minAge: setMinAgeMessage,
    maxAge: setMaxAgeMessage,
    skill: setSkillMessage,
    requiredSkill: setRequiredSkillMessage,
    noticeMail: setNoticeMailMessage,
    playNum: setPlayNumMessage,
    desiredDate: setDesiredDateMessage
  }

  // 現在のフォームの選択値を保存
  const [formData, setFormData] = useState(null);
  // 現在の場所の選択値を保存
  const [placeData, setPlaceData] = useState([]);
  // 現在の希望日の選択値を保存
  const [desiredDate, setdesiredDate] = useState([]);
  // 現在の希望日の選択値を表示する
  const [desiredDateDisplay, setDesiredDateDisplay] = useState(<></>);
  // 通知チェックボックスの表示非表示
  const [noticeCheckBox, setNoticeCheckBox] = useState(true);

  useEffect(() => {
    // 初期値投入 編集用の情報がある場合は上書きする
    setFormData((prevFormData) => ({
      ...prevFormData,
      age: user.age !== "非公開" ? user.age : "0",
      noticeMail: cognitoUser.attributes.email,
      skill: "1",
      playNum: "4",
      minAge: "0",
      maxAge: "0",
      requiredSkill: "1",
      place: "",
      prefecture: "東京都",
      ...matchingReserve,
    }));
    // 編集時
    if (matchingReserve) {
      setNoticeCheckBox(matchingReserve.noticeMail.length !== 0);
      setPlaceData(matchingReserve.place.split(","));
      setdesiredDate(matchingReserve.desiredDate.split(","));
      displayDesiredDates(matchingReserve.desiredDate);
    }
  }, []);

  // フォームに変更があった場合選択値を更新する
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // 場所チェックボックスに変更があった場合選択値を更新する
  const handleInputPlaceCheckboxChange = (e) => {
    const { value } = e.target;
    var newPlaceData = [];
    if (placeData.includes(value)) {
      newPlaceData = placeData.filter(place => place !== value);
    } else {
      newPlaceData = placeData.concat(value);
    }
    setPlaceData(newPlaceData);
    setFormData((prevFormData) => ({
      ...prevFormData,
      "place": newPlaceData.join(",")
    }));
  };

  // 通知チェックボックスに変更があった場合
  const handleInputNoticeCheckboxChange = (e) => {
    const { value } = e.target;
    setNoticeCheckBox(!noticeCheckBox);
  };

  // マッチング予約情報を登録
  const handleRegister = async () => {
    setIsLoading(true)
    var registerFormData = formData;
    if (!noticeCheckBox) {
      registerFormData.noticeMail = "";
    }
    if (checkInputOK(registerFormData, setMessaages)) {
      registerMatchingReserve(registerFormData, user, setErrorMessage);
      await new Promise((resolve) => setTimeout(resolve, 1000))
      navigate(`/Matching`);
    };
    setIsLoading(false)
  };

  // マッチング予約情報を編集
  const handleEdit = async () => {
    setIsLoading(true)
    var editFormData = formData;
    if (!noticeCheckBox) {
      editFormData.noticeMail = "";
    }
    if (checkInputOK(editFormData, setMessaages)) {
      if (isDiscardedReEntry) {
        // 破棄したマッチングを再開する際は登録日を現在日に修正
        reEntryMatchingReserve(matchingReserve, editFormData, setErrorMessage);
      } else {
        editMatchingReserve(matchingReserve, editFormData, setErrorMessage);
      }
      await new Promise((resolve) => setTimeout(resolve, 1000))
      navigate(`/Matching`);
      if (setKeyTime) setKeyTime(Date.now());
    };
    setIsLoading(false);
  };

  // 希望日フォームに変更があった場合に更新する
  const handleDateChange = (date) => {
    setDesiredDateMessage("");
    const formattedDate = (new Date(date.toISOString())).toLocaleDateString('ja-JP');
    var newdesiredDate = [];
    if (desiredDate.includes(formattedDate)) {
      newdesiredDate = desiredDate.filter(place => place !== formattedDate);
    } else {
      const today = new Date();
      // 3日後の日付を計算
      const threeDaysLater = new Date(today.getTime() + 3 * 24 * 60 * 60 * 1000);
      // 3ヶ月後の1日の日付を取得
      const threeMonthsLater = new Date(today.getFullYear(), today.getMonth() + 3, 1);
      if (date < threeDaysLater || threeMonthsLater <= date || 10 <= desiredDate.length) {
        setDesiredDateMessage("希望日は3日後以降・2ヶ月後の月末まで、最大10日まで選択できます");
        return;
      }
      newdesiredDate = desiredDate.concat(formattedDate);
    }

    // 日付文字列をDateオブジェクトに変換し、配列に追加
    const dateObjects = newdesiredDate.map(dateString => new Date(dateString));
    // 日付を昇順にソート
    dateObjects.sort((a, b) => a - b);
    // ソートされた日付を文字列に変換して表示
    const sortedDesiredDates = dateObjects.map(date => {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${year}/${month}/${day}`;
    });
    setdesiredDate(sortedDesiredDates);
    displayDesiredDates(sortedDesiredDates.join(","))
    setFormData((prevFormData) => ({
      ...prevFormData,
      "desiredDate": sortedDesiredDates.join(",")
    }));
  };

  // カンマ区切りの文字列を改行して表示する
  const displayDesiredDates = (dateString) => {
    if (!dateString) {
      setDesiredDateDisplay(<><br /><br /></>);
      return;
    }
    var dateMap = [];
    // カンマで分割して日付の配列を作成
    const dates = dateString.split(',');

    // 日付を年月日に変換する関数
    const formatDate = (dateString) => {
      const dateParts = dateString.split('/');
      const year = dateParts[0];
      const month = dateParts[1];
      const day = dateParts[2];
      return `${year}年${month}月 ${day}日`;
    };

    // 年と月を保持するための変数
    let currentYear = '';
    let currentMonth = '';
    let stringDate = null;

    // 日付をフォーマットして出力
    dates.forEach(date => {
      const formattedDate = formatDate(date);
      const [year, month, day] = date.split('/');

      // 年または月が変わった場合は新しく行を開始
      if (year !== currentYear || month !== currentMonth) {
        if (stringDate) dateMap.push(stringDate)
        stringDate = formattedDate;
        currentYear = year;
        currentMonth = month;
      } else {
        // 同じ年月の場合はスペースを入れて連結
        stringDate = stringDate + formattedDate.replace(`${year}年`, '').replace(`${month}月`, '')
      }
    });
    dateMap.push(stringDate)
    setDesiredDateDisplay(<div>{dateMap[0]}<br />{dateMap[1]}<br />{dateMap[2]}</div>)
  }

  if (!formData) return (<></>);

  return (
    <Page>
      <Flex direction={"column"} alignItems={'center'}>
        <SubTitle>マッチング条件</SubTitle>
      </Flex>
      <FlexColumn>
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel id="age">あなたの年齢</InputLabel>
          <Select
            name="age"
            value={formData.age}
            label="あなたの年齢"
            labelId="age"
            onChange={handleInputChange}
            error={ageMessage}>
            {MATCHING_AGE.map((opt) => (
              <MenuItem value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText style={{ color: 'red' }}>{ageMessage}</FormHelperText>
        </FormControl>
      </FlexColumn>

      <FlexColumn>
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel id="skill">あなたの腕前</InputLabel>
          <Select
            name="skill"
            value={formData.skill}
            label="あなたの腕前"
            labelId="skill"
            onChange={handleInputChange}
            error={skillMessage}>
            {MATCHING_SKILL.map((opt) => (
              <MenuItem value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText style={{ color: 'red' }}>{skillMessage}</FormHelperText>
        </FormControl>
      </FlexColumn>
      <Annotation>
        腕前目安<br />
        初心者 : 点数申告やゲーム進行にサポートが必要<br />
        中級者 : 基本的な点数申告や卓操作が1人でできる<br />
        上級者 : 複雑な符計算や手牌でも素早く対応できる<br />
      </Annotation>

      <FlexColumn>
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel id="playNum">プレイ人数</InputLabel>
          <Select
            name="playNum"
            value={formData.playNum}
            label="プレイ人数"
            labelId="playNum"
            onChange={handleInputChange}
            error={playNumMessage}>
            {MATCHING_MAHJONG_PLAYNUM.map((opt) => (
              <MenuItem value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText style={{ color: 'red' }}>{playNumMessage}</FormHelperText>
        </FormControl>
      </FlexColumn>

      <FlexColumn>
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel id="prefecture">都道府県</InputLabel>
          <Select
            name="prefecture"
            value="東京都"
            label="都道府県"
            labelId="prefecture"
            onChange={handleInputChange}
            disabled={true}
            error={prefectureMessage}>
            {MATCHING_PREFECTURES.map((opt) => (
              <MenuItem value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText style={{ color: 'red' }}>{prefectureMessage}</FormHelperText>
        </FormControl>
      </FlexColumn>

      <AccordionSetting>
        <Accordion>
          <AccordionSummary
            expandIcon={<span className="material-symbols-rounded">
              expand_more
            </span>}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div>駅名</div>
          </AccordionSummary>
          <AccordionDetails >
            <FlexCheckbox>
              {MATCHING_PLACE.map((opt) => (
                <Flex alignItems={'center'} justifyContent="center">
                  <FormControlLabel label={opt.label} control={
                    <Checkbox
                      value={opt.value}
                      onChange={handleInputPlaceCheckboxChange}
                      name={opt.label}
                      checked={placeData.includes(opt.value)}
                    />} />
                </Flex>
              ))}
              <FormHelperText style={{ color: 'red' }}>{placeMessage}</FormHelperText>
            </FlexCheckbox>
          </AccordionDetails>
        </Accordion>
      </AccordionSetting>

      <FlexRow>
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel id="minAge">年齢下限</InputLabel>
          <Select
            name="minAge"
            value={formData.minAge}
            label="年齢下限"
            labelId="minAge"
            onChange={handleInputChange}
            error={minAgeMessage}>
            {MATCHING_LIMIT_AGE.map((opt) => (
              <MenuItem value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText style={{ color: 'red' }}>{minAgeMessage}</FormHelperText>
        </FormControl>
        -
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel id="maxAge">年齢上限</InputLabel>
          <Select
            name="maxAge"
            value={formData.maxAge}
            label="年齢上限"
            labelId="maxAge"
            onChange={handleInputChange}
            error={maxAgeMessage}>
            {MATCHING_LIMIT_AGE.map((opt) => (
              <MenuItem value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText style={{ color: 'red' }}>{maxAgeMessage}</FormHelperText>
        </FormControl>
      </FlexRow>

      <FlexColumn>
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel id="requiredSkill">腕前制限</InputLabel>
          <Select
            name="requiredSkill"
            value={formData.requiredSkill}
            label="腕前制限"
            labelId="requiredSkill"
            onChange={handleInputChange}
            error={requiredSkillMessage}>
            {MATCHING_REQUIRED_SKILL.map((opt) => (
              <MenuItem value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText style={{ color: 'red' }}>{requiredSkillMessage}</FormHelperText>
        </FormControl>
      </FlexColumn>

      <Flex alignItems={'left'} justifyContent="left">
        <FormControlLabel label="マッチング成立時に通知する" control={
          <Checkbox
            onChange={handleInputNoticeCheckboxChange}
            checked={noticeCheckBox}
          />} />
      </Flex>
      {noticeCheckBox ? (
        <>
          <FlexColumn>
            <TextField
              variant="outlined"
              fullWidth
              type='text'
              size="small"
              inputProps={{ maxLength: TEXTFIELDMAXLENGTH }}
              label="通知用メールアドレス"
              name="noticeMail"
              value={formData.noticeMail}
              onChange={handleInputChange}
              error={noticeMailMessage}
              helperText={noticeMailMessage} />
          </FlexColumn>
        </>
      ) : (<></>)}


      <CustomGrid
        templateColumns={{ base: '1fr', large: "1fr 1fr" }}
        templateRows={{ base: '1fr 3fr', large: "1fr" }}>
        <Left>
          <MinTitle>
            選択中の希望日
          </MinTitle>
          {desiredDateDisplay}
          <ErrorText> {desiredDateMessage}</ErrorText>
        </Left>

        <Card variant="outlined" sx={{ minWidth: 300 }} >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={jaJP.components.MuiLocalizationProvider.defaultProps.localeText}
            dateFormats={{ monthAndYear: 'YYYY年 MM月' }}>
            <DateCalendar onChange={(date) => handleDateChange(date)} />
          </LocalizationProvider>
        </Card>
      </CustomGrid>
      {
        matchingReserve ? (
          <Center>
            <Button variant="contained" color="primary" onClick={handleEdit}>保存</Button>
          </Center>
        ) : (
          <Center>
            <Button variant="contained" color="primary" onClick={handleRegister}>マッチング登録</Button>
          </Center>
        )
      }

      {/* 更新処理ダイアログ */}
      <Dialog open={isLoading}>
        <DialogTitle>処理中</DialogTitle>
        <DialogContent>
          <Center>
            <CircularProgress />
          </Center>
        </DialogContent>
      </Dialog>
    </Page>
  );
};

export default MatchingForm;
