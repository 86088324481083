import '@aws-amplify/ui-react/styles.css';
import '../../../App.css';
import { RecruitmentHeadLine } from '/';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Flex, Grid } from '@aws-amplify/ui-react';
import { API, graphqlOperation } from 'aws-amplify';
import {
  recruitmentListMByIsEnd, recruitmentHistoryMSByUsermIDAndStartDateTime,
  getRecruitmentListM, recruitmentListMSByUserIDAndStartDateTime, categoryMSByMasterAndCategoryName, categoryMSByCategoryName
} from '../../../graphql/queries'
import { yyyyMMddHHmmFormatter } from '../../../my-func/dateTimeFormatter'
import { Pagenation } from '../../../common-my-ui-components'
import { styled } from 'styled-components';
import Chip from '@mui/material/Chip';

const LinkHover = styled.div`
width:100%;
a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
  color: inherit;
}
text-decoration: none;
`;

const CategoryName = styled.div`
margin: 1rem 0.5rem;
`;

const Page = styled.div`
width: 100%; 
margin: 0;
padding: 0;
`;

/**
 * userId 現在ログイン中のユーザーID 
 * nowTab 現在表示中のタブ
 * sortDirection 現在のソート順
 * filtering 現在の検索値
 * detailFilterOption 現在の詳細検索値(nullでも可)
 * keyTime ページング更新指示の時間
 * @param {dispComponentList,dispComponentCount,siblingCount,keyTime} props 
 * @returns 
 */
const FilteringRecruitmentList = (props) => {

  // 現在のページの表示を保存
  const [filteringRecruitmentList, setFilteringRecruitmentList] = useState();

  // 引数を受け取る
  const { userId, nowTab, sortDirection, filtering, detailFilterOption, keyTime } = props;
  var { resourceName } = props;

  useEffect(() => {
    if ("isOpenRecruitmentTab" === nowTab) { getOpenRecruitment(); }
    if ("isMyRecruitmentTab" === nowTab) { getMyRecruitment(); }
    if ("isMyEntryRecruitmentTab" === nowTab) { getMyEntryRecruitment(); }
    if ("isCategoryTab" === nowTab) { getCategory(); }
    if ("isCloseRecruitmentTab" === nowTab) { getCloseRecruitment(); }
  }, [keyTime]);

  // 募集中の募集を取得する
  const getOpenRecruitment = async () => {
    var nextToken = null
    var recruitmentList = [];
    const currentDate = new Date().toISOString();
    do {
      const opt = {
        isEnd: "0",
        nextToken: nextToken,
        filter: {
          and: [
            {
              or: [
                { title: { contains: filtering } },
                { detail: { contains: filtering } },
                { place: { contains: filtering } }
              ]
            },
            { endDateTime: { gt: currentDate } } 
          ]
        },
        sortDirection: sortDirection
      };
      // 詳細検索の入力値をoption設定に追加する
      if (Object.keys(detailFilterOption).length != 0) {
        opt.filter.and = detailFilterOption;
      }
      var recList = (await API.graphql(graphqlOperation(recruitmentListMByIsEnd, opt)
      )).data.recruitmentListMByIsEnd;
      recruitmentList = recruitmentList.concat(recList.items.filter((item) => item._deleted != true));
      nextToken = recList.nextToken;
    } while (nextToken)
    const dispData = []
    for (let item of recruitmentList) {
      item.startDateTime = yyyyMMddHHmmFormatter(new Date(item.startDateTime));
      dispData.push(
        <LinkHover>
          <Link to={`/Recruitment/${item.id}`}>
            <RecruitmentHeadLine
              recruitment={item}
              keyTime={Date.now()}
            />
          </Link>
        </LinkHover>
      )
    }
    setFilteringRecruitmentList(
      <Pagenation dispComponentList={dispData} dispComponentCount={20} siblingCount={1} keyTime={Date.now()} />
    )
  }

  // 自分の募集を取得する
  const getMyRecruitment = async () => {
    var nextToken = null
    var recruitmentList = []
    do {
      const opt = {
        userID: userId,
        sortDirection: sortDirection,
        nextToken: nextToken,
        filter: {
          or: [
            { title: { contains: filtering } },
            { detail: { contains: filtering } },
            { place: { contains: filtering } }
          ]
        }
      };
      const recList = (await API.graphql(graphqlOperation(recruitmentListMSByUserIDAndStartDateTime, opt)))
        .data.recruitmentListMSByUserIDAndStartDateTime
      recruitmentList = recruitmentList.concat(recList.items.filter((item) => item._deleted != true));
      nextToken = recList.nextToken;
    } while (nextToken)
    const dispData = []
    for (let item of recruitmentList) {
      item.startDateTime = yyyyMMddHHmmFormatter(new Date(item.startDateTime));
      dispData.push(
        <LinkHover>
          <Link to={`/Recruitment/${item.id}`} >
            <RecruitmentHeadLine
              recruitment={item}
              key={item.id}
            />
          </Link>
        </LinkHover>
      )
    }
    setFilteringRecruitmentList(
      <Pagenation dispComponentList={dispData} dispComponentCount={20} siblingCount={1} keyTime={Date.now()} />
    )
  }

  // 参加中の募集を取得する
  const getMyEntryRecruitment = async () => {
    var nextToken = null
    // 退出時間が入っていない参加履歴を取得する
    var recruitmentHistoryList = []
    do {
      const opt = {
        usermID: userId,
        sortDirection: sortDirection,
        nextToken: nextToken,
        filter: { exitDateTime: { attributeExists: false } }
      };
      const recHisList = (await API.graphql(graphqlOperation(recruitmentHistoryMSByUsermIDAndStartDateTime, opt)))
        .data.recruitmentHistoryMSByUsermIDAndStartDateTime;
      recruitmentHistoryList = recruitmentHistoryList.concat(recHisList.items);
      nextToken = recHisList.nextToken;
    } while (nextToken)
    const dispData = []
    const recruitmentList = Array(recruitmentHistoryList.length);
    await Promise.all(recruitmentHistoryList.map(async (recruitmentHistory, i) => {
      const opt = {
        id: recruitmentHistory.recruitmentlistmID
      };
      const recruitment = await API.graphql(graphqlOperation(getRecruitmentListM, opt));
      if (recruitment.data.getRecruitmentListM) recruitmentList[i] = recruitment;
    }))
    for (let item of recruitmentList) {
      if (!item) continue;
      const recruitment = item.data.getRecruitmentListM;
      // 自分の作成した募集と削除済みの募集は除外する
      if ((recruitment.title.includes(filtering) || recruitment.detail.includes(filtering)
        || recruitment.place.includes(filtering))
        && recruitment.userID !== userId && !recruitment._deleted) {
        recruitment.startDateTime = yyyyMMddHHmmFormatter(new Date(recruitment.startDateTime));
        dispData.push(
          <LinkHover>
            <Link to={`/Recruitment/${recruitment.id}`} >
              <RecruitmentHeadLine
                recruitment={recruitment}
                key={recruitment.id}
              />
            </Link>
          </LinkHover>
        )
      }
    }
    setFilteringRecruitmentList(
      <Pagenation dispComponentList={dispData} dispComponentCount={20} siblingCount={1} keyTime={Date.now()} />)
  }

  // カテゴリ一覧を取得する
  const getCategory = async () => {
    if (resourceName) {
      // resourceは１度きりなので使い終わったら破棄する
      const recruitmentList = await getRecruitmentListByCategory(resourceName);
      resourceName = null;
      return recruitmentList;
    }

    let nextToken = null
    var categoryList = []
    do {
      const opt = {
        master: "1",
        nextToken: nextToken,
        sortDirection: "ASC"
      };
      var catList = (await API.graphql(graphqlOperation(categoryMSByMasterAndCategoryName, opt)))
        .data.categoryMSByMasterAndCategoryName;

      categoryList = categoryList.concat(catList.items);
      nextToken = catList.nextToken;
    } while (nextToken)

    const dispData = []
    for (let item of categoryList) {
      dispData.push(
        <CategoryName onClick={() => getRecruitmentListByCategory(item.categoryName)}>
          <Chip label={item.categoryName} color="primary" />
        </CategoryName>
      )
    }
    setFilteringRecruitmentList(
      <Grid templateColumns={{ base: "1fr 1fr ", large: "1fr 1fr 1fr 1fr 1fr" }}>
        {dispData}
      </Grid>
    )
  }

  // カテゴリから募集を検索
  const getRecruitmentListByCategory = async (categoryName) => {
    let nextToken = null
    var categoryList = []
    do {
      const opt = {
        categoryName: categoryName,
        filter: { master: { ne: '1' } }
      };
      var catList = (await API.graphql(graphqlOperation(categoryMSByCategoryName, opt)))
        .data.categoryMSByCategoryName;

      categoryList = categoryList.concat(catList.items);
      nextToken = catList.nextToken;
    } while (nextToken)
    const unDeletedCategoryList = categoryList.filter((item) => {
      return item._deleted != true;
    });
    const dispData = []
    const recruitmentList = [];
    await Promise.all(unDeletedCategoryList.map(async (CategoryM) => {
      const opt = {
        id: CategoryM.recruitmentListId
      };
      const recruitment = await API.graphql(graphqlOperation(getRecruitmentListM, opt));
      // 募集中のみ表示
      if (recruitment.data.getRecruitmentListM && recruitment.data.getRecruitmentListM.isEnd !== "1") {
        recruitmentList.push(recruitment)
      }
    }))
    for (let item of recruitmentList) {
      const recruitment = item.data.getRecruitmentListM
      if (!recruitment) continue;
      if (!recruitment._deleted) {
        recruitment.startDateTime = yyyyMMddHHmmFormatter(new Date(recruitment.startDateTime));
        dispData.push(
          <LinkHover>
            <Link to={`/Recruitment/${recruitment.id}`} >
              <RecruitmentHeadLine
                recruitment={recruitment}
                key={recruitment.id}
              />
            </Link>
          </LinkHover>
        )
      }
    }
    setFilteringRecruitmentList(<>
      <Link onClick={(getCategory)}>カテゴリ一覧に戻る</Link>
      「{categoryName}」の検索結果
      <Pagenation dispComponentList={dispData} dispComponentCount={20} siblingCount={1} keyTime={Date.now()} /></>)
  }

  // 終了した募集を取得する
  const getCloseRecruitment = async () => {
    var nextToken = null
    var recruitmentList = [];
    do {
      const opt = {
        isEnd: "1",
        nextToken: nextToken,
        filter: {
          or: [
            { title: { contains: filtering } },
            { detail: { contains: filtering } },
            { place: { contains: filtering } }
          ]
        },
        sortDirection: sortDirection
      };
      var recList = (await API.graphql(graphqlOperation(recruitmentListMByIsEnd, opt)
      )).data.recruitmentListMByIsEnd;
      recruitmentList = recruitmentList.concat(recList.items);
      nextToken = recList.nextToken;
    } while (nextToken)
    const dispData = []
    for (let item of recruitmentList) {
      if (!item._deleted) {
        item.startDateTime = yyyyMMddHHmmFormatter(new Date(item.startDateTime));
        dispData.push(
          <LinkHover>
            <Link to={`/Recruitment/${item.id}`} >
              <RecruitmentHeadLine
                recruitment={item}
                keyTime={Date.now()}
              />
            </Link>
          </LinkHover>
        )
      }
    }
    setFilteringRecruitmentList(
      <Pagenation dispComponentList={dispData} dispComponentCount={20} siblingCount={1} keyTime={Date.now()} />
    )
  }

  return (
    <Page>
      <Flex direction={"column"} alignItems={'center'} >
        {filteringRecruitmentList}
      </Flex>
    </Page>
  );
};

export default FilteringRecruitmentList;
