import '@aws-amplify/ui-react/styles.css';
import '../../../App.css';
import { Flex } from '@aws-amplify/ui-react';
import { styled } from 'styled-components';
import { useState, useEffect, useContext } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { getUserM } from "../../../graphql/queries";
import { UserIconListRow, MatchingForm, BlockUserForm } from "../../../common-my-ui-components";
import { getMatching, updateMatchingStatus, updateMatchingReserveStatus } from '../../../my-func/MatchingManager'
import {
  Button, Card, List, Dialog, DialogContent, DialogTitle,
  DialogActions, DialogContentText, CircularProgress
} from '@mui/material';
import MessageContext from "../../../MessageContext";
import UserContext from "../../../UserContext";
import { MatchingInfo } from "."

// ページ全体の設定
const Page = styled.div`
width: 100%;  
padding: 0 0.5rem;
`;

// ボタンの間隔設定
const ButtonStyle = styled.div`
margin: 1rem;
`;

const Center = styled.table`
margin: auto; /* サークルを中央に配置 */
`;

const MinTitle = styled.div`
width:100%;
line-height: 120%;
font-size: 1.2rem;
font-weight: 450;
text-align: left;
margin:auto;
position: relative;
padding: 0.25em 0;
&::after {
  content: "";
  display: block;
  height: 4px;
  background: linear-gradient(90deg, ${(props) => (props.theme.palette.text.green)},
  ${(props) => (props.theme.palette.quinary.main)});
}
`;

const TopText = styled.div`
text-align: left;
padding: 0.5em 1em;
background: ${(props) => (props.theme.palette.quinary.main)};
border-radius: 10px;
border: solid 1px ${(props) => (props.theme.palette.quaternary.main)};
border-top: solid 10px ${(props) => (props.theme.palette.text.green)};
`;


// マッチングを未確認
const UN_CONFIRM = "1";
// マッチングを確認
const CONFIRM = "2";
// マッチングの破棄確認
const DISCARDED_CONFIRM = "3";
// マッチングを破棄
const DISCARDED = "9";

const MatchedMatching = (props) => {
  // 引数で募集情報を受け取る
  const { matchingReserve, keyTime, setKeyTime } = props;
  const { setErrorMessage } = useContext(MessageContext);
  const { user } = useContext(UserContext);
  const [dispUserList, setDispUserList] = useState(null);
  const [matching, setMatching] = useState(null);
  const [matchingStatus, setMatchingStatus] = useState(null);
  const [matchingUserList, setMatchingUserList] = useState(null);

  // 処理中の状態を保存する
  const [isLoading, setIsLoading] = useState(false);

  // マッチング破棄ダイアログ表示
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const dialogHandler = () => {
    setIsOpenDialog(!isOpenDialog);
  }

  // マッチング情報を取得
  const getMatchingMInfo = async () => {
    try {
      const matching = await getMatching(matchingReserve.matchingMId, setErrorMessage);
      setMatching(matching);
    } catch (e) {
      setErrorMessage(<div>
        マッチング情報取得時にエラーが発生しました。<br />
        しばらくしてからもう一度お試しください。
      </div>);
    }
  }

  // マッチングユーザーリストを取得
  const getMatchingUserList = async () => {
    try {
      // マッチングユーザーの情報を表示する
      const entryUserList = [matching.userm1ID, matching.userm2ID, matching.userm3ID, matching.userm4ID];
      const dispUserListData = Array(entryUserList.length);
      await Promise.all(entryUserList.map(async (entryUser, i) => {
        // ユーザーIDが格納されていない場合や3人麻雀のようにダミーの場合は処理を終える
        if (!entryUser || entryUser === "dummyId" || user.id === entryUser) return;
        const opt = {
          id: entryUser,
        };
        const userInfo = (await API.graphql(graphqlOperation(getUserM, opt))).data.getUserM;
        dispUserListData[i] = userInfo;
      }));
      setMatchingUserList(dispUserListData);
      setDispUserList(
        <List>
          <UserIconListRow userMs={dispUserListData} />
        </List>
      );
    } catch (e) {
      setErrorMessage(<div>
        マッチング情報取得時にエラーが発生しました。<br />
        しばらくしてからもう一度お試しください。
      </div>);
    }
  }

  // マッチングのステータスを更新を確認
  const handleMatchingStatus = async (status) => {
    setIsLoading(true);
    if (checkOverMatchingDueDate() && status === CONFIRM) {
      // ボタン押下時に期日超過しているか確認する
      window.location.reload();
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return;
    }
    updateMatchingStatus(matching, user, status, setErrorMessage);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setKeyTime(Date.now());
    setIsLoading(false);
  };

  // マッチング予約のステータスを更新
  const handleMatchingReserveStatus = async (status) => {
    setIsLoading(true);
    updateMatchingReserveStatus(matchingReserve, status, setErrorMessage);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setKeyTime(Date.now());
    setIsLoading(false);
  };

  // 自身のマッチングステータスを取得する
  const getMatchingStatus = () => {
    if (matching.userm1ID === user.id) {
      setMatchingStatus(matching.user1Confirm);
    } else if (matching.userm2ID === user.id) {
      setMatchingStatus(matching.user2Confirm);
    } else if (matching.userm3ID === user.id) {
      setMatchingStatus(matching.user3Confirm);
    } else if (matching.userm4ID === user.id) {
      setMatchingStatus(matching.user4Confirm);
    }
  };

  // マッチングが自身以外に破棄されているかチェックする
  const checkMatchingDiscarded = () => {
    var discarded = false;
    if (matching.userm1ID !== user.id && matching.user1Confirm === DISCARDED) {
      discarded = true;
    } else if (matching.userm2ID !== user.id && matching.user2Confirm === DISCARDED) {
      discarded = true;
    } else if (matching.userm3ID !== user.id && matching.user3Confirm === DISCARDED) {
      discarded = true;
    } else if (matching.userm4ID !== user.id && matching.user4Confirm === DISCARDED) {
      discarded = true;
    }
    return discarded;
  };

  // マッチング期日を超えているかチェックする
  // 期日が2024/5/1の場合、23時59分までは期限内としたいので+1日する
  const checkOverMatchingDueDate = () => {
    const today = new Date();
    const dueDate = new Date(matching.dueDate);
    dueDate.setDate(dueDate.getDate() + 1);
    return dueDate < today;
  };

  useEffect(() => {
    setIsOpenDialog(false);
    getMatchingMInfo();
  }, [keyTime]);

  useEffect(() => {
    if (!matching) return;
    getMatchingUserList();
    getMatchingStatus();
  }, [matching]);

  if (!matching && !matchingUserList) return;

  return (
    <Page>
      {matchingStatus === CONFIRM && checkMatchingDiscarded() ? (
        /*マッチング確認済み ほかユーザーに破棄されている */
        <>
          <MatchingInfo matchingReserve={matchingReserve} matching={matching} />
          {/* マッチング破棄確認ダイアログ */}
          <Dialog open={true} >
            <DialogContent>
              <DialogContentText>
                他ユーザーにマッチングが破棄されました
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleMatchingStatus(DISCARDED_CONFIRM)}>確認</Button>
            </DialogActions>
          </Dialog>
        </>
      ) : matchingStatus === CONFIRM && checkOverMatchingDueDate() ? (
        /*マッチング確認済み 確認期日超過 */
        <>
          <MatchingInfo matchingReserve={matchingReserve} matching={matching} />
          {/* マッチング期日超過確認ダイアログ */}
          <Dialog open={true} >
            <DialogContent>
              <DialogContentText>
                他ユーザーが期日までにマッチング確認しませんでした
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleMatchingStatus(DISCARDED_CONFIRM)}>確認</Button>
            </DialogActions>
          </Dialog>
        </>
      ) : matchingStatus === CONFIRM ? (
        /*マッチング確認済み 他のユーザーの確認待ち */
        <>
          <TopText>
            マッチング確認済みです<br />
            他ユーザーのマッチング確認が完了するまでお待ち下さい
          </TopText>
          <MatchingInfo matchingReserve={matchingReserve} matching={matching} />
          <MinTitle>マッチングユーザー</MinTitle>
          <Card variant="outlined" sx={{ minWidth: 300 }} >
            {dispUserList}
          </Card>
        </>
      ) : matchingStatus === UN_CONFIRM && checkOverMatchingDueDate() ? (
        /*マッチング未確認 確認期日超過 */
        <>
          <MatchingInfo matchingReserve={matchingReserve} matching={matching} />
          {/* マッチング期日確認ダイアログ */}
          <Dialog open={true} >
            <DialogContent>
              <DialogContentText>
                マッチング確認が完了する前に期日となったため、マッチングが破棄されました
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleMatchingStatus(DISCARDED)}>確認</Button>
            </DialogActions>
          </Dialog>
        </>
      ) : matchingStatus === UN_CONFIRM && checkMatchingDiscarded() ? (
        /*マッチング未確認 ほかユーザーに破棄されている */
        <>
          <MatchingInfo matchingReserve={matchingReserve} matching={matching} />
          {/* マッチング破棄確認ダイアログ */}
          <Dialog open={true} >
            <DialogContent>
              <DialogContentText>
                他ユーザーにマッチングが破棄されました
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleMatchingStatus(DISCARDED_CONFIRM)}>確認</Button>
            </DialogActions>
          </Dialog>
        </>
      ) : matchingStatus === UN_CONFIRM ? (
        /*マッチング未確認 */
        <>
          <TopText>
            マッチングしました！<br />
            マッチングユーザーに問題がなければ確認ボタンを押してください
          </TopText>
          <MatchingInfo matchingReserve={matchingReserve} matching={matching} />
          <MinTitle>マッチングユーザー</MinTitle>
          <Card variant="outlined" sx={{ minWidth: 300 }} >
            {dispUserList}
          </Card>
          <ButtonStyle>
            <Button variant="contained" onClick={() => handleMatchingStatus(CONFIRM)}>マッチング確認</Button>
          </ButtonStyle>
          <Flex direction={"row"} alignItems={'right'} justifyContent="right">
            <Button onClick={dialogHandler}>
              <span className="material-symbols-rounded">
                delete
              </span>
              マッチング破棄
            </Button>
          </Flex>

          {/* マッチング破棄ダイアログ */}
          <Dialog fullScreen open={isOpenDialog} >
            <DialogContent>
              <DialogActions>
                <Button onClick={dialogHandler}>キャンセル</Button>
              </DialogActions>
              <TopText>
                マッチングを破棄することができます<br />
                マッチングしたくないユーザーがいた場合、ブロックすることができます<br />
                ※あなたがブロックしたことは相手に通知されません
              </TopText>
              <BlockUserForm targetUserList={matchingUserList} targetTitle={"マッチングユーザー"} />
              <ButtonStyle>
                <Center>
                  <Button variant="contained" onClick={() => handleMatchingStatus(DISCARDED)}>マッチング破棄</Button>
                </Center>
              </ButtonStyle>
            </DialogContent>
          </Dialog>
        </>
      ) : matchingStatus === DISCARDED_CONFIRM ? (
        /*マッチング破棄確認 */
        <>
          <TopText>
            マッチングは破棄されています<br />
            同条件で再度マッチングをする場合は再マッチングを押してください
          </TopText>
          <MatchingInfo matchingReserve={matchingReserve} matching={matching} />
          <ButtonStyle>
            <Button variant="contained" onClick={dialogHandler}>再マッチング</Button>
          </ButtonStyle>
          <Flex direction={"row"} alignItems={'right'} justifyContent="right">
            <Button onClick={() => handleMatchingReserveStatus(DISCARDED)}>
              <span className="material-symbols-rounded">
                globe_asia
              </span>
              新しく登録
            </Button>
          </Flex>
          {/* マッチング編集ダイアログ */}
          <Dialog fullScreen open={isOpenDialog} >
            <DialogContent>
              <DialogActions>
                <Button onClick={dialogHandler}>閉じる</Button>
              </DialogActions>
              <Center>
                <MatchingForm matchingReserve={matchingReserve} setKeyTime={setKeyTime} />
              </Center>
            </DialogContent>
          </Dialog>
        </>
      ) : matchingStatus === DISCARDED ? (
        /*マッチングを破棄 */
        <>
          <TopText>
            マッチングは破棄されています<br />
            同条件で再度マッチングをする場合は再マッチングを押してください
          </TopText>
          <MatchingInfo matchingReserve={matchingReserve} matching={matching} />
          <ButtonStyle>
            <Button variant="contained" onClick={dialogHandler}>再マッチング</Button>
          </ButtonStyle>
          <Flex direction={"row"} alignItems={'right'} justifyContent="right">
            <Button name="delete" onClick={() => handleMatchingReserveStatus(DISCARDED)}>
              <span className="material-symbols-rounded">
                globe_asia
              </span>
              新しく登録
            </Button>
          </Flex>
          {/* マッチング編集ダイアログ */}
          <Dialog fullScreen open={isOpenDialog} >
            <DialogContent>
              <DialogActions>
                <Button onClick={dialogHandler}>閉じる</Button>
              </DialogActions>
              <Center>
                <MatchingForm matchingReserve={matchingReserve} isDiscardedReEntry={true} setKeyTime={setKeyTime} />
              </Center>
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <TopText>
          エラーが発生しました。<br />
          雀の巣公式までお問い合わせください。
        </TopText>
      )}

      {/* 更新処理ダイアログ */}
      <Dialog open={isLoading}>
        <DialogTitle>処理中</DialogTitle>
        <DialogContent>
          <Center>
            <CircularProgress />
          </Center>
        </DialogContent>
      </Dialog>
    </Page>
  );
};

export default MatchedMatching;
