import { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { getRecruitmentListDetailTemplate } from '../my-func/getRecruitmentListDetailTemplate';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import styled from 'styled-components';

const TEXTFIELDMAXLENGTH = 10;

const Page = styled.div`
  width:100%;
`;

const FlexColumn = styled(Box)`
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const RecruitmentDetailSetting = (props, ref) => {

  // 引数を受け取る
  // defaultRecruitmentData 初期表示する詳細情報
  // isSerch 検索用のフォームならtrue
  var { defaultRecruitmentData,  isSearch } = props;

  // 外部からフォームの入力値を取得する関数を用意
  useImperativeHandle(
    ref,
    () => ({
      getFilterOption() {
        // 検索時のフィルター条件を返却する
        // 「-1:指定しない」の場合はクエリ条件として設定しない
        const opt = {};
        if (formData.playNum !== "-1") opt.playNum = { contains: formData.playNum }
        if (formData.gameRoundNum !== "-1") opt.gameRoundNum = { contains: formData.gameRoundNum }
        if (formData.gameScoreJudge !== "-1") opt.gameScore = { contains: formData.gameScore }
        if (formData.redFive !== "-1") opt.redFive = { contains: formData.redFive }
        if (formData.additionalYaku !== "-1") opt.additionalYaku = { contains: formData.additionalYaku }
        if (formData.allSimples !== "-1") opt.allSimples = { contains: formData.allSimples }
        if (formData.yakuBack !== "-1") opt.yakuBack = { contains: formData.yakuBack }
        if (formData.swapCalling !== "-1") opt.swapCalling = { contains: formData.swapCalling }
        if (formData.noDrawReach !== "-1") opt.noDrawReach = { contains: formData.noDrawReach }
        if (formData.furitenDraw !== "-1") opt.furitenDraw = { contains: formData.furitenDraw }
        if (formData.points30Doubles4 !== "-1") opt.points30Doubles4 = { contains: formData.points30Doubles4 }
        if (formData.nearestPlayerWin !== "-1") opt.nearestPlayerWin = { contains: formData.nearestPlayerWin }
        if (formData.moreGameRound !== "-1") opt.moreGameRound = { contains: formData.moreGameRound }
        if (formData.drawnGameJudge !== "-1") {
          opt.drawnGame = formData.drawnGameJudge === "0" ? { contains: formData.drawnGameJudge } :
            { contains: formData.drawnGame }
        }
        if (formData.lastWinStop !== "-1") opt.lastWinStop = { contains: formData.lastWinStop }
        if (formData.additionalRoundJudge !== "-1") {
          opt.additionalRound = (formData.additionalRoundJudge === "0") ? { contains: formData.additionalRoundJudge } :
            { contains: formData.additionalRound }
        }
        if (formData.minusScore !== "-1") opt.minusScore = { contains: formData.minusScore }
        if (formData.north_3 !== "-1") opt.north_3 = { contains: formData.north_3 }
        if (formData.drawLoss_3 !== "-1") opt.drawLoss_3 = { contains: formData.drawLoss_3 }
        if (formData.flower_3 !== "-1") opt.flower_3 = { contains: formData.flower_3 }
        return opt;
      },
      getDetailForm() {
        // 詳細情報を返却する
        var copyFormData = { ...formData }
        delete copyFormData.gameScoreJudge;
        delete copyFormData.additionalRoundJudge;
        delete copyFormData.drawnGameJudge;
        return copyFormData;
      }
    }),
  )

  // 検索用フォームの初期値を保存
  const defaultSearchFormData = {
    playNum: "-1",
    gameRoundNum: "-1",
    gameScoreJudge: "-1",
    gameScore: "25000",
    redFive: "-1",
    additionalYaku: "-1",
    allSimples: "-1",
    yakuBack: "-1",
    swapCalling: "-1",
    noDrawReach: "-1",
    furitenDraw: "-1",
    points30Doubles4: "-1",
    nearestPlayerWin: "-1",
    moreGameRound: "-1",
    drawnGameJudge: "-1",
    drawnGame: "0",
    additionalRoundJudge: "-1",
    additionalRound: "30000",
    lastWinStop: "-1",
    minusScore: "-1",
    north_3: "-1",
    drawLoss_3: "-1",
    flower_3: "-1"
  };

  // 作成・編集用フォームの初期値を保存
  const defaultRegisterEditFormData = {
    playNum: "4",
    gameRoundNum: "2",
    gameScoreJudge: "1",
    gameScore: "25000",
    redFive: "1",
    additionalYaku: "1",
    allSimples: "1",
    yakuBack: "1",
    swapCalling: "0",
    noDrawReach: "1",
    furitenDraw: "1",
    points30Doubles4: "1",
    nearestPlayerWin: "1",
    moreGameRound: "2",
    drawnGameJudge: "0",
    drawnGame: "0",
    additionalRoundJudge: "0",
    additionalRound: "0",
    lastWinStop: "0",
    minusScore: "0",
    north_3: "",
    drawLoss_3: "",
    flower_3: ""
  };

  // 現在のフォームの選択値を保存
  const [formData, setFormData] = useState(defaultRegisterEditFormData);

  // フォームに変更があった場合選択値を更新する
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // 途中流局フォームに変更があった場合選択値を更新する
  const drawnGameHandleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      ["drawnGame"]: value,
    }));
  };

  // 途中流局 1:有,0:無 1:九種九牌,2:四風子連打,3:四槓流れ,4:三家和,5:四家立直から選択 "1,3,4"のようにカンマ区切り
  const drawnGame = [
    { name: 'drawnGame', label: '九種九牌', value: "1", checked: false },
    { name: 'drawnGame', label: '四風子連打', value: "2", checked: false },
    { name: 'drawnGame', label: '四槓流れ', value: "3", checked: false },
    { name: 'drawnGame', label: '三家和', value: "4", checked: false },
    { name: 'drawnGame', label: '四家立直', value: "5", checked: false }];
  // 現在の途中流局の選択値を保存
  const [drawnGameVal, setDrawnGameVal] = useState(drawnGame);
  // チェックボックスの挙動を操作
  const drawnGameChange = (e) => {
    const { name, value } = e.target;
    const newDrawnGameVal = [...drawnGameVal];
    let drawnGameRealVal = [];
    newDrawnGameVal.map((item) => {
      if (item.value === value) {
        item.checked = !item.checked;
      }
      if (item.checked) {
        drawnGameRealVal.push(item.value)
      }
    })
    setDrawnGameVal(newDrawnGameVal);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: drawnGameRealVal.join(",")
    }));
  };

  // テンプレートが選択された場合に値を更新する
  const changeTemplates = (e) => {
    const template = getRecruitmentListDetailTemplate(e.target.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...template
    }))
  };

  useEffect(() => {
    if (isSearch) {
      setFormData(defaultSearchFormData)
    } else {
      setFormData(defaultRegisterEditFormData)
    }

    // 編集時に情報が渡ってきたらそれに更新する
    if (defaultRecruitmentData) {
      // 持ち点、南入・西入、途中流局は値によってありなしの条件を追加する
      defaultRecruitmentData.gameScoreJudge = "1";
      defaultRecruitmentData.additionalRoundJudge = defaultRecruitmentData.additionalRound === "0" ? "0" : "1";
      defaultRecruitmentData.drawnGameJudge = defaultRecruitmentData.drawnGame === "0" ? "0" : "1";
      var drawnGameData = (defaultRecruitmentData.drawnGame).split(",");
      const newDrawnGameVal = [...drawnGameVal];
      drawnGameData.map((value) => {
        newDrawnGameVal.map((item) => {
          if (value === item.value) item.checked = true;
        })
      })
      setDrawnGameVal(newDrawnGameVal);

      // formDataが持つ項目以外は更新しない
      setFormData((prevFormData) => {
        for (const key in defaultRecruitmentData) {
          if (prevFormData.hasOwnProperty(key)) {
            prevFormData[key] = defaultRecruitmentData[key];
          }
        }
        return prevFormData;
      })
    }
  }, []);

  var selectRecruitmentListDetail = [
    {
      name: 'playNum', label: 'プレイ人数', type: 'select',
      options: [
        { label: '3人麻雀', value: '3' },
        { label: '4人麻雀', value: '4' },
        { label: '指定しない', value: '-1' },
      ]
    },
    {
      name: 'gameRoundNum', label: '局数', type: 'select',
      options: [
        { label: '東風戦', value: '1' },
        { label: '半荘戦', value: '2' },
        { label: '指定しない', value: '-1' },
      ]
    },
    {
      name: 'gameScore', judgeName: 'gameScoreJudge', label: '持ち点', type: 'selecttext',
      options: [
        { label: '指定する', value: '1' },
        { label: '指定しない', value: '-1' },
      ]
    },
    {
      name: 'redFive', label: '赤牌', type: 'select',
      options: [
        { label: 'なし', value: "0" },
        { label: '各1枚', value: "1" },
        { label: '各2枚', value: "2" },
        { label: '各3枚', value: "3" },
        { label: '各4枚', value: "4" },
        { label: '指定しない', value: "-1" }
      ]
    },
    {
      name: 'additionalYaku', label: '一発・裏ドラ・槓ドラ・槓裏ドラ', type: 'select',
      options: [
        { label: '有', value: '1' },
        { label: '無', value: '0' },
        { label: '指定しない', value: '-1' },
      ]
    },
    {
      name: 'allSimples', label: '喰いタン', type: 'select',
      options: [
        { label: '有', value: '1' },
        { label: '無', value: '0' },
        { label: '指定しない', value: '-1' },
      ]
    },
    {
      name: 'yakuBack', label: '後付け', type: 'select',
      options: [
        { label: '有', value: '1' },
        { label: '無', value: '0' },
        { label: '指定しない', value: '-1' },
      ]
    },
    {
      name: 'swapCalling', label: '喰い替え', type: 'select',
      options: [
        { label: '有(現物以外)', value: '2' },
        { label: '有', value: '1' },
        { label: '無', value: '0' },
        { label: '指定しない', value: '-1' },
      ]
    },
    {
      name: 'noDrawReach', label: 'ツモ番なしリーチ', type: 'select',
      options: [
        { label: '有', value: '1' },
        { label: '無', value: '0' },
        { label: '指定しない', value: '-1' },
      ]
    },
    {
      name: 'furitenDraw', label: 'フリテンツモ', type: 'select',
      options: [
        { label: '有', value: '1' },
        { label: '無', value: '0' },
        { label: '指定しない', value: '-1' },
      ]
    },
    {
      name: 'points30Doubles4', label: '30符4翻の切り上げ', type: 'select',
      options: [
        { label: '有', value: '1' },
        { label: '無', value: '0' },
        { label: '指定しない', value: '-1' },
      ]
    },
    {
      name: 'nearestPlayerWin', label: '頭ハネ', type: 'select',
      options: [
        { label: '有', value: '1' },
        { label: '無', value: '0' },
        { label: '指定しない', value: '-1' },
      ]
    },
    {
      name: 'moreGameRound', label: '連荘', type: 'select',
      options: [
        { label: '和了連荘', value: '1' },
        { label: '聴牌連荘', value: '2' },
        { label: '無', value: '0' },
        { label: '指定しない', value: '-1' },
      ]
    },
    {
      name: 'drawnGame', judgeName: 'drawnGameJudge', label: '途中流局', type: 'drawnGameJudge',
      options: [
        { label: '有', value: '1' },
        { label: '無', value: '0' },
        { label: '指定しない', value: '-1' },
      ]
    },
    {
      name: 'additionalRound', judgeName: 'additionalRoundJudge', label: '南入・西入', type: 'selecttext',
      options: [
        { label: '有', value: '1' },
        { label: '無', value: '0' },
        { label: '指定しない', value: '-1' },
      ]
    },
    {
      name: 'lastWinStop', label: 'オーラスの和了止め', type: 'select',
      options: [
        { label: '有', value: '1' },
        { label: '無', value: '0' },
        { label: '指定しない', value: '-1' },
      ]
    },
    {
      name: 'minusScore', label: '飛び', type: 'select',
      options: [
        { label: '有', value: '1' },
        { label: '無', value: '0' },
        { label: '指定しない', value: '-1' },
      ]
    },
    {
      name: 'north_3', label: '北の扱いについて(※3人麻雀のみ)', type: 'select',
      options: [
        { label: '無', value: "0" },
        { label: '抜きドラ', value: "1" },
        { label: '共通役牌', value: "2" },
        { label: '指定しない', value: "-1" }
      ]
    },
    {
      name: 'drawLoss_3', label: 'ツモ損(※3人麻雀のみ)', type: 'select',
      options: [
        { label: '有', value: '1' },
        { label: '無', value: '0' },
        { label: '指定しない', value: '-1' },
      ]
    },
    {
      name: 'flower_3', label: '花牌(※3人麻雀のみ)', type: 'select',
      options: [
        { label: '有', value: '1' },
        { label: '無', value: '0' },
        { label: '指定しない', value: '-1' },
      ]
    }
  ]


  return (
    <Page>
      <FormControl fullWidth variant="outlined" size="small">
        <InputLabel id='template'>テンプレート</InputLabel>
        <Select
          onChange={changeTemplates}
          label='テンプレート'
          labelId='template'
          defaultValue="0">
          <MenuItem value="0">指定しない</MenuItem>
          <MenuItem value="1">Mリーグ</MenuItem>
          <MenuItem value="2">最高位戦日本プロ麻雀協会</MenuItem>
          <MenuItem value="3">日本プロ麻雀連盟</MenuItem>
          <MenuItem value="4">日本プロ麻雀協会</MenuItem>
          <MenuItem value="5">ザンリーグ</MenuItem>
        </Select>
      </FormControl>

      {
        selectRecruitmentListDetail.map((item) => {
          if (item.type === "select") {
            return (
              <FlexColumn>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel id={item.name}>{item.label}</InputLabel>
                  <Select
                    value={formData[item.name]}
                    name={item.name}
                    onChange={handleInputChange}
                    label={item.label}
                    labelId={item.name}>
                    {item.options.map((opt) => {
                      if (!isSearch && opt.value === "-1") return;
                      return (
                        <MenuItem
                          value={opt.value}>
                          {opt.label}
                        </MenuItem>
                      );
                    }
                    )}
                  </Select>
                </FormControl>
              </FlexColumn>
            );
          } else if (item.type === "selecttext") {
            return (
              <div>
                <FlexColumn>
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id={item.name}>{item.label}</InputLabel>
                    <Select
                      value={formData[item.judgeName]}
                      name={item.judgeName}
                      onChange={handleInputChange}
                      label={item.label}
                      labelId={item.name}>
                      {item.options.map((opt) => {
                        if (!isSearch && opt.value === "-1") return;
                        return (
                          <MenuItem
                            value={opt.value}>
                            {opt.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </FlexColumn>
                <FlexColumn>
                  <TextField
                    fullWidth
                    size="small"
                    id="outlined-basic"
                    label={item.label}
                    variant="outlined"
                    type='number'
                    inputProps={{ maxLength: TEXTFIELDMAXLENGTH }}
                    value={formData[item.name]}
                    onChange={handleInputChange}
                    name={item.name}
                    disabled={formData[item.judgeName] !== "1"}
                  />
                </FlexColumn>
              </div>
            )
          } else if (item.type === "drawnGameJudge") {
            return (
              <div>
                <FlexColumn>
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id={item.name}>{item.label}</InputLabel>
                    <Select
                      value={formData[item.judgeName]}
                      name={item.judgeName}
                      onChange={drawnGameHandleInputChange}
                      label={item.label}
                      labelId={item.name}>
                      {item.options.map((opt) => {
                        if (!isSearch && opt.value === "-1") return;
                        return (
                          <MenuItem
                            value={opt.value}>
                            {opt.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </FlexColumn>
                {drawnGameVal.map((item) => {
                  return (
                    <span key={item.label}>
                      <label>
                        <Checkbox
                          value={item.value}
                          onChange={drawnGameChange}
                          checked={item.checked}
                          name={item.name}
                          disabled={formData["drawnGameJudge"] !== "1"} />
                        {item.label}
                      </label>
                    </span>
                  );
                })}
              </div>
            )
          }
        })
      }
    </Page>
  );
};

export default forwardRef(RecruitmentDetailSetting);
