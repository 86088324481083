/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRecruitmentHistoryM = /* GraphQL */ `
  query GetRecruitmentHistoryM($id: ID!) {
    getRecruitmentHistoryM(id: $id) {
      id
      usermID
      recruitmentlistmID
      startDateTime
      entryDateTime
      exitDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRecruitmentHistoryMS = /* GraphQL */ `
  query ListRecruitmentHistoryMS(
    $filter: ModelRecruitmentHistoryMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecruitmentHistoryMS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usermID
        recruitmentlistmID
        startDateTime
        entryDateTime
        exitDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRecruitmentHistoryMS = /* GraphQL */ `
  query SyncRecruitmentHistoryMS(
    $filter: ModelRecruitmentHistoryMFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRecruitmentHistoryMS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        usermID
        recruitmentlistmID
        startDateTime
        entryDateTime
        exitDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const recruitmentHistoryMSByUsermIDAndStartDateTime = /* GraphQL */ `
  query RecruitmentHistoryMSByUsermIDAndStartDateTime(
    $usermID: ID!
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecruitmentHistoryMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recruitmentHistoryMSByUsermIDAndStartDateTime(
      usermID: $usermID
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usermID
        recruitmentlistmID
        startDateTime
        entryDateTime
        exitDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const recruitmentHistoryMSByRecruitmentlistmIDAndEntryDateTime = /* GraphQL */ `
  query RecruitmentHistoryMSByRecruitmentlistmIDAndEntryDateTime(
    $recruitmentlistmID: ID!
    $entryDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecruitmentHistoryMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recruitmentHistoryMSByRecruitmentlistmIDAndEntryDateTime(
      recruitmentlistmID: $recruitmentlistmID
      entryDateTime: $entryDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usermID
        recruitmentlistmID
        startDateTime
        entryDateTime
        exitDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserTalkRoomM = /* GraphQL */ `
  query GetUserTalkRoomM($id: ID!) {
    getUserTalkRoomM(id: $id) {
      id
      talkroommID
      usermID
      hidden
      lastViewTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserTalkRoomMS = /* GraphQL */ `
  query ListUserTalkRoomMS(
    $filter: ModelUserTalkRoomMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserTalkRoomMS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        talkroommID
        usermID
        hidden
        lastViewTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserTalkRoomMS = /* GraphQL */ `
  query SyncUserTalkRoomMS(
    $filter: ModelUserTalkRoomMFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserTalkRoomMS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        talkroommID
        usermID
        hidden
        lastViewTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listUserTalkRoomMByTalkroommID = /* GraphQL */ `
  query ListUserTalkRoomMByTalkroommID(
    $talkroommID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserTalkRoomMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserTalkRoomMByTalkroommID(
      talkroommID: $talkroommID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        talkroommID
        usermID
        hidden
        lastViewTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const userTalkRoomMSByUsermID = /* GraphQL */ `
  query UserTalkRoomMSByUsermID(
    $usermID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserTalkRoomMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userTalkRoomMSByUsermID(
      usermID: $usermID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        talkroommID
        usermID
        hidden
        lastViewTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMessageM = /* GraphQL */ `
  query GetMessageM($id: ID!) {
    getMessageM(id: $id) {
      id
      content
      talkroommID
      usermID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMessageMS = /* GraphQL */ `
  query ListMessageMS(
    $filter: ModelMessageMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageMS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        talkroommID
        usermID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMessageMS = /* GraphQL */ `
  query SyncMessageMS(
    $filter: ModelMessageMFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMessageMS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        content
        talkroommID
        usermID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listMessageMSByTalkroommID = /* GraphQL */ `
  query ListMessageMSByTalkroommID(
    $talkroommID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageMSByTalkroommID(
      talkroommID: $talkroommID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        talkroommID
        usermID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const messageMSByUsermID = /* GraphQL */ `
  query MessageMSByUsermID(
    $usermID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageMSByUsermID(
      usermID: $usermID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        talkroommID
        usermID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getNotificationTokenM = /* GraphQL */ `
  query GetNotificationTokenM($id: ID!) {
    getNotificationTokenM(id: $id) {
      id
      usermID
      token
      enabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listNotificationTokenMS = /* GraphQL */ `
  query ListNotificationTokenMS(
    $filter: ModelNotificationTokenMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationTokenMS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usermID
        token
        enabled
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNotificationTokenMS = /* GraphQL */ `
  query SyncNotificationTokenMS(
    $filter: ModelNotificationTokenMFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotificationTokenMS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        usermID
        token
        enabled
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const notificationTokenMSByUsermID = /* GraphQL */ `
  query NotificationTokenMSByUsermID(
    $usermID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationTokenMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationTokenMSByUsermID(
      usermID: $usermID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usermID
        token
        enabled
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRecruitmentListM = /* GraphQL */ `
  query GetRecruitmentListM($id: ID!) {
    getRecruitmentListM(id: $id) {
      id
      title
      startDateTime
      place
      recruitmentNumbers
      detail
      userID
      isEnd
      endDateTime
      participationConditions
      password
      playNum
      gameRoundNum
      gameScore
      redFive
      additionalYaku
      allSimples
      yakuBack
      swapCalling
      noDrawReach
      furitenDraw
      points30Doubles4
      nearestPlayerWin
      moreGameRound
      drawnGame
      additionalRound
      lastWinStop
      minusScore
      north_3
      drawLoss_3
      flower_3
      talkRoomMId
      TalkRoomM {
        id
        roomName
        MessageMS {
          nextToken
          startedAt
        }
        UserTalkRoomM {
          nextToken
          startedAt
        }
        countMessage
        iconSrc
        isDirect
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      RecruitmentHistoryMS {
        items {
          id
          usermID
          recruitmentlistmID
          startDateTime
          entryDateTime
          exitDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRecruitmentListMS = /* GraphQL */ `
  query ListRecruitmentListMS(
    $filter: ModelRecruitmentListMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecruitmentListMS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        startDateTime
        place
        recruitmentNumbers
        detail
        userID
        isEnd
        endDateTime
        participationConditions
        password
        playNum
        gameRoundNum
        gameScore
        redFive
        additionalYaku
        allSimples
        yakuBack
        swapCalling
        noDrawReach
        furitenDraw
        points30Doubles4
        nearestPlayerWin
        moreGameRound
        drawnGame
        additionalRound
        lastWinStop
        minusScore
        north_3
        drawLoss_3
        flower_3
        talkRoomMId
        TalkRoomM {
          id
          roomName
          countMessage
          iconSrc
          isDirect
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        RecruitmentHistoryMS {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRecruitmentListMS = /* GraphQL */ `
  query SyncRecruitmentListMS(
    $filter: ModelRecruitmentListMFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRecruitmentListMS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        startDateTime
        place
        recruitmentNumbers
        detail
        userID
        isEnd
        endDateTime
        participationConditions
        password
        playNum
        gameRoundNum
        gameScore
        redFive
        additionalYaku
        allSimples
        yakuBack
        swapCalling
        noDrawReach
        furitenDraw
        points30Doubles4
        nearestPlayerWin
        moreGameRound
        drawnGame
        additionalRound
        lastWinStop
        minusScore
        north_3
        drawLoss_3
        flower_3
        talkRoomMId
        TalkRoomM {
          id
          roomName
          countMessage
          iconSrc
          isDirect
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        RecruitmentHistoryMS {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const recruitmentListMSByUserIDAndStartDateTime = /* GraphQL */ `
  query RecruitmentListMSByUserIDAndStartDateTime(
    $userID: ID!
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecruitmentListMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recruitmentListMSByUserIDAndStartDateTime(
      userID: $userID
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        startDateTime
        place
        recruitmentNumbers
        detail
        userID
        isEnd
        endDateTime
        participationConditions
        password
        playNum
        gameRoundNum
        gameScore
        redFive
        additionalYaku
        allSimples
        yakuBack
        swapCalling
        noDrawReach
        furitenDraw
        points30Doubles4
        nearestPlayerWin
        moreGameRound
        drawnGame
        additionalRound
        lastWinStop
        minusScore
        north_3
        drawLoss_3
        flower_3
        talkRoomMId
        TalkRoomM {
          id
          roomName
          countMessage
          iconSrc
          isDirect
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        RecruitmentHistoryMS {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const recruitmentListMByIsEnd = /* GraphQL */ `
  query RecruitmentListMByIsEnd(
    $isEnd: String!
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecruitmentListMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recruitmentListMByIsEnd(
      isEnd: $isEnd
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        startDateTime
        place
        recruitmentNumbers
        detail
        userID
        isEnd
        endDateTime
        participationConditions
        password
        playNum
        gameRoundNum
        gameScore
        redFive
        additionalYaku
        allSimples
        yakuBack
        swapCalling
        noDrawReach
        furitenDraw
        points30Doubles4
        nearestPlayerWin
        moreGameRound
        drawnGame
        additionalRound
        lastWinStop
        minusScore
        north_3
        drawLoss_3
        flower_3
        talkRoomMId
        TalkRoomM {
          id
          roomName
          countMessage
          iconSrc
          isDirect
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        RecruitmentHistoryMS {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const recruitmentListMSByTalkRoomMIdAndId = /* GraphQL */ `
  query RecruitmentListMSByTalkRoomMIdAndId(
    $talkRoomMId: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecruitmentListMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recruitmentListMSByTalkRoomMIdAndId(
      talkRoomMId: $talkRoomMId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        startDateTime
        place
        recruitmentNumbers
        detail
        userID
        isEnd
        endDateTime
        participationConditions
        password
        playNum
        gameRoundNum
        gameScore
        redFive
        additionalYaku
        allSimples
        yakuBack
        swapCalling
        noDrawReach
        furitenDraw
        points30Doubles4
        nearestPlayerWin
        moreGameRound
        drawnGame
        additionalRound
        lastWinStop
        minusScore
        north_3
        drawLoss_3
        flower_3
        talkRoomMId
        TalkRoomM {
          id
          roomName
          countMessage
          iconSrc
          isDirect
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        RecruitmentHistoryMS {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCategoryM = /* GraphQL */ `
  query GetCategoryM($id: ID!) {
    getCategoryM(id: $id) {
      id
      categoryName
      recruitmentListId
      master
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCategoryMS = /* GraphQL */ `
  query ListCategoryMS(
    $filter: ModelCategoryMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategoryMS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        categoryName
        recruitmentListId
        master
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCategoryMS = /* GraphQL */ `
  query SyncCategoryMS(
    $filter: ModelCategoryMFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCategoryMS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        categoryName
        recruitmentListId
        master
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const categoryMSByCategoryName = /* GraphQL */ `
  query CategoryMSByCategoryName(
    $categoryName: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoryMSByCategoryName(
      categoryName: $categoryName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryName
        recruitmentListId
        master
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const categoryMSByRecruitmentListIdAndCategoryName = /* GraphQL */ `
  query CategoryMSByRecruitmentListIdAndCategoryName(
    $recruitmentListId: String!
    $categoryName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoryMSByRecruitmentListIdAndCategoryName(
      recruitmentListId: $recruitmentListId
      categoryName: $categoryName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryName
        recruitmentListId
        master
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const categoryMSByMasterAndCategoryName = /* GraphQL */ `
  query CategoryMSByMasterAndCategoryName(
    $master: String!
    $categoryName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoryMSByMasterAndCategoryName(
      master: $master
      categoryName: $categoryName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryName
        recruitmentListId
        master
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserM = /* GraphQL */ `
  query GetUserM($id: ID!) {
    getUserM(id: $id) {
      id
      cognitoUsername
      name
      prefecture
      age
      hobby
      introduction
      skill
      canCalculateScore
      mainMahjongType
      canOperateTable
      favoriteRole
      mahjongStartReason
      mahjongStudyMethod
      RecruitmentLists {
        items {
          id
          title
          startDateTime
          place
          recruitmentNumbers
          detail
          userID
          isEnd
          endDateTime
          participationConditions
          password
          playNum
          gameRoundNum
          gameScore
          redFive
          additionalYaku
          allSimples
          yakuBack
          swapCalling
          noDrawReach
          furitenDraw
          points30Doubles4
          nearestPlayerWin
          moreGameRound
          drawnGame
          additionalRound
          lastWinStop
          minusScore
          north_3
          drawLoss_3
          flower_3
          talkRoomMId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      MessageM {
        items {
          id
          title
          startDateTime
          place
          recruitmentNumbers
          detail
          userID
          isEnd
          endDateTime
          participationConditions
          password
          playNum
          gameRoundNum
          gameScore
          redFive
          additionalYaku
          allSimples
          yakuBack
          swapCalling
          noDrawReach
          furitenDraw
          points30Doubles4
          nearestPlayerWin
          moreGameRound
          drawnGame
          additionalRound
          lastWinStop
          minusScore
          north_3
          drawLoss_3
          flower_3
          talkRoomMId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      UserTalkRoomM {
        items {
          id
          talkroommID
          usermID
          hidden
          lastViewTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      NotificationTokenM {
        items {
          id
          usermID
          token
          enabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      iconSrc
      RecruitmentHistoryMS {
        items {
          id
          usermID
          recruitmentlistmID
          startDateTime
          entryDateTime
          exitDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      identityId
      isOver18
      isAgreeTerms
      isSuspended
      lastLoginAt
      loginDays
      feedbackSent
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserMS = /* GraphQL */ `
  query ListUserMS(
    $filter: ModelUserMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoUsername
        name
        prefecture
        age
        hobby
        introduction
        skill
        canCalculateScore
        mainMahjongType
        canOperateTable
        favoriteRole
        mahjongStartReason
        mahjongStudyMethod
        RecruitmentLists {
          nextToken
          startedAt
        }
        MessageM {
          nextToken
          startedAt
        }
        UserTalkRoomM {
          nextToken
          startedAt
        }
        NotificationTokenM {
          nextToken
          startedAt
        }
        iconSrc
        RecruitmentHistoryMS {
          nextToken
          startedAt
        }
        identityId
        isOver18
        isAgreeTerms
        isSuspended
        lastLoginAt
        loginDays
        feedbackSent
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserMS = /* GraphQL */ `
  query SyncUserMS(
    $filter: ModelUserMFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserMS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        cognitoUsername
        name
        prefecture
        age
        hobby
        introduction
        skill
        canCalculateScore
        mainMahjongType
        canOperateTable
        favoriteRole
        mahjongStartReason
        mahjongStudyMethod
        RecruitmentLists {
          nextToken
          startedAt
        }
        MessageM {
          nextToken
          startedAt
        }
        UserTalkRoomM {
          nextToken
          startedAt
        }
        NotificationTokenM {
          nextToken
          startedAt
        }
        iconSrc
        RecruitmentHistoryMS {
          nextToken
          startedAt
        }
        identityId
        isOver18
        isAgreeTerms
        isSuspended
        lastLoginAt
        loginDays
        feedbackSent
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const userMSByCognitoUsername = /* GraphQL */ `
  query UserMSByCognitoUsername(
    $cognitoUsername: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMSByCognitoUsername(
      cognitoUsername: $cognitoUsername
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUsername
        name
        prefecture
        age
        hobby
        introduction
        skill
        canCalculateScore
        mainMahjongType
        canOperateTable
        favoriteRole
        mahjongStartReason
        mahjongStudyMethod
        RecruitmentLists {
          nextToken
          startedAt
        }
        MessageM {
          nextToken
          startedAt
        }
        UserTalkRoomM {
          nextToken
          startedAt
        }
        NotificationTokenM {
          nextToken
          startedAt
        }
        iconSrc
        RecruitmentHistoryMS {
          nextToken
          startedAt
        }
        identityId
        isOver18
        isAgreeTerms
        isSuspended
        lastLoginAt
        loginDays
        feedbackSent
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTalkRoomM = /* GraphQL */ `
  query GetTalkRoomM($id: ID!) {
    getTalkRoomM(id: $id) {
      id
      roomName
      MessageMS {
        items {
          id
          content
          talkroommID
          usermID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      UserTalkRoomM {
        items {
          id
          talkroommID
          usermID
          hidden
          lastViewTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      countMessage
      iconSrc
      isDirect
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTalkRoomMS = /* GraphQL */ `
  query ListTalkRoomMS(
    $filter: ModelTalkRoomMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTalkRoomMS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        roomName
        MessageMS {
          nextToken
          startedAt
        }
        UserTalkRoomM {
          nextToken
          startedAt
        }
        countMessage
        iconSrc
        isDirect
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTalkRoomMS = /* GraphQL */ `
  query SyncTalkRoomMS(
    $filter: ModelTalkRoomMFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTalkRoomMS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        roomName
        MessageMS {
          nextToken
          startedAt
        }
        UserTalkRoomM {
          nextToken
          startedAt
        }
        countMessage
        iconSrc
        isDirect
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getContactM = /* GraphQL */ `
  query GetContactM($id: ID!) {
    getContactM(id: $id) {
      id
      usermID
      email
      subject
      content
      status
      responseMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listContactMS = /* GraphQL */ `
  query ListContactMS(
    $filter: ModelContactMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactMS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        usermID
        email
        subject
        content
        status
        responseMessage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncContactMS = /* GraphQL */ `
  query SyncContactMS(
    $filter: ModelContactMFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContactMS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        usermID
        email
        subject
        content
        status
        responseMessage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const contactMSByUsermID = /* GraphQL */ `
  query ContactMSByUsermID(
    $usermID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContactMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactMSByUsermID(
      usermID: $usermID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usermID
        email
        subject
        content
        status
        responseMessage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFeedbackM = /* GraphQL */ `
  query GetFeedbackM($id: ID!) {
    getFeedbackM(id: $id) {
      id
      usermID
      rating
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFeedbackMS = /* GraphQL */ `
  query ListFeedbackMS(
    $filter: ModelFeedbackMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbackMS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        usermID
        rating
        content
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFeedbackMS = /* GraphQL */ `
  query SyncFeedbackMS(
    $filter: ModelFeedbackMFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFeedbackMS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        usermID
        rating
        content
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const feedbackMSByUsermID = /* GraphQL */ `
  query FeedbackMSByUsermID(
    $usermID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedbackMSByUsermID(
      usermID: $usermID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usermID
        rating
        content
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMatchingReserveM = /* GraphQL */ `
  query GetMatchingReserveM($id: ID!) {
    getMatchingReserveM(id: $id) {
      id
      usermID
      age
      prefecture
      place
      minAge
      maxAge
      playNum
      skill
      requiredSkill
      desiredDate
      registerDate
      status
      matchingMId
      noticeMail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMatchingReserveMS = /* GraphQL */ `
  query ListMatchingReserveMS(
    $filter: ModelMatchingReserveMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMatchingReserveMS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usermID
        age
        prefecture
        place
        minAge
        maxAge
        playNum
        skill
        requiredSkill
        desiredDate
        registerDate
        status
        matchingMId
        noticeMail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMatchingReserveMS = /* GraphQL */ `
  query SyncMatchingReserveMS(
    $filter: ModelMatchingReserveMFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMatchingReserveMS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        usermID
        age
        prefecture
        place
        minAge
        maxAge
        playNum
        skill
        requiredSkill
        desiredDate
        registerDate
        status
        matchingMId
        noticeMail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const matchingReserveMSByUsermID = /* GraphQL */ `
  query MatchingReserveMSByUsermID(
    $usermID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMatchingReserveMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    matchingReserveMSByUsermID(
      usermID: $usermID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usermID
        age
        prefecture
        place
        minAge
        maxAge
        playNum
        skill
        requiredSkill
        desiredDate
        registerDate
        status
        matchingMId
        noticeMail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const matchingReserveMSByStatusAndRegisterDate = /* GraphQL */ `
  query MatchingReserveMSByStatusAndRegisterDate(
    $status: String!
    $registerDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMatchingReserveMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    matchingReserveMSByStatusAndRegisterDate(
      status: $status
      registerDate: $registerDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usermID
        age
        prefecture
        place
        minAge
        maxAge
        playNum
        skill
        requiredSkill
        desiredDate
        registerDate
        status
        matchingMId
        noticeMail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const matchingReserveMSByMatchingMId = /* GraphQL */ `
  query MatchingReserveMSByMatchingMId(
    $matchingMId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMatchingReserveMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    matchingReserveMSByMatchingMId(
      matchingMId: $matchingMId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usermID
        age
        prefecture
        place
        minAge
        maxAge
        playNum
        skill
        requiredSkill
        desiredDate
        registerDate
        status
        matchingMId
        noticeMail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMatchingM = /* GraphQL */ `
  query GetMatchingM($id: ID!) {
    getMatchingM(id: $id) {
      id
      userm1ID
      user1Confirm
      userm2ID
      user2Confirm
      userm3ID
      user3Confirm
      userm4ID
      user4Confirm
      dueDate
      matchingDate
      matchingPlace
      status
      talkRoomMId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMatchingMS = /* GraphQL */ `
  query ListMatchingMS(
    $filter: ModelMatchingMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMatchingMS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userm1ID
        user1Confirm
        userm2ID
        user2Confirm
        userm3ID
        user3Confirm
        userm4ID
        user4Confirm
        dueDate
        matchingDate
        matchingPlace
        status
        talkRoomMId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMatchingMS = /* GraphQL */ `
  query SyncMatchingMS(
    $filter: ModelMatchingMFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMatchingMS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userm1ID
        user1Confirm
        userm2ID
        user2Confirm
        userm3ID
        user3Confirm
        userm4ID
        user4Confirm
        dueDate
        matchingDate
        matchingPlace
        status
        talkRoomMId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const matchingMSByStatus = /* GraphQL */ `
  query MatchingMSByStatus(
    $status: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMatchingMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    matchingMSByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userm1ID
        user1Confirm
        userm2ID
        user2Confirm
        userm3ID
        user3Confirm
        userm4ID
        user4Confirm
        dueDate
        matchingDate
        matchingPlace
        status
        talkRoomMId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBlockedUsersM = /* GraphQL */ `
  query GetBlockedUsersM($id: ID!) {
    getBlockedUsersM(id: $id) {
      id
      usermID
      blockedUsermID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBlockedUsersMS = /* GraphQL */ `
  query ListBlockedUsersMS(
    $filter: ModelBlockedUsersMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlockedUsersMS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        usermID
        blockedUsermID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBlockedUsersMS = /* GraphQL */ `
  query SyncBlockedUsersMS(
    $filter: ModelBlockedUsersMFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBlockedUsersMS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        usermID
        blockedUsermID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const blockedUsersMSByUsermID = /* GraphQL */ `
  query BlockedUsersMSByUsermID(
    $usermID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBlockedUsersMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blockedUsersMSByUsermID(
      usermID: $usermID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usermID
        blockedUsermID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const blockedUsersMSByBlockedUsermID = /* GraphQL */ `
  query BlockedUsersMSByBlockedUsermID(
    $blockedUsermID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBlockedUsersMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blockedUsersMSByBlockedUsermID(
      blockedUsermID: $blockedUsermID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usermID
        blockedUsermID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
