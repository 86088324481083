import '../../App.css';
import { RecruitmentForm } from '../../common-my-ui-components';
import useSaveLastViewRecruitmentPage from '../../my-func/useSaveLastViewRecruitmentPage';

const RegisterRecruitment = () => {
  useSaveLastViewRecruitmentPage();

  return (
    <div >
      <RecruitmentForm/>
    </div>
  );
};

export default RegisterRecruitment;
