import React from "react";
import styled from "styled-components";

const StyledFlex = styled.div`
  width: ${props => props.size || '2.5rem'};
  height: ${props => props.size || '2.5rem'};
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

export default function UserIconImage(props) {
  const { userM,size,...rest } = props;
  return (
    <StyledFlex size={size} {...rest}>
      <StyledImage src={userM?.iconSrc} alt="" />
    </StyledFlex>
  );
}
