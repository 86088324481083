import {listUserTalkRoomMS,userTalkRoomMSByUsermID } from '../graphql/queries';
import { API } from "aws-amplify";


// ユーザが参加しているトークルームの一覧を取得
const getJoinedTalkRooms = async (userID) => {
  
  let nextToken = null,joinedTalkRooms=[];

  do {
    const opt1 = {
        limit: 1000,
        usermID: userID,
        nextToken:nextToken,
      }
      await API.graphql({
        query: userTalkRoomMSByUsermID,
        variables: opt1,
      }).then(result => {
        joinedTalkRooms= joinedTalkRooms.concat(result.data.userTalkRoomMSByUsermID.items);
        nextToken=result.data.userTalkRoomMSByUsermID.nextToken;
    });
  } while (nextToken); // nextTokenがnullになるまで繰り返します

    const unDeletedJoinedTalkRooms= joinedTalkRooms.filter((item)=>{
      return item._deleted!=true;
    });

  return unDeletedJoinedTalkRooms;
};


export { getJoinedTalkRooms };