import '@aws-amplify/ui-react/styles.css';
import '../App.css';
import { useState ,useContext} from 'react';
import UserContext from "../UserContext";
import MessageContext from "../MessageContext";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Button } from '@mui/material';
import { Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, CircularProgress } from '@mui/material';
import styled from 'styled-components';

const Text = styled.div`
text-align:left
`;

const Center = styled.table`
margin: auto; /* サークルを中央に配置 */
`;

const DeleteCognitoUserForm = ({ deleteUser }) => {
  // 処理後に各画面に遷移させる
  const navigate = useNavigate();

  const { user } = useContext(UserContext);
  const { setErrorMessage } = useContext(MessageContext);

  // 削除ダイアログ表示フラグ
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  // ダイアログ操作
  const handleDeleteDialog = () => {
    setIsOpenDeleteDialog(!isOpenDeleteDialog);
  }

  // 削除手続きダイアログ表示フラグ
  const [isLoading, setIsLoading] = useState(false);
  // ダイアログ操作
  const handleLoading = () => {

    setIsLoading(!isLoading);
  }
  const closeLoading = () => {
    setIsLoading(false);
  }

  const handleDelete = async () => {
    handleDeleteDialog();
    handleLoading();
    // 裏のDB処理結果が200で成功ならcognitを削除する
    if(deleteUserBackend()){
      await new Promise((resolve) => setTimeout(resolve, 2000))
      navigate('/');
      deleteUser();
    }
    await new Promise((resolve) => setTimeout(resolve, 2000))
    closeLoading();
  };

  /**
   * 退会処理に伴うユーザー情報の削除
   * @param {RecruitmentListM} RecruitmentInfo 募集情報
   * @param {categoryInfo} categoryInfo カテゴリ情報
   * @param {userId} userId ユーザー情報
   * @returns {String} id 募集ID
   */
  const deleteUserBackend = async () => {
    // ヘッダーの作成
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // リクエスト情報の作成
    var raw = JSON.stringify({
      "env": process.env.REACT_APP_NOW_ENV,
      "userID": user.id
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    // APIを実行
    try {
      const response = await fetch(process.env.REACT_APP_DELETE_USERBACKEND_API, requestOptions);
      const result = await response.text();
  
      if ((JSON.parse(result).statusCode) !== 200) throw new Error();
      return true;
    } catch (error) {
      setErrorMessage(<div>
        退会処理時にエラーが発生しました。<br />
        しばらくしてからもう一度お試しください。
      </div>);
      return false;
    }
  };

  return (
    <>
      <Text>
        雀の巣をご利用いただき、ありがとうございます。<br />
        退会をご希望の方は注意事項をご確認の上、「退会」ボタンを押してください。<br />
        <br />
        【注意事項】<br />
        ・退会をすると雀の巣のご登録情報はすべて削除されます。ご登録情報は即時削除されるため、アカウントの復旧はできません。<br />
        ・作成した募集は削除、参加中の募集やトークは退出されます。<br />
      </Text>
      <Button onClick={handleDeleteDialog}  >退会</Button>

      {/* 募集終了ダイアログ */}
      <Dialog open={isOpenDeleteDialog} >
        <DialogTitle>雀の巣 退会フォーム</DialogTitle>
        <DialogContent>
          <DialogContentText>
            本当に雀の巣を退会しますか？<br />
            サイトの改善要望等は<Link to="/Contact" >お問い合わせ</Link>よりご相談いただくことで解決することがあります。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialog}>キャンセル</Button>
          <Button onClick={handleDelete}>退会する</Button>
        </DialogActions>
      </Dialog>

      {/* 退会処理ダイアログ */}
      <Dialog open={isLoading}>
        <DialogTitle>退会手続き中</DialogTitle>
        <DialogContent>
          <Center>
            <CircularProgress />
          </Center>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DeleteCognitoUserForm;
