import {getUserM ,listUserTalkRoomMByTalkroommID} from '../graphql/queries';
import { API } from "aws-amplify";

const getUserMs = async (talkRoomID) => {
  let userMs = [];
  let userTalkRoomMs=[];
  let nextToken=null;

  do{
    const opt = {
      talkroommID: talkRoomID ,
      nextToken: nextToken
    }
    await API.graphql({query:listUserTalkRoomMByTalkroommID, variables:opt}).then((result)=>{
      userTalkRoomMs=userTalkRoomMs.concat(result.data.listUserTalkRoomMByTalkroommID.items);
      nextToken=result.data.listUserTalkRoomMByTalkroommID.nextToken;
    });
    
  } while(nextToken);
  
  userTalkRoomMs=userTalkRoomMs.filter((item)=>item._deleted!=true);

  await Promise.all(
    userTalkRoomMs.map(async (userTalkRoomM) => {
      const userM = await API.graphql({
        query: getUserM,
        variables: { id: userTalkRoomM.usermID },
      });
      if(userM.data.getUserM!=null){
        userMs.push(userM.data.getUserM);
      }
    })
  );
  const sortedUserMs = userMs.sort((a, b) => a.name.localeCompare(b.name));
  
  return sortedUserMs;
};

export { getUserMs };