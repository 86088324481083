// apiUtils.js
import { API } from "aws-amplify";
import {
  getTalkRoomM,
  listMessageMSByTalkroommID,
} from "../../graphql/queries";
import { getRoomName } from "../../my-func/getRoomName";

// トークルームの情報を取得
export const fetchTalkRoomInfo = async (userId, talkRoomId) => {
  const opt2 = { id: talkRoomId };
  const talkRoomM = (
    await API.graphql({ query: getTalkRoomM, variables: opt2 })
  ).data.getTalkRoomM;
  talkRoomM.roomName = await getRoomName(userId, talkRoomM.id);
  return talkRoomM;
};

// 最新のメッセージを取得
export const fetchLatestMessage = async (talkRoomId) => {
  const opt = { talkroommID: talkRoomId, limit: 100, sortDirection: "DESC" };
  const recentMessages = (
    await API.graphql({ query: listMessageMSByTalkroommID, variables: opt })
  ).data.listMessageMSByTalkroommID.items;
  return recentMessages;
};
