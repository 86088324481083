import '@aws-amplify/ui-react/styles.css';
import '../../../App.css';
import { Grid, Flex, View } from '@aws-amplify/ui-react';
import { styled } from 'styled-components';
import { yyyyMMddHHmmFormatter } from '../../../my-func/dateTimeFormatter'
import { useState, useEffect, useContext } from 'react';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import { getCategoryByRecruitmentListId } from '../../../my-func/CategoryManager'
import MessageContext from "../../../MessageContext";
import { Link } from "react-router-dom";

// ページ全体の設定
const Page = styled.div`
width: 100%;  
padding: 0 0.5rem;
`;

// Gridの設定
const GridStyle = styled(Grid)`
text-align: left;
margin: 0.5rem 0;
`;

// 募集カードの設定
const Recruitment = styled.div`
width: 100%; 
padding: 0.5rem 1rem;
margin: 0.5rem 0;
background: ${(props) => (props.theme.palette.quinary.main)};
border: solid 1px ${(props) => (props.theme.palette.quaternary.main)};
border-radius: 10px;
`;

// 募集ステータスの表示
const IsEnd = styled.div`
width:5em;
padding: 0.5em 0.5em;
background: ${(props) => (props.isEnd === '募集中' ?
    props.theme.palette.primaryred.main : props.theme.palette.disabled.main)};
border-radius: 10px;
color:${(props) => (props.theme.palette.text.white)};
font-size: clamp(16px, 3vw, 20px);
font-weight: 600;
text-align: center;
margin-right: auto;
`;


// 募集名など見出しに適用
const Title = styled.div`
padding: 0em 0.5em;
width: 100%;
line-height: 160%;
font-size: clamp(16px, 3vw, 20px);
font-weight: 700;
text-align: left;
overflow: hidden;
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 2; /* 任意の行数を指定 */
}
`;

//項目タイトルの設定
const ItemTitleStyle = styled.div`
text-align: left;
white-space: break-spaces;
font-size: clamp(12px, 1.1vw, 16px);
overflow: hidden;
display: -webkit-box;
-webkit-box-orient: vertical;
`;

//項目内容の設定
const ItemContentStyle = styled.div`
text-align: left;
white-space: break-spaces;
font-size: clamp(12px, 1.1vw, 16px);
overflow: hidden;
display: -webkit-box;
-webkit-box-orient: vertical;
`;

const StartDateTime = styled.div`
margin: 0.25rem 0;
text-align: right;
white-space: break-spaces;
font-size: clamp(10px, 1.1vw, 12px);
overflow: hidden;
display: -webkit-box;
-webkit-box-orient: vertical;
`;

const LinkStyle = styled(Link)`
text-decoration: none;
color: inherit;
`;

const RecruitmentHeadLine = (props) => {
  // 引数で募集情報を受け取る
  const { recruitment } = props;
  const [formattedRecruitment, setFormattedRecruitment] = useState(null);
  const [formattedCategory, setFormattedCategory] = useState(null);
  const { setErrorMessage } = useContext(MessageContext);

  // 募集に紐づいたカテゴリを取得する
  async function getCategory() {
    const categoryList = await getCategoryByRecruitmentListId(recruitment.id, setErrorMessage);
    const formattedCategory = []
    if (categoryList) {
      for (let item of categoryList) {
        formattedCategory.push(
          <LinkStyle to={`/RecruitmentList/isCategoryTab/` + item.categoryName}>
            <Chip sx={{ fontSize: 'clamp(12px, 1.1vw, 16px)', margin: '0.3rem;' }} label={item.categoryName} color="primary" />
          </LinkStyle>
        )
      }
    }
    setFormattedCategory(formattedCategory);
  }

  useEffect(() => {
    const formattedRecruitment = {
      ...recruitment,
      startDateTime: yyyyMMddHHmmFormatter(new Date(recruitment.startDateTime)),
      endDateTime: yyyyMMddHHmmFormatter(new Date(recruitment.endDateTime))
    };

    // 募集の状態
    if (new Date(recruitment.endDateTime) < new Date()) {
      formattedRecruitment.isEnd = "1";
    }
    if (formattedRecruitment.isEnd === "1") {
      formattedRecruitment.isEnd = "募集終了"
    } else {
      formattedRecruitment.isEnd = "募集中"
    }

    // 募集詳細が長い場合は切り取る
    // 全体で300文字　改行5行までに制限
    const firstFiveLines = formattedRecruitment.detail.substr(0, 300).split('\n').slice(0, 5);
    const formattedDetail = firstFiveLines.join('\n');
    if (formattedRecruitment.detail !== formattedDetail) formattedRecruitment.detail = formattedDetail + "...";

    // 参加条件がない時
    if (!formattedRecruitment.participationConditions) {
      formattedRecruitment.participationConditions = "特になし";
    }

    // 募集に紐づいたカテゴリを取得する
    getCategory();

    // 上書きした値をコンポーネントのローカルステートにセット
    setFormattedRecruitment(formattedRecruitment);
  }, [recruitment]);


  // formattedRecruitmentがnullの場合はロード中の表示
  if (!formattedRecruitment) {
    return <Skeleton variant="rounded" width={'100%'} height={'10em'} />;
  }

  return (
    <Page>
      <Recruitment>
        <GridStyle alignItems="center" templateColumns={{ base: '2fr 8fr', large: "3fr 17fr" }}>
          <IsEnd isEnd={formattedRecruitment.isEnd}>{formattedRecruitment.isEnd}</IsEnd>
          <Title>{formattedRecruitment.title}</Title>
        </GridStyle>
        <Divider />
        <GridStyle alignItems="center" templateColumns={{ base: '3fr 7fr', large: "3fr 7fr 3fr 7fr" }}>
          <ItemTitleStyle>募集終了日</ItemTitleStyle>
          <ItemContentStyle>{formattedRecruitment.endDateTime}</ItemContentStyle>
          <ItemTitleStyle>場所</ItemTitleStyle>
          <ItemContentStyle>{formattedRecruitment.place}</ItemContentStyle>
          <ItemTitleStyle>募集人数</ItemTitleStyle>
          <ItemContentStyle>{formattedRecruitment.recruitmentNumbers + "人"}</ItemContentStyle>
          <ItemTitleStyle>参加条件</ItemTitleStyle>
          <ItemContentStyle>{formattedRecruitment.participationConditions}</ItemContentStyle>
        </GridStyle>


          <ItemTitleStyle>詳細説明</ItemTitleStyle>
          <Divider />
          <ItemContentStyle>
            {formattedRecruitment.detail}
          </ItemContentStyle>

        {formattedCategory}
        <StartDateTime>募集作成日：{formattedRecruitment.startDateTime}</StartDateTime>
      </Recruitment>

    </Page>
  );
};

export default RecruitmentHeadLine;
