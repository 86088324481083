import { TalkRoomInfo, TalkRoomItem } from "./my-ui-components"
import { Link } from "react-router-dom"
import { yyyyMMddHHmmFormatter } from "../../my-func/dateTimeFormatter"
import { getJoinedTalkRooms } from "../../my-func/getJoinedTalkRooms"
import { styled } from "styled-components"
import { deleteDataFromIndexedDB, openIndexedDB, saveToIndexedDB, fetchFromIndexedDB } from "../../my-func/indexedDBUtils"
import { setHiddenUserTalkRoom } from "../../my-func/userTalkRoomManager"
import { limitString } from "../../my-func/strUtils"
import { fetchTalkRoomInfo, fetchLatestMessage } from "./apiUtils"

const dbName = "TalkRoomList"
const storeName = "TalkRoomList"
const keyPath = "talkRoomId"

const TalkRoomLink = styled(Link)`
  width: auto;
`

const MAX_MESSAGE_LIMIT = 100
const MAX_MESSAGE_COUNT = "99+"

// 未読メッセージの数を取得
export const getUnreadMessageCount = (recentMessages, lastViewTime) => {
  let count = recentMessages.reduce((count, recentMessage) => (recentMessage.createdAt > lastViewTime ? count + 1 : count), 0)
  return count >= MAX_MESSAGE_LIMIT ? MAX_MESSAGE_COUNT : count
}

// トークルーム情報を整形
export const formatTalkRoomInfo = (
  talkRoomM,
  recentMessages,
  countUnreadMessage,
  setShowModal,
  setDeleteTalkRoomID,
  setDeleteTalkRoomName
) => {
  let timer

  const handlePointerDown = (e) => {
    e.preventDefault()
    timer = setTimeout(() => {
      setDeleteTalkRoomID(talkRoomM.id)
      const limitedRoomName = limitString(talkRoomM.roomName)
      setDeleteTalkRoomName(limitedRoomName)
      setShowModal(true)
    }, 300)
  }

  const handlePointerUp = () => {
    clearTimeout(timer)
  }
  let latestMessageM = null
  if (recentMessages.length > 0) {
    latestMessageM = { ...recentMessages[0] }
    latestMessageM.createdAt = yyyyMMddHHmmFormatter(new Date(latestMessageM.createdAt))
  }

  return {
    body: (
      <TalkRoomItem
        talkRoomM={talkRoomM}
        latestMessageM={latestMessageM}
        countNewMessage={countUnreadMessage}
        onPointerUp={handlePointerUp}
        onPointerDown={handlePointerDown}
      />
    ),
    createdAt: latestMessageM ? new Date(latestMessageM.createdAt) : new Date(talkRoomM.createdAt)
  }
}

export const getTalkRoom = async (userId, joinedTalkRoom) => {
  const talkRoomM = await fetchTalkRoomInfo(userId, joinedTalkRoom.talkroommID)
  const recentMessages = await fetchLatestMessage(joinedTalkRoom.talkroommID)
  const countUnreadMessage = getUnreadMessageCount(recentMessages, joinedTalkRoom.lastViewTime)

  // joinedTalkRoomのhiddenがtrue(手動削除したトークルーム)の除外、ただし新規メッセージ（未読メッセージ）がある場合は除外せず、手動削除のフラグをfalseに変更する。
  if (joinedTalkRoom.hidden == true && countUnreadMessage == 0) {
    openIndexedDB(dbName, userId, keyPath).then((db) => {
      deleteDataFromIndexedDB(db, userId, talkRoomM.id)
    })
    return null
  } else if (joinedTalkRoom.hidden == true && countUnreadMessage > 0) {
    setHiddenUserTalkRoom(joinedTalkRoom.id, false)
  }

  const tempData = {
    talkRoomId: talkRoomM.id,
    talkRoomM: talkRoomM,
    recentMessages: recentMessages,
    countUnreadMessage,
    countUnreadMessage
  }

  openIndexedDB(dbName, userId, keyPath).then((db) => {
    saveToIndexedDB(db, userId, tempData)
  })

  return {
    talkRoomM: talkRoomM,
    recentMessages: recentMessages,
    countUnreadMessage: countUnreadMessage
  }
}
