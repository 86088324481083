import '@aws-amplify/ui-react/styles.css';
import '../../App.css';
import { Grid, View } from '@aws-amplify/ui-react';
import { styled } from 'styled-components';
import { useState, useEffect, useContext } from 'react';
import { getMatchingReserveMByUsermID } from '../../my-func/MatchingManager'
import MessageContext from "../../MessageContext";
import { MatchingForm } from "../../common-my-ui-components";
import UserContext from "../../UserContext";

// ページ全体の設定
const Page = styled.div`
width: 100%;  
padding: 1rem;
margin-bottom: 1rem;
margin-top: 1rem;
`;

const MatchingRegister = (props) => {

  const { user } = useContext(UserContext);
  const { setErrorMessage } = useContext(MessageContext);

  // マッチング予約
  const [matchingReserve, setMatchingReserve] = useState(null);

  const getMatchingReserve = async () => {
    const matchingReserve = (await getMatchingReserveMByUsermID(user.id, setErrorMessage))[0]
    setMatchingReserve(matchingReserve);
  }

  useEffect(() => {
    getMatchingReserve();
  }, []);



  if (matchingReserve === null) {
    return (<></>)
  } if (matchingReserve) {
    return (<>すでにマッチングが登録済みです。</>)
  }

  return (
    <Page>
      <Grid templateColumns={{ base: '1fr', large: "2fr 8fr 2fr" }}>
        <View rowSpan={1}>{/* PC時の両脇 空白スペース */}</View>
        <MatchingForm />
        <View rowSpan={1}>{/* PC時の両脇 空白スペース */}</View>
      </Grid>
    </Page>
  );
};

export default MatchingRegister;
