import { API, graphqlOperation } from "aws-amplify";
import { createCategoryM } from '../graphql/mutations';
import { RecruitmentListM } from '../models';
import { categoryMSByMasterAndCategoryName, categoryMSByRecruitmentListIdAndCategoryName } from '../graphql/queries'

/**
 * 新規カテゴリを作成する
 * 現在このメソッドは使用していません。ユーザーにカテゴリの追加機能を解放後に使用します。
 * @param {String} categoryName カテゴリ名
 * @returns {RecruitmentListM} newCreateCategoryM 作成したカテゴリ情報
 * 
 * @async
 * @function createCategory
 */
const createCategory = async (categoryName, setErrorMessage) => {
  try {
    // カテゴリ作成
    //すでにマスタが作られている場合は作成しない
    const opt = {
      master: "1",
      sortDirection: "ASC",
      filter: {
        or: [{ categoryName: { eq: categoryName } }]
      }
    };
    const category = (await API.graphql(graphqlOperation(categoryMSByMasterAndCategoryName, opt)))
      .data.categoryMSByMasterAndCategoryName.items;
    if (category.length != 0) return categoryName;

    //カテゴリが未作成のため新規作成する
    var newCreateCategoryM = await API.graphql(
      graphqlOperation(createCategoryM, {
        input: {
          categoryName: categoryName,
          master: '1'
        }
      })
    );
    return categoryName;
  } catch (error) {
    setErrorMessage(<div>
      カテゴリ作成時にエラーが発生しました。<br />
      しばらくしてからもう一度お試しください。
    </div>);
  }
};

/**
 * 募集IDからカテゴリを取得する
 * @param {String} categoryName カテゴリ名
 * @param {String} recruitmentListId 募集ID
 * @returns {RecruitmentListM} newCreateCategoryM 作成したカテゴリ情報
 * 
 * @async
 * @function createCategory
 */
const getCategoryByRecruitmentListId = async (recruitmentListId, setErrorMessage) => {
  try {
    // カテゴリ取得
    const opt = {
      recruitmentListId: recruitmentListId,
      sortDirection: "ASC"
    };
    return (await API.graphql(graphqlOperation(categoryMSByRecruitmentListIdAndCategoryName, opt)))
      .data.categoryMSByRecruitmentListIdAndCategoryName.items.filter((item) => item._deleted != true);
  } catch (error) {
    setErrorMessage(<div>
      カテゴリ情報取得時にエラーが発生しました。<br />
      しばらくしてからもう一度お試しください。
    </div>);
  }
};

export { createCategory, getCategoryByRecruitmentListId };
