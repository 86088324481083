import React from 'react';
import { PageSelecter } from './my-ui-components';
import { useEffect, useState, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Grid, Flex, View, useBreakpointValue } from '@aws-amplify/ui-react';
import { CommonAdSense } from '../../common-my-ui-components'
import { styled } from 'styled-components';

const Page = styled.div`
width:100%;
`

const Contents = styled.div`
width:100%;
max-width: 980px;
`

const RuleIntroduction = () => {
  // 入門ページIDを取得
  var { pageID } = useParams();
  const [page, setPage] = useState(null);

  useEffect(() => {
    if (!pageID) pageID = "MahjongIntroduction";
    setPage(pageID);
    window.location.href = "#start";
  }, [pageID]);

  return (
    <Page id="start">
      {/* PC時に中央の66%にコンテンツを配置する 最大横幅は980px*/}
      <Grid templateColumns={{ base: '1fr', large: "2fr 8fr 2fr" }}>
        <View rowSpan={1}>{/* PC時の両脇 空白スペース */}<CommonAdSense position="left" /></View>
        <View rowSpan={1}>
          <Flex direction={"column"} alignItems={'center'}>
            <Contents>
              <PageSelecter page={page} />
            </Contents>
          </Flex>
        </View >
        <View rowSpan={1}>{/* PC時の両脇 空白スペース */}<CommonAdSense position="right" /></View>
      </Grid >
    </Page>
  );
}

export default RuleIntroduction;
