
/**
 * 入力チェック
 * 英数字、スペース、ハイフン、アンダースコア、日本語文字（ひらがな、カタカナ、漢字）のみで構成されている
 * @param {String} input チェック対象
 * @return {Boolean} true:汎用文字列のみで構成されている false:特殊文字列を含む 
 */
const checkValidGeneralString = (input) => {
  return /^[a-zA-Z0-9\s-_ぁ-んァ-ヶ一-龥々ー]+$/.test(input.target);
};

/**
 * 入力チェック
 * 空白のみで構成されている
 * @param {String} input チェック対象
 * @return {Boolean} true:空白のみで構成されている false:空白以外を含む 
 */
const checkValidWhitespaceOnly = (input) => {
  return /^\s*$/.test(input)&& input.length !== 0;
};

/**
 * 入力チェック
 * パスワード用の入力チェック
 * 半角英数時と記号(@$!%*?&)のみで構成されている
 * @param {String} input チェック対象
 * @return {Boolean} true:半角英数時と記号(@$!%*?&)のみで構成されている false:半角英数時と記号(@$!%*?&)以外を含む 
 */
const checkValidPassword = (input) => {
  return /^(?=.*[a-zA-Z\d@$!%*?&])[a-zA-Z\d@$!%*?&]+$/.test(input);
};

/**
 * 入力チェック
 * 整数数字のみで構成されている
 * @param {String} input チェック対象
 * @return {Boolean} true:整数数字のみで構成されている false:整数数字以外を含む 
 */
const checkValidOnlyNumber = (input) => {
  return /^\d+$/.test(input);
};

export { checkValidGeneralString, checkValidWhitespaceOnly, checkValidPassword, checkValidOnlyNumber };
