// 指定しない初期設定
const value0 = {
  playNum: "-1",
  gameRoundNum: "-1",
  gameScoreJudge: "-1",
  gameScore: "25000",
  redFive: "-1",
  additionalYaku: "-1",
  allSimples: "-1",
  yakuBack: "-1",
  swapCalling: "-1",
  noDrawReach: "-1",
  furitenDraw: "-1",
  points30Doubles4: "-1",
  nearestPlayerWin: "-1",
  moreGameRound: "-1",
  drawnGameJudge: "-1",
  drawnGame: "0",
  additionalRoundJudge: "-1",
  additionalRound: "30000",
  lastWinStop: "-1",
  minusScore: "-1",
  north_3: "-1",
  drawLoss_3: "-1",
  flower_3: "-1"
};

// Mリーグ
const value1MLeague = {
  playNum: "4",
  gameRoundNum: "2",
  gameScoreJudge: "1",
  gameScore: "25000",
  redFive: "1",
  additionalYaku: "1",
  allSimples: "1",
  yakuBack: "1",
  swapCalling: "0",
  noDrawReach: "1",
  furitenDraw: "1",
  points30Doubles4: "1",
  nearestPlayerWin: "1",
  moreGameRound: "2",
  drawnGameJudge: "0",
  drawnGame: "0",
  additionalRoundJudge: "0",
  additionalRound: "0",
  lastWinStop: "0",
  minusScore: "0",
  north_3: "",
  drawLoss_3: "",
  flower_3: ""
};

// 最高位戦
const value2Saikouisen = {
  playNum: "4",
  gameRoundNum: "2",
  gameScoreJudge: "1",
  gameScore: "30000",
  redFive: "0",
  additionalYaku: "1",
  allSimples: "1",
  yakuBack: "1",
  swapCalling: "0",
  noDrawReach: "1",
  furitenDraw: "1",
  points30Doubles4: "1",
  nearestPlayerWin: "1",
  moreGameRound: "2",
  drawnGameJudge: "0",
  drawnGame: "0",
  additionalRoundJudge: "0",
  additionalRound: "0",
  lastWinStop: "0",
  minusScore: "0",
  north_3: "",
  drawLoss_3: "",
  flower_3: ""
};

// 日本プロ麻雀連盟
const value3Jpml = {
  playNum: "4",
  gameRoundNum: "2",
  gameScoreJudge: "1",
  gameScore: "30000",
  redFive: "0",
  additionalYaku: "0",
  allSimples: "1",
  yakuBack: "1",
  swapCalling: "2",
  noDrawReach: "1",
  furitenDraw: "1",
  points30Doubles4: "0",
  nearestPlayerWin: "1",
  moreGameRound: "2",
  drawnGameJudge: "0",
  drawnGame: "0",
  additionalRoundJudge: "0",
  additionalRound: "0",
  lastWinStop: "0",
  minusScore: "0",
  north_3: "",
  drawLoss_3: "",
  flower_3: ""
};

// 日本プロ麻雀協会
const value4Npm = {
  playNum: "4",
  gameRoundNum: "2",
  gameScoreJudge: "1",
  gameScore: "25000",
  redFive: "0",
  additionalYaku: "1",
  allSimples: "1",
  yakuBack: "1",
  swapCalling: "0",
  noDrawReach: "1",
  furitenDraw: "1",
  points30Doubles4: "1",
  nearestPlayerWin: "1",
  moreGameRound: "2",
  drawnGameJudge: "0",
  drawnGame: "0",
  additionalRoundJudge: "0",
  additionalRound: "0",
  lastWinStop: "0",
  minusScore: "0",
  north_3: "",
  drawLoss_3: "",
  flower_3: ""
};

// ザンリーグ
const value5ThanmLeague = {
  playNum: "3",
  gameRoundNum: "2",
  gameScoreJudge: "1",
  gameScore: "50000",
  redFive: "4",
  additionalYaku: "1",
  allSimples: "1",
  yakuBack: "1",
  swapCalling: "0",
  noDrawReach: "1",
  furitenDraw: "0",
  points30Doubles4: "1",
  nearestPlayerWin: "0",
  moreGameRound: "2",
  drawnGameJudge: "0",
  drawnGame: "0",
  additionalRoundJudge: "0",
  additionalRound: "0",
  lastWinStop: "0",
  minusScore: "1",
  north_3: "2",
  drawLoss_3: "0",
  flower_3: "1"
};

// valueによってテンプレートをそれぞれ返却
const getRecruitmentListDetailTemplate = (value) => {
  if(value === '0'){
    return value0;
  }else if(value === '1'){
    return value1MLeague;
  }else if(value === '2'){
    return value2Saikouisen;
  }else if(value === '3'){
    return value3Jpml;
  }else if(value === '4'){
    return value4Npm;
  }else if(value === '5'){
    return value5ThanmLeague;
  }

};


export { getRecruitmentListDetailTemplate };