import { RecruitmentListM } from '../models';
import { getCategoryByRecruitmentListId } from '../my-func/CategoryManager'

/**
 * 募集作成処理
 * @param {RecruitmentListM} RecruitmentInfo 募集情報
 * @param {categoryInfo} categoryInfo カテゴリ情報
 * @param {userId} userId ユーザー情報
 * @returns {String} id 募集ID
 */
const createRecruitment = async (RecruitmentInfo, categoryInfo, userId, setErrorMessage) => {
  // ヘッダーの作成
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  // リクエスト情報の作成
  var raw = JSON.stringify({
    "env": process.env.REACT_APP_NOW_ENV,
    "RecruitmentInfo": RecruitmentInfo,
    "categoryInfo": categoryInfo,
    "userId": userId
  });
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  // APIを実行
  try {
    const response = await fetch(process.env.REACT_APP_CREATE_RECRUITMENT_API, requestOptions);
    const result = await response.text();
    if ((JSON.parse(result).statusCode) === 500) throw new Error();
    return JSON.parse((JSON.parse(result).body)).recruitmentListId
  } catch (error) {
    setErrorMessage(<div>
      募集作成時にエラーが発生しました。<br />
      しばらくしてからもう一度お試しください。
    </div>);
  }
};

/**
 * 募集参加処理
 * @param {String} recruitmentlistID 募集ID
 * @param {String} userID 募集退出ユーザーID
 * @param {Func} setKeyTime 更新用ステートのセッター
 * @return 成功:true 失敗:false
 */
const entryRecruitment = async (recruitmentInfo, userID, setKeyTime, setErrorMessage) => {
  let result;
  try {
    // ヘッダーの作成
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // リクエスト情報の作成
    var raw = JSON.stringify({
      "env": process.env.REACT_APP_NOW_ENV,
      "RecruitmentInfo": recruitmentInfo,
      "userId": userID
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    // APIを実行
    const response = await fetch(process.env.REACT_APP_ENTRY_RECRUITMENT_API, requestOptions);
    result = await response.text();
    if ((JSON.parse(result).statusCode) === 500) throw new Error();
  } catch (error) {
    if (JSON.parse(JSON.parse(result).body).message) {
      setErrorMessage(JSON.parse(JSON.parse(result).body).message);
    } else {
      setErrorMessage(<div>
        募集参加時にエラーが発生しました。<br />
        しばらくしてからもう一度お試しください。
      </div>);
    }
  }
  // 更新処理用ステートを更新する
  setKeyTime(Date.now());
};

/**
 * 募集退出処理
 * @param {String} recruitmentlistID 募集ID
 * @param {String} userID 募集退出ユーザーID 
 * @param {Func} setKeyTime 更新用ステートのセッター
 */
const exitRecruitment = async (recruitmentInfo, userID, setKeyTime, setErrorMessage) => {
  try {
    // ヘッダーの作成
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // リクエスト情報の作成
    var raw = JSON.stringify({
      "env": process.env.REACT_APP_NOW_ENV,
      "RecruitmentInfo": recruitmentInfo,
      "userId": userID
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    // APIを実行
    const response = await fetch(process.env.REACT_APP_EXIT_RECRUITMENT_API, requestOptions);
    const result = await response.text();
    if ((JSON.parse(result).statusCode) === 500) throw new Error();
  } catch (error) {
    setErrorMessage(<div>
      募集退出時にエラーが発生しました。<br />
      しばらくしてからもう一度お試しください。
    </div>);
  }
  // 更新処理用ステートを更新する
  setKeyTime(Date.now())
};

/**
 * 募集編集処理
 * @param {RecruitmentListM} RecruitmentInfo 募集情報
 * @param {addCategorys} addCategorys 追加カテゴリー情報
 * @param {deleteCategorys} deleteCategorys 削除カテゴリー情報
 * @returns {String} id 募集ID
 * @return 成功:true 失敗:false
 */
const editRecruitment = async (RecruitmentInfo, addCategorys, deleteCategorys, setErrorMessage) => {
  const categoryList = await getCategoryByRecruitmentListId(RecruitmentInfo.id, setErrorMessage);
  let result;
  try {
    // ヘッダーの作成
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // リクエスト情報の作成
    var raw = JSON.stringify({
      "env": process.env.REACT_APP_NOW_ENV,
      "RecruitmentInfo": RecruitmentInfo,
      "categoryList": categoryList,
      "addCategorys": addCategorys,
      "deleteCategorys": deleteCategorys
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    // APIを実行

    const response = await fetch(process.env.REACT_APP_EDIT_RECRUITMENT_API, requestOptions);
    result = await response.text();
    if ((JSON.parse(result).statusCode) === 500) throw new Error();
    return true;
  } catch (error) {
    if (JSON.parse(JSON.parse(result).body).message) {
      setErrorMessage(JSON.parse(JSON.parse(result).body).message);
    } else {
      setErrorMessage(<div>
        募集保存時にエラーが発生しました。<br />
        しばらくしてからもう一度お試しください。
      </div>);
    }
    return false;
  }
};

/**
 * 募集削除処理
 * @param {RecruitmentListM} RecruitmentInfo 募集情報
 * @return 成功:true 失敗:false
 */
const deleteRecruitment = async (RecruitmentInfo, setErrorMessage) => {
  try {
    // ヘッダーの作成
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // リクエスト情報の作成
    var raw = JSON.stringify({
      "env": process.env.REACT_APP_NOW_ENV,
      "RecruitmentInfo": RecruitmentInfo,
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    // APIを実行

    const response = await fetch(process.env.REACT_APP_DELETE_RECRUITMENT_API, requestOptions);
    const result = await response.text();
    if ((JSON.parse(result).statusCode) === 500) throw new Error();
    return true;
  } catch (error) {
    setErrorMessage(<div>
      募集削除時にエラーが発生しました。<br />
      しばらくしてからもう一度お試しください。
    </div>);
    return false;
  }
};

export { createRecruitment, exitRecruitment, entryRecruitment, editRecruitment, deleteRecruitment };
