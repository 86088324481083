import "@aws-amplify/ui-react/styles.css"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import "../App.css"
import {
  Top,
  RecruitmentList,
  Recruitment,
  TalkRoom,
  ProfileEdit,
  TalkRoomList,
  Profile,
  RegisterRecruitment,
  RuleDetail,
  RecruitmentEdit,
  PrivacyPolicy,
  Contact,
  Terms,
  YakuList,
  PrivacySetting,
  InitialSetting,
  NotFound,
  Restriction,
  Matching,
  RuleIntroduction
} from "../page"

import { Header, Footer, InstallButton, Sidebar } from "."
import { useState } from "react"
import { styled } from "styled-components"
import { Message } from "./"
import { CommonTag } from "../common-my-ui-components";

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100);
`

const MainContainer = styled.div`
  flex: 1;
  overflow: auto;
  overscroll-behavior: contain;
`

const HeaderContainer = styled.div`
  flex: 0 0 auto;
`

const FooterContainer = styled.div`
  flex: 0 0 auto;
`

function GestBrowserRouter() {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const onSetSidebarOpen = (open) => {
    setSidebarOpen(open)
  }

  return (
    <BrowserRouter>
      <AppContainer>

        <CommonTag />
        <InstallButton />
        <Sidebar isOpen={sidebarOpen} onSetSidebarOpen={onSetSidebarOpen} />

        <HeaderContainer>
          <Header onMenuClick={() => onSetSidebarOpen(true)} />
        </HeaderContainer>

        <MainContainer>
          <Message />
          <Routes>
            <Route path="/" element={<Top />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Terms" element={<Terms />} />
            <Route path="/Profile/:userID" element={<Profile />} />
            <Route path="/RecruitmentList" element={<RecruitmentList />} />
            <Route path="/RecruitmentList/:requestNowTab/" element={<RecruitmentList />} />
            <Route path="/RecruitmentList/:requestNowTab/:resourceName" element={<RecruitmentList />} />
            <Route path="/Recruitment/:recruitmentId" element={<Recruitment />} />
            <Route path="/RuleDetail" element={<RuleDetail />} />
            <Route path="/YakuList" element={<YakuList />} />
            <Route path="/Matching" element={<Matching />} />
            <Route path="/RuleIntroduction" element={<RuleIntroduction />} />

            {/* 未ログインユーザーは機能制限ページを表示 */}
            <Route path="/TalkRoomList" element={<Restriction />} />
            <Route path="/TalkRoom/:talkRoomID" element={<Restriction />} />
            <Route path="/RegisterRecruitment" element={<Restriction />} />
            <Route path="/RecruitmentEdit/:recruitmentId" element={<Restriction />} />
            <Route path="/ProfileEdit" element={<Restriction />} />
            <Route path="/PrivacySetting" element={<Restriction />} />
            <Route path="/MatchingRegister" element={<Restriction />} />
            <Route path="/MatchingEdit" element={<Restriction />} />
            <Route path="/MatchingHistory" element={<Restriction />} />
            <Route path="/RuleIntroduction/:pageID" element={<RuleIntroduction />} />

            {/* 不正なURLはNotFoundページを表示 */}
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </MainContainer>
        <FooterContainer>
          <Footer />
        </FooterContainer>
      </AppContainer>
    </BrowserRouter>
  )
}

export default GestBrowserRouter
