export const FOOTER_MENU_HEIGHT = "3.5rem";
export const HEADER_HEIGHT = "3.5rem";
export const TALKROOMHEADER_HEIGHT = "5rem";
export const TEXTAREAMAXLENGTH = 1000;
export const TEXTFIELDMAXLENGTH = 50;
export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
export const AGE = [
  { value: "非公開", label: "非公開" },
  ...Array.from({ length: 83 }, (_, index) => ({
    value: (index + 18).toString(),
    label: (index + 18).toString(),
  })),
];
export const YES_OR_NO = [
  { value: "未回答", label: "未回答" },
  { value: "はい", label: "はい" },
  { value: "いいえ", label: "いいえ" },
]
export const MAIN_MAHJONG = [
  { value: "未回答", label: "未回答" },
  { value: "4人", label: "4人" },
  { value: "3人", label: "3人" },
]
export const PREFECTURES = [
  { value: "非公開", label: "非公開" },
  { value: "北海道", label: "北海道" },
  { value: "青森県", label: "青森県" },
  { value: "岩手県", label: "岩手県" },
  { value: "宮城県", label: "宮城県" },
  { value: "秋田県", label: "秋田県" },
  { value: "山形県", label: "山形県" },
  { value: "福島県", label: "福島県" },
  { value: "茨城県", label: "茨城県" },
  { value: "栃木県", label: "栃木県" },
  { value: "群馬県", label: "群馬県" },
  { value: "埼玉県", label: "埼玉県" },
  { value: "千葉県", label: "千葉県" },
  { value: "東京都", label: "東京都" },
  { value: "神奈川県", label: "神奈川県" },
  { value: "新潟県", label: "新潟県" },
  { value: "富山県", label: "富山県" },
  { value: "石川県", label: "石川県" },
  { value: "福井県", label: "福井県" },
  { value: "山梨県", label: "山梨県" },
  { value: "長野県", label: "長野県" },
  { value: "岐阜県", label: "岐阜県" },
  { value: "静岡県", label: "静岡県" },
  { value: "愛知県", label: "愛知県" },
  { value: "三重県", label: "三重県" },
  { value: "滋賀県", label: "滋賀県" },
  { value: "京都府", label: "京都府" },
  { value: "大阪府", label: "大阪府" },
  { value: "兵庫県", label: "兵庫県" },
  { value: "奈良県", label: "奈良県" },
  { value: "和歌山県", label: "和歌山県" },
  { value: "鳥取県", label: "鳥取県" },
  { value: "島根県", label: "島根県" },
  { value: "岡山県", label: "岡山県" },
  { value: "広島県", label: "広島県" },
  { value: "山口県", label: "山口県" },
  { value: "徳島県", label: "徳島県" },
  { value: "香川県", label: "香川県" },
  { value: "愛媛県", label: "愛媛県" },
  { value: "高知県", label: "高知県" },
  { value: "福岡県", label: "福岡県" },
  { value: "佐賀県", label: "佐賀県" },
  { value: "長崎県", label: "長崎県" },
  { value: "熊本県", label: "熊本県" },
  { value: "大分県", label: "大分県" },
  { value: "宮崎県", label: "宮崎県" },
  { value: "鹿児島県", label: "鹿児島県" },
  { value: "沖縄県", label: "沖縄県" }
];

export const PROFILEITEMGROUP1 = {
  groupName: "自己紹介",
  items: [
    {
      label: "自己紹介",
      valueName: "introduction",
      type: "textarea",
      labelDisabled: true,
    },
  ],
};

export const PROFILEITEMGROUP2 = {
  groupName: "基本情報",
  items: [
    {
      label: "名前",
      valueName: "name",
      type: "text",
    },
    {
      label: "居住地",
      type: "select",
      valueName: "prefecture",
      options: PREFECTURES,
    },
    {
      label: "年齢",
      type: "select",
      valueName: "age",
      options: [...Array(81).keys()].map((num) => num + 20),
    },
    {
      label: "趣味",
      type: "text",
      valueName: "hobby",
    },
  ],
};

export const PROFILEITEMGROUP3 = {
  groupName: "麻雀情報",
  items: [
    {
      label: "腕前",
      type: "text",
      valueName: "skill",
    },
    {
      label: "点数計算ができるか？",
      type: "select",
      valueName: "canCalculateScore",
      options: ["はい", "いいえ"],
    },
    {
      label: "3人麻雀と4人麻雀、どっちがメインか?",
      type: "select",
      valueName: "mainMahjongType",
      options: ["3人", "4人"],
    },
    {
      label: "麻雀卓操作ができるか？",
      type: "select",
      valueName: "canOperateTable",
      options: ["はい", "いいえ"],
    },
    {
      label: "好きな役",
      type: "text",
      valueName: "favoriteRole",
    },
    {
      label: "麻雀を始めたきっかけ",
      type: "text",
      valueName: "mahjongStartReason",
    },
    {
      label: "麻雀をどうやって勉強したか",
      type: "text",
      valueName: "mahjongStudyMethod",
    },
  ],
};

export const MATCHING_AGE = [
  ...Array.from({ length: 83 }, (_, index) => ({
    value: (index + 18).toString(),
    label: (index + 18).toString(),
  })),
];

export const MATCHING_LIMIT_AGE = [
  { value: "0", label: "指定しない" },
  { value: "18", label: "18" },
  ...Array.from({ length: 9 }, (_, index) => ({
    value: (index * 5 + 20).toString(),
    label: (index * 5 + 20).toString(),
  })),
];

export const MATCHING_MAHJONG_PLAYNUM = [
  { value: 4, label: "4人" },
  { value: 3, label: "3人" },
];

export const MATCHING_SKILL = [
  { value: "1", label: "初心者" },
  { value: "2", label: "中級者" },
  { value: "3", label: "上級者" },
];

export const MATCHING_REQUIRED_SKILL = [
  { value: "1", label: "初心者以上" },
  { value: "2", label: "中級者以上" },
  { value: "3", label: "上級者のみ" },
];

export const MATCHING_PREFECTURES = [
  { value: "北海道", label: "北海道" },
  { value: "青森県", label: "青森県" },
  { value: "岩手県", label: "岩手県" },
  { value: "宮城県", label: "宮城県" },
  { value: "秋田県", label: "秋田県" },
  { value: "山形県", label: "山形県" },
  { value: "福島県", label: "福島県" },
  { value: "茨城県", label: "茨城県" },
  { value: "栃木県", label: "栃木県" },
  { value: "群馬県", label: "群馬県" },
  { value: "埼玉県", label: "埼玉県" },
  { value: "千葉県", label: "千葉県" },
  { value: "東京都", label: "東京都" },
  { value: "神奈川県", label: "神奈川県" },
  { value: "新潟県", label: "新潟県" },
  { value: "富山県", label: "富山県" },
  { value: "石川県", label: "石川県" },
  { value: "福井県", label: "福井県" },
  { value: "山梨県", label: "山梨県" },
  { value: "長野県", label: "長野県" },
  { value: "岐阜県", label: "岐阜県" },
  { value: "静岡県", label: "静岡県" },
  { value: "愛知県", label: "愛知県" },
  { value: "三重県", label: "三重県" },
  { value: "滋賀県", label: "滋賀県" },
  { value: "京都府", label: "京都府" },
  { value: "大阪府", label: "大阪府" },
  { value: "兵庫県", label: "兵庫県" },
  { value: "奈良県", label: "奈良県" },
  { value: "和歌山県", label: "和歌山県" },
  { value: "鳥取県", label: "鳥取県" },
  { value: "島根県", label: "島根県" },
  { value: "岡山県", label: "岡山県" },
  { value: "広島県", label: "広島県" },
  { value: "山口県", label: "山口県" },
  { value: "徳島県", label: "徳島県" },
  { value: "香川県", label: "香川県" },
  { value: "愛媛県", label: "愛媛県" },
  { value: "高知県", label: "高知県" },
  { value: "福岡県", label: "福岡県" },
  { value: "佐賀県", label: "佐賀県" },
  { value: "長崎県", label: "長崎県" },
  { value: "熊本県", label: "熊本県" },
  { value: "大分県", label: "大分県" },
  { value: "宮崎県", label: "宮崎県" },
  { value: "鹿児島県", label: "鹿児島県" },
  { value: "沖縄県", label: "沖縄県" }
];

export const MATCHING_PLACE = [
  { value: "新宿", label: "新宿" },
  { value: "渋谷", label: "渋谷" },
  { value: "池袋", label: "池袋" },
  { value: "東京", label: "東京" },
  { value: "品川", label: "品川" },
  { value: "新橋", label: "新橋" },
  { value: "秋葉原", label: "秋葉原" },
  { value: "北千住", label: "北千住" },
  { value: "綾瀬", label: "綾瀬" },
  { value: "高田馬場", label: "高田馬場" },
  { value: "上野", label: "上野" },
  { value: "有楽町", label: "有楽町" },
  { value: "立川", label: "立川" },
  { value: "大手町", label: "大手町" },
  { value: "浜松町", label: "浜松町" },
  { value: "大崎", label: "大崎" },
  { value: "田町", label: "田町" },
  { value: "町田", label: "町田" },
  { value: "中野", label: "中野" },
  { value: "蒲田", label: "蒲田" },
  { value: "吉祥寺", label: "吉祥寺" },
  { value: "恵比寿", label: "恵比寿" },
  { value: "神保町", label: "神保町" }

];
