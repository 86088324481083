import React from 'react';
import { PrivacyPolicyContent } from './my-ui-components';
import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PrivacyPolicy=()=> {
  return (
    <Container>
      <PrivacyPolicyContent />
      </Container>
  );
}

export default PrivacyPolicy;
