import '@aws-amplify/ui-react/styles.css';
import '../../App.css';
import { useState, useEffect, useRef, useContext } from 'react';
import { Link } from "react-router-dom";
import { Grid, Flex, View } from '@aws-amplify/ui-react';
import UserContext from "../../UserContext";
import MessageContext from "../../MessageContext";
import styled from 'styled-components';
import { getMatchingReserveMByUsermID } from "../../my-func/MatchingManager"
import { MatchedMatching, RegisteredMatching } from './my-ui-components'
import { MatchingHowToUse ,CommonAdSense} from '../../common-my-ui-components'

const CustomLink = styled(Link)`
text-decoration: none;
color: inherit;
margin:0 1rem;
`;

const Page = styled.div`
padding: 1rem;
width:100%;
`

const Test = styled.div`
position: relative;
width: 100%;
height: 100vh; /* 高さは適宜調整 */
display: flex;
justify-content: center; /* 水平方向に中央揃え */
`

const Matching = () => {
  // コンテキストに保存したユーザー情報を読み込み
  const { user } = useContext(UserContext);
  const { setErrorMessage } = useContext(MessageContext);

  // マッチング予約
  const [matchingReserve, setMatchingReserve] = useState(null);
  // 更新処理用ステート
  const [keyTime, setKeyTime] = useState(Date.now());
  // 登録ボタン
  const [registerButton, setRegisterButton] = useState("");

  const drawMatching = async () => {
    const matchingReserve = (await getMatchingReserveMByUsermID(user.id, setErrorMessage))[0]
    setMatchingReserve(matchingReserve);
    if (matchingReserve) {
      setRegisterButton(
        <CustomLink to="/MatchingHistory">
          <span className="material-symbols-rounded">
            event_repeat
          </span><p>マッチング履歴</p>
        </CustomLink>
      )
    } else if (!matchingReserve) {
      setRegisterButton(
        <Grid templateColumns={{ base: '1fr 1fr ' }}>
          <CustomLink to="/MatchingRegister">
            <span className="material-symbols-rounded">
              event_note
            </span><p>マッチング登録</p>
          </CustomLink>
          <CustomLink to="/MatchingHistory">
            <span className="material-symbols-rounded">
              event_repeat
            </span><p>マッチング履歴</p>
          </CustomLink>
        </Grid>)
    }
  }

  useEffect(() => {
    if (!user) {
      setMatchingReserve(undefined);
      return;
    }
    drawMatching();
  }, [keyTime]);


  return (
    <Page>

      {/* PC時に中央の66%にコンテンツを配置する */}
      <Grid templateColumns={{ base: '1fr', large: "2fr 8fr 2fr" }}>
        <View rowSpan={1}>{/* PC時の両脇 空白スペース */}<CommonAdSense  position="left"/></View>
        <View rowSpan={1}>
          <Flex direction={"column"} alignItems={'right'} >
            <Flex direction={"column"} alignItems={'center'}>
              {registerButton}
            </Flex>
            {
              !matchingReserve ? (
                // マッチング予約情報を取り終えるまでは何も表示しない
                <>
                </>
              ) : matchingReserve.status === "1" ? (
                <>
                  {/* 登録済みマッチング */}
                  <RegisteredMatching matchingReserve={matchingReserve} setKeyTime={setKeyTime} />
                </>
              ) : matchingReserve.status === "2" ? (
                <>
                  {/* マッチング済み */}
                  <MatchedMatching matchingReserve={matchingReserve} keyTime={keyTime} setKeyTime={setKeyTime} />
                </>
              ) : (
                <></>
              )
            }
          </Flex>
          <br/>
          <MatchingHowToUse />
        </View >
        <View rowSpan={1}>{/* PC時の両脇 空白スペース */}<CommonAdSense  position="right"/></View>
      </Grid >
    </Page>
  );
};

export default Matching;