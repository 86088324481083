import { styled } from "styled-components";
import { Flex } from '@aws-amplify/ui-react';
import {
  MainTitle,
  SubTitle,
  FullLineSubTitle,
  CommonLink,
  Ul,
  Li,
  LeftText,
  LeftChat,
  RightChat,
  BoldText,
  Kugiri10000
} from "../../../../common-my-ui-components";
import LetsReach1 from '../../../../common-my-ui-components/img/introduction/LetsReach1.png';

import teacherSmile from '../../../../common-my-ui-components/img/miniChar/teacherSmile.png';
import teacherPoint from '../../../../common-my-ui-components/img/miniChar/teacherPoint.png';
import teacherHappy from '../../../../common-my-ui-components/img/miniChar/teacherHappy.png';
import girlQuestion from '../../../../common-my-ui-components/img/miniChar/girlQuestion.png';
import girlSmile from '../../../../common-my-ui-components/img/miniChar/girlSmile.png';
import girlHappy from '../../../../common-my-ui-components/img/miniChar/girlHappy.png';

// ページ全体の設定
const Page = styled.div`
width: 100%; 
`;

const Contents = styled.div`
width: 100%;
padding: 1rem;
margin: 0 auto;
text-align: center;
`;

const LetsReach = () => {
  return (
    <Page>
      <Contents>
        <MainTitle text={"リーチをしよう！"} tabText={"麻雀の基本"} textAlign={"left"} />
        <LeftChat src={teacherPoint} alt="teacherPoint" text={(<>
          和了には役が必要になるといいましたが、その中でも最も重要なリーチについて学びましょう。
        </>)} />
        <RightChat src={girlHappy} alt="girlHappy" text={(<>
          どんな役なんだろう、楽しみ！
        </>)} />

        <SubTitle text={"リーチって何？"} textAlign={"left"} />
        <LeftText text={<>
          <BoldText text={"立直(リーチ)"} />とは、<BoldText text={"門前(メンゼン)"} />で聴牌しているときに、
          「リーチ」と宣言し、供託として1,000点棒を出すことで成立する<BoldText text={"1飜"} />の役です。<br />
          リーチ後は手牌の入れ替えができなくなります。<br />
          和了後に裏ドラをめくることができます。
        </>} />
        <RightChat src={girlQuestion} alt="girlQuestion" text={(<>
          牌の組み合わせとかは関係ないんだ！
        </>)} />
        <LeftChat src={teacherSmile} alt="teacherSmile" text={(<>
          はい、麻雀の中で最頻出の基本的な役です。<br />
          初心者のうちはとにかくリーチすることをオススメします。<br />
          門前のときだけリーチをかけることができます。
        </>)} />
        <RightChat src={girlSmile} alt="girlSmile" text={(<>
          門前っていうのは何？
        </>)} />
        <LeftChat src={teacherSmile} alt="teacherSmile" text={(<>
          門前は<BoldText text={"副露(フーロ)"} />していない状態をいいます。<br />
          副露とは他の人の捨てた牌をもらってくることです。<br />
          詳しくは次の章で説明します。
        </>)} />

        <SubTitle text={"リーチの手順"} textAlign={"left"} />
        <LeftText text={<>
          門前の状態で聴牌しているときに「リーチ」と<BoldText text={"発声"} />します。<br />
          捨て牌を横に向け、供託として1,000点を場に出します。
        </>} />
        <img src={LetsReach1} style={{ width: '100%', maxWidth: '500px' }} alt="リーチ手順説明図" />

        <RightChat src={girlSmile} alt="girlSmile" text={(<>
          この1,000点棒は誰がもらえるの？
        </>)} />
        <LeftChat src={teacherHappy} alt="teacherSmile" text={(<>
          供託はその局でアガった人がもらえます。<br />
          流局で誰もアガれなかった場合は、そのまま次の局に引き継がれ、次の局で上がった人がもらえます。<br />
        </>)} />

        <RightChat src={girlQuestion} alt="girlSmile" text={(<>
          私が出した1,000点……絶対にアガって取り返さなくちゃ！
        </>)} />

        <SubTitle text={"リーチのメリット"} textAlign={"left"} />
        <LeftChat src={teacherPoint} alt="teacherPoint" text={(<>
          リーチは麻雀の役の中でも特に強いと言われています。<br />
          その理由を見ていきましょう。
        </>)} />

        <FullLineSubTitle text={"一発"} />
        <LeftText text={<>
          リーチをかけた1巡以内にアガると、<BoldText text={"一発"} />という役がつきます。<br />
          ロンでもツモでも成立しますが、1巡以内に副露が入ると成立しません。<br />
        </>} />

        <FullLineSubTitle text={"裏ドラ"} />
        <LeftText text={<>
          リーチをかけてアガると<BoldText text={"裏ドラ"} />を見ることができます。<br />
          裏ドラはドラ表示牌の下にある牌のその次の牌で、アガったときに追加のドラとして点数に加算されます。<br />
          槓が入っている場合、槓ドラの裏も同様に見ることができます。
        </>} />
        <RightChat src={girlHappy} alt="girlHappy" text={(<>
          ボーナスが増えるの！？<br />
          なんだかワクワクしちゃうね！
        </>)} />
        <LeftChat src={teacherSmile} alt="teacherSmile" text={(<>
          はい、リーチ自体は1飜の役ですが、和了後に運が良ければ大幅に点数アップしますよ。<br />
          ドキドキしながら裏ドラをめくるのは麻雀の醍醐味の1つです。
        </>)} />

        <br />
        <Kugiri10000 />
        <br />

        <RightChat src={girlQuestion} alt="girlQuestion" text={(<>
          リーチはいいことばっかりで怪しいなー。<br />
          うまい話には裏があるんじゃない？
        </>)} />
        <LeftChat src={teacherHappy} alt="teacherHappy" text={(<>
          鋭いですね。<br />
          次はリーチのデメリットについて見ていきましょう。<br />
        </>)} />

        <SubTitle text={"リーチのデメリット"} textAlign={"left"} />
        <FullLineSubTitle text={"聴牌宣言をしなければならない"} />
        <LeftText text={<>
          リーチをかけると他家に聴牌していることがわかります。<br />
          そのため警戒されてロン牌が出てくる可能性が減ってしまうのです。<br />
        </>} />

        <FullLineSubTitle text={"手牌の入れ替えができない"} />
        <LeftText text={<>
          リーチをかけたあとは手牌を入れ替えることができません。<br />
          和了牌以外はすべてツモ切りしなければいけません。<br />
          ただし牌姿が変わらない暗槓は可能です。
        </>} />


        <RightChat src={girlQuestion} alt="girlQuestion" text={(<>
          リーチしたあとは何にもできなくなっちゃうんだ……
        </>)} />
        <LeftChat src={teacherPoint} alt="teacherPoint" text={(<>
          はい、ですが役がないときや大きな点数をとりたいときには大活躍してくれますよ。<br />
          リーチをかけるほうが得な場面が圧倒的に多いので、初めはとにかくたくさんリーチをかけてみましょう！<br />
        </>)} />

        <Flex direction={"column"} alignItems={'center'}>
          <Ul text={(
            <>
              <Li text={<CommonLink to="/RuleIntroduction/MahjongIntroduction#start" text={"麻雀ルールTOP"} />} />
              <Li text={<CommonLink to="/RuleIntroduction/LetsCall#start" text={"次ページ 鳴いてみよう！"} />} />
              <Li text={<CommonLink to="/RuleIntroduction/LetsWin#start" text={"前ページ 和了を目指そう！"} />} />
            </>
          )} />
        </Flex>
      </Contents>
    </Page>
  );
};

export default LetsReach;
