import '@aws-amplify/ui-react/styles.css';
import '../../../App.css';
import { Grid, Flex, View } from '@aws-amplify/ui-react';
import { styled } from 'styled-components';
import { Chip, List, ListItem, Skeleton, Divider } from '@mui/material';
import { useState, useEffect } from 'react';

const SubTitle = styled.div`
line-height: 160%;
font-size: 1.6rem;
text-align: left;
padding: 0.25em 0;
&::after {
  content: "";
  display: block;
  height: 4px;
  background: linear-gradient(90deg, ${(props) => (props.theme.palette.text.green)},
  ${(props) => (props.theme.palette.quinary.main)});
}
`;

const Tag = styled.div`
text-align: left;
height:100%;
display: grid;
place-items: center;
`;


const YakuHeader = (props) => {
  // 引数で募集情報を受け取る
  const { name, isMenzen,isCall, kuisagari } = props;

  const [isKuisagari, setIsKuisagari] = useState(false);

  useEffect(() => {
    setIsKuisagari(kuisagari != undefined)

  }, [kuisagari]);


  return (
    <div>
      <Grid
        templateColumns={{ base: '2fr 8fr', large: "8fr 2fr" }}
        templateRows={{ base: "1fr 0.5fr", large: "1fr" }}
      >
        <View columnSpan={{ base: 2, large: 1 }}><SubTitle>{name}</SubTitle></View>
        <View><Tag>
          {isMenzen ? (
            <Chip label="門前のみ" color="primary" variant="outlined" />
          )
            : isKuisagari ? (
              <Chip label={`喰下り` + kuisagari +`翻`}  color="primary" variant="outlined" />
            ) : isCall ? (
              <Chip label={"鳴きOK"}  color="primary" variant="outlined" />
            ) : (
              <> </>)
          } </Tag></View>
      </Grid>
    </div>
  );
};

export default YakuHeader;
