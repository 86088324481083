import "@aws-amplify/ui-react/styles.css"
import "./App.css"
import { API, Amplify, Auth } from "aws-amplify"
import aws_exports from "./aws-exports"
import { useState, useEffect, useLayoutEffect } from "react"
import UserContext from "./UserContext"
import MessageContext from "./MessageContext"
import { getUserByCognitoUser, getCognitoUser } from "./my-func/userManager"
import { ThemeProvider as MaterialThemeProvider } from "@mui/material/styles"
import { ThemeProvider as StyledThemeProvider } from "styled-components"
import theme from "./theme"
import { MemberBrowserRouter, GestBrowserRouter, FeedbackModal } from "./common-my-ui-components"
import { styled } from "styled-components"
import texture from "../src/common-my-ui-components/img/texture/groovepaper.png"
import { loginFormLocalize } from "./LoginForm"
import { createFeedbackM, updateUserM } from "./graphql/mutations"

const CommonStyle = styled.div`
  flex: 1;
  overflow: auto;
  overscroll-behavior: contain;
  background-image: url(${texture});
  background-color: ${(props) => props.theme.palette.background.default};
  text-align: center;
  word-break: break-all;
`

// 認証系の日本語化
loginFormLocalize()

Amplify.configure({ ...aws_exports, aws_cognito_unauthenticated_id: true })

// cognitoユーザの有無で認証方法を変更（認証済み→Cognitoユーザ認証、未認証→IAM認証）
const authTypeControl = (isAuthenticated) => {
  if (isAuthenticated) {
    Amplify.configure({
      aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
    })
  } else {
    Amplify.configure({
      aws_appsync_authenticationType: "AWS_IAM"
    })
  }
}

function App() {
  // ユーザー情報の格納を行う
  const [cognitoUser, setCognitoUser] = useState(null)
  const [user, setUser] = useState(null)
  // メッセージの格納を行う
  const [successMessage, setSuccessMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [infoMessage, setInfoMessage] = useState(null)
  const [confirmMessage, setConfirmMessage] = useState(null)
  // Cognitoから情報取得が完了しているか状態確認
  const [loading, setLoading] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [feedbackData, setFeedbackData] = useState(null)

  window.oncontextmenu = function (event) {
    event.preventDefault()
    event.stopPropagation()
    return false
  }
  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleFeedbackProcess = (user) => {
    // ログイン日数が4日以上になるとフィードバック画面を表示
    // if (user.loginDays) { //テスト用コード
    if (user.loginDays > 3 && !(user.feedbackSent == true)) {
      handleOpenModal()
    } else if (!(user.feedbackSent == true)) {
      // 現在日が最終ログイン日より後の日付である場合、ログイン日数を更新する
      const today = new Date()
      const lastLoginDate = new Date(user.lastLoginAt)
      const daysSinceLastLogin = Math.round((today - lastLoginDate) / (1000 * 60 * 60 * 24))
      if (daysSinceLastLogin >= 1) {
        const updatedUserData = {
          id: user.id,
          loginDays: user.loginDays + 1,
          lastLoginAt: new Date().toISOString(),
          _version: user._version
        }
        try {
          API.graphql({
            query: updateUserM,
            variables: { input: updatedUserData }
          })
        } catch (error) {
          setErrorMessage(<div>ユーザー情報の更新に失敗しました</div>)
        }
      }
    }
  }

  const handleFeedbackSubmit = (data) => {
    setFeedbackData(data)

    //フィードバック内容を登録
    const newFeedbackData = {
      usermID: user.id,
      rating: data.rating,
      content: data.content
    }

    try {
      API.graphql({
        query: createFeedbackM,
        variables: { input: newFeedbackData }
      })
    } catch (error) {
      setErrorMessage(<div>フィードバックの登録に失敗しました</div>)
    }

    // フィードバック送信済みに変更
    const updatedUserData = {
      id: user.id,
      feedbackSent: true,
      __version: user.__version
    }
    //user更新
    try {
      API.graphql({
        query: updateUserM,
        variables: { input: updatedUserData }
      })
    } catch (error) {
      setErrorMessage(<div>ユーザー情報の更新に失敗しました</div>)
    }
  }

  function setHeight() {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }

  let resizeTimeout
  window.addEventListener("resize", () => {
    clearTimeout(resizeTimeout)
    resizeTimeout = setTimeout(setHeight, 150) // 150msの遅延後にsetHeightを実行
  })

  setHeight()

  // ログアウト処理
  // Router内で処理を実行すると一時的に認証が外れ標準のログインフォームが表示されてしまうため、
  // ログアウト処理中はApp.js内でロード中の白画面を表示するように実装
  const logout = async () => {
    setLoading(true);
    Auth.signOut();
    await new Promise((resolve) => setTimeout(resolve, 700));
    window.location.reload();
  }

  // 退会処理
  // Router内で処理を実行すると一時的に認証が外れ標準のログインフォームが表示されてしまうため、
  // 退会処理はApp.js内でロード中の白画面を表示するように実装
  const deleteUser = async () => {
    setLoading(true);
    Auth.deleteUser();
    await new Promise((resolve) => setTimeout(resolve, 700));
    window.location.reload();
  }

  // Cognitoからログイン中のユーザ情報の取得
  useLayoutEffect(() => {
    getCognitoUser(setErrorMessage)
      .then((currentUser) => {
        authTypeControl(currentUser)
        setCognitoUser(currentUser)
        getUserByCognitoUser(currentUser, setErrorMessage).then((user) => {
          if (user) {
            handleFeedbackProcess(user)
          }
          setUser(user)
          setLoading(false)
        })
      })
      .catch((error) => {
        setErrorMessage(
          <div>
            ユーザー情報取得時にエラーが発生しました。
            <br />
            しばらくしてからもう一度お試しください。
          </div>
        )
      })
  }, [])

  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload()
      }
    }
  }, [])

  return (
    <div className="App">
      <MaterialThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          <CommonStyle>
            <UserContext.Provider value={{ user, setUser, cognitoUser, setCognitoUser }}>
              <MessageContext.Provider
                value={{
                  successMessage,
                  setSuccessMessage,
                  errorMessage,
                  setErrorMessage,
                  infoMessage,
                  setInfoMessage,
                  confirmMessage,
                  setConfirmMessage
                }}
              >
                {loading ? (
                  // ロード中は何も表示させない
                  <></>
                ) : !cognitoUser ? (
                  // 未ログインユーザー
                  <GestBrowserRouter />
                ) : (
                  // ログインユーザー
                  <>
                    <MemberBrowserRouter logout={logout} deleteUser={deleteUser}/>
                    <FeedbackModal open={isModalOpen} handleClose={handleCloseModal} handleSubmit={handleFeedbackSubmit} />
                  </>
                )}
              </MessageContext.Provider>
            </UserContext.Provider>
          </CommonStyle>
        </StyledThemeProvider>
      </MaterialThemeProvider>
    </div>
  )
}

export default App
