import '@aws-amplify/ui-react/styles.css';
import '../App.css';
import { styled } from 'styled-components';
import { Grid, View } from '@aws-amplify/ui-react';
import { useBreakpointValue } from '@aws-amplify/ui-react';
import HowToUse1 from './img/Matching/HowToUse1.png';
import HowToUse2 from './img/Matching/HowToUse2.png';
import HowToUse3 from './img/Matching/HowToUse3.png';
import HowToUse4 from './img/Matching/HowToUse4.png';
import HowToUse5 from './img/Matching/HowToUse5.png';
import HowToUse6 from './img/Matching/HowToUse6.png';

const Top = styled.div`
width: 100%;
overflow: hidden;
`;

const LineSubTitle = styled.h4`
font-family: 'BIZ UDPGothic', sans-serif;
border-bottom: 3px solid ${(props) => (props.theme.palette.primary.main)};
display: inline-block;
line-height: 2;
width:15rem;
`;

const Title = styled.h1`
font-family: 'BIZ UDPGothic', sans-serif;
display: inline-block;
margin-bottom: 1rem;
background: linear-gradient( 120deg, ${(props) => (props.theme.palette.secondary.main)},
${(props) => (props.theme.palette.secondaryred.main)});
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
`;

const SubTitle = styled.h3`
margin: 0.5em 0;
text-align: left;
font-weight: bold;
/*文字の上下 左右の余白*/
padding: 0.4rem 0.5rem;
/*文字色*/
color: ${(props) => (props.theme.palette.text.black)};
/*背景色*/
background: ${(props) => (props.theme.palette.text.white)};
/*左線*/
border-left: solid 5px ${(props) => (props.theme.palette.primary.main)};
/*下線*/
border-bottom: solid 3px ${(props) => (props.theme.palette.quaternary.main)};
`;

const Text = styled.div`
display: flex;
line-height: 1.5;
justify-content: center;
text-align: ${(props) => (props.variation === 'base' ? 'left' : 'center')};
margin-bottom: 1.5rem;
`;

const DescriptionText = styled.div`
display: flex;
line-height: 1.5;
justify-content: left;
text-align: left;
margin-bottom: 1.5rem;
`;

// ステップの表示
const STEP = styled.div`
width:5em;
background: ${(props) => (props.theme.palette.primary.main)};
border-radius: 10px;
color:${(props) => (props.theme.palette.text.white)};
font-size: clamp(16px, 3vw, 20px);
font-weight: 600;
text-align: center;
margin:auto;
padding: 0.2em 0.2em;
`;

const HowToUseImgStyle = styled.img`
border: 3px solid ${(props) => (props.theme.palette.primary.main)};
border-radius: 20px;
width: ${(props) => (props.variation === 'base' ? '250px' : '300px')};
margin: 0.5rem;
`
const StepText = styled.div`
display: flex;
line-height: 1.5;
justify-content: center;
text-align: left;
margin: 0 1rem  1.5rem 1rem;
`;

const Scroll = styled.div`
width: ${(props) => (props.variation === 'base' ? '90vw' : '66vw')};
overflow: auto;
`;

const ImgComponent = ({ src, alt, order, text, variation }) => {
  return (
    <div>
      <STEP >STEP {order}</STEP>
      <HowToUseImgStyle src={src} alt={alt} variation={variation} />
      <StepText variation={variation} dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

const MatchingHowToUse = (props) => {
  // 画面サイズを取得
  const variation = useBreakpointValue({
    base: 'base',
    large: 'large',
  });

  return (
    <Top>
      <Title>
        麻雀マッチング
      </Title>
      <Text variation={variation}>
        日付・場所・年齢・腕前を条件に登録することで麻雀仲間を探すことができます<br />
        マッチング後は各プレイヤーのプロフィールを確認できるので、事前にどんな人と麻雀を打つか把握することができます！<br/>
      </Text>
      <LineSubTitle>
        マッチング登録方法
      </LineSubTitle>
      <SubTitle>基本ルール</SubTitle>
      <DescriptionText >
        時間：12時〜18時<br />
        ※日付は登録時に設定できます<br />
        4人麻雀：Mリーグルール<br />
        3人麻雀：ザンリーグルール<br />
      </DescriptionText>

      <SubTitle>登録方法</SubTitle>
      <Scroll variation={variation}>
        <Grid templateColumns={{ base: '1fr 1fr 1fr', large: '1fr 1fr 1fr' }}>
          <View rowSpan={1}>
            <ImgComponent src={HowToUse1} alt="使い方1" order="1" text={"あなたの年齢・腕前・プレイ人数・駅名を選択してください<br/>入力した個人情報はほかユーザーに公開されません<br/>※現在は東京都のみサービス提供しています"} variation={variation} />
          </View>

          <View rowSpan={1}>
            <ImgComponent src={HowToUse2} alt="使い方2" order="2" text={"相手の年齢や腕前の条件、希望日付を入力してください<br/>通知にチェックをいれると指定されたメールアドレスにマッチング時に通知メールが送信されます"} variation={variation} />
          </View >

          <View rowSpan={1}>
            <ImgComponent src={HowToUse3} alt="使い方3" order="3" text={"登録が完了したらマッチング待ちになります<br/>条件に合うプレイヤーが見つかるまでお待ち下さい"} variation={variation} />
          </View>
        </Grid >
        <Grid templateColumns={{ base: '1fr 1fr 1fr', large: '1fr 1fr 1fr' }}>
          <View rowSpan={1}>
            <ImgComponent src={HowToUse4} alt="使い方4" order="4" text={"条件に合うプレイヤーが見つかると仮マッチングします<br/>相手や日程、場所を確認し問題がなければ確認ボタンを押してください<br/>期日までに全員が確認することでマッチングが成立しトークルームが作成されます"} variation={variation} />
          </View>

          <View rowSpan={1}>
            <ImgComponent src={HowToUse5} alt="使い方5" order="5" text={"マッチングを破棄する場合は破棄ボタンを押してください<br/>また特定のユーザーの問題でマッチングを破棄する場合、次回以降マッチングしないようにブロックできます<br/>マッチングを破棄したユーザーやブロックしたことは相手に通知されないのでご安心ください"} variation={variation} />
          </View >

          <View rowSpan={1}>
            <ImgComponent src={HowToUse6} alt="使い方6" order="6" text={"成立したマッチングはマッチング履歴から確認できます<br/>トークルームは詳細な場所決めや待ち合わせにご利用ください"} variation={variation} />
          </View>
        </Grid >
      </Scroll>
    </Top>
  );
};

export default MatchingHowToUse;
