
import '@aws-amplify/ui-react/styles.css';
import '../../../App.css';
import React from 'react';
import { Grid, View } from '@aws-amplify/ui-react';
import { styled } from 'styled-components';
import { TopLogoForm, HowToUse } from './';
import { useBreakpointValue } from '@aws-amplify/ui-react';
import PcTopImg from '../../../common-my-ui-components/img/Top/PC_TopImg.png';
import SpTopImg from '../../../common-my-ui-components/img/Top/SP_TopImg.png';
import kugiri from '../../../common-my-ui-components/img/bones/10000.png';
import { MatchingHowToUse } from '../../../common-my-ui-components'

const TopImage = styled.div`
text-align: center;
position: relative;
${(props) => (props.variation === 'base' ? '' : 'max-width: 1920px;')}
margin: auto;
& toplogoform{
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  margin:0;
  padding:0;
  max-width: ${(props) => (props.variation === 'base' ? '300px;' : '33%;')}
  ${(props) => (props.variation === 'base' ? 'width: 65%;' : '')}
}
`;

const HrImg = styled.img`
max-height:50px;
max-width:50%;
margin: 2rem auto;
display: block;
`;


const Contents = styled.div`
padding:1rem;
`;

const SubTitle = styled.h1`
font-family: 'BIZ UDPGothic', sans-serif;
display: inline-block;
margin-bottom: 2rem;
background: linear-gradient( 120deg, ${(props) => (props.theme.palette.secondary.main)},
${(props) => (props.theme.palette.secondaryred.main)});
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
`;

const Text = styled.div`
display: flex;
line-height: 1.5;
justify-content: center;
text-align: ${(props) => (props.variation === 'base' ? 'left' : 'center')};
margin-bottom: 1.5rem;
`;

const GestTop = () => {
  // 画面サイズを取得
  const variation = useBreakpointValue({
    base: 'base',
    large: 'large',
  });


  return (
    <>
      <TopImage variation={variation}>
        <img src={variation === 'base' ? SpTopImg : PcTopImg} style={{ width: '100%' }} alt="トップページ" />
        <toplogoform>
          <TopLogoForm />
        </toplogoform>
      </TopImage>

      {/* PC時に中央の66%にコンテンツを配置する */}
      <Grid templateColumns={{ base: '1fr', large: "2fr 8fr 2fr" }}>
        <View rowSpan={1}>{/* PC時の両脇 空白スペース */}</View>
        <View rowSpan={1}>
          <Contents>
            <SubTitle>
              一緒に麻雀をする<br />最高の友人を見つけよう！
            </SubTitle>
            <Text variation={variation}>
              雀の巣は麻雀に特化した基本料無料の次世代のマッチングサービスです<br />
              麻雀は近年Mリーグの登場により人気が高まっているゲームです<br />
              誘う相手が見つからない、点数計算が苦手、麻雀の話ができる友人がほしい...<br />
              ぜひ雀の巣で自分にぴったりな友達を探してみてください！
            </Text>
            <HrImg src={kugiri} alt="区切り10000点棒" />
            <MatchingHowToUse />
            <HrImg src={kugiri} alt="区切り10000点棒" />
            <HowToUse />
          </Contents>
        </View >
        <View rowSpan={1}>{/* PC時の両脇 空白スペース */}</View>
      </Grid >
    </>
  );
};

export default GestTop;
