import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect ,useRef,useLayoutEffect} from 'react';

export const useGoBack = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const prevLocation = useRef(location);
 

  useLayoutEffect(() => {
    // setHistory();  
  }, [navigate,location]);

  const setHistory=()=>{
    const pathParts = location.pathname.split('/').filter(part => part !== "");

    if (pathParts.length > 0) {
      const firstPartOfPath = pathParts[0];
      switch (firstPartOfPath) {
        case "TalkRoom":
          window.history.pushState(null,null,"/TalkRoomList");
          window.history.pushState(null,null,location.pathname);
          break;
        case "Recruitment":
        case "RecruitmentEdit":
        case "RegisterRecruitment":
          window.history.pushState(null,null,"/RecruitmentList");
          window.history.pushState(null,null,location.pathname);
          break;
        case "RecruitmentList":
        case "TalkRoomList":
          window.history.pushState(null,null,"/");
          window.history.pushState(null,null,location.pathname);
         break; 
        default:
          break;
      }
    } else {
      // window.history.pushState(null,null,location.pathname);
    }

  }

  const goBack = () => {
    const pathParts = location.pathname.split('/').filter(part => part !== "");

    // 遷移元のURLに基づいて遷移先を制御
    if (pathParts.length > 0) {
      const firstPartOfPath = pathParts[0];
      switch (firstPartOfPath) {
        case "TalkRoom":
          navigate("/TalkRoomList", { replace: true });
          break;
        case "Recruitment":
        case "RecruitmentEdit":
        case "RegisterRecruitment":
          navigate("/RecruitmentList", { replace: true });
          break;
        default:
          navigate(-1);
          break;
      }
    } else {
      navigate(-1);
    }
  };

  return goBack;
};
