import '@aws-amplify/ui-react/styles.css';
import '../../../App.css';
import { API, graphqlOperation } from 'aws-amplify';
import { getUserM } from "../../../graphql/queries";
import { UserIconListRow } from "../../../common-my-ui-components";
import { useState, useEffect } from 'react';

import { Card, List, ListItem } from '@mui/material';
import styled from 'styled-components';

const MinTitle = styled.div`
width:100%;
line-height: 120%;
font-size: 1.2rem;
font-weight: 450;
text-align: left;
margin:auto;
position: relative;
padding: 0.25em 0;
&::after {
  content: "";
  display: block;
  height: 4px;
  background: linear-gradient(90deg, ${(props) => (props.theme.palette.text.green)},
  ${(props) => (props.theme.palette.quinary.main)});
}
`;


const EntryUserList = (props) => {

  const [dispUserList, setDispUserList] = useState(null);

  // 引数で参加ユーザーリストを受け取る
  const { entryUserList, message, keyTime } = props;

  useEffect(() => {
    drawUserList();
  }, [keyTime]);

  if (entryUserList.length === 0) return (
    <div>
      <MinTitle>{message}</MinTitle>
      <Card variant="outlined" sx={{ minWidth: 300 }} >
        <ListItem>
          {message}は存在しません
        </ListItem>
      </Card>
    </div>
  )


  async function drawUserList() {
    const dispUserListData = Array(entryUserList.length);
    await Promise.all(entryUserList.map(async (entryUser, i) => {
      const opt = {
        id: entryUser,
      };
      const userInfo = (await API.graphql(graphqlOperation(getUserM, opt)))
        .data.getUserM;
      dispUserListData[i] = userInfo;
    }));

    setDispUserList(
      <List>
        <UserIconListRow userMs={dispUserListData} />
      </List>
    )
  }

  return (
    <div>
      <MinTitle>{message}</MinTitle>
      <Card variant="outlined" sx={{ minWidth: 300 }} >
        {dispUserList}
      </Card>
    </div>
  );
};

export default EntryUserList;
