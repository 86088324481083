
import '@aws-amplify/ui-react/styles.css';
import '../../App.css';
import { useContext } from 'react';
import React from 'react';
import { GestTop,MemberTop } from './my-ui-components';
import UserContext from "../../UserContext";
import { Grid, View } from '@aws-amplify/ui-react';
import { styled } from 'styled-components';

const Footer = styled.div`
background: ${(props) => (props.theme.palette.tertiary.main)};
text-align: left;
padding: 0.5rem;
font-size: 12px;
color: ${(props) => (props.theme.palette.text.green)}
`;

const Top = () => {
  // ログインユーザーを取得
  const { user } = useContext(UserContext);

  return (
    <>
      {user ? (
        <div>
          <MemberTop />
        </div>
      ) : (
        <div>
          <GestTop />
        </div>
      )}
      {/* 会社設立後に記載する
      <Footer>
        <Grid templateColumns={{ base: '1fr', large: "1fr 2fr 2fr" }}>
          <View rowSpan={1}></View>
          <View rowSpan={1}>
            〒000-1111<br/>
            東京都どこどこのどこか何番町<br/>
            合同会社 真鶴灯<br/>
            &copy; 2024 Matsurubi LLC
          </View>
          <View rowSpan={1}></View>
        </Grid >
      </Footer>
    */}
    </>
  );
};

export default Top;
