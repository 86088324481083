import { API, graphqlOperation } from "aws-amplify";
import { RecruitmentListM } from '../models';
import { blockedUsersMSByUsermID, getMatchingM } from '../graphql/queries'
import { createBlockedUsersM ,deleteBlockedUsersM} from '../graphql/mutations';


/**
 * ユーザーがブロックしているユーザーリストを取得
 * @param {String} userm ユーザー
 * 
 * @async
 * @function getBlockedUserByUserID
 */
const getBlockedUserByUserID = async (userm, setErrorMessage) => {
  try {
    // マッチング予約取得
    const opt = {
      usermID: userm.id
    };
    const blockedUser = (await API.graphql(graphqlOperation(blockedUsersMSByUsermID, opt)))
      .data.blockedUsersMSByUsermID.items;
    const unDeletedBlockedUser = blockedUser.filter((item) => {
      return item._deleted != true;
    });
    return unDeletedBlockedUser;
  } catch (error) {
    setErrorMessage(<div>
      ブロックユーザー情報取得時にエラーが発生しました。<br />
      しばらくしてからもう一度お試しください。
    </div>);
  }
};

/**
 * ユーザーをブロック
 * @param {String} usermID ユーザーID
 * @param {String} targetUsermID ブロック対象のユーザーID
 * @async
 * @function getBlockedUserByUserID
 */
const registerBlockedUserM = async (usermID, targetUsermID, setErrorMessage) => {
  try {
    const blockedUserM = {
      input: {
        usermID: usermID,
        blockedUsermID: targetUsermID,
      }
    }
    await API.graphql({
      query: createBlockedUsersM,
      variables: blockedUserM,
    }).then((result) => {
      return result.data.createBlockedUsersM;
    });
  } catch (error) {
    setErrorMessage(<div>
      ブロック時にエラーが発生しました。<br />
      しばらくしてからもう一度お試しください。
    </div>);
  }
};

/**
 * ユーザーをブロック解除
 * @param {String} blockedUsersMID ブロックユーザーMID
 * @async
 * @function getBlockedUserByUserID
 */
const deleteBlockedUserM = async (blockedUsersM ,setErrorMessage) => {
  try {
    const blockedUserM = {
      input: {
        id: blockedUsersM.id,
        _version: blockedUsersM._version
      }
    }
    await API.graphql({
      query: deleteBlockedUsersM,
      variables: blockedUserM,
    }).then((result) => {
      return result.data.deleteBlockedUsersM;
    });
  } catch (error) {
    setErrorMessage(<div>
      ブロック解除時にエラーが発生しました。<br />
      しばらくしてからもう一度お試しください。
    </div>);
  }
};

export { getBlockedUserByUserID, registerBlockedUserM, deleteBlockedUserM };
