import { Grid } from "@aws-amplify/ui-react"
import { UserIcon, GoBackButton } from "../common-my-ui-components"
import { styled } from "styled-components"
import { HEADER_HEIGHT } from "../constants.js"
import logo from './img/logo/logo.png'
import '@aws-amplify/ui-react/styles.css';

const HeaderGrid = styled(Grid)`
  align-items: center;
  width: 100%;
`

const HeaderContainer = styled.div`
  background-color: ${(props) => props.theme.palette.tertiary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  height: ${HEADER_HEIGHT};
  padding: 0.2rem;
  z-index: 5000;
`

const MenuIcon = styled.span`
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 20%; /* 丸い形にする */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s; /* ホバー時のトランジション効果 */
  margin:auto;
  &:hover {
    background-color: ${(props) => (props.theme.palette.secondary.main)}; /* ホバー時の背景色 */
  }
`

const SiteTitle = styled.div`
height: ${HEADER_HEIGHT};
`;

const IconStyle = styled.div`
width: 100%;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
margin:auto;
`;

const ImgStyle = styled.img`
height: calc(${HEADER_HEIGHT} * 0.95);
position: absolute;
left: 50%;
transform: translateX(-50%);
`;

function Header({ user, onMenuClick }) {
  return (
    <HeaderContainer id="App-header">
      <HeaderGrid templateColumns="2fr 16fr 1fr 1fr">
          <GoBackButton />
          <SiteTitle>
            <ImgStyle
              src={logo}
              alt="ロゴ"
            />
          </SiteTitle>
        <IconStyle>{user && <UserIcon userM={user} />}</IconStyle>
        <MenuIcon className="material-symbols-rounded" onClick={onMenuClick}>
          menu
        </MenuIcon>
      </HeaderGrid>
    </HeaderContainer>
  )
}

export default Header
