import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import UserContext from "../UserContext";
import MessageContext from "../MessageContext";
import { openIndexedDB, saveToIndexedDB } from "./indexedDBUtils";

// 募集関連のページに遷移（マウント）した時にindexedDBにURLを保存する。
const useSaveLastViewRecruitmentPage = () => {
  const { user } = useContext(UserContext);
  const { setErrorMessage } = useContext(MessageContext);
  const location = useLocation();

  useEffect(() => {
    const saveLastViewRecruitmentPage = async () => {
      try {
        const data = { "keyPath": "keyPath", "LastViewRecruitmentPage": location.pathname };
        const db = await openIndexedDB("LastViewRecruitmentPage", user ? user.id : "UnregisteredUser", "keyPath");
        await saveToIndexedDB(db, user ? user.id : "UnregisteredUser", data);
      } catch (error) {
        setErrorMessage(<div>
          ページ遷移時にエラーが発生しました。<br />
          しばらくしてからもう一度お試しください。
        </div>)
      }
    };

    saveLastViewRecruitmentPage();
  }, [user, location.pathname]);
};

export default useSaveLastViewRecruitmentPage;
