import '@aws-amplify/ui-react/styles.css';
import '../../App.css';
import { Grid, Flex, View } from '@aws-amplify/ui-react';
import { styled } from 'styled-components';
import { Link } from "react-router-dom";
import { useState } from 'react';
import { Tabs, Tab, tabsClasses } from '@mui/material';
import kiriage from '../../common-my-ui-components/img/table/麻雀点数表切り上げ.png'
import kirisage from '../../common-my-ui-components/img/table/麻雀点数表切り下げ.png'
import { CommonAdSense } from '../../common-my-ui-components'

const RuleDetailPage = styled.div`
padding: 1em;
text-align: left;
`;

const RuleDetailH3 = styled.h3`
margin: 0.5rem 0;
text-align: left;
font-weight: bold;
/*文字の上下 左右の余白*/
padding: 0.4rem 0.5rem;
/*文字色*/
color: ${(props) => (props.theme.palette.text.black)};
/*背景色*/
background: ${(props) => (props.theme.palette.text.white)};
/*左線*/
border-left: solid 5px ${(props) => (props.theme.palette.primary.main)};
/*下線*/
border-bottom: solid 3px ${(props) => (props.theme.palette.quaternary.main)};
`;

const RuleDetailTable = styled.table`
border-collapse: collapse;
margin: 10 auto;
text-align: left;
width:100%

`;

const RuleDetailTh = styled.th`
border-bottom: 1px dashed ${(props) => (props.theme.palette.disabled.main)};
padding: 0.4em 0.5em
`;

const RuleDetailTd = styled.td`
border-bottom: 1px dashed ${(props) => (props.theme.palette.disabled.main)};
padding: 0.4em 0.5em
`;

const RuleDetailTr = styled.tr`
border-bottom: 1px dashed ${(props) => (props.theme.palette.disabled.main)};
padding: 0.4em 0.5em
`;

const RuleDetailTdTitle = styled.td`
width:30%;
`;

const RuleDetail = () => {
  // 現在表示のタブ
  const [nowTab, setNowTab] = useState("kiriage");

  // 現在表示のタブの順番
  const [nowTabNum, setNowTabNum] = useState(0);

  return (
    <Grid
      templateColumns={{ base: '1fr', large: "2fr 8fr 2fr" }}
    >
      <View rowSpan={1}>{/* PC時の両脇 空白スペース */}<CommonAdSense position="left" /></View>
      <View rowSpan={1}>
        <Flex direction={"column"} alignItems={'center'}>
          <RuleDetailPage>
            当サイトの麻雀のルールはMリーグルールを基本とします。<br />
            ※「募集ごとのルール設定」は参加した募集によって異なるので注意してください。<br /><br />

            <RuleDetailH3>募集ごとのルール設定</RuleDetailH3>
            <RuleDetailTable>
              <RuleDetailTr ><RuleDetailTh></RuleDetailTh><RuleDetailTh ></RuleDetailTh></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >プレイ人数</RuleDetailTdTitle><RuleDetailTd >3人麻雀/4人麻雀</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >局数</RuleDetailTdTitle><RuleDetailTd >東風戦/半荘戦</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >持ち点</RuleDetailTdTitle><RuleDetailTd >25,000点/自由設定</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >赤牌</RuleDetailTdTitle><RuleDetailTd >有/無<br />ありの場合、枚数を指定する</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >一発・裏ドラ・槓ドラ・槓裏ドラ</RuleDetailTdTitle><RuleDetailTd >有/無</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >喰いタン</RuleDetailTdTitle><RuleDetailTd >有/無</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >後付け</RuleDetailTdTitle><RuleDetailTd >有/無</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >喰い替え</RuleDetailTdTitle><RuleDetailTd >有/無</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >ツモ番なしリーチ</RuleDetailTdTitle><RuleDetailTd >有/無</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >フリテンツモ</RuleDetailTdTitle><RuleDetailTd >有/無</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >30符4翻の切り上げ</RuleDetailTdTitle><RuleDetailTd >有/無</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >頭ハネ</RuleDetailTdTitle><RuleDetailTd >有/無</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >連荘</RuleDetailTdTitle><RuleDetailTd >和了連荘/聴牌連荘/無</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >途中流局</RuleDetailTdTitle><RuleDetailTd >有/無<br />ありの場合、九種九牌・四風子連打・四槓流れ・三家和・四家立直から選択</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >南入・西入</RuleDetailTdTitle><RuleDetailTd >有/無<br />ありの場合、点数を指定する</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >オーラス 和了止め</RuleDetailTdTitle><RuleDetailTd >有/無</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >飛び</RuleDetailTdTitle><RuleDetailTd >有/無<br />ありの場合、持ち点0は続行</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >北の扱いについて(※3人麻雀のみ)</RuleDetailTdTitle><RuleDetailTd >抜きドラ/共通役牌</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >ツモ損(※3人麻雀のみ)</RuleDetailTdTitle><RuleDetailTd >有/無</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >花牌(※3人麻雀のみ)</RuleDetailTdTitle><RuleDetailTd >有/無</RuleDetailTd></RuleDetailTr>
            </RuleDetailTable>
            <br />


            <br />
            <RuleDetailH3>ゲームの進行について</RuleDetailH3>
            <RuleDetailTable>
              <RuleDetailTr ><RuleDetailTh ></RuleDetailTh><RuleDetailTh ></RuleDetailTh></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >優先順位</RuleDetailTdTitle><RuleDetailTd > 和了→ポン・カン→チーの順で優先する</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >ドラ</RuleDetailTdTitle><RuleDetailTd >各ドラは1枚につき1翻とし、役にしない</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >聴牌料</RuleDetailTdTitle><RuleDetailTd >場に3,000点とする</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >積み場</RuleDetailTdTitle><RuleDetailTd >連荘および平局時に次局を積み場とする<br />一本場につき300点を和了点に加算し、子の和了で積み棒を解消する</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >リーチ</RuleDetailTdTitle><RuleDetailTd >リーチ後の暗槓は可能とする<br />和了牌や面子構成の変わる槓は不可とする</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >和了</RuleDetailTdTitle><RuleDetailTd >一翻以上の役を必要とする</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >王牌</RuleDetailTdTitle><RuleDetailTd >14枚残しとする</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >連風牌の雀頭</RuleDetailTdTitle><RuleDetailTd >2符とする</RuleDetailTd></RuleDetailTr>
            </RuleDetailTable>


            <br />
            <RuleDetailH3>採用役について</RuleDetailH3>
            <Link to="/YakuList#start">
              <p>役一覧はこちら</p>
            </Link>
            <RuleDetailH3>点数計算について</RuleDetailH3>
            <Tabs value={nowTabNum}
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 },
                }
              }}
            >
              <Tab label="切り上げ満貫" onClick={() => { setNowTab("kiriage"); setNowTabNum(0); }} />
              <Tab label="切り下げ満貫" onClick={() => { setNowTab("kirisage"); setNowTabNum(1); }} />
            </Tabs>
            <div hidden={"kiriage" !== nowTab}>
              <img src={kiriage} alt="切り上げ満貫点数表" style={{ width: '100%' }} />
            </div>
            <div hidden={"kirisage" !== nowTab}>
              <img src={kirisage} alt="切り下げ満貫点数表" style={{ width: '100%' }} />
            </div>

            <RuleDetailH3>マナーについて</RuleDetailH3>
            <RuleDetailTable>
              <RuleDetailTr ><RuleDetailTh ></RuleDetailTh><RuleDetailTh ></RuleDetailTh></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >点数計算について</RuleDetailTdTitle><RuleDetailTd >点数計算ができないまたは心配な方は事前に同卓者に伝えましょう。<br />また、同卓者の点数申告が間違っていた場合などは優しく指摘するようにお願いします。</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >牌山を前に出す</RuleDetailTdTitle><RuleDetailTd > 開局時に牌山を牌１枚分だけやや右斜め上に前に押し出してください。他の人、特に対面の人が牌を取りやすいようにするためです。</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >嶺上牌をおろす</RuleDetailTdTitle><RuleDetailTd > 自身の正面に王牌が来た場合、嶺上牌をおろしてください。ツモや槓の際に腕が当たり嶺上牌が見えてしまうのを防ぐためです。</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >捨て牌は６枚切り</RuleDetailTdTitle><RuleDetailTd >捨て牌は6枚横に並べたら、次の段に並べてください。<br />捨て牌は1局あたり17〜18枚になることが多いため、6枚ずつ区切ることで誰が何を何巡目に切ったか見やすくなります。</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >ツモ牌を手牌に入れない</RuleDetailTdTitle><RuleDetailTd >打牌前にツモった牌を手牌に入れないでください。打牌までに時間がかかることや、和了時に待ちの形で点数が変わることがあるためです。</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >先ヅモをしない</RuleDetailTdTitle><RuleDetailTd >上家が打牌する前にツモをしないでください。副露時に牌を戻したとしても先ヅモした人だけが何の牌かわかってしまうためです。</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >強打、引きヅモをしない</RuleDetailTdTitle><RuleDetailTd >牌を強く卓に叩きつけないでください。牌や卓が傷つくことや、同卓者に威圧感を与えてしまいます。</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >過度な長考をしない</RuleDetailTdTitle><RuleDetailTd >過度な長考はしないでください。<br />どうしても時間がほしい場合は同卓者に「すみません」と一言かけるようにお願いします。</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >発声ははっきりとする</RuleDetailTdTitle><RuleDetailTd >リーチやポンなどの発声は他の人が聞き取りやすいように大きな声ではっきりとしてください。</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >ポンとチーについて</RuleDetailTdTitle><RuleDetailTd >チーの発声は少し間をおいてからするようにお願いします。<br />副露はチーよりもポンが優先されます。チーの副露者はポンの発声がないことを確認してから発声してください。<br />ただ明らかに間をおいたチーの発声後にポンの発声があった場合はチーを優先してください。</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >三味線行為をしない</RuleDetailTdTitle><RuleDetailTd >三味線とは麻雀を打っている時に相手を惑わすまたは誘導するような行為を指します。<br />不完全情報ゲームである麻雀の楽しみを損なわせるため、三味線行為はしないようにしてください。</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >打牌批判をしない</RuleDetailTdTitle><RuleDetailTd >トラブルの原因になるのでルール違反ではないような打ち方について批判をしないでください。</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >点棒は丁寧に渡す</RuleDetailTdTitle><RuleDetailTd >点棒を渡すときは相手の前に置いて渡すようにしてください。</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >不快になる行為をしない</RuleDetailTdTitle><RuleDetailTd >上記のマナーに限らず相手が不快になるような行為は一切しないでください。<br />全員が麻雀を楽しめるように宜しくお願いします。</RuleDetailTd></RuleDetailTr>
            </RuleDetailTable>



            <br />
            <RuleDetailH3>パオ(責任払い)/チョンボ(罰符・アガリ放棄・供託)について</RuleDetailH3>
            <RuleDetailTable>
              <RuleDetailTr ><RuleDetailTh ></RuleDetailTh><RuleDetailTh ></RuleDetailTh></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >はじめに</RuleDetailTdTitle><RuleDetailTd >本項目はプレイヤー同士のトラブルを避けるために定めた基準になります。プレイヤー全員が納得できる場合はこの基準に従う必要はありません。</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >パオ(責任払い)について</RuleDetailTdTitle><RuleDetailTd >パオとは大三元や大四喜、四槓子を確定させる牌の副露をさせ、その役でアガりを許してしまったプレイヤーに科される罰則のことです。<br />ツモでアガった場合は点数のすべてを、自分以外の誰かがロンされた場合は点数の半分を責任払いする必要があります。</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >チョンボについて</RuleDetailTdTitle><RuleDetailTd >チョンボとは麻雀における反則行為です。反則の重さによって罰則は以下の3種類に分類されます。</RuleDetailTd></RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >罰符について</RuleDetailTdTitle>
                <RuleDetailTd >
                  罰符とはその局の続行が不可能な反則をしたプレイヤーに科される罰則のことです。チョンボをしたプレイヤーが満貫相当の点数を全員に支払います。(そのプレイヤーが親であれば4,000点ずつ、子であれば親に4,000点、子に2,000点ずつ支払います)<br />罰符となるのは以下のケースです。<br />
                  ・倒牌を伴う誤ロンや誤ツモ<br />
                  &emsp;アガリ牌を間違える、フリテンや役なしの状態でのアガリ宣言ののち手牌を倒してしまった場合<br />
                  ・ノーテンリーチ<br />
                  &emsp;聴牌指定ない状態でリーチをし流局になってしまった場合(他の誰かがアガった場合は罰符にはなりません)<br />
                  ・リーチ後の待ちが変わる暗槓<br />
                  &emsp;リーチ後に待ちが変わる暗槓をし流局もしくはアガリ宣言をしてしまった場合(他の誰かがアガった場合は罰符にはなりません)<br />
                  ・修復不可能なほど牌山を崩す<br />
                </RuleDetailTd>
              </RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >アガリ放棄について</RuleDetailTdTitle>
                <RuleDetailTd >
                  アガリ放棄とはその局の続行が可能な反則をしたプレイヤーに科される罰則のことです。チョンボをしたプレイヤーはその局でアガることができなくなります。リーチや副露などもできず、流局時に聴牌していた場合でもノーテン扱いとします。<br />罰符となるのは以下のケースです。<br />
                  ・倒牌を伴わない誤ロンや誤ツモ<br />
                  ・喰い替え<br />
                  ・多牌・少牌<br />
                  ・打牌を伴う誤副露<br />
                  &emsp;晒す牌を間違え面子にならない、副露できない牌に対して副露を宣言してしまった場合<br />

                </RuleDetailTd>
              </RuleDetailTr>
              <RuleDetailTr ><RuleDetailTdTitle >供託について</RuleDetailTdTitle>
                <RuleDetailTd >
                  供託とは軽微な反則をしたプレイヤーに科される罰則のことです。チョンボをしたプレイヤーは供託として1,000点を場に支払います。<br />罰符となるのは以下のケースです。<br />
                  ・打牌を伴わない誤副露<br />
                  ・修復できる範囲で牌山を崩す<br />
                </RuleDetailTd>
              </RuleDetailTr>
            </RuleDetailTable>
          </RuleDetailPage>
        </Flex>
      </View>
      <View rowSpan={1}>{/* PC時の両脇 空白スペース */}<CommonAdSense position="right" /></View>
    </Grid>

  );
};

export default RuleDetail;