import { getTalkRoomM, recruitmentListMSByTalkRoomMIdAndId } from "../graphql/queries"
import { API } from "aws-amplify"
import { getUserMs } from "./getUserMs"

// トークルーム名画設定されている場合はその名前、
// トークルームに紐づく募集がある場合は募集のタイトル、ない場合はユーザ一覧を返す
const getRoomName = async (currentUserID, talkRoomID) => {
  let roomName = null

  const opt1 = {
    talkRoomMId: talkRoomID
  }
  await API.graphql({ query: recruitmentListMSByTalkRoomMIdAndId, variables: opt1 }).then((result) => {
    let recruitmentMs = result.data.recruitmentListMSByTalkRoomMIdAndId.items
    if (recruitmentMs.length > 0) {
      roomName = recruitmentMs[0].title
    }
  })

  const opt2 = {
    id: talkRoomID
  }
  await API.graphql({ query: getTalkRoomM, variables: opt2 }).then((result) => {
    let talkRoomM = result.data.getTalkRoomM;
    if (talkRoomM.roomName) {
      roomName = talkRoomM.roomName;
    }
  })

  if (roomName === null) {
    const userMs = await getUserMs(talkRoomID)
    roomName = userMs
      .filter((userM) => userM.id !== currentUserID)
      .map((userM) => userM.name)
      .join(", ")
  }

  // 半角スペースのみで構成されているかのチェック
  if (roomName && roomName.trim().length === 0) {
    return roomName // 半角スペースのみの場合はそのまま返す
  }

  if (roomName == false) {
    roomName = "誰もいません..."
  }

  return roomName
}

export { getRoomName }
