import { useParams } from "react-router-dom";
import { DataStore } from '@aws-amplify/datastore';
import { UserIconListRow, } from '../../common-my-ui-components';
import { MessageInput, TalkRoomHeader, MessageList, } from './my-ui-components';
import UserContext from "../../UserContext";
import MessageContext from "../../MessageContext";
import { MessageM } from '../../models';
import { useState, useEffect, useContext, useRef, useLayoutEffect } from 'react';
import Modal from 'react-modal';
import { getUserMs } from "../../my-func/getUserMs";
import { styled } from 'styled-components';
import useSaveLastViewTalkRoomPage from "../../my-func/useSaveLastViewTalkRoomPage";
import { getTalkRoomM } from "../../graphql/queries";
import { API, graphqlOperation } from 'aws-amplify';
import { Link } from "react-router-dom";


const TalkRoomBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%; 
`;

const Header = styled.div`
  flex: 0 0 auto; 
`;

const MessageListBody = styled.div`
  flex: 1 1 auto; 
  overflow: auto;
  overscroll-behavior: contain;
`;

const Footer = styled.div`
  flex: 0 0 auto; 
`;


// 動的スタイル
const MessageListBodyComponent = ({ bottom, top, children }) => {
  return <MessageListBody bottom={bottom} top={top}>{children}</MessageListBody>;
}

// ルート要素のフォントサイズを取得する関数
const getRootFontSize = () => {
  const fontSize = window.getComputedStyle(document.documentElement).fontSize;
  return parseFloat(fontSize);
}

// remの文字サイズをピクセルに変換
const remToPx = (remValue) => {
  return remValue * getRootFontSize();
}

// トークルームを表示するコンポーネント
const TalkRoom = (() => {
  const { user } = useContext(UserContext);
  const { setErrorMessage } = useContext(MessageContext);
  const [userMs, setUserMs] = useState([]);
  const { talkRoomID } = useParams();
  const [isModalOpen, setModalOpen] = useState(false);
  useSaveLastViewTalkRoomPage();
  const [talkRoomM, setTalkRoomM] = useState([]);


  // MessageInputコンポーネントの送信ボタンを押された時のイベントハンドラ（メッセージ送信）
  const handleSend = async (message) => {
    const newMessage = new MessageM({
      usermID: user.id,
      content: message,
      talkroommID: talkRoomID,
      sentAt: new Date().toISOString(),
    });
    await DataStore.save(newMessage);
  };

  // MessageInputコンポーネントのファイルが選択された時のイベントハンドラ
  const handleFileSelect = (file) => {
    // ここでファイルを扱う処理を書く（例：ファイルのアップロード等）
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const getTalkRoomMById = async () => {
    try {
      //トークルーム情報を取得する
      const opt = {
        id: talkRoomID
      };
      const talkRoom = (await API.graphql(graphqlOperation(
        getTalkRoomM, opt))).data.getTalkRoomM;
      setTalkRoomM(talkRoom);

    } catch (error) {
      setErrorMessage(<div>
        トークルーム情報取得時にエラーが発生しました。<br />
        しばらくしてからもう一度お試しください。
      </div>);
    }
  };


  // ユーザ一覧取得
  useEffect(() => {
    getUserMs(talkRoomID).then((result) => {
      setUserMs(result);
    });
    getTalkRoomMById();
  }, []);

  // アプリケーション全体に適用しているヘッダーを非表示に設定　トークルームがアンマウントされる際にヘッダーを再表示
  useEffect(() => {
    const elem1 = document.getElementById("App-header");
    elem1.style.display = "none";
    const elem2 = document.getElementById("App-footer");
    elem2.style.display = "none";

    const handleUnload = () => {
      const elem1 = document.getElementById("App-header");
      elem1.style.display = "block";
      const elem2 = document.getElementById("App-footer");
      elem2.style.display = "block";
    };

    return () => {
      handleUnload();
    };
  }, []);

  // 募集が見つからない場合
  if (!talkRoomM) {
    return <div>
      このトークは存在しません
      <Link to="/TalkRoomList">
        <p>トーク一覧に戻る</p>
      </Link>
    </div>
  }
  // 募集が削除済みの場合
  if (talkRoomM._deleted) {
    return <div>
      このトークは削除済みです
      <Link to="/TalkRoomList">
        <p>トーク一覧に戻る</p>
      </Link>
    </div>
  }
  // 参加者がいない場合表示しない
  if (userMs.length == 0) {
    return
  }
  // アクセスユーザーが参加者ではない場合
  var isTalkRoomMember = false;
  userMs.map((userM) => {
    if (userM.id === user.id) isTalkRoomMember = true;
  })
  if (!isTalkRoomMember) {
    return <div>
      このトークには参加していません。
      <Link to="/TalkRoomList">
        <p>トーク一覧に戻る</p>
      </Link>
    </div>
  }

  return (
    <>
      <TalkRoomBody>
        <Header >
          <TalkRoomHeader talkRoomID={talkRoomID} userMs={userMs} openModal={openModal} />
        </Header>

        <MessageListBodyComponent>
          <MessageList talkRoomID={talkRoomID} userMs={userMs} />
        </MessageListBodyComponent>

        <Footer id="footer" >
          <MessageInput onSend={handleSend} onFileSelect={handleFileSelect} talkRoomID={talkRoomID} />
        </Footer>

      </TalkRoomBody>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="参加者一覧"
        style={{
          overlay: {
            zIndex: 9999
          }
        }}
      >
        <div>参加者一覧</div>
        <hr />
        <UserIconListRow userMs={userMs} />
      </Modal>
    </>
  );
});

export default TalkRoom;
