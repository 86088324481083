import { styled } from "styled-components";
import { Flex } from '@aws-amplify/ui-react';
import {
  MainTitle,
  SubTitle,
  FullLineSubTitle,
  CommonLink,
  Ul,
  Li,
  LeftText,
  LeftChat,
  RightChat,
  BoldText,
  Kugiri10000,
  Table,
  Th,
  Td
} from "../../../../common-my-ui-components";
import RememberBasic1 from '../../../../common-my-ui-components/img/introduction/RememberBasic1.png';
import RememberBasic2 from '../../../../common-my-ui-components/img/introduction/RememberBasic2.png';
import RememberBasic3 from '../../../../common-my-ui-components/img/introduction/RememberBasic3.png';
import RememberBasic4 from '../../../../common-my-ui-components/img/introduction/RememberBasic4.png';
import Tanyao from '../../../../common-my-ui-components/img/Yaku/Tanyao.png';
import Tyuren from '../../../../common-my-ui-components/img/Yaku/Tyuren.png';
import teacherSmile from '../../../../common-my-ui-components/img/miniChar/teacherSmile.png';
import teacherPoint from '../../../../common-my-ui-components/img/miniChar/teacherPoint.png';
import teacherHappy from '../../../../common-my-ui-components/img/miniChar/teacherHappy.png';
import girlQuestion from '../../../../common-my-ui-components/img/miniChar/girlQuestion.png';
import girlHappy from '../../../../common-my-ui-components/img/miniChar/girlHappy.png';

// ページ全体の設定
const Page = styled.div`
width: 100%; 
`;

const Contents = styled.div`
width: 100%;
padding: 1rem;
margin: 0 auto;
text-align: center;
`;

const RememberBasic = () => {
  return (
    <Page>
      <Contents>
        <MainTitle text={"まずはこれを覚えよう！"} tabText={"はじめての麻雀"} textAlign={"left"} />
        <LeftChat src={teacherPoint} alt="teacherPoint" text={(<>麻雀の基本的な用語や内容を説明しますね！</>)} />

        <SubTitle text={"麻雀の基本"} textAlign={"left"} />
        <LeftText text={<>
          麻雀は<BoldText text={"牌(パイ/ハイ)"} />とよばれる直方体の駒を使うゲームです。
          ゲームの最初に配られる13枚を<BoldText text={"配牌(ハイパイ)"} />といいます。<br />
          <BoldText text={"牌山(ハイヤマ)"} />から1枚取って(=<BoldText text={"自摸(ツモ)"} />)、自分の手元の牌(<BoldText text={"手牌(テハイ)"} />)と1枚交換します。<br />
          不要になった牌を<BoldText text={"捨て牌(ステハイ)"} />といい、<BoldText text={"河(カワ)"} />に捨てます。<br />
        </>} />
        <img src={RememberBasic1} style={{ width: '100%', maxWidth: '500px' }} alt="麻雀卓上説明図" />

        <SubTitle text={"和了の形"} textAlign={"left"} />
        <LeftText text={<>
          14枚の組み合わせの完成形を作ることを<BoldText text={"和了(アガリ)"} />といいます。<br />
          和了の形は「3枚1セット」が4つ、「2枚1セット」が1つの合計14枚で構成されます。<br />
          <img src={RememberBasic2} style={{ width: '100%' }} alt="手牌" />
          「3枚1セット」のことを<BoldText text={"面子(メンツ)"} />、「2枚1セット」を<BoldText text={"雀頭(ジャントウ)"} />といいます。<br />
          <br />
          面子には<BoldText text={"順子(シュンツ)"} />と<BoldText text={"刻子(コーツ)"} />の2種類があります。<br />
          順子は「2・3・4」といった連続した同じ種類の数牌3枚で構成されている面子のことです。<br />
          刻子は数牌・字牌かかわらず、同じ牌3枚で構成されている面子のことです。<br />
          <br />
          また、同じ牌2枚のセットのことを<BoldText text={"対子(トイツ)"} />ともいいます。
        </>} />

        <RightChat src={girlQuestion} alt="girlQuestion" text={(<>和了って14枚だよね！<br />手牌に13枚しかない！1枚なくしちゃった！</>)} />
        <LeftChat src={teacherHappy} alt="teacherHappy" text={(<>手牌は13枚で大丈夫です。<br />ツモで持ってきた牌でちょうど14枚になりますよね。<br />
          ちなみにあと1枚で和了になる状態を<BoldText text={"聴牌(テンパイ)"} />といいます。</>)} />

        <SubTitle text={"和了の条件"} textAlign={"left"} />
        <LeftText text={<>
          麻雀でアガるためには<BoldText text={"役(ヤク)"} />が必要になります。<br />
          役とは特別な条件や、特定の牌の組み合わせのことです。<br />
          その難しさによって<BoldText text={"飜(ハン)"} />という点数の単位がそれぞれ決められています。<br />
        </>} />
        <RightChat src={girlHappy} alt="girlHappy" text={(<>
          <CommonLink to="/RuleIntroduction/WhatIsMahjong#start" text={"麻雀ってなに？"} />
          で出てきたこの２つはどんな役なの？
        </>)} />
        <img src={Tyuren} style={{ width: '100%' }} alt="九蓮宝燈" /><br />
        <img src={Tanyao} style={{ width: '100%' }} alt="断么九" /><br />
        <LeftChat src={teacherPoint} alt="teacherSmile" text={(<>
          上は九蓮宝燈(チューレンポートー)、下は断么九(タンヤオ)という役です。<br />
          九蓮宝燈は<BoldText text={"役満(ヤクマン)"} />といって麻雀の中で最も難しい役のうちの1つです。<br />
        </>)} />
        <RightChat src={girlQuestion} alt="girlQuestion" text={(<>見慣れない漢字ばっかり……<br />他にはどんな役があるの？</>)} />
        <LeftChat src={teacherSmile} alt="teacherSmile" text={(<>聴牌時にリーチと宣言し1,000点を支払うことが条件になる<BoldText text={"立直(リーチ)"} />は有名ですね。<br />
          まだまだたくさんあるので<CommonLink to="/YakuList#start" text={"麻雀の役一覧"} />もチェックしてみてください！
        </>)} />

        <SubTitle text={"麻雀牌の種類"} textAlign={"left"} />
        <LeftText text={<>
          麻雀牌には数字で書かれた<BoldText text={"数牌(シューパイ/すうぱい)"} />と、文字で書かれた<BoldText text={"字牌(ツーパイ/じはい)"} />の2種類存在します。<br />
          全34種類、4枚ずつあるので合計136枚でゲームを行います。
        </>} />
        <FullLineSubTitle text={"数牌"} />
        <LeftText text={<>
          数牌は<BoldText text={"萬子(マンズ)"} />、<BoldText text={"筒子(ピンズ)"} />、<BoldText text={"索子(ソーズ)"} />の3種類に分けられ、それぞれ1〜9まであります。
        </>} />
        <img src={RememberBasic3} style={{ width: '100%', maxWidth: '500px' }} alt="数牌" />
        <LeftText text={<>
          数牌の読み方も特殊で、数字と組み合わせて次の表のように読むことが一般的です。
        </>} />
        <Table text={(<>
          <tr><Th text={"数字"} /><Th text={"萬子"} /><Th text={"筒子"} /><Th text={"索子"} /></tr>
          <tr><Td text={"1"} /><Td text={"イーワン"} /><Td text={"イーピン"} /><Td text={"イーソー"} /></tr>
          <tr><Td text={"2"} /><Td text={"リャンワン"} /><Td text={"リャンピン"} /><Td text={"リャンソー"} /></tr>
          <tr><Td text={"3"} /><Td text={"サンワン"} /><Td text={"サンピン"} /><Td text={"サンソー"} /></tr>
          <tr><Td text={"4"} /><Td text={"スーワン"} /><Td text={"スーピン"} /><Td text={"スーソー"} /></tr>
          <tr><Td text={"5"} /><Td text={"ウーワン"} /><Td text={"ウーピン"} /><Td text={"ウーソー"} /></tr>
          <tr><Td text={"6"} /><Td text={"ローワン"} /><Td text={"ローピン"} /><Td text={"ローソー"} /></tr>
          <tr><Td text={"7"} /><Td text={"チーワン"} /><Td text={"チーピン"} /><Td text={"チーソー"} /></tr>
          <tr><Td text={"8"} /><Td text={"パーワン"} /><Td text={"パーピン"} /><Td text={"パーソー"} /></tr>
          <tr><Td text={"9"} /><Td text={"キューワン"} /><Td text={"キューピン"} /><Td text={"キューソー"} /></tr>
        </>)} />
        <RightChat src={girlHappy} alt="girlHappy" text={(<>イーソーは鳥の絵柄なんだ！</>)} />

        <FullLineSubTitle text={"字牌"} />
        <LeftText text={<>
          字牌は<BoldText text={"風牌(カゼハイ)"} />と<BoldText text={"三元牌(サンゲンパイ)"} />の2種類に分けられます。
        </>} />
        <img src={RememberBasic4} style={{ width: '100%', maxWidth: '500px' }} alt="字牌" />
        <Table text={(<>
          <tr><Th text={"東"} /><Th text={"南"} /><Th text={"西"} /><Th text={"北"} /></tr>
          <tr><Td text={"トン"} /><Td text={"ナン"} /><Td text={"シャー"} /><Td text={"ペー"} /></tr>
          <tr><br /></tr>
          <tr><Th text={"白"} /><Th text={"発"} /><Th text={"中"} /></tr>
          <tr><Td text={"ハク"} /><Td text={"ハツ"} /><Td text={"チュン"} /></tr>
        </>)} />
        <LeftChat src={teacherPoint} alt="teacherPoint" text={(<>麻雀では東西南北ではなく、東南西北の順番が重要です。</>)} />

        <br />
        <Kugiri10000 />
        <br />

        <RightChat src={girlQuestion} alt="girlQuestion" text={(<>
          イー、リャン、トン、ナン……<br />
          配牌から和了の形、牌の読み方まで、覚えることがたくさん！
        </>)} />
        <LeftChat src={teacherHappy} alt="teacherHappy" text={(<>
          最初は覚えることが多いかもしれませんが、麻雀の基本を押さえればあとはプレイしながら自然と覚えられると思いますよ。
        </>)} />
        <Flex direction={"column"} alignItems={'center'}>
          <Ul text={(
            <>
              <Li text={<CommonLink to="/RuleIntroduction/MahjongIntroduction#start" text={"麻雀ルールTOP"} />} />
              <Li text={<CommonLink to="/RuleIntroduction/LearnGameFlow#start" text={"次ページ ゲームの流れを知ろう！"} />} />
              <Li text={<CommonLink to="/RuleIntroduction/WhatIsMahjong#start" text={"前ページ 麻雀ってなに？"} />} />
            </>)} />
        </Flex>
      </Contents>
    </Page>
  );
};

export default RememberBasic;
