import '@aws-amplify/ui-react/styles.css';
import '../App.css';
import { useState, useEffect, useContext } from 'react';
import React from 'react';
import { Dialog, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import MessageContext from "../MessageContext";
import { Box, Button, Alert as MUIAlert } from "@mui/material";
import styled from "styled-components";

const Alert = styled(MUIAlert)`
  position: fixed;
  z-index: 9999;
`;

const FlexColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
// successMessage, setSuccessMessageは緑色の成功メッセージ
// errorMessage, setErrorMessageは赤色の警告メッセージ
// infoMessage, setInfoMessageは青色の情報メッセージ
// confirmMessage, setConfirmMessageは確認ダイアログメッセージ
const ErrorMessageDialog = () => {
  // エラーメッセージを取得
  const {
    successMessage, setSuccessMessage,
    errorMessage, setErrorMessage,
    infoMessage, setInfoMessage,
    confirmMessage, setConfirmMessage
  } = useContext(MessageContext);

  // メッセージダイアログ
  const [dispMessage, setDispMessage] = useState(null);

  // メッセージの表示をリセットする
  const resetAllMessage = () => {
    setConfirmMessage(null);
    setSuccessMessage(null);
    setErrorMessage(null);
    setInfoMessage(null);
    setDispMessage(null);
  }

  // エラーメッセージがある場合ダイアログを表示
  useEffect(() => {
    if (successMessage) {
      setDispMessage(
        <Alert
          onClose={resetAllMessage}
          variant="filled"
          severity="success">{successMessage}
        </Alert>
      )
      setTimeout(resetAllMessage, 5000);
    } else if (errorMessage) {
      setDispMessage(
        <Alert
          onClose={resetAllMessage}
          variant="filled"
          severity="error">{errorMessage}
        </Alert>
      )
      setTimeout(resetAllMessage, 5000);
    } else if (infoMessage) {
      setDispMessage(
        <Alert
          onClose={resetAllMessage}
          variant="filled"
          severity="info">{infoMessage}
        </Alert>
      )
      setTimeout(resetAllMessage, 5000);
    } else if (confirmMessage) {
      setDispMessage(
        <Dialog open={true}>
          <DialogContent>
            <DialogContentText>
              {confirmMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={resetAllMessage}>確認</Button>
          </DialogActions>
        </Dialog>
      );
    }
  }, [successMessage, errorMessage, infoMessage, confirmMessage]);

  return (
    <FlexColumn>
      {dispMessage}
    </FlexColumn>
  );
};

export default ErrorMessageDialog;
