import { Link, useParams, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import UserContext from "../../UserContext";
import { API } from "aws-amplify";
import { getUserM } from "../../graphql/queries";
import { UserIcon } from "../../common-my-ui-components";
import { ProfileActionButton } from "./my-ui-components";
import { Flex } from '@aws-amplify/ui-react';

import { Box, Typography, Button } from "@mui/material";
import styled from "styled-components";
import {
  PREFECTURES,
  PROFILEITEMGROUP1,
  PROFILEITEMGROUP2,
  PROFILEITEMGROUP3,
} from "../../constants.js";

const ProfileContainer = styled(Box)`
  padding: 2rem;
  gap: 1rem;
`;

const ProfileItem = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => (props.theme.palette.quinary.main)};
  padding: 1rem 1rem;
  border-radius: 5px;
`;

const ProfileIcon = styled(ProfileItem)`
  justify-content: center;
  background-color: initial;
`;

const ItemName = styled(Typography)`
color: ${(props) => props.theme.palette.text.green};
  min-width: 4rem;
  text-align: start;
`;

const ItemValue = styled(Typography)`

  word-break: break-all;
  white-space: pre-line;
  text-align: start;
`;

const IconContainer = styled(Box)`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const SubTitle = styled(Typography)`
  display: Flex;
  justify-content: start;
  padding-top: 1.5rem;
`;

const Profile = () => {
  const { userID } = useParams();
  const [profileUser, setProfileUser] = useState(null);
  const [isExistUser, setIsExistUser] = useState(null);
  const { user } = useContext(UserContext);
  const [profileItems, setProfileItems] = useState({
    group1: [],
    group2: [],
    group3: []
  });

  const generateProfileItems = (group, user) => {
    return group.items.map((item) => ({
      label: item.label,
      value: user[item.valueName],
      labelDisabled: item.labelDisabled,
    }));
  };

  useEffect(() => {
    API.graphql({ query: getUserM, variables: { id: userID } }).then(
      ({ data: { getUserM } }) => {
        // ユーザーが見つからない場合はURL上の入力IDが間違っている
        if (!getUserM || getUserM._deleted) {
          setIsExistUser(false);
          return;
        }
        setIsExistUser(true);
        setProfileUser(getUserM);
        setProfileItems({
          group1: generateProfileItems(PROFILEITEMGROUP1, getUserM),
          group2: generateProfileItems(PROFILEITEMGROUP2, getUserM),
          group3: generateProfileItems(PROFILEITEMGROUP3, getUserM),
        });
      }
    );
  }, [userID, user]);

  if (isExistUser === null) {
    return (<div></div>)
  } else if (!isExistUser) {
    return (<div>
      このユーザーは存在しません
      <Link to="/">
        <p>ホームに戻る</p>
      </Link>
    </div>)
  }

  return (
    <ProfileContainer>
      {profileUser && user ? (
        <ProfileActionButton
          profileUserId={profileUser.id}
          currentUserId={user.id}
        />
      ) : (
        <></>
      )}
      {isExistUser ? (
        <Flex direction={"column"} >
          <IconContainer>
            <UserIcon userM={profileUser} size={"6rem"} />
          </IconContainer>

          {Object.values(profileItems).map((group, idx) => (
            <>
              <SubTitle variant="subtitle2">
                {
                  [PROFILEITEMGROUP1, PROFILEITEMGROUP2, PROFILEITEMGROUP3][idx]
                    .groupName
                }
              </SubTitle>
              {group.map((item) => (
                <ProfileItem key={item.label}>
                  {!item.labelDisabled && <ItemName variant="caption">{item.label}</ItemName>}
                  <ItemValue variant="caption">{item.value}</ItemValue>
                </ProfileItem>
              ))}
            </>
          ))}
        </Flex>
      ) : (
        <></>
      )}
    </ProfileContainer>
  );
};

export default Profile;
