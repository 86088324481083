import React from 'react';
import { Link } from "react-router-dom";
import { styled } from 'styled-components';
import { Button } from '@mui/material';
import  haku from '../../common-my-ui-components/img/tile/haku.png'
import  m4 from '../../common-my-ui-components/img/tile/4m.png'

const Page = styled.div`
padding: 1rem;
margin: 0 auto;
`;

const H1 = styled.h1`
margin: 1rem;
color:${(props) => (props.theme.palette.primary.main)};
font-weight: bold;
font-size: 3rem;
`;

const Contents = styled.div`
margin: 2rem;
`;

const NotFound = () => {
  return (
    <Page>
      <H1 >
        Not Found!
      </H1>

      <div>
        <img src={m4} style={{ width: '30%', maxWidth: '200px' }} alt="4" />
        <img src={haku} style={{ width: '30%', maxWidth: '200px' }} alt="0" />
        <img src={m4} style={{ width: '30%', maxWidth: '200px' }} alt="4" />
      </div>

      <Contents>
        申し訳ございません。<br />
        お探しのページは見つかりませんでした。<br />
        アドレスが変更されているか、ページが削除されている可能性があります。
      </Contents>

      <Link to="/" >
        <Button variant="contained" color="primary" size="large">ホームに戻る</Button>
      </Link>
    </Page>
  );
}

export default NotFound;
