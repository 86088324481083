import { Flex} from '@aws-amplify/ui-react';
import { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import { styled } from "styled-components";

const PagenationFlex=styled(Flex)`
word-break: break-all;
width: 100%;
`;

/**
 * dispComponentList ページネーションで表示したいComponentの配列  
 * dispComponentCount 1ページあたりに表示する数を引数として渡す  
 * siblingCount 現在のページ前後に表示するページ数
 * keyTime ページング更新指示の時間
 * @param {dispComponentList,dispComponentCount,siblingCount,keyTime} props 
 * @returns 
 */
const Pagenation = (props) => {
  // 現在のページ数を保存
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  // 現在のページで表示するコンポーネントリスト
  const [currentPageContents, setCurrentPageContents] = useState();

  // 表示する全コンポーネントリスト
  const Componentlist = props.dispComponentList;
  // 1ページに表示するコンポーネント数
  const count = props.dispComponentCount;
  // 総ページ数
  const totalPages = Math.ceil(Componentlist.length / count);
  // 現在のページ前後に表示するページ数
  const siblingCount = props.siblingCount;
  // ページング更新指示の時間
  const keyTime=props.keyTime;


  // 現在のページで表示するコンポーネントリストを更新
  useEffect(() => {
    const dispComponentlist = []
    for (let i = 0; i < count; i++) {
      dispComponentlist.push(Componentlist[count * (currentPageIndex - 1) + i])
    }
    setCurrentPageContents(
      <PagenationFlex direction={"column"} alignItems={'center'} >
        {dispComponentlist}
      </PagenationFlex>)
  }, [currentPageIndex, keyTime]);

  useEffect(() => {
    setCurrentPageIndex(1)
  }, [keyTime]);


  //数字を直接選択した場合そのページに進む
  const handleOnChange = (e,newPageIndex) => {
    setCurrentPageIndex(newPageIndex);
  };

  return (
    <>
      <Pagination size="small" 
        page={currentPageIndex}
        count={totalPages}
        onChange={handleOnChange}
        siblingCount={siblingCount}
      />
     {currentPageContents}
      <Pagination size="small" 
        page={currentPageIndex}
        count={totalPages}
        onChange={handleOnChange}
        siblingCount={siblingCount}
      />
    </>
  );
};

export default Pagenation;
