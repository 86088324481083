import UserIcon from "./UserIcon";
import { ListItem, ListItemText, ListItemAvatar, Box, } from '@mui/material';
import { Link } from "react-router-dom";
import styled from 'styled-components';

const IconContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const CustomLink = styled(Link)`
text-decoration: none;
color: inherit;
&:hover {
    color: ${(props) => (props.theme.palette.text.link)};
  }
`;

// ユーザアイコンとユーザ名をセットで縦方向に表示するコンポーネント
const UserIconListRow = ({ userMs }) => {
    return userMs.map(userM => (
        <ListItem>
            <ListItemAvatar>
                <IconContainer>
                    <UserIcon userM={userM} />
                </IconContainer>
            </ListItemAvatar>
            {!userM || userM._deleted ?
                <ListItemText primary={"削除ユーザー"} />
                :
                <CustomLink to={`/Profile/${userM.id}`} >
                    <ListItemText primary={userM.name} />
                </CustomLink>
            }
        </ListItem>
    ));
};

export default UserIconListRow;
