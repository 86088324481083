import { useState, useEffect, useRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

const InstallButton = () => {
  const [showInstallMessage, setShowInstallMessage] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [installCancelled, setInstallCancelled] = useState(false);

  const handleCloseButton = () => {
    setShowInstallMessage(false);
  };

  const snackbarRef = useRef(null);
  let touchStartPosY = 0;

  useEffect(() => {
    const handleTouchStart = (e) => {
      touchStartPosY = e.touches[0].clientY;
    };

    const handleTouchMove = (e) => {
      const touchMovePosY = e.touches[0].clientY;
      if (touchStartPosY - touchMovePosY > 50) {
        setShowInstallMessage(false);
      }
    };

    const snackbarElem = snackbarRef.current;
    if (snackbarElem) {
      snackbarElem.addEventListener("touchstart", handleTouchStart);
      snackbarElem.addEventListener("touchmove", handleTouchMove);
    }

    return () => {
      if (snackbarElem) {
        snackbarElem.removeEventListener("touchstart", handleTouchStart);
        snackbarElem.removeEventListener("touchmove", handleTouchMove);
      }
    };
  }, [showInstallMessage]);
  useEffect(() => {
    const beforeInstallPromptHandler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      if (!installCancelled) {
        setShowInstallMessage(true);
      }
    };

    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.indexOf("iphone") === -1 && ua.indexOf("ipad") === -1) {
      window.addEventListener(
        "beforeinstallprompt",
        beforeInstallPromptHandler
      );

      return () => {
        window.removeEventListener(
          "beforeinstallprompt",
          beforeInstallPromptHandler
        );
      };
    }
  }, [installCancelled]);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "dismissed") {
          // インストールがキャンセルされた場合
          setInstallCancelled(true);
        }
        setDeferredPrompt(null);
      });
      setShowInstallMessage(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowInstallMessage(false);
  };

  return (
    <Snackbar
      ref={snackbarRef}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      open={showInstallMessage}
      onClose={handleClose}
      action={
        <>
          <Button color="inherit" size="small" onClick={handleInstallClick}>
            アプリをインストール
          </Button>
          <IconButton size="small" color="inherit" onClick={handleCloseButton}>
            ×
          </IconButton>
        </>
      }
      style={{ zIndex: 9999 }}
    />
  );
};

export default InstallButton;
