import { styled } from "styled-components";
import { Flex } from '@aws-amplify/ui-react';
import {
  MainTitle,
  CommonLink,
  Ul,
  Li,
  LeftText,
  LeftChat,
  RightChat,
  BoldText,
  Kugiri10000
} from "../../../../common-my-ui-components";
import introduction1 from '../../../../common-my-ui-components/img/introduction/WhatIsMahjong1.png';
import Tanyao from '../../../../common-my-ui-components/img/Yaku/Tanyao.png';
import Tyuren from '../../../../common-my-ui-components/img/Yaku/Tyuren.png';
import teacherSmile from '../../../../common-my-ui-components/img/miniChar/teacherSmile.png';
import teacherPoint from '../../../../common-my-ui-components/img/miniChar/teacherPoint.png';
import girlQuestion from '../../../../common-my-ui-components/img/miniChar/girlQuestion.png';

// ページ全体の設定
const Page = styled.div`
width: 100%; 
`;

const Contents = styled.div`
width: 100%;
padding: 1rem;
margin: 0 auto;
text-align: center;
`;

const WhatIsMahjong = () => {
  return (
    <Page>
      <Contents>
        <MainTitle text={"麻雀ってなに？"} tabText={"はじめての麻雀"} textAlign={"left"} />
        <LeftText text={<>
          麻雀とは、特定の<BoldText text={"絵の組み合わせ"} />を相手より早く完成させることを目指すゲームです。<br />
          <br />
          まず<BoldText text={"13枚"} />の手牌が配られ、順番に牌山から1枚ずつ引き、手牌のいらない1枚を捨て、絵の組み合わせの完成形を目指します。<br />
          完成形は<BoldText text={"「3枚1セット」が4つ"} />と、<BoldText text={"「2枚1セット」が1つ"} />の合計14枚の形です。<br />
          <img src={introduction1} style={{ width: '100%' }} alt="和了形" />
          <LeftChat src={teacherPoint} alt="teacherPoint" text={(<>漢数字や竹の絵柄など、同じ種類をセットにできますよ。</>)} />
          <br />
          <Kugiri10000 />
          <br />
          麻雀は基本的に4人でプレイし、<BoldText text={"最初に完成形を作った人"} />だけが点数をもらえます。<br />
          点数は完成形の難易度によって決まります。<br />
          <LeftChat src={teacherSmile} alt="teacherSmile" text={(<>例えば、上と下の完成形では上の方が点数が高そうに見えませんか？</>)} />
          <img src={Tyuren} style={{ width: '100%' }} alt="九蓮宝燈" /><br />
          <img src={Tanyao} style={{ width: '100%' }} alt="断么九" /><br />
        </>} />
        <RightChat src={girlQuestion} alt="girlQuestion" text={(<>
          漢字の絵柄ばっかりで集めるのが大変そう！<br />
          最初は難しそうに見えたけど、説明を読んで少し理解できたかも。<br />
          完成形を目指して進めていくっていうのが面白そう！
        </>)} />
        <LeftChat src={teacherSmile} alt="teacherSmile" text={(<>
          細かいルールはありますが、実は麻雀でやることはこれだけです！<br />
          麻雀はいろんな役や戦略を覚えていくと、さらに面白さが増すのでぜひ挑戦してみてください！<br/>
          次の章からは麻雀用語を用いて説明していきますね。
        </>)} />
        <Flex direction={"column"} alignItems={'center'}>
          <Ul text={(
            <>
              <Li text={<CommonLink to="/RuleIntroduction/MahjongIntroduction#start" text={"麻雀ルールTOP"} />} />
              <Li text={<CommonLink to="/RuleIntroduction/RememberBasic#start" text={"次ページ まずはこれを覚えよう！"} />} />
            </>
          )} />
        </Flex>
      </Contents>
    </Page>
  );
};

export default WhatIsMahjong;
