import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { BrowserRouter, Route, Routes, } from "react-router-dom";
import "../App.css";
import {
  Top,
  RecruitmentList,
  Recruitment,
  TalkRoom,
  ProfileEdit,
  TalkRoomList,
  Profile,
  RegisterRecruitment,
  RuleDetail,
  RecruitmentEdit,
  PrivacyPolicy,
  Contact,
  Terms,
  YakuList,
  PrivacySetting,
  InitialSetting,
  NotFound,
  Restriction,
  Suspension,
  Matching,
  MatchingRegister,
  MatchingEdit,
  MatchingHistory,
  RuleIntroduction
} from "../page";
import {
  Header,
  Footer,
  InstallButton,
  Sidebar,
} from ".";
import UserContext from "../UserContext";
import { useState, useLayoutEffect, useContext } from "react";
import { styled } from "styled-components";
import { Message } from "./"
import { getUserByCognitoUser, getCognitoUser } from "../my-func/userManager";
import MessageContext from "../MessageContext";
import { CommonTag } from "../common-my-ui-components";

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100);
`;

const MainContainer = styled.div`
  flex: 1;
  overflow: auto;
  overscroll-behavior: contain;
`;

const HeaderContainer = styled.div`
  flex:0 0 auto;
`;

const FooterContainer = styled.div`
  flex:0 0 auto;
`;

function MemberBrowserRouter({ logout, deleteUser }) {
  const { setErrorMessage } = useContext(MessageContext);
  const { user, setUser, cognitoUser, setCognitoUser } = useContext(UserContext);
  const [userExists, setUserExists] = useState(null);
  const [userSuspended, setUserSuspended] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const updateUserExists = (cognitoUser) => {
    getUserByCognitoUser(cognitoUser, setErrorMessage)
      .then((user) => {
        setUser(user);
        if (user && !user._deleted) {
          setUserExists(true);
          setUserSuspended(user.isSuspended);
        } else {
          setUserExists(false);
        }
      })
  }

  // Cognitoからログイン中のユーザ情報の取得
  useLayoutEffect(() => {
    getCognitoUser(setErrorMessage)
      .then((currentUser) => {
        setCognitoUser(currentUser);
        updateUserExists(currentUser);
      })
  }, []);

  // サイドバーの状態を管理
  const onSetSidebarOpen = (open) => {
    setSidebarOpen(open);
  };

  return (
    <BrowserRouter>
      <AppContainer>

        <CommonTag />
        <InstallButton />
        <Sidebar
          isOpen={sidebarOpen}
          onSetSidebarOpen={onSetSidebarOpen}
          logout={logout}
        />

        <HeaderContainer>
          <Header user={user} onMenuClick={() => onSetSidebarOpen(true)} />
        </HeaderContainer>

        <MainContainer>
          <Message />
          <Routes>
            {
              userExists == null ? (
                // ユーザー情報を取り終えるまでは何も表示しない
                <>
                  <Route path="/*" element={<></>} />
                </>
              ) : userSuspended ? (
                // ユーザーが凍結されている
                <>
                  <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                  <Route path="/Contact" element={<Contact />} />
                  <Route path="/Terms" element={<Terms />} />
                  <Route path="/*" element={<Suspension />} />
                </>
              ) : userExists ? (
                // ユーザー情報が登録済み
                <>
                  <Route path="/" element={<Top />} />
                  <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                  <Route path="/Contact" element={<Contact />} />
                  <Route path="/Terms" element={<Terms />} />
                  <Route path="/Profile/:userID" element={<Profile />} />
                  <Route path="/ProfileEdit" element={<ProfileEdit />} />
                  <Route path="/RecruitmentList" element={<RecruitmentList />} />
                  <Route path="/RecruitmentList/:requestNowTab/" element={<RecruitmentList />} />
                  <Route path="/RecruitmentList/:requestNowTab/:resourceName" element={<RecruitmentList />} />
                  <Route path="/Recruitment/:recruitmentId" element={<Recruitment />} />
                  <Route path="/RuleDetail" element={<RuleDetail />} />
                  <Route path="/YakuList" element={<YakuList />} />
                  <Route path="/PrivacySetting" element={<PrivacySetting deleteUser={deleteUser} />} />
                  <Route path="/Matching" element={<Matching />} />
                  <Route path="/RuleIntroduction" element={<RuleIntroduction />} />
                  <Route path="/RuleIntroduction/:pageID" element={<RuleIntroduction />} />
                  
                  {/* 18歳未満は機能制限ページを表示 */}
                  <Route path="/TalkRoomList" element={user.isOver18 ? <TalkRoomList /> : <Restriction />} />
                  <Route path="/TalkRoom/:talkRoomID" element={user.isOver18 ? <TalkRoom /> : <Restriction />} />
                  <Route path="/RegisterRecruitment" element={user.isOver18 ? <RegisterRecruitment /> : <Restriction />} />
                  <Route path="/RecruitmentEdit/:recruitmentId" element={user.isOver18 ? <RecruitmentEdit /> : <Restriction />} />
                  <Route path="/MatchingRegister" element={user.isOver18 ? <MatchingRegister /> : <Restriction />} />
                  <Route path="/MatchingEdit" element={user.isOver18 ? <MatchingEdit /> : <Restriction />} />
                  <Route path="/MatchingHistory" element={user.isOver18 ? <MatchingHistory /> : <Restriction />} />

                  {/* 不正なURLはNotFoundページを表示 */}
                  <Route path="/*" element={<NotFound />} />
                </>
              ) : (
                // ユーザー情報がない場合は初回設定
                <>
                  <Route path="/*" element={<InitialSetting />} />
                </>
              )}
          </Routes>
        </MainContainer>
        <FooterContainer>
          <Footer />
        </FooterContainer>
      </AppContainer>
    </BrowserRouter>
  );
}

export default withAuthenticator(MemberBrowserRouter, false);
