import '@aws-amplify/ui-react/styles.css';
import '../../../App.css';
import { Flex } from '@aws-amplify/ui-react';
import { styled } from 'styled-components';
import { useState, useEffect, useContext } from 'react';
import { deleteMatchingReserve } from '../../../my-func/MatchingManager'
import {
  Button, Dialog, DialogContent, DialogTitle,
  DialogActions, DialogContentText, CircularProgress,
} from '@mui/material';
import MessageContext from "../../../MessageContext";
import UserContext from "../../../UserContext";
import { Link } from "react-router-dom";
import { MatchingReserveInfo } from "."

// ページ全体の設定
const Page = styled.div`
width: 100%;  
`;

const LinkHover = styled.div`
a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
}
`;

const Center = styled.table`
margin: auto; /* サークルを中央に配置 */
`;

const TopText = styled.div`
text-align: left;
padding: 0.5em 1em;
background: ${(props) => (props.theme.palette.quinary.main)};
border-radius: 10px;
border: solid 1px ${(props) => (props.theme.palette.quaternary.main)};
border-top: solid 10px ${(props) => (props.theme.palette.text.green)};
`;

const RegisteredMatching = (props) => {
  // 引数で募集情報を受け取る
  const { matchingReserve, setKeyTime } = props;

  const { setErrorMessage } = useContext(MessageContext);
  const { user } = useContext(UserContext);

  // 処理中の状態を保存する
  const [isLoading, setIsLoading] = useState(false);

  // 登録削除チェック中
  const [isDeleteCheck, setIsDeleteCheck] = useState(false);
  // 登録削除ボタン押下時に登録削除確認のダイアログを開く
  const openDeleteDialog = () => {
    setIsDeleteCheck(true);
  }
  // 登録削除確認ダイアログを閉じる
  const closeDeleteDialog = () => {
    setIsDeleteCheck(false);
  }
  // 登録削除する
  const deleteMatchingReserveHandle = async () => {
    setIsLoading(true);
    deleteMatchingReserve(matchingReserve, setErrorMessage);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setKeyTime(Date.now());
    setIsDeleteCheck(false);
  }

  useEffect(() => {

  }, []);

  return (
    <Page>
      <TopText>現在マッチング検索中です<br />
        条件に合うユーザーが見つかるまでしばらくお待ち下さい<br/>
        3日後以降の希望日がないマッチング登録は自動的に削除されます
      </TopText>
      <MatchingReserveInfo matchingReserve={matchingReserve} />
      <LinkHover><Link to={`/MatchingEdit`}>
        <Button variant="contained" color="quaternary">編集</Button></Link></LinkHover>
      <Flex direction={"row"} alignItems={'right'} justifyContent="right">
        <Button name="delete" onClick={() => openDeleteDialog()}>
          <span className="material-symbols-rounded">
            delete
          </span>
          登録削除
        </Button>
      </Flex>

      {/* 登録削除ダイアログ */}
      <Dialog open={isDeleteCheck} >
        <DialogTitle>登録削除</DialogTitle>
        <DialogContent>
          <DialogContentText>
            この登録を本当に削除しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>キャンセル</Button>
          <Button onClick={deleteMatchingReserveHandle}>削除する</Button>
        </DialogActions>
      </Dialog>

      {/* 更新処理ダイアログ */}
      <Dialog open={isLoading}>
        <DialogTitle>処理中</DialogTitle>
        <DialogContent>
          <Center>
            <CircularProgress />
          </Center>
        </DialogContent>
      </Dialog>
    </Page >
  );
};

export default RegisteredMatching;
