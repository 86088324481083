import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#407332',
    },
    secondary: {
      main: '#409C27',
    },
    tertiary: {
      main: '#82B870',
    },
    quaternary: {
      main: '#BCDBAE',
    },
    quinary: {
      main: '#E7EFE4',
    },
    primaryred: {
      main: '#E60000',
    },
    secondaryred: {
      main: '#FF2D2D',
    },
    tertiaryred: {
      main: '#FF7E7D',
    },
    quaternaryred: {
      main: '#FFADB0',
    },
    quinaryred: {
      main: '#FEE0E7',
    },
    primaryblue: {
      main: '#0055aa',
    },
    secondaryblue: {
      main: '#0080ff',
    },
    tertiaryblue: {
      main: '#55aaff',
    },
    quaternaryblue: {
      main: '#aad5ff',
    },
    quinaryblue: {
      main: '#d5eaff',
    },
    disabled: {
      main: '#949494',
    },
    background: {
      default: '#F5F5F5',
      paper: '#F5F5F5',
      white: '#fff',
    },
    text: {
      white: '#fff',
      black: '#000',
      green: '#407332',
      link: '#0d6efd',
    },
  },
  typography: {
    fontFamily: [
      "Noto Sans JP",

    ].join(","),
    fontSize: 16, 
  },
});

export default theme;
