import React, { useState, useEffect } from "react"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import DialogContentText from "@mui/material/DialogContentText"
import styled from "styled-components"
import { requestPermission } from "../my-func/pushNotification"
import { getCurrentToken } from "../my-func/pushNotification"
import { notificationTokenMSByUsermID } from "../graphql/queries"
import { API } from "aws-amplify"
import Switch from "@mui/material/Switch"
import { updateNotificationTokenM } from "../graphql/mutations"
import { getToken, isSupported } from "firebase/messaging"

const StyledDialogContentText = styled(DialogContentText)`
  white-space: pre-line;
`

function SidebarNotificationSetting({ isOpen, onClose, user }) {
  const [isNotificationActive, setIsNotificationActive] = useState(false)
  const [notificationDialog, setNotificationDialog] = useState("")
  const [notificationToken, setNotificationToken] = useState()

  const handleToggleChange = async (event) => {
    const newActiveState = event.target.checked
    setIsNotificationActive(newActiveState)

    try {
      const data = {
        input: {
          id: notificationToken.id,
          enabled: newActiveState,
          _version: notificationToken._version
        }
      }
      const newNotificationToken = await API.graphql({
        query: updateNotificationTokenM,
        variables: data
      })
      setNotificationToken(newNotificationToken.data.updateNotificationTokenM)
    } catch (error) {
    }
  }

  const fetchNotificationToken = async (currentToken) => {
    const variables = {
      usermID: user.id,
      filter: {
        token: { eq: currentToken }
      }
    }
    try {
      const result = await API.graphql({
        query: notificationTokenMSByUsermID,
        variables: variables
      })

      const items = result.data.notificationTokenMSByUsermID.items
      if (items.length > 0) {
        setNotificationToken(items[0]) // ステートに設定
        setIsNotificationActive(items[0].enabled)
      } else {

      }
    } catch (error) {

    }
  }

  // 通知設定のロジック
  useEffect(() => {
    if (isOpen) {
      if ("Notification" in window) {
        if (Notification.permission === "granted") {
          setNotificationDialog("")

          getCurrentToken().then((currentToken) => {
            fetchNotificationToken(currentToken)
          })
        } else if (Notification.permission === "denied") {
          setNotificationDialog(`通知がブロックされています。
              通知を受け取るには通知の許可が必要です。
              ご利用端末の「設定」から通知を許可してください。`)
        } else {
          setNotificationDialog(`通知を受け取りたい場合は通知を許可してください`)
          requestPermission(user.id)
        }
      }
    }
  }, [isOpen, user])

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="notification-dialog-title" aria-describedby="notification-dialog-description">
      <DialogTitle id="notification-dialog-title">通知設定</DialogTitle>

      <DialogContent dividers>
        <StyledDialogContentText id="notification-dialog-description">{notificationDialog}</StyledDialogContentText>
        {"Notification" in window && Notification.permission === "granted" ? (
          <div>
            <Switch checked={isNotificationActive} onChange={handleToggleChange} />
            通知を有効にする
          </div>
        ) : (
          <>
            <p>このブラウザでは通知非対応です。</p> <p>通知を受け取りたい場合はホーム画面に追加してください。</p>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SidebarNotificationSetting
