import '@aws-amplify/ui-react/styles.css';
import '../../App.css';
import { ProfileForm } from './my-ui-components';

const ProfileEdit = ({ onFormSubmit, isAgreeTerms, isOver18 }) => {

  return (
    <>
      <ProfileForm
        onFormSubmit={onFormSubmit}
        isAgreeTerms={isAgreeTerms}
        isOver18={isOver18} />
    </>
  );
};

export default ProfileEdit;