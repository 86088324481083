import UserIcon from "./UserIcon";
import styled from 'styled-components';
import { Box } from '@mui/material';

const IconContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
// ユーザアイコンを横方向に表示するコンポーネント
const UserIconListCol = ({ userMs }) => {
    return userMs.map(userM => (
        <IconContainer className="m-1" key={userM.id}>
            <UserIcon userM={userM} />
        </IconContainer>
    ));
}

export default UserIconListCol;