import styled, { css } from "styled-components";
import { limitString } from "../../../my-func/strUtils";
import { Grid, View } from "@aws-amplify/ui-react"

const TalkRoomContainer = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  height: 4rem;
`;

const FrontContainer = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  height: 4rem;
  overflow: hidden;
`;

const LimitText = styled.div`
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
`;

const BackContainer = styled(View)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: end;
  height: 4rem;
`;

const StyledText = styled.div`
  font-weight: 400;
  color: rgba(16, 24, 40, 1);
  line-height: 24px;
  text-align: left;
`;

const TalkRoomTitle = styled(StyledText)`
  font-size: 0.8rem;
`;

const TalkRoomContent = styled(StyledText)`
  font-size: 0.6rem;
  color: ${(props) => props.theme.palette.disabled.main};
`;

const MessageTimestamp = styled(StyledText)`
  font-size:  0.55rem;
  color: ${(props) => props.theme.palette.disabled.main};
`;

const UnreadMessageCount = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.primary.main};
  visibility: ${(props) => (props.count === 0 ? "hidden" : "visible")};
`;

const UnreadMessageText = styled(StyledText)`
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 1);
  text-align: center;
`;

export default function TalkRoomInfo({
  talkRoomM,
  messageM,
  countNewMessage,
  ...rest
}) {
  return (
    <TalkRoomContainer {...rest}>
      <Grid templateColumns={{ base: '8fr 2fr', large: "8fr 2fr" }}  >
        <FrontContainer rowSpan={1}>
          {talkRoomM.isDirect ? (
            <span className="material-symbols-rounded" >person</span>
          ) : (
            <span className="material-symbols-rounded">groups</span>
          )}
          <LimitText>
            <TalkRoomTitle><LimitText>{talkRoomM?.roomName}</LimitText></TalkRoomTitle>
            <TalkRoomContent><LimitText>{messageM?.content}</LimitText></TalkRoomContent>
          </LimitText>
        </FrontContainer>

        <BackContainer rowSpan={1}>
        <MessageTimestamp>{messageM?.createdAt}</MessageTimestamp>
          <UnreadMessageCount count={countNewMessage}>
            <UnreadMessageText>{countNewMessage}</UnreadMessageText>
          </UnreadMessageCount>

        </BackContainer>
      </Grid>
    </TalkRoomContainer>
  );
}
