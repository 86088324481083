import React from "react";
import styled from "styled-components";
import {
  UserIcon,
  UserIconImage,
  UserIconDefault,
} from "../../../common-my-ui-components";
import { Box } from "@mui/material";

const FlexRow = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled.div`
  padding: 0%;
  position: relative;
`;

const EditIcon = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  padding: 0.2rem;
  cursor: pointer;
`;

const DeleteIcon = styled(EditIcon)`
  position: relative;
  margin-top: auto;
  margin-left: 0.2rem;
`;

const renderIconChange = (iconComponent, onClick) => (
  <>
    <span className="material-symbols-rounded" style={{ fontSize: "2rem" }}>
      arrow_right
    </span>
    <IconWrapper>
      {iconComponent}
      <EditIcon className="material-symbols-rounded" onClick={onClick}>
        close
      </EditIcon>
    </IconWrapper>
  </>
);

const ProfileIcon = ({
  loginUser,
  imagePreview,
  onIconClick,
  cancelIconChange,
  deleteIcon,
  iconDelete,
}) => (
  <FlexRow>
    <IconWrapper>
      <UserIcon userM={loginUser} size="6rem" onClick={onIconClick} />
      <EditIcon className="material-symbols-rounded" onClick={onIconClick}>
        edit
      </EditIcon>
    </IconWrapper>
    {loginUser?.iconSrc && !iconDelete && !imagePreview && (
      <DeleteIcon className="material-symbols-rounded" onClick={deleteIcon}>
        delete
      </DeleteIcon>
    )}
    {imagePreview &&
      renderIconChange(
        <UserIconImage
          userM={{ ...loginUser, iconSrc: imagePreview }}
          size="6rem"
        />,
        (e) => cancelIconChange(e)
      )}
    {iconDelete &&
      renderIconChange(
        <UserIconDefault iconText={loginUser.name[0]} size="6rem" />,
        (e) => cancelIconChange(e)
      )}
  </FlexRow>
);

export default ProfileIcon;
